import {FC, useCallback} from "react";

import {useI18n} from "common/hooks/useI18n";

import {Button, Dialog} from "@bolteu/kalep-react";

interface DialogProps {
    isOpen: boolean;
    setIsOpen: (value: boolean) => void;
    confirmDialog: () => void;
}

const DisableCashRidesDialog: FC<DialogProps> = ({isOpen, setIsOpen, confirmDialog}) => {
    const {i18n} = useI18n();
    const closeDialog = useCallback(() => setIsOpen(false), [setIsOpen]);

    return (
        <Dialog
            isOpen={isOpen}
            onRequestClose={closeDialog}
            title={i18n("auth.app.fleet.driver-profile.cash_rides_dialog.title_disable")}
        >
            <Dialog.Content>{i18n("auth.app.fleet.driver-profile.cash_rides_dialog.content_disable")}</Dialog.Content>
            <Dialog.Footer>
                <Button onClick={confirmDialog}>{i18n("common.disable")}</Button>
                <Button variant="secondary" onClick={closeDialog}>
                    {i18n("common.cancel")}
                </Button>
            </Dialog.Footer>
        </Dialog>
    );
};

export default DisableCashRidesDialog;
