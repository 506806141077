import React from "react";

import TopLeftCornerNotification from "../components/TopLeftCornerNotification";

export enum NotificationType {
    SUCCESS = "success",
    ERROR = "error",
}

export interface Notification {
    type: NotificationType;
    text: React.ReactNode;
    timeout?: number;
}

export interface NotificationContextValue {
    notification: Notification | null;
    setNotification: React.Dispatch<React.SetStateAction<Notification | null>>;
}

export const NotificationContext = React.createContext<NotificationContextValue>({
    notification: null,
    setNotification: () => {},
});
NotificationContext.displayName = "NotificationContext";

export const NotificationProvider = ({children}: {children: React.ReactNode}) => {
    const [notification, setNotification] = React.useState<Notification | null>(null);

    return (
        <NotificationContext.Provider value={{notification, setNotification}}>
            <TopLeftCornerNotification notification={notification} setNotification={setNotification} />
            {children}
        </NotificationContext.Provider>
    );
};
