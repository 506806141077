import {FC} from "react";

import LoadingSpinner from "common/components/spinner";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import StatCard from "./StatCard";

interface CardRowProps {
    cards: FleetOwnerPortalService.StatCard[];
    isLoading: boolean;
}

const CardRow: FC<CardRowProps> = ({cards, isLoading}) => {
    if (isLoading) {
        return <LoadingSpinner show />;
    }

    return (
        <div className="flex gap-4 overflow-auto sm:flex-wrap">
            {cards.map((card) => (
                <StatCard
                    title={card.title}
                    value={card.value}
                    postfix={card.postfix}
                    subtext={card.subtext}
                    tooltip={card.tooltip}
                />
            ))}
        </div>
    );
};

export default CardRow;
