import {useI18n} from "common/hooks/useI18n";

import {Switch, Typography} from "@bolteu/kalep-react";
import {Route} from "@bolteu/kalep-react-icons";

interface ShowDriverRoutesProps {
    isShowDriverRoutesChecked: boolean;
    isShowDriverRoutesEnabled: boolean;
    onToggle: () => void;
}

function ShowDriverRoutes({isShowDriverRoutesChecked, isShowDriverRoutesEnabled, onToggle}: ShowDriverRoutesProps) {
    const {i18n} = useI18n();

    if (!isShowDriverRoutesEnabled) {
        return null;
    }

    return (
        <div className="flex gap-3 rounded-lg bg-white px-4 py-5 shadow">
            <div className="flex items-center px-1">
                <Route />
            </div>
            <div className="flex w-full flex-col gap-1">
                <Typography variant="body-primary">
                    {i18n("auth.app.fleet.live-map.driver-routes.view-driver-route")}
                </Typography>
                <Typography variant="label-primary" color="secondary">
                    {i18n("auth.app.fleet.live-map.driver-routes.view-driver-route-subtext")}
                </Typography>
            </div>
            <div className="flex items-center">
                <Switch onChange={onToggle} checked={isShowDriverRoutesChecked} />
            </div>
        </div>
    );
}

export default ShowDriverRoutes;
