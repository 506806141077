import {useCallback} from "react";

import {useSnackbar} from "@bolteu/kalep-react";

import {useI18n} from "./useI18n";

export const useCopyToClipboard = () => {
    const {i18n} = useI18n();
    const snackbar = useSnackbar();

    const copyToClipboard = useCallback(
        (text: string) => {
            navigator.clipboard.writeText(text);
            snackbar.add({description: i18n("common.copied_to_clipboard")}, {timeout: 2000});
        },
        [snackbar, i18n],
    );

    return {copyToClipboard};
};
