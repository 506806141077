import {createContext, useCallback, useContext, useState} from "react";
import {useNavigate} from "react-router-dom";

import {EventName} from "common/constants/events";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";

import {CarApplicationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {NavigationBarVisbilityContextProvider} from "../../../../../NavigationBarVisibilityProvider";
import AddVehiclePage from "../../add-vehicle/components/AddVehiclePage";

export interface AddVehiclePageProviderValue {
    isAddVehiclePageOpen: boolean;
    openAddVehiclePage: () => void;
}

const AddVehiclePageContextProvider = createContext<AddVehiclePageProviderValue>({
    isAddVehiclePageOpen: false,
    openAddVehiclePage: () => {},
});

AddVehiclePageContextProvider.displayName = "AddVehiclePageContextProvider";

interface AddVehiclePageProviderProps {
    children: React.ReactNode;
    onVehicleAdded?: () => Promise<void>;
}

const AddVehiclePageProvider = ({children, onVehicleAdded}: AddVehiclePageProviderProps) => {
    const {trackEvent} = useTracking();
    const {setIsNavigationBarVisible} = useContext(NavigationBarVisbilityContextProvider);
    const [isAddVehiclePageOpen, setIsAddVehiclePageOpen] = useState(false);

    const {i18n} = useI18n();
    const navigate = useNavigate();
    const {getVehicleApplicationPath} = useAbsolutePath();

    const openAddVehiclePage = useCallback(() => {
        trackEvent(EventName.AddNewVehicleButtonClicked);
        setIsAddVehiclePageOpen(true);
        setIsNavigationBarVisible(false);
    }, [setIsNavigationBarVisible, trackEvent]);

    const closeAddVehiclePage = useCallback(() => {
        setIsAddVehiclePageOpen(false);
        setIsNavigationBarVisible(true);
    }, [setIsNavigationBarVisible]);

    const onVehicleAdd = useCallback(
        async (carApplication: CarApplicationService.CarApplicationModel | null) => {
            setIsAddVehiclePageOpen(false);
            setIsNavigationBarVisible(true);
            if (onVehicleAdded) {
                await onVehicleAdded();
            }
            if (carApplication?.id) {
                const path = getVehicleApplicationPath(carApplication.id);
                navigate(path);
            }
        },
        [setIsNavigationBarVisible, onVehicleAdded, getVehicleApplicationPath, navigate],
    );

    const contextValue: AddVehiclePageProviderValue = {
        isAddVehiclePageOpen,
        openAddVehiclePage,
    };

    return (
        <AddVehiclePageContextProvider.Provider value={contextValue}>
            {isAddVehiclePageOpen && (
                <AddVehiclePage
                    closePage={closeAddVehiclePage}
                    onVehicleAdded={onVehicleAdd}
                    submitButtonLabel={i18n("auth.app.fleet.add-vehicle.add_vehicle")}
                />
            )}
            {children}
        </AddVehiclePageContextProvider.Provider>
    );
};

export {AddVehiclePageProvider, AddVehiclePageContextProvider};
