import {useCallback} from "react";
import {createSearchParams, To} from "react-router-dom";

import {CommonRoute} from "common/constants/routes";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";

export const useVerificationNavigations = () => {
    const {
        getVerificationConfirmationPath,
        getCompanyRegistrationPath,
        getCompanyOnboardingPath,
        getVerification2faPath,
        getVerification2faConfirmationPath,
        getVerificationPath,
    } = useAbsolutePath();

    const getContinueToAddCompanyNavigationUrl = useCallback((registrationHash?: string): string => {
        const addCompanyUrl = `${window.origin}/v2${CommonRoute.ADD_COMPANY}`;
        if (!registrationHash) {
            return addCompanyUrl;
        }
        const search = {
            reuse_registration_hash: registrationHash,
        };

        return `${addCompanyUrl}?${createSearchParams(search).toString()}`;
    }, []);

    const getConfirmationNavigationTo = useCallback(
        (username: string, attemptKey: string): To => {
            return {
                pathname: getVerificationConfirmationPath(attemptKey),
                search: createSearchParams({username}).toString(),
            };
        },
        [getVerificationConfirmationPath],
    );

    const getVerificationTo = useCallback(
        (attemptKey: string): To => {
            return {
                pathname: getVerificationPath(attemptKey),
            };
        },
        [getVerificationPath],
    );

    const get2faNavigationTo = useCallback(
        (attemptKey: string): To => {
            return {
                pathname: getVerification2faPath(attemptKey),
            };
        },
        [getVerification2faPath],
    );

    const get2faConfirmationNavigationTo = useCallback(
        (attemptKey: string): To => {
            return {
                pathname: getVerification2faConfirmationPath(attemptKey),
            };
        },
        [getVerification2faConfirmationPath],
    );

    const getContinueToCompanRegistrationStepsNavigationUrl = useCallback(
        (registrationHash: string): string => {
            return `${window.origin}/v2${getCompanyRegistrationPath(registrationHash)}`;
        },
        [getCompanyRegistrationPath],
    );

    const getContinueToCompanRegistrationOnboardingNavigationUrl = useCallback(
        (companyId: number): string => {
            return `${window.origin}/v2${getCompanyOnboardingPath(companyId.toString())}`;
        },
        [getCompanyOnboardingPath],
    );

    return {
        getContinueToAddCompanyNavigationUrl,
        getConfirmationNavigationTo,
        getContinueToCompanRegistrationStepsNavigationUrl,
        getContinueToCompanRegistrationOnboardingNavigationUrl,
        get2faNavigationTo,
        get2faConfirmationNavigationTo,
        getVerificationTo,
    };
};
