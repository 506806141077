import {useCallback, useContext, useEffect} from "react";

import {DocumentsAlertProps} from "common/components/document/DocumentsAlert";
import {DocumentsListProps} from "common/components/document/DocumentsList";
import {useEntityDocumentFunctions} from "common/components/document/entity/useEntityDocumentFunctions";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {apiTimestampToDate} from "common/util/apiTimestampToDate";
import {hasExpired} from "common/util/dateExpiryUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {sortDocumentSet} from "./documentOrder";
import DocumentStatus = FleetOwnerPortalService.DocumentStatus;

export interface Document {
    id: number;
    expires: number | null;
    status: DocumentStatus;
}

export interface DocumentType {
    id: number;
    name: string;
    title: string;
    description: string;
    is_expiry_required: boolean;
    is_required: boolean;
}

export interface DocumentData {
    document: Document | null;
    type: DocumentType;
}

export enum DocumentOwnerType {
    Vehicle = "vehicle",
    VehicleApplication = "vehicleApplication",
    Driver = "driver",
    Company = "company",
}

export interface DocumentOwner {
    id: number;
    type: DocumentOwnerType;
}

interface Props {
    areDocumentsEnabled: boolean;
    owner: DocumentOwner;
    isUploadAllowed: boolean;
    onDocumentUpload?: () => void;
}

interface Result {
    documentsListProps: DocumentsListProps | null;
    documentsAlertsProps: DocumentsAlertProps | null;
}

const documentsListDisabledResult: Result = {
    documentsListProps: null,
    documentsAlertsProps: null,
};

export const useDocumentsList = ({areDocumentsEnabled, isUploadAllowed, owner, onDocumentUpload}: Props): Result => {
    const {documentsFetchFunction} = useEntityDocumentFunctions(owner);
    const {data: apiDocuments, fetch, status} = useFetch(documentsFetchFunction);
    const fleetId = useContext(AccountContextProvider)?.getFleet()?.id;

    const getDocumentSet = useCallback(() => {
        if (fetch && fleetId && areDocumentsEnabled) {
            fetch({});
        }
    }, [fetch, fleetId, areDocumentsEnabled]);

    useEffect(() => {
        getDocumentSet();
    }, [getDocumentSet]);

    const onDocumentUploadCallback = useCallback(async () => {
        if (fetch && fleetId && areDocumentsEnabled) {
            await fetch({});
        }
        if (onDocumentUpload) {
            onDocumentUpload();
        }
    }, [fetch, fleetId, areDocumentsEnabled, onDocumentUpload]);

    if (!areDocumentsEnabled) {
        return documentsListDisabledResult;
    }

    if (status !== FetchStatus.Success) {
        return {
            ...documentsListDisabledResult,
            documentsListProps: {
                owner,
                shouldShowDocumentsLoading: true,
                isUploadAllowed,
                documents: [],
                onDocumentUpload: onDocumentUploadCallback,
            },
        };
    }

    const documents = [...apiDocuments].sort(sortDocumentSet);
    const hasListDeclinedDocuments = documents?.some(
        ({document}) => document?.status === FleetOwnerPortalService.DocumentStatus.DECLINED,
    );
    const hasListExpiredDocuments = documents?.some(
        ({document}) => document?.expires && hasExpired(apiTimestampToDate(document.expires)),
    );

    const shouldShowDocumentsAlert = hasListDeclinedDocuments || hasListExpiredDocuments;

    return {
        documentsListProps: {
            owner,
            shouldShowDocumentsLoading: status !== FetchStatus.Success,
            isUploadAllowed,
            documents,
            onDocumentUpload: onDocumentUploadCallback,
        },
        documentsAlertsProps: shouldShowDocumentsAlert
            ? {
                  expiredDocuments: hasListExpiredDocuments,
                  declinedDocuments: hasListDeclinedDocuments,
              }
            : null,
    };
};
