import {FC} from "react";

import {useI18n} from "common/hooks/useI18n";

import {Island} from "@bolteu/kalep-react/build/components/Island";
import {InfoCircle} from "@bolteu/kalep-react-icons";

const InformationBlock: FC = () => {
    const {i18n} = useI18n();

    return (
        <div className="mt-4">
            <Island>
                <div className="flex flex-row gap-4 text-sm">
                    <InfoCircle className="text-content-primary shrink-0" />
                    <div>
                        <div className="mb-3 text-sm font-semibold">
                            {i18n("auth.app.fleet.balance.taxable_revenue_info_title")}
                        </div>
                        <ul className="ml-5 list-disc text-neutral-700">
                            <li>{i18n("auth.app.fleet.balance.transactions_list.earnings.earning_in_app.title")}</li>
                            <li>
                                {i18n(
                                    "auth.app.fleet.balance.transactions_list.earnings.compensated_cash_discount.title",
                                )}
                            </li>
                            <li>{i18n("auth.app.fleet.balance.transactions_list.earnings.cash_payment.title")}</li>
                        </ul>
                    </div>
                </div>
            </Island>
        </div>
    );
};

export default InformationBlock;
