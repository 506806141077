import {useCallback, useEffect} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

interface Props {
    onCaptchaSubmit: (captchaToken: string | null) => void;
}

const CaptchaV3 = ({onCaptchaSubmit}: Props) => {
    const {executeRecaptcha} = useGoogleReCaptcha();

    const handleReCaptchaSolved = useCallback(async () => {
        if (!executeRecaptcha) {
            return;
        }

        try {
            const captchaToken = await executeRecaptcha();
            onCaptchaSubmit(captchaToken);
        } catch (e) {
            onCaptchaSubmit(null);
        }
    }, [executeRecaptcha, onCaptchaSubmit]);

    // Trigger recaptcha with small timeout to avoid an error script loading
    useEffect(() => {
        const timeout = setTimeout(() => handleReCaptchaSolved(), 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [handleReCaptchaSolved]);

    return null;
};

export default CaptchaV3;
