import {MimeTypes} from "common/constants/mimeTypes";

export function downloadBase64File(fileStr: string, mimeType: MimeTypes, fileName: string): void {
    const linkSource = `data:${mimeType};base64,${fileStr}`;
    downloadFile(linkSource, fileName);
}

export function downloadUtf8File(fileStr: string, mimeType: MimeTypes, fileName: string): void {
    const linkSource = `data:${mimeType};charset=utf-8,${fileStr}`;
    downloadFile(linkSource, fileName);
}

export function downloadFile(linkSource: string, fileName: string): void {
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

export function cleanStringForFileName(str: string) {
    return str.replace(/[-./\\:*?"<>|]/g, "_").trim();
}
