import {Company, CompanyType, isFleet, isHoldingFleet} from "common/constants/types";

import {FleetOwnerService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {ListItemLayout} from "@bolteu/kalep-react";

import {useCompanySelector} from "./hooks/useCompanySelector";

interface Props {
    company: Company;
    onSelectCompany: (company: Company, type: CompanyType) => () => void;
    withDivider: boolean;
}

const DropdownListItem = ({company, onSelectCompany, withDivider}: Props) => {
    const {getStatusChip, getDescription, getCompanyName} = useCompanySelector();

    const description = getDescription(company, true);
    const selectedCompanyName = getCompanyName(company);
    const isCompanyHoldingFleet = isHoldingFleet(company);
    const isCompanyFleet = isFleet(company);

    let renderStatusChip;
    if (isCompanyFleet) {
        if (company.status !== FleetOwnerService.ExpandedCompanyStatus.ACTIVE) {
            renderStatusChip = () => getStatusChip(company.status);
        }
    }

    return (
        <ListItemLayout
            key={company.id}
            onClick={onSelectCompany(
                company,
                isCompanyHoldingFleet ? CompanyType.HOLDING_FLEET : CompanyType.FLEET_COMPANY,
            )}
            primary={selectedCompanyName}
            secondary={description}
            renderEndSlot={renderStatusChip}
            separator={withDivider}
        />
    );
};

export {DropdownListItem};
