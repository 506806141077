import {FixedHeaderTable} from "common/components/styledComponent/FixedHeaderTable";

import {CarApplicationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {useTableColumns} from "../hooks/useVehicleApplicationsTableColumns";

interface ApplicationsTabProps {
    applications: CarApplicationService.CarApplicationListModel[];
}

const Applications = ({applications}: ApplicationsTabProps) => {
    const columns = useTableColumns();

    return <FixedHeaderTable columns={columns} items={applications} aria-label="Vehicle applications table" />;
};

export default Applications;
