import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import ApiColumn = FleetOwnerPortalService.ApiColumn;
import StateColumn = FleetOwnerPortalService.StateColumn;
import ReferenceColumn = FleetOwnerPortalService.ReferenceColumn;
import StatusColumn = FleetOwnerPortalService.StatusColumn;

export interface CellProps {
    valueAtIdx: number;
    column: ApiColumn;
    alignVertical?: AlignVertical;
    alignHorizontal?: AlignHorizontal;
}

export enum AlignHorizontal {
    RIGHT = "right",
    LEFT = "left",
}

export enum AlignVertical {
    TOP = "top",
    MIDDLE = "middle",
}

export interface UseableColumns {
    subColumns: ApiColumn[];
    hiddenColumnTitleKeys: string[];
    stateColumns?: StateColumn[];
    customizableColumns: ApiColumn[];
    referenceColumns?: ReferenceColumn[];
    userDefinedVisibleColumns: ApiColumn[];
    statusColumn?: StatusColumn;
}

export interface ApiDrivenTableTableProps extends UseableColumns {
    numberOfRecords: number;
    alignVertical?: AlignVertical;
    alignHorizontal?: AlignHorizontal;
    emptyStateComponent?: React.ReactNode;
    transforms?: ApiDrivenTableTransforms;
    inlineActions?: ApiDriverTableInlineAction[];
}

export interface Transform {
    renderCellOnMobile: (props: CellProps) => JSX.Element;
    renderCellOnDesktop: (props: CellProps) => JSX.Element;
    renderCellInAccordionTitle?: (props: CellProps) => JSX.Element;
}

export type ApiDrivenTableTransforms = Record<string, Transform>;

export interface ApiDriverTableInlineAction {
    render: (columnIndex: number) => React.ReactNode;
}
