import {FC} from "react";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import styles from "../style.module.css";

export const Avatar: FC<{
    picture: string;
    driverState: FleetOwnerPortalService.DriverState;
}> = (props) => {
    const getIconClass = (state: FleetOwnerPortalService.DriverState) => {
        switch (state) {
            case FleetOwnerPortalService.DriverState.HAS_ORDER:
                return "bg-promo-primary";
            case FleetOwnerPortalService.DriverState.WAITING_FOR_ORDERS:
                return "bg-action-primary";
            default:
                return "";
        }
    };
    return (
        <div className="relative my-auto inline-block shrink-0">
            <img src={props.picture} alt="avatar" className="h-12 w-12 rounded-full" />
            <span className={`${styles["avatar-status"]} ${getIconClass(props.driverState)}`} />
        </div>
    );
};
