import {FC} from "react";
import {Chart} from "react-chartjs-2";

import {
    type ChartOptions,
    BarController,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    ChartDataset,
    Legend,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    Title,
    Tooltip,
} from "chart.js";
import Header, {HeaderSize} from "common/components/header/Header";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Island} from "@bolteu/kalep-react";

import ChartData = FleetOwnerPortalService.Chart;
import DataPointType = FleetOwnerPortalService.DataPointType;

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    BarController,
    LineController,
    Title,
    Tooltip,
    Legend,
);

interface DashboardChartsProps {
    charts: {
        earnings: ChartData;
        activity: ChartData;
        utilization?: ChartData;
    };
}

const colorsMain = [
    "#5B68F6", // Main line color
    "#32BB78", // Main positive bar color
    "#EB4755", // Main negative bar color
];

const colorsSecondary = [
    "#B8BEFF", // Secondary line color
    "#A1F7CD", // Secondary positive bar color
    "#FCC5C9", // Secondary negative bar color
];

const transformChartDataWithStyles = (chartData: ChartData) => {
    const {pivot, data_points: dataPoints} = chartData;

    const labels = pivot.keys.slice(Math.ceil(pivot.keys.length / 2)); // Pick the last 7 days
    const datasets = dataPoints.map((dataset) => {
        const isBar = dataset.type === "discrete";
        const isLine = dataset.type === "connected";
        const isSecondary = dataset.snap_id;

        let backgroundColor;
        if (isBar) {
            if (!isSecondary) {
                backgroundColor = dataset.name.includes("expenses") ? colorsMain[2] : colorsMain[1];
            } else {
                backgroundColor = dataset.name.includes("expenses") ? colorsSecondary[2] : colorsSecondary[1];
            }
        }

        let borderColor;
        if (isLine) {
            borderColor = dataset.snap_id ? colorsSecondary[0] : colorsMain[0];
        }

        const datasetOptions: ChartDataset<"bar" | "line", number[]> = {
            pointStyle: false,
            type: isBar ? "bar" : "line",
            yAxisID: isBar ? "y1" : "y2",
            tension: isLine ? 0.3 : undefined,
            borderRadius: isBar ? 2 : undefined,
            barThickness: isBar ? 5 : undefined,
            borderWidth: isLine ? 3 : undefined,
            borderColor,
            backgroundColor,
            label: `${dataset.name}${dataset.unit ? ` (${dataset.unit})` : ""}`,
            data: pivot.keys.map((k) => dataset.key_to_values[k]).filter((value) => value !== undefined),
        };

        return datasetOptions;
    });

    return {
        labels,
        datasets,
    };
};

const ChartContainer: FC<{title: string; description?: string; chartData: ChartData}> = ({
    title,
    description,
    chartData,
}) => {
    const {labels, datasets} = transformChartDataWithStyles(chartData);

    const mainUnit =
        chartData.data_points.find(
            (dataset) => dataset.snap_id === undefined && dataset.type === DataPointType.DISCRETE,
        )?.unit || "";
    const secondaryUnit =
        chartData.data_points.find((dataset) => dataset.snap_id && dataset.type === DataPointType.CONNECTED)?.unit ||
        "";

    const options: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {position: "bottom"},
            tooltip: {mode: "index", intersect: false},
        },
        scales: {
            x: {
                offset: false,
            },
            y1: {
                position: "left",
                beginAtZero: true,
                ticks: {
                    callback(val) {
                        return `${val} ${mainUnit}`;
                    },
                },
            },
            y2: {
                position: "right",
                beginAtZero: true,
                ticks: {
                    callback(val) {
                        return `${val} ${secondaryUnit}`;
                    },
                },
                // min: 0,
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };

    const data = {labels, datasets};

    return (
        <Island>
            <Header size={HeaderSize.ExtraSmall} text={title} />
            {description && <p className="mt-2 text-sm text-gray-500">{description}</p>}
            <div className="mt-6 h-[500px]">
                <Chart type="bar" options={options} data={data} />
            </div>
        </Island>
    );
};

const DashboardCharts: FC<DashboardChartsProps> = ({charts}) => {
    return (
        <div className="flex-col space-y-6">
            {/* Earnings Chart */}
            <div className="flex-row gap-4">
                <ChartContainer
                    title={charts.earnings.name}
                    description={charts.earnings.description}
                    chartData={charts.earnings}
                />
            </div>

            {/* Activity and Utilization Charts */}
            <div className="flex flex-row flex-wrap justify-between gap-4">
                <div className="w-full flex-1 lg:w-0">
                    <ChartContainer
                        title={charts.activity.name}
                        description={charts.activity.description}
                        chartData={charts.activity}
                    />
                </div>
                <div className="w-full flex-1 lg:w-0">
                    {charts.utilization && (
                        <ChartContainer
                            title={charts.utilization.name}
                            description={charts.utilization.description}
                            chartData={charts.utilization}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default DashboardCharts;
