import {FC, ReactNode, useEffect} from "react";
import {useParams} from "react-router-dom";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import LoadingSpinner from "common/components/spinner";
import {getDriverName} from "common/constants/driverName";
import {FetchStatus} from "common/hooks/useFetch";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {Breadcrumb} from "@bolteu/kalep-react";

import {useBreadcrumbs} from "../../common/hooks/useBreadcrumbs";
import {useDriverRegistration} from "../hooks/useDriverRegistration";
import DriverRegistrationBody from "./DriverRegistrationBody";

const DriverRegistration: FC = () => {
    const params = useParams();
    const breadcrumbs = useBreadcrumbs();
    const driverRegistration = useDriverRegistration(Number(params.id));

    const driverName = getDriverName(
        driverRegistration?.data?.first_name,
        driverRegistration?.data?.last_name,
        driverRegistration?.data?.email,
    );

    useEffect(() => {
        if (driverRegistration.status === FetchStatus.Success) {
            document.title = getDocumentTitle(driverName);
        }
    }, [driverName, driverRegistration]);

    let Body: ReactNode = null;
    switch (driverRegistration.status) {
        case FetchStatus.Init:
        case FetchStatus.Loading:
            Body = <LoadingSpinner show showLoadingText parentClassName="mt-9" />;
            break;
        case FetchStatus.Error:
            Body = <ErrorView type={ErrorViewType.NotFound} />;
            break;
        case FetchStatus.Success:
            Body = <DriverRegistrationBody data={driverRegistration.data} />;
            break;
        default:
            UnreachableCode.never(driverRegistration);
    }

    return (
        <div className="container mx-auto flex h-full w-full flex-col px-6 py-8">
            <Breadcrumb items={breadcrumbs} />
            {Body}
        </div>
    );
};

export default DriverRegistration;
