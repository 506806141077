import {useContext} from "react";

import {TOPBAR_HEIGHT_TAILWIND_CONFIG, TopBarTailwindClassNameConfig} from "common/styles";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {NavigationBarVisbilityContextProvider} from "../../NavigationBarVisibilityProvider";

export const useTopBarHeightTailwindConfig = (): TopBarTailwindClassNameConfig => {
    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    const {isNavigationBarVisible} = useContext(NavigationBarVisbilityContextProvider);
    if (!isNavigationBarVisible) {
        return TOPBAR_HEIGHT_TAILWIND_CONFIG.height0;
    }

    return isMobileView ? TOPBAR_HEIGHT_TAILWIND_CONFIG.height16 : TOPBAR_HEIGHT_TAILWIND_CONFIG.height14;
};
