import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, Period} from "common/services/period";
import {getIsoDate} from "common/util/FormatUtil";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {getDecimalSeparatorFromLocale} from "../../../../../common/services/decimalSeparator";

export function useDriverOrderHistoryDownloadApi(
    driverId: number,
    period: ApiPeriod,
): (api: Api) => Promise<FleetOwnerReportingService.GetOrdersHistoryCsvResponse> {
    const dates =
        period.period === Period.CUSTOM ? {start: period.startDate, end: period.endDate} : apiPeriodToDates(period);

    const fetchDriverOrderHistory = (api: Api) =>
        api.fleetOwnerReporting.getOrdersHistoryCsv({
            from_date: getIsoDate(dates.start),
            to_date: getIsoDate(dates.end),
            decimal_separator: getDecimalSeparatorFromLocale(),
            driver_id: driverId,
        });

    return fetchDriverOrderHistory;
}
