import {Alert} from "@bolteu/kalep-react";

import {useVehicleAlert} from "../hooks/useVehicleAlert";

export interface VehicleAlertProps {
    expiredDocuments: boolean;
    declinedDocuments: boolean;
}

const VehicleAlert = ({expiredDocuments, declinedDocuments}: VehicleAlertProps) => {
    const {title, description} = useVehicleAlert({expiredDocuments, declinedDocuments});

    if (!title || !description) {
        return null;
    }

    return (
        <Alert severity="error">
            <p className="mb-2 font-semibold">{title}</p>
            <p className="text-secondary">{description}</p>
        </Alert>
    );
};

export default VehicleAlert;
