import {FC} from "react";

import {useI18n} from "common/hooks/useI18n";

import {SpinnerIcon} from "@bolteu/kalep-react";

export interface LoadingSpinnerProps {
    show: boolean;
    size?: "xs" | "sm" | "md" | "lg";
    spinnerColorCss?: string;
    showLoadingText?: boolean;
    parentClassName?: string;
}

const LoadingSpinner: FC<LoadingSpinnerProps> = ({
    show,
    size,
    spinnerColorCss = "text-green-500",
    parentClassName,
    showLoadingText,
}) => {
    const {i18n} = useI18n();
    if (!show) {
        return null;
    }

    return (
        <div className={`flex items-end justify-center gap-2 ${parentClassName}`}>
            <SpinnerIcon size={size} className={`animate-spin ${spinnerColorCss}`} />
            {showLoadingText && i18n("api.loading")}
        </div>
    );
};

export const FullScreenLoadingSpinner = () => (
    <div className="flex h-screen items-center justify-center">
        <LoadingSpinner show />
    </div>
);

export default LoadingSpinner;
