import {FC, useCallback, useEffect, useMemo} from "react";

import {ButtonActionEvent, EventName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";

import {FleetIntegrationControlService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import ClientInfoBlock from "./ClientInfoBlock";
import CredentialsBlock from "./CredentialsBlock";
import CredentialsViewInfoBlock from "./CredentialsViewInfoBlock";

type FleetIntegrationCredentials = FleetIntegrationControlService.FleetIntegrationCredentials;
type FleetIntegrationClientInfo = FleetIntegrationControlService.FleetIntegrationClientInfo;

interface CredentialsViewProps {
    generatedCredentials: FleetIntegrationCredentials | null;
    clientInfo: FleetIntegrationClientInfo | null;
    setCredentials: (credentials: FleetIntegrationCredentials | null) => void;
    setClientInfo: (clientInfo: FleetIntegrationClientInfo | null) => void;
}

const revokeFetchFunction = (api: Api) => api.fleetIntegrationControl.fleetIntegrationRevokeCredentials();
const renewFetchFunction = (api: Api) => api.fleetIntegrationControl.fleetIntegrationCreateOrRenewCredentials();

const CredentialsView: FC<CredentialsViewProps> = ({
    generatedCredentials,
    clientInfo,
    setCredentials,
    setClientInfo,
}) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();
    const {fetch: revokeFetch, status: revokeStatus} = useFetch(revokeFetchFunction);
    const {fetch: renewFetch, status: renewStatus, data: newCredentials} = useFetch(renewFetchFunction);

    const onRevokingButtonClick = useCallback(() => {
        if (revokeFetch) {
            revokeFetch({});
        }
    }, [revokeFetch]);

    const onRenewingButtonClick = useCallback(() => {
        if (renewFetch) {
            renewFetch({});
        }
    }, [renewFetch]);

    useEffect(() => {
        if (revokeStatus === FetchStatus.Success) {
            setCredentials(null);
            setClientInfo(null);
            trackEvent(EventName.ButtonClicked, {action: ButtonActionEvent.RevokeCredentials});
        }
    }, [revokeStatus, setClientInfo, setCredentials, trackEvent]);

    useEffect(() => {
        if (renewStatus === FetchStatus.Success && newCredentials) {
            setCredentials(newCredentials);
            setClientInfo(null);
            trackEvent(EventName.ButtonClicked, {action: ButtonActionEvent.RenewCredentials});
        }
    }, [newCredentials, renewStatus, setClientInfo, setCredentials, trackEvent]);

    const isRevokingLoading = revokeStatus === FetchStatus.Loading;
    const isRenewingLoading = renewStatus === FetchStatus.Loading;

    const revokingTitle = i18n("auth.app.fleet.api-credentials.credentials.revoking.title");
    const revokingDescription = i18n("auth.app.fleet.api-credentials.credentials.revoking.description");
    const revokingButtonText = i18n("auth.app.fleet.api-credentials.credentials.revoking.button_text");

    const renewingTitle = i18n("auth.app.fleet.api-credentials.credentials.renewing.title");
    const renewingDescription = i18n("auth.app.fleet.api-credentials.credentials.renewing.description");
    const renewingButtonText = i18n("auth.app.fleet.api-credentials.credentials.renewing.button_text");

    const credentialsBlock = useMemo(() => {
        if (generatedCredentials) {
            return (
                <CredentialsBlock
                    clientId={generatedCredentials.client_id}
                    clientSecret={generatedCredentials.client_secret}
                />
            );
        }

        if (clientInfo) {
            return (
                <ClientInfoBlock
                    partialClientId={clientInfo.partial_client_id}
                    lastCredentialsChangeTimestamp={clientInfo.last_credentials_change}
                />
            );
        }

        return null;
    }, [generatedCredentials, clientInfo]);

    const isCredentialsJustGenerated = Boolean(generatedCredentials);

    return (
        <div className="flex flex-col gap-9">
            {credentialsBlock}
            <CredentialsViewInfoBlock
                title={revokingTitle}
                description={revokingDescription}
                isLoading={isRevokingLoading}
                buttonText={revokingButtonText}
                onButtonClick={onRevokingButtonClick}
            />
            {!isCredentialsJustGenerated && (
                <CredentialsViewInfoBlock
                    title={renewingTitle}
                    description={renewingDescription}
                    isLoading={isRenewingLoading}
                    buttonText={renewingButtonText}
                    onButtonClick={onRenewingButtonClick}
                />
            )}
        </div>
    );
};

export default CredentialsView;
