import {useCallback} from "react";

import {EventName} from "common/constants/events";
import {useCopyToClipboard} from "common/hooks/useCopyToClipboard";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";

import {Button, Dialog, TextField} from "@bolteu/kalep-react";

export interface ShareInvitationLinkDialogProps {
    isOpen: boolean;
    closeDialog: () => void;
    link: string;
}

const ShareInvitationLinkDialog = ({isOpen, closeDialog, link}: ShareInvitationLinkDialogProps) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();
    const {copyToClipboard} = useCopyToClipboard();

    const copyInvitationLink = useCallback(() => {
        copyToClipboard(link);
        trackEvent(EventName.InviteDriverSubmitSuccessful);
    }, [copyToClipboard, link, trackEvent]);

    return (
        <Dialog
            title={i18n("auth.app.fleet.invitation-link-dialog.title")}
            isOpen={isOpen}
            onRequestClose={closeDialog}
        >
            <Dialog.Content>
                <div className="mb-4 text-base text-neutral-700">
                    {i18n("auth.app.fleet.invitation-link-dialog.description")}
                </div>
                <TextField id="invitation-link" type="text" disabled value={link} fullWidth />
            </Dialog.Content>
            <Dialog.Footer>
                <div className="flex flex-wrap items-center justify-end gap-4">
                    <Button onClick={copyInvitationLink}>
                        {i18n("auth.app.fleet.invitation-link-dialog.copy-link")}
                    </Button>
                </div>
            </Dialog.Footer>
        </Dialog>
    );
};

export default ShareInvitationLinkDialog;
