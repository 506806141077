import {useCallback} from "react";

import {CheckCircle} from "@bolteu/kalep-react-icons";

import {Step} from "../../utils/types";

const WizardStep = ({
    title,
    isCurrentStep,
    isCompletedStep,
    index,
    onClick,
}: {
    title: string;
    index: number;
    isCurrentStep: boolean;
    isCompletedStep: boolean;
    onClick: () => void;
}) => {
    let Indicator = (
        <span
            className={`min-w-3 min-h-3 mr-3 ml-1.5 shrink-0 rounded-full ${
                isCurrentStep ? "bg-action-primary" : "bg-neutral-secondary-hard"
            }`}
        />
    );

    if (isCompletedStep && !isCurrentStep) {
        Indicator = <CheckCircle className="text-content-action-secondary mx-0.5 mr-2 shrink-0" size="sm" />;
    }

    let stepTitleClassName = isCompletedStep ? "text-content-primary" : "text-content-secondary";
    if (isCurrentStep) {
        stepTitleClassName = "text-content-primary font-semibold";
    }

    return (
        <div
            className={`flex items-center ${isCompletedStep ? "hover:cursor-pointer" : ""}`}
            onClick={onClick}
            tabIndex={index}
            onKeyDown={onClick}
            role="button"
        >
            {Indicator}
            <div className={stepTitleClassName}>{title}</div>
        </div>
    );
};

interface Props {
    steps: Step[];
    currentStep?: Step;
    onClick: (step: Step) => void;
}

const WizardStepSeparator = () => {
    return <div className="border-separator ml-[11px] h-8 border-l" />;
};

const WizardStepper = ({steps, onClick, currentStep}: Props) => {
    const onStepClicked = useCallback((step) => () => onClick(step), [onClick]);
    const stepIsCurrent = useCallback((step) => step.id === currentStep?.id, [currentStep]);

    return (
        <div className="flex flex-col">
            {steps.map((step, i) => (
                <div key={step.id}>
                    <WizardStep
                        title={step.title}
                        isCurrentStep={stepIsCurrent(step)}
                        isCompletedStep={step.is_completed}
                        index={i}
                        onClick={onStepClicked(step)}
                    />
                    {i < steps.length - 1 && <WizardStepSeparator />}
                </div>
            ))}
        </div>
    );
};

export default WizardStepper;
