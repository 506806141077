import {CountryCode} from "common/geo/constants/countries";

import {FleetOwnerService} from "@bolteu/bolt-server-api-fleet-owner-portal";

export const countriesHaveTransportLicense = [
    CountryCode.Australia,
    CountryCode.Estonia,
    CountryCode.Lithuania,
    CountryCode.Norway,
    CountryCode.Poland,
    CountryCode.Slovakia,
    CountryCode.Spain,
    CountryCode.UnitedKingdom,
];

export const countriesHaveTransportLicenseExpiry = [
    CountryCode.Australia,
    CountryCode.Estonia,
    CountryCode.Slovakia,
    CountryCode.UnitedKingdom,
];

const citiesWhitelist = [
    333, // Moscow
];

export const isTransportLicenseVisible = (fleet: FleetOwnerService.Company) => {
    return (
        countriesHaveTransportLicense.includes(fleet.country as CountryCode) || citiesWhitelist.includes(fleet.city_id)
    );
};

export const isTransportLicenseExpiresVisible = (fleet: FleetOwnerService.Company) => {
    return (
        countriesHaveTransportLicenseExpiry.includes(fleet.country as CountryCode) ||
        citiesWhitelist.includes(fleet.city_id)
    );
};
