import {ChangeEventHandler, useCallback} from "react";

import {useI18n} from "common/hooks/useI18n";
import {getHoursOfTheDay} from "common/util/timeUtil";

import {ComboBox, GhostButton, Grid, SelectOption, TextField} from "@bolteu/kalep-react";
import {BinOutlined, TimeOutlined} from "@bolteu/kalep-react-icons";

import {ShiftBreakState} from "./ShiftActionDialog";

export interface ShiftBreakProps {
    index: number;
    data: ShiftBreakState;
    isOnlyBreak: boolean;
    handleTextFieldChange: ChangeEventHandler<HTMLInputElement>;
    handleBreakHourChange: (index: number, newValue: string) => void;
    onDeleteClick: (index: number) => void;
}

const renderTimeIcon = () => <TimeOutlined aria-hidden="true" className="text-content-secondary flex-none" />;

export const BreakRow = ({
    index,
    data,
    isOnlyBreak,
    handleTextFieldChange,
    handleBreakHourChange,
    onDeleteClick,
}: ShiftBreakProps) => {
    const {i18n} = useI18n();

    const onDeleteClickWrapper = useCallback(() => {
        onDeleteClick(index);
    }, [index, onDeleteClick]);

    const onBreakHourChange = useCallback(
        (newValue: SelectOption | SelectOption[] | null) => {
            const selectedOption = Array.isArray(newValue) ? newValue[0] : newValue;
            handleBreakHourChange(index, String(selectedOption?.value) || "");
        },
        [index, handleBreakHourChange],
    );

    const getHourOption = (hour: string): SelectOption | undefined => {
        return getHoursOfTheDay.find((option) => option.value === hour);
    };

    return (
        <Grid columns={12}>
            <Grid.Item colSpan={6}>
                <div className="flex items-end gap-1">
                    <ComboBox
                        placeholder={i18n("common.hour_placeholder")}
                        label={i18n("auth.app.fleet.shifts.shift_form.break_start")}
                        options={getHoursOfTheDay}
                        value={getHourOption(data.breakStartHour)}
                        onChange={onBreakHourChange}
                        rootClassName="min-w-[90px] max-w-[90px] w-[90px]"
                        clearable={false}
                        fullWidth
                    />
                    <div className="mb-3">:</div>
                    <TextField
                        type="number"
                        placeholder={i18n("common.minute_placeholder")}
                        onChange={handleTextFieldChange}
                        data-index={index}
                        data-type="minute"
                        data-name="breakStartMinute"
                        value={data.breakStartMinute}
                        fullWidth
                    />
                </div>
            </Grid.Item>
            <Grid.Item colSpan={6}>
                <div className="flex h-full items-end">
                    <TextField
                        type="number"
                        placeholder="mm"
                        label={i18n("auth.app.fleet.shifts.shift_form.break_duration")}
                        onChange={handleTextFieldChange}
                        data-index={index}
                        data-name="breakDuration"
                        value={data.breakDuration}
                        fullWidth
                        renderStartSlot={renderTimeIcon}
                    />
                    <GhostButton
                        aria-label="Delete break"
                        onClick={onDeleteClickWrapper}
                        overrideClassName="mb-[13px] ml-3"
                        disabled={isOnlyBreak}
                    >
                        <BinOutlined className={isOnlyBreak ? "text-tertiary" : ""} />
                    </GhostButton>
                </div>
            </Grid.Item>
        </Grid>
    );
};
