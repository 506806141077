import {CircleFlag} from "react-circle-flags";

import Briefcase from "assets/icons/briefcase.svg?react";
import BusinessRide from "assets/icons/business_ride.svg?react";
import {iconNameToKalepIcon} from "common/util/iconNameToKalepIcon";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

interface IconProps {
    config: FleetOwnerRegistrationService.IconConfig;
}

const Icon = ({config}: IconProps) => {
    const {type, value} = config;

    const getCustomIcon = (customIcon: FleetOwnerRegistrationService.CustomIcon): JSX.Element => {
        switch (customIcon) {
            case FleetOwnerRegistrationService.CustomIcon.BRIEFCASE:
                return <Briefcase />;
            case FleetOwnerRegistrationService.CustomIcon.BUSINESS_RIDE:
                return <BusinessRide />;
            default:
                return UnreachableCode.never(customIcon, <></>);
        }
    };

    const getIcon = () => {
        switch (type) {
            case FleetOwnerRegistrationService.IconType.KALEP:
                return iconNameToKalepIcon(value);
            case FleetOwnerRegistrationService.IconType.REACT_FLAGS:
                return <CircleFlag countryCode={value.toLowerCase()} height={24} width={24} />;
            case FleetOwnerRegistrationService.IconType.CUSTOM:
                return getCustomIcon(value);
            default:
                return UnreachableCode.never(value);
        }
    };

    return <>{getIcon()}</>;
};

export {Icon};
