import {FC} from "react";

import {PeriodDatePicker} from "common/components/periodDatePicker";
import {AllowedDatePeriod} from "common/components/periodDatePicker/CustomPeriodPicker";
import {useI18n} from "common/hooks/useI18n";
import {SelectPeriodOption} from "common/hooks/usePeriodSelectorOptions";
import {ApiPeriod} from "common/services/period";

import BalanceDownload from "./BalanceDownload";

const GET_DATA_DAYS_RANGE_LIMIT = 31;

interface TransactionsHeaderProps {
    period: ApiPeriod;
    onPeriodChange: (apiPeriod: ApiPeriod) => void;
}

const TransactionsHeader: FC<TransactionsHeaderProps> = ({period, onPeriodChange}) => {
    const {i18n} = useI18n();

    const title = i18n("auth.app.fleet.balance.transactions.header.title");

    const selectPeriodOptions = [
        SelectPeriodOption.OnGoingDay,
        SelectPeriodOption.ThisWeek,
        SelectPeriodOption.LastWeek,
        SelectPeriodOption.ThisMonth,
        SelectPeriodOption.LastMonth,
    ];

    return (
        <div className="flex flex-col justify-between gap-4 md:flex-row md:items-center">
            <span className="text-2xl font-semibold">{title}</span>
            <div className="flex gap-4">
                <PeriodDatePicker
                    period={period}
                    selectPeriodOptions={selectPeriodOptions}
                    onPeriodChange={onPeriodChange}
                    dateRangeLimitDays={GET_DATA_DAYS_RANGE_LIMIT}
                    allowedDatePeriod={AllowedDatePeriod.Past}
                />
                <BalanceDownload period={period} />
            </div>
        </div>
    );
};

export default TransactionsHeader;
