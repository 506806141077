import {Company, CompanyType} from "common/constants/types";

import {DropdownListItem} from "./ListItem";

interface Props {
    companies: Company[];
    onSelectCompany: (company: Company, type: CompanyType) => () => void;
}

const CompanyList = ({companies, onSelectCompany}: Props) => {
    const list = companies.map((company, i) => (
        <DropdownListItem
            key={company.id}
            company={company}
            onSelectCompany={onSelectCompany}
            withDivider={i < companies.length - 1}
        />
    ));

    return <>{list}</>;
};

export default CompanyList;
