import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";

import {ListingTab} from "..";

export function useTabOptions(): Tab<ListingTab>[] {
    const {i18n} = useI18n();

    return [
        {
            id: ListingTab.View,
            title: i18n("auth.app.fleet.vehicle_marketplace.listings.view.tabs.preview"),
            trackingEventTabName: TabNameEvent.ListingPreview,
        },
        {
            id: ListingTab.RentalRequests,
            title: i18n("auth.app.fleet.vehicle_marketplace.listings.view.tabs.rental_requests"),
            trackingEventTabName: TabNameEvent.ListingRentalRequests,
        },
    ];
}
