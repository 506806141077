import {useI18n} from "common/hooks/useI18n";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";

import {Breaks} from "./card/Breaks";
import {CardHeader} from "./card/CardHeader";

interface Props {
    shift: FleetShiftManagementService.ShiftWithAllocationSummary;
    onDeleteClick: (shiftId: number) => void;
    onEditClick: (shift: FleetShiftManagementService.ShiftWithAllocationSummary) => void;
}

export const ShiftCard = ({shift, onDeleteClick, onEditClick}: Props) => {
    const {i18n} = useI18n();

    return (
        <div className="border-neutral-secondary w-full flex-col rounded-lg border pb-2">
            <CardHeader shift={shift} onDeleteClick={onDeleteClick} onEditClick={onEditClick} />
            <div className="flex flex-col gap-6 p-4">
                <Typography fontWeight="semibold">
                    {i18n("auth.app.fleet.shifts.planning.card.duration")}
                    <Typography inline>{`${shift.start_time} - ${shift.end_time}`}</Typography>
                </Typography>
                <Breaks breaks={shift.breaks} />
            </div>
        </div>
    );
};
