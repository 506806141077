import {FleetRoute} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";

export function useBreadcrumbs() {
    const {i18n} = useI18n();

    return [
        {
            href: `../../${FleetRoute.DRIVER_REGISTRATIONS}`,
            title: i18n("auth.app.fleet.engagement-dashboard.applications"),
        },
        {
            href: "",
            title: i18n("auth.app.fleet.driver-registrations.driver-registration"),
        },
    ];
}
