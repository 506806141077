import {FC, useCallback} from "react";

import {twMerge} from "tailwind-merge";

import {Menu} from "@bolteu/kalep-react";
import {ChevronDown as DownIcon, ChevronUp as UpIcon} from "@bolteu/kalep-react-icons";

export interface CompanyMenuProps {
    selectedCompanyName: string | undefined;
    chip?: React.ReactElement;
    hasSubMenu?: boolean;
    children: React.ReactNode;
}

const CompanyMenu: FC<CompanyMenuProps> = ({children, selectedCompanyName, chip, hasSubMenu}) => {
    const menuButton = useCallback(
        ({open}) => {
            return (
                <button type="button" className={`group flex items-center ${"max-w-[240px] text-right"}`}>
                    <p className="truncate py-3 text-sm">{selectedCompanyName}</p>
                    {chip && <div className="ml-2 leading-4">{chip}</div>}
                    <div className="ml-2 shrink-0">
                        {!open && <DownIcon className="text-neutral-600" />}
                        {open && <UpIcon className="text-neutral-600" />}
                    </div>
                </button>
            );
        },
        [chip, selectedCompanyName],
    );

    // TODO: Remove when KALEP-436 is done
    const overrideClassName = hasSubMenu ? "[&_ul]:max-h-[400px] [&_ul]:overflow-auto" : "max-h-[400px] overflow-auto";

    return (
        <Menu
            overrideClassName={twMerge(overrideClassName, "p-0")}
            direction="bottom"
            align="start"
            menuButton={menuButton}
        >
            {children}
        </Menu>
    );
};

export {CompanyMenu};
