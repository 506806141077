import {useContext} from "react";

import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {InvoiceTab} from "..";
import {countriesWithoutCompensations, countriesWithoutRiderInvoices} from "../contants";

import BillingModel = FleetOwnerReportingService.BillingModel;

export function useTabOptions(billingModel?: BillingModel): Tab<InvoiceTab>[] {
    const fleetCountry = useContext(AccountContextProvider)?.getFleet()?.country;
    const {i18n} = useI18n();

    const tabs: Tab<InvoiceTab>[] = [];

    if (fleetCountry && !countriesWithoutRiderInvoices.includes(fleetCountry)) {
        tabs.push({
            id: InvoiceTab.FleetToRider,
            title: i18n("auth.app.invoices.fleet-to-rider.tab_title"),
            trackingEventTabName: TabNameEvent.InvoicesPageFleetToRider,
        });
    }

    const boltInvoicesTitle =
        billingModel === BillingModel.INTERMEDIATE
            ? i18n(`auth.app.invoices.bolt-to-fleet.tab_title`)
            : i18n(`auth.app.invoices.bolt-to-fleet.tab_title_resale`);
    tabs.push({
        id: InvoiceTab.BoltInvoices,
        title: boltInvoicesTitle,
        trackingEventTabName: TabNameEvent.InvoicesPageBoltToFleet,
    });

    if (fleetCountry && !countriesWithoutCompensations.includes(fleetCountry)) {
        tabs.push({
            id: InvoiceTab.Compensations,
            title: i18n("auth.app.invoices.compensations_v2.tab_title"),
            trackingEventTabName: TabNameEvent.InvoicesPageCompensations,
        });
    }

    tabs.push({
        id: InvoiceTab.Payouts,
        title: i18n("auth.app.reports.payouts.title"),
        trackingEventTabName: TabNameEvent.InvoicesPagePayouts,
    });

    return tabs;
}
