import {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, IconButton, Menu, MenuItem} from "@bolteu/kalep-react";
import {MoreIos} from "@bolteu/kalep-react-icons";

import ActivateConfirmationDialog from "./ActivateConfirmationDialog";
import DeactivateConfirmationDialog from "./DeactivateConfirmationDialog";

import ListingStatus = VehicleMarketplaceService.ListingStatus;
import GetListingData = VehicleMarketplaceService.GetListingData;
import DuplicateConfirmationDialog from "./DuplicateConfirmationDialog";

const publishListingDetailsFetchFunction = (api: Api, request: VehicleMarketplaceService.PublishListingRequest) =>
    api.vehicleMarketplace.vehicleListingPublish(request);
const deactivateListingDetailsFetchFunction = (api: Api, request: VehicleMarketplaceService.DeactivateListingRequest) =>
    api.vehicleMarketplace.vehicleListingDeactivate(request);
const duplicateListingFetchFunction = (api: Api, request: VehicleMarketplaceService.CopyListingRequest) =>
    api.vehicleMarketplace.vehicleListingCopy(request);

interface Props {
    listingId: number;
    listingData: GetListingData;
    refreshListingData: () => void;
}

const ListingActions = ({listingId, listingData, refreshListingData}: Props) => {
    const {i18n} = useI18n();

    const navigate = useNavigate();
    const {getVehicleListingEditPath} = useAbsolutePath();

    const {fetch: publishFetch, status: publishStatus} = useFetch(publishListingDetailsFetchFunction);
    const {fetch: deactivateFetch, status: deactivateStatus} = useFetch(deactivateListingDetailsFetchFunction);
    const {
        fetch: duplicateFetch,
        status: duplicateStatus,
        data: duplicateData,
    } = useFetch(duplicateListingFetchFunction);

    const [isActivateConfirmationDialogOpen, setActivateConfirmationDialogOpen] = useState(false);
    const [isDeactivateConfirmationDialogOpen, setDeactivateConfirmationDialogOpen] = useState(false);
    const [isDuplicateConfirmationDialogOpen, setDuplicateConfirmationDialogOpen] = useState(false);

    const openActivateConfirmationDialog = useCallback(() => {
        setActivateConfirmationDialogOpen(true);
    }, []);
    const closeActivateConfirmationDialog = useCallback(() => {
        setActivateConfirmationDialogOpen(false);
    }, []);

    const openDeactivateConfirmationDialog = useCallback(() => {
        setDeactivateConfirmationDialogOpen(true);
    }, []);
    const closeDeactivateConfirmationDialog = useCallback(() => {
        setDeactivateConfirmationDialogOpen(false);
    }, []);

    const openDuplicateConfirmationDialog = useCallback(() => {
        setDuplicateConfirmationDialogOpen(true);
    }, []);
    const closeDuplicateConfirmationDialog = useCallback(() => {
        setDuplicateConfirmationDialogOpen(false);
    }, []);

    const publish = useCallback(() => {
        if (publishFetch) {
            publishFetch({listing_id: listingId});
        }
    }, [publishFetch, listingId]);

    const deactivate = useCallback(
        (shouldDeclineApplications: boolean) => {
            if (deactivateFetch) {
                deactivateFetch({
                    listing_id: listingId,
                    should_decline_applications: shouldDeclineApplications,
                });
            }
        },
        [deactivateFetch, listingId],
    );

    const duplicate = useCallback(() => {
        if (duplicateFetch) {
            duplicateFetch({listing_id: listingId});
        }
    }, [duplicateFetch, listingId]);

    useEffect(() => {
        if (publishStatus === FetchStatus.Success || deactivateStatus === FetchStatus.Success) {
            refreshListingData();
        }
    }, [publishStatus, deactivateStatus, refreshListingData]);

    useEffect(() => {
        if (duplicateStatus === FetchStatus.Success) {
            navigate(getVehicleListingEditPath(duplicateData.listing_id));
        }
    }, [duplicateStatus, getVehicleListingEditPath, duplicateData, navigate]);

    const openEditListingPage = useCallback(() => {
        navigate(getVehicleListingEditPath(listingId), {state: {isFromListingDetails: true}});
    }, [navigate, getVehicleListingEditPath, listingId]);

    const menus = [
        {
            onClick: openDuplicateConfirmationDialog,
            label: i18n("auth.app.fleet.vehicle_marketplace.listings.view.action.duplicate"),
            isDisabled: false,
        },
        listingData.status === ListingStatus.PUBLISHED
            ? {
                  onClick: openDeactivateConfirmationDialog,
                  label: i18n("auth.app.fleet.vehicle_marketplace.listings.view.action.deactivate"),
                  isDisabled: deactivateStatus === FetchStatus.Loading,
              }
            : {
                  onClick: openActivateConfirmationDialog,
                  label: i18n("auth.app.fleet.vehicle_marketplace.listings.view.action.activate"),
                  isDisabled: publishStatus === FetchStatus.Loading,
              },
    ];

    return (
        <>
            <div className="flex gap-4">
                <Button onClick={openEditListingPage} variant="secondary">
                    {i18n("auth.app.fleet.vehicle_marketplace.listings.view.action.edit")}
                </Button>
                <Menu menuButton={<IconButton icon={<MoreIos />} aria-label="Add Vehicle Options" />}>
                    {menus.map((menu) => (
                        <MenuItem
                            key={menu.label}
                            label={menu.label}
                            onClick={menu.onClick}
                            disabled={menu.isDisabled}
                        />
                    ))}
                </Menu>
            </div>
            <DeactivateConfirmationDialog
                isOpen={isDeactivateConfirmationDialogOpen}
                close={closeDeactivateConfirmationDialog}
                confirmDeactivation={deactivate}
                isLoading={deactivateStatus === FetchStatus.Loading}
            />
            <ActivateConfirmationDialog
                isOpen={isActivateConfirmationDialogOpen}
                close={closeActivateConfirmationDialog}
                confirmActivation={publish}
                isLoading={publishStatus === FetchStatus.Loading}
            />
            <DuplicateConfirmationDialog
                isOpen={isDuplicateConfirmationDialogOpen}
                close={closeDuplicateConfirmationDialog}
                confirmDuplication={duplicate}
                isLoading={duplicateStatus === FetchStatus.Loading}
            />
        </>
    );
};

export default ListingActions;
