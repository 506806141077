import {FC} from "react";

import styles from "../../style.module.css";

export const LocationMapMarker: FC<{
    type: "start" | "destination" | "last-dropoff";
}> = ({type}) => {
    return (
        <span
            className={`absolute h-6 w-6 -translate-x-1/2 -translate-y-1/2 rounded-full border-[3px] border-white ${
                styles["marker-shadow"]
            } ${getLocationMapMarkerType(type)}`}
        />
    );
};

function getLocationMapMarkerType(type: "start" | "destination" | "last-dropoff") {
    switch (type) {
        case "start":
            return styles["marker-location-green"];
        case "destination":
            return styles["marker-location-red"];
        case "last-dropoff":
            return styles["marker-location-black"];
        default:
            return "";
    }
}
