import React, {useContext} from "react";
import {useLocation} from "react-router-dom";

import {EventAdditionalProperties, EventName} from "common/constants/events";
import {publicPageNames} from "common/constants/pages";
import {localStorageService} from "common/services/LocalStorageService";
import * as mixpanel from "common/services/mixpanel";
import {getPageNameFromRoute} from "common/util/routeUtils";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {ApplicationContextProvider} from "features/app/appStateProvider";
import {ThirdPartyContextProvider} from "features/app/appThirdPartyProvider";

export const useTracking = () => {
    const location = useLocation();

    const {
        bugsnag: {bugsnagLeaveBreadcrumb},
    } = useContext(ThirdPartyContextProvider);
    const accountState = useContext(AccountContextProvider);
    const appState = useContext(ApplicationContextProvider);

    const {isAuthenticated} = appState;
    const companyId = accountState.selectedCompany?.company?.id;
    const companyType = accountState.selectedCompany?.type;
    const pageName = getPageNameFromRoute(location.pathname);

    const trackPageLoaded = React.useCallback(async () => {
        if (!pageName) {
            return;
        }

        const isPublicPage = publicPageNames.includes(pageName);

        // This checks that user is not isAuthenticated before page changes from logged out to logged in
        if (isPublicPage && isAuthenticated) {
            return;
        }

        // This checks that isAuthenticated is set for private pages AND that companyId is also set
        // Page in some cases is rendered first without company id and then again with company id
        //  - companyId check to reduce page opened opened duplication
        if (!isPublicPage && (!isAuthenticated || companyId === undefined)) {
            return;
        }

        const language = localStorageService.getLanguageWithDefaultLocale();
        await mixpanel.trackPageLoaded({pageName, companyId, companyType, language});
        bugsnagLeaveBreadcrumb("page loaded", {pageName, companyId, companyType, language});
    }, [pageName, isAuthenticated, companyId, companyType, bugsnagLeaveBreadcrumb]);

    const trackEvent = React.useCallback(
        async (eventName: EventName, additionalProperties?: EventAdditionalProperties) => {
            if (!pageName) {
                return;
            }

            const language = localStorageService.getLanguageWithDefaultLocale();
            await mixpanel.trackEvent({
                eventName,
                pageName,
                companyId,
                companyType,
                language,
                additionalProperties,
            });
            bugsnagLeaveBreadcrumb("event tracked", {
                eventName,
                pageName,
                companyId,
                companyType,
                additionalProperties,
            });
        },
        [pageName, companyType, companyId, bugsnagLeaveBreadcrumb],
    );

    return {trackPageLoaded, trackEvent};
};
