import {createContext, useState} from "react";

import {useSupportWidget} from "common/hooks/useSupportWidget";

interface SupportWidgetData {
    isSupportWidgetEnabled: boolean;
    isSupportWidgetOpen: boolean;
    hasUnreadCases: boolean;
    toggleSupportWidget: () => void;
    updateSupportWidgetLocale: (locale: string) => void;
    setSelectedCaseId: (id: number) => void;
}

const defaultContextValue: SupportWidgetData = {
    isSupportWidgetEnabled: false,
    isSupportWidgetOpen: false,
    hasUnreadCases: false,
    toggleSupportWidget: () => {},
    updateSupportWidgetLocale: () => {},
    setSelectedCaseId: () => {},
};

const SupportWidgetContextProvider = createContext<SupportWidgetData>(defaultContextValue);
SupportWidgetContextProvider.displayName = "SupportWidgetContextProvider";

const SupportWidgetProvider = ({children}: {children: React.ReactNode}) => {
    const [selectedCaseId, setSelectedCaseId] = useState<number | undefined>(undefined);

    const {isSupportWidgetFeatureEnabled, isWidgetOpen, unreadCases, toggle, updateSupportWidgetLocale} =
        useSupportWidget(selectedCaseId);

    const contextValue: SupportWidgetData = {
        isSupportWidgetEnabled: isSupportWidgetFeatureEnabled,
        isSupportWidgetOpen: isWidgetOpen,
        hasUnreadCases: !!unreadCases?.length,
        toggleSupportWidget: toggle,
        updateSupportWidgetLocale,
        setSelectedCaseId,
    };

    return (
        <SupportWidgetContextProvider.Provider value={contextValue}>{children}</SupportWidgetContextProvider.Provider>
    );
};

export {SupportWidgetProvider, SupportWidgetContextProvider};
