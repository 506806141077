import {useMapCommon} from "common/geo/components/google-maps/useMapCommon";

import {Address} from "../../../types";
import {useAddressMarkers} from "./useAddressMarkers";
import {useZoomLevel} from "./useZoomLevel";

export const useMap = (pickup: Address, dropoffs: Address[], geoLocation: GeolocationPosition | null) => {
    const {googleMapsApi, setBounds, onGoogleApiLoaded, getBbox} = useMapCommon();
    useZoomLevel(googleMapsApi, pickup, dropoffs, setBounds, getBbox, geoLocation);
    const markers = useAddressMarkers(pickup, dropoffs);

    return {onGoogleApiLoaded, markers};
};
