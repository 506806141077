import {FC, lazy, Suspense, useCallback, useState} from "react";

import FileInfo from "common/components/fileUpload/FileInfo";

import ImagePreview from "./ImagePreview";

const PdfPreview = lazy(() => import("./PdfPreview"));

interface PreviewDocumentProps {
    document: File;
}

const PreviewDocument: FC<PreviewDocumentProps> = ({document}) => {
    const [hasFilePreviewFailed, setHasFilePreviewFailed] = useState(false);

    const isPdf = document.type === "application/pdf";

    const handlePreviewFailure = useCallback(() => {
        setHasFilePreviewFailed(true);
    }, []);

    if (hasFilePreviewFailed) {
        return <FileInfo file={document} isLoading={false} />;
    }

    if (isPdf) {
        return (
            <Suspense fallback={<FileInfo file={document} />}>
                <PdfPreview document={document} onFailure={handlePreviewFailure} />
            </Suspense>
        );
    }

    return <ImagePreview document={document} onFailure={handlePreviewFailure} />;
};

export default PreviewDocument;
