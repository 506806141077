import {useCallback, useContext, useEffect, useState} from "react";

import {ResponsiveDialog} from "common/components/dialog/ResponsiveDialog";
import {EventName} from "common/constants/events";
import {isActiveFleet} from "common/constants/types";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {browserInfo} from "common/util/BrowserInfo";
import {formatContentHtml} from "common/util/formatContentHtml";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetCompanyService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, Dialog} from "@bolteu/kalep-react";

const CHECK_TERMS_AND_CONDITIONS_INTERVAL = 1000 * 60 * 60 * 4; // 4 hours

const getTermsConditionsFetchFunction = (api: Api, query: FleetCompanyService.CheckTermsAndConditionsRequest) =>
    api.fleetCompany.companyTermsAndConditionsGet(query);

const acceptTermsConditionsFetchFunction = (api: Api, body: FleetCompanyService.AcceptTermsAndConditionsRequest) =>
    api.fleetCompany.companyTermsAndConditionsAccept(body);

function formatTitleHtml(content?: FleetCompanyService.TCStepContent): JSX.Element | null {
    if (!content?.title && !content?.img_url) {
        return null;
    }

    return (
        <div className="flex flex-col gap-4">
            {content?.img_url && <img src={content?.img_url} className="h-28 object-scale-down" alt="T&C" />}
            <div className="w-full text-center">{formatContentHtml(content?.title)}</div>
        </div>
    );
}

const TermsAndConditionsUpdate = () => {
    const fleetId = useContext(AccountContextProvider)?.getFleet()?.id;
    const isRelevantCompany = isActiveFleet(useContext(AccountContextProvider)?.getFleet());
    const [isOpen, setIsOpen] = useState(false);
    const {trackEvent} = useTracking();

    const {status, data, fetch} = useFetch(getTermsConditionsFetchFunction);
    const {fetch: acceptTC} = useFetch(acceptTermsConditionsFetchFunction);

    const handleTermsAndConditionsOpen = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleTermsAndConditionsClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const emptyCallback = useCallback(() => {}, []);

    const checkTermsCallback = useCallback(() => {
        if (!fetch || !fleetId || !isRelevantCompany) {
            return;
        }

        const deviceInfo = browserInfo.getDeviceInfo();
        fetch({device_uuid: deviceInfo.device_uuid, user_agent: deviceInfo.user_agent});
    }, [fetch, fleetId, isRelevantCompany]);

    useEffect(() => {
        if (status === FetchStatus.Success && data?.accept_markup) {
            handleTermsAndConditionsOpen();
        }
        if (status === FetchStatus.Error) {
            handleTermsAndConditionsClose();
        }
    }, [data?.accept_markup, status, handleTermsAndConditionsOpen, handleTermsAndConditionsClose]);

    const acceptMarkup = useCallback(() => {
        if (!data?.flow_uuid || !acceptTC) {
            return;
        }

        const deviceInfo = browserInfo.getDeviceInfo();
        acceptTC({flow_uuid: data?.flow_uuid, device_uuid: deviceInfo.device_uuid, user_agent: deviceInfo.user_agent});

        trackEvent(EventName.TermsAndConditionsAccepted);
        handleTermsAndConditionsClose();
    }, [trackEvent, acceptTC, data?.flow_uuid, handleTermsAndConditionsClose]);

    useEffect(() => {
        checkTermsCallback();
        const interval = setInterval(() => checkTermsCallback(), CHECK_TERMS_AND_CONDITIONS_INTERVAL);
        return () => {
            clearInterval(interval);
        };
    }, [checkTermsCallback]);

    return (
        <ResponsiveDialog
            title={formatTitleHtml(data?.accept_markup)}
            isOpen={isOpen}
            onRequestClose={emptyCallback}
            variant="alert"
        >
            <Dialog.Content>
                <div className="w-full text-center">{formatContentHtml(data?.accept_markup?.content)}</div>
                <Button onClick={acceptMarkup} fullWidth overrideClassName="my-4">
                    <div className="w-full text-center">{formatContentHtml(data?.accept_markup?.button)}</div>
                </Button>
            </Dialog.Content>
        </ResponsiveDialog>
    );
};

export default TermsAndConditionsUpdate;
