import {useMemo} from "react";

import {Api} from "common/services/apiProvider";
import {ApiPeriod, Period} from "common/services/period";
import {getIsoDate} from "common/util/FormatUtil";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {DriverEarningsService} from "@bolteu/bolt-server-api-fleet-owner-portal";

export interface DriverEarningsParams {
    offset: number;
    limit: number;
    search?: string;
}

export function useDriverEarningsApi(
    period: ApiPeriod,
): (
    api: Api,
    {offset, limit, search}: DriverEarningsParams,
) => Promise<DriverEarningsService.GetDriverEarningsPageResponse> {
    return useMemo(() => {
        switch (period.period) {
            case Period.ONGOING_DAY:
                return (api: Api, {offset, limit, search}: DriverEarningsParams) => {
                    return api.driverEarnings.getDriverEarningsRecent({
                        period: DriverEarningsService.RecentEarningsPeriod.ONGOING_DAY,
                        offset,
                        limit,
                        ...(search && {search}),
                    });
                };
            case Period.PREVIOUS_7_DAYS:
                return (api: Api, {offset, limit, search}: DriverEarningsParams) => {
                    return api.driverEarnings.getDriverEarningsRecent({
                        period: DriverEarningsService.RecentEarningsPeriod.PREVIOUS_7_DAYS,
                        offset,
                        limit,
                        ...(search && {search}),
                    });
                };
            case Period.WEEK:
                return (api: Api, {offset, limit, search}: DriverEarningsParams) => {
                    return api.driverEarnings.getDriverEarningsWeek({
                        week: period.week,
                        offset,
                        limit,
                        ...(search && {search}),
                    });
                };
            case Period.MONTH:
                return (api: Api, {offset, limit, search}: DriverEarningsParams) => {
                    return api.driverEarnings.getDriverEarningsMonth({
                        month: period.month,
                        offset,
                        limit,
                        ...(search && {search}),
                    });
                };
            case Period.CUSTOM:
                return (api: Api, {offset, limit, search}: DriverEarningsParams) => {
                    return api.driverEarnings.getDriverEarningsDateRange({
                        start_date: getIsoDate(period.startDate),
                        end_date: getIsoDate(period.endDate),
                        offset,
                        limit,
                        ...(search && {search}),
                    });
                };
            default:
                return UnreachableCode.never(period, async () => {
                    return {
                        drivers: [],
                        currency_symbol: ",",
                        total_rows: 0,
                        columns_configuration: {
                            is_show_vat_sum_booking_fees: false,
                            is_show_vat_sum_revenue_in_app: false,
                            is_show_vat_sum_revenue_cash: false,
                            is_show_vat_sum_compensations: false,
                            is_show_vat_sum_bonuses: false,
                            is_show_vat_sum_cancellation_fees: false,
                            is_show_withholding_tax: false,
                        },
                    };
                });
        }
    }, [period]);
}
