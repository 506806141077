import React from "react";

import {twMerge} from "tailwind-merge";

interface OverrideClassNameProp {
    overrideClassName?: string;
}

type CustomStyle = string | string[];

export const getOverrideStyledKalepComponent =
    <T extends OverrideClassNameProp>(component: React.ComponentType<T>, customStyle: CustomStyle) =>
    (props: T) => {
        const {overrideClassName} = props;
        const Component = component;
        return <Component {...props} overrideClassName={twMerge(customStyle, overrideClassName)} />;
    };

interface ClassNameProp {
    className?: string;
}

export const getStyledKalepComponent =
    <T extends ClassNameProp>(component: React.ComponentType<T>, customStyle: CustomStyle) =>
    (props: T) => {
        const {className} = props;
        const Component = component;
        return <Component {...props} className={twMerge(customStyle, className)} />;
    };
