import {useContext} from "react";

import {AccountContextProvider} from "features/account/accountStateProvider";

import {Menu, MenuHeader, MenuItem} from "@bolteu/kalep-react";
import {User} from "@bolteu/kalep-react-icons";

import {useProfileMenuItems} from "../hooks/useProfileMenuItems";

export const TopBarProfileMenu = () => {
    const accountState = useContext(AccountContextProvider);
    const profileMenuItems = useProfileMenuItems();

    return (
        <Menu
            menuButton={
                <button
                    type="button"
                    className="min-w-9 flex h-9 w-9 items-center justify-center rounded-full bg-neutral-300"
                >
                    <User size="sm" className="text-neutral-700" />
                </button>
            }
            overrideClassName="w-60"
        >
            <MenuHeader>
                <div className="px-4 py-3">
                    <div className="truncate text-base">{accountState.profile?.name}</div>
                    <div className="truncate text-sm text-neutral-700">{accountState.profile?.email}</div>
                </div>
            </MenuHeader>
            {profileMenuItems.map(({label, action}) => (
                <MenuItem key={label} label={label} onClick={action} />
            ))}
        </Menu>
    );
};
