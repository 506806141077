import {useContext} from "react";

import {isFleet} from "common/constants/types";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {CompanyDropdown} from "./CompanyDropdown";

const CompanySelector = () => {
    const accountState = useContext(AccountContextProvider);

    const {profile} = accountState;
    const selectedCompany = accountState.selectedCompany?.company;

    if (!profile) {
        const companyName = isFleet(selectedCompany)
            ? selectedCompany.company_name ?? selectedCompany.name ?? ""
            : selectedCompany?.name;
        return <p className="truncate py-3 text-sm">{companyName}</p>;
    }

    return (
        <CompanyDropdown
            fleets={profile.fleets}
            selectedCompany={accountState.selectedCompany}
            holdingFleets={profile.holdingFleets}
        />
    );
};

export default CompanySelector;
