import {useCallback, useMemo} from "react";

import {PaymentMethod} from "common/constants/PaymentMethod";
import {useI18n} from "common/hooks/useI18n";

import {Select, SelectOption} from "@bolteu/kalep-react";

interface Props {
    value: PaymentMethod | null;
    onChange: (paymentMethod: PaymentMethod | null) => void;
}

const PaymentFilter = ({value, onChange}: Props) => {
    const {i18n} = useI18n();

    const paymentMethodOptions = useMemo(
        () => [
            {value: null, title: i18n("common.all")},
            {value: PaymentMethod.CASH, title: i18n(`payment-methods.${PaymentMethod.CASH}`)},
            {value: PaymentMethod.CARD, title: i18n(`payment-methods.${PaymentMethod.CARD}`)},
            {value: PaymentMethod.BUSINESS, title: i18n(`payment-methods.${PaymentMethod.BUSINESS}`)},
            {value: PaymentMethod.APP_PAYMENT, title: i18n(`payment-methods.${PaymentMethod.APP_PAYMENT}`)},
        ],
        [i18n],
    );

    const onSelectChange = useCallback(
        (newValue: SelectOption | SelectOption[] | null) => {
            let newPaymentMethod: PaymentMethod | null;
            if (Array.isArray(newValue)) {
                newPaymentMethod = newValue[0].value === null ? null : (newValue[0].value as PaymentMethod);
            } else {
                newPaymentMethod = newValue?.value === null ? null : (newValue?.value as PaymentMethod);
            }
            onChange(newPaymentMethod);
        },
        [onChange],
    );

    const selectValue = paymentMethodOptions.find((option) => option.value === value);

    return (
        <div className="w-80 shrink-0">
            <Select
                value={selectValue}
                defaultValue={paymentMethodOptions[0]}
                onChange={onSelectChange}
                options={paymentMethodOptions}
                label={i18n("common.payment_method")}
                fullWidth
            />
        </div>
    );
};

export default PaymentFilter;
