import {FC, useEffect} from "react";
import {useIntl} from "react-intl";

import AvatarIcon from "assets/icons/avatar.svg?react";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {getDriverName} from "common/constants/driverName";
import {getDriverRegistrationStatus} from "common/constants/driverRegistrationStatus";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip, LinkButton} from "@bolteu/kalep-react";

import {DriverRegistrationDeclinedAlert} from "./DriverRegistrationDeclinedAlert";
import DriverRegistrationDetails from "./DriverRegistrationDetails";
import DriverRegistrationByCompanyIdAndSource = FleetOwnerPortalService.DriverRegistrationByCompanyIdAndSource;
import DriverRegistrationLogStatus = FleetOwnerPortalService.DriverRegistrationLogStatus;
import GetDriverRegistrationStatusRequest = FleetOwnerPortalService.GetDriverRegistrationStatusRequest;

interface DriverRegistrationBodyProps {
    data: DriverRegistrationByCompanyIdAndSource;
}

const getDriverRegistrationStatusDataFetchFunction = (api: Api, request: GetDriverRegistrationStatusRequest) =>
    api.fleetOwnerPortal.getDriverRegistrationStatus(request);

const DriverRegistrationBody: FC<DriverRegistrationBodyProps> = ({data}) => {
    const {i18n} = useI18n();
    const driverName = getDriverName(data.first_name, data.last_name, data.email);
    const language = useIntl().locale;
    const statusChipData = getDriverRegistrationStatus(data.status);

    const {data: statusData, fetch: statusFetch} = useFetch(getDriverRegistrationStatusDataFetchFunction);

    const isDeclinedRegistration = [
        DriverRegistrationLogStatus.DECLINED,
        DriverRegistrationLogStatus.DECLINED_SILENTLY,
    ].includes(data.status);

    useEffect(() => {
        if (isDeclinedRegistration) {
            if (statusFetch) {
                statusFetch({hash: data.hash});
            }
        }
    }, [data.hash, isDeclinedRegistration, language, statusFetch]);

    return (
        <>
            <div className="mt-6 flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                <div className="flex flex-row">
                    <AvatarIcon />
                    <div className="flex flex-col pl-6">
                        <ResponsiveHeader type={ResponsiveHeaderType.InnerPage} text={driverName} />
                        <div className="flex flex-row gap-x-2 pt-4">
                            <Chip
                                label={i18n(`auth.app.fleet.driver-registrations.status.${statusChipData.label}`)}
                                size="sm"
                                color={statusChipData.color}
                                variant="filled"
                            />
                        </div>
                    </div>
                </div>
                {data.registration_link && (
                    <LinkButton href={data.registration_link} target="_blank">
                        {i18n("auth.app.fleet.driver-registrations.complete-registration")}
                    </LinkButton>
                )}
            </div>
            <div className="flex w-full flex-col pt-8">
                {isDeclinedRegistration && (
                    <div className="flex flex-col md:w-1/2">
                        <DriverRegistrationDeclinedAlert declineReasons={statusData?.decline_reasons} />
                    </div>
                )}
                <div className="flex w-full flex-row flex-wrap justify-between gap-x-20">
                    <div className="mb-8 flex w-full flex-col md:w-1/2 md:max-w-[480px]">
                        {data && <DriverRegistrationDetails driverRegistration={data} />}
                    </div>
                </div>
            </div>
        </>
    );
};

export default DriverRegistrationBody;
