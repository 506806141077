import {useCallback} from "react";

import {DocumentData} from "common/components/document/useDocumentsList";
import {Api} from "common/services/apiProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

const driverDocFetchFunction = async (
    api: Api,
    body: FleetOwnerPortalService.GetDriverDocumentsSetRequest,
): Promise<DocumentData[]> => {
    const resp = await api.fleetOwnerPortal.driverDocumentsGetDriverDocumentsSet(body);
    return resp.list.map((doc) => {
        return {
            ...(doc.document
                ? {
                      document: {
                          id: doc.document.id,
                          expires: doc.document.expires,
                          status: doc.document.status,
                      },
                  }
                : {document: null}),
            type: {
                ...doc.document_type,
                is_expiry_required: !!doc.document_type.is_expiry_required,
                is_required: !!doc.document_type.is_required,
            },
        };
    });
};

const documentUploadFetchFunction = (
    api: Api,
    body: FleetOwnerPortalService.UploadDriverDocumentRequest,
    files: FleetOwnerPortalService.Files,
) => api.fleetOwnerPortal.driverDocumentsUploadDriverDocument(body, files);

export const useDriverDocumentFunctions = (driverId: number) => {
    const documentsFetchFunction = useCallback(
        (api: Api) => {
            return driverDocFetchFunction(api, {driver_id: driverId});
        },
        [driverId],
    );

    const documentUploadFunction = useCallback(
        async (api: Api, expiresIsoDate: string | null, files: FleetOwnerPortalService.Files) => {
            await documentUploadFetchFunction(
                api,
                {driver_id: driverId, ...(expiresIsoDate && {expires: expiresIsoDate})},
                files,
            );
        },
        [driverId],
    );

    return {
        documentsFetchFunction,
        documentUploadFunction,
    };
};
