import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip} from "@bolteu/kalep-react";
import {ChipAppearance} from "@bolteu/kalep-react/build/components/Chip/Chip.types";

interface StatusCellRendererProps {
    value: string;
    valueMap: FleetOwnerPortalService.StatusColumnValueMap;
}

export const StatusChip = ({value, valueMap}: StatusCellRendererProps) => {
    return (
        <Chip
            label={valueMap[value]?.title}
            size="sm"
            appearance={valuMapEntityStatusToChipAppearance(valueMap[value]?.entity_state)}
        />
    );
};

export const StatusCell = (props: StatusCellRendererProps) => {
    return (
        <div>
            <StatusChip {...props} />
        </div>
    );
};

function valuMapEntityStatusToChipAppearance(entityState: FleetOwnerPortalService.EntityState): ChipAppearance {
    switch (entityState) {
        case FleetOwnerPortalService.EntityState.ALERT:
        case FleetOwnerPortalService.EntityState.DANGER:
            return "danger";

        case FleetOwnerPortalService.EntityState.DEFAULT:
            return "promo";
        case FleetOwnerPortalService.EntityState.INACTIVE:
            return "neutral";

        case FleetOwnerPortalService.EntityState.PENDING:
        case FleetOwnerPortalService.EntityState.WARNING:
            return "warning";

        case FleetOwnerPortalService.EntityState.SUCCESS:
            return "action";

        default:
            return "neutral";
    }
}
