import {useContext} from "react";

import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {OrdersTabs} from "..";
import {isIncomingOrdersFeatureEnabled} from "../contants";
import {ScheduledRidesContextProvider} from "../ScheduledRidesContextProvider";

export function useTabSelectorOptions(): Tab<OrdersTabs>[] {
    const {i18n} = useI18n();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const isScheduledRidesEnabled = useContext(ScheduledRidesContextProvider).isEnabled;

    const isIncomingOrdersAvailable = fleet && isIncomingOrdersFeatureEnabled(fleet.country);

    const tabs: Tab<OrdersTabs>[] = [
        ...(isIncomingOrdersAvailable
            ? [
                  {
                      id: OrdersTabs.IncomingOrders,
                      title: `${i18n("auth.app.sidebar.incoming-orders")}`,
                      trackingEventTabName: TabNameEvent.OrdersPageIncomingOrders,
                  },
              ]
            : []),
        {
            id: OrdersTabs.OrderHistory,
            title: `${i18n("auth.app.sidebar.order-history")}`,
            trackingEventTabName: TabNameEvent.OrdersPageOrderHistory,
        },
        ...(isScheduledRidesEnabled
            ? [
                  {
                      id: OrdersTabs.ScheduledRides,
                      title: `${i18n("auth.app.sidebar.order-requests")}`,
                      trackingEventTabName: TabNameEvent.OrdersPagScheduledRides,
                  },
              ]
            : []),
    ];

    return tabs;
}
