import {useMemo} from "react";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Table} from "@bolteu/kalep-react";

import {CellRenderer, SubContentRenderer} from "./cell-renderers";
import {ReferenceCell} from "./cell-renderers/ReferenceCell";
import {
    AlignHorizontal,
    AlignVertical,
    ApiDrivenTableTransforms,
    ApiDriverTableInlineAction,
    CellProps,
    Transform,
} from "./types";
import {generateSimpleKey, isReferenceColumn} from "./util";

import ApiColumn = FleetOwnerPortalService.ApiColumn;
import StateColumn = FleetOwnerPortalService.StateColumn;

interface DesktopTableBodyProps {
    numberOfRecords: number;
    userDefinedVisibleColumns: ApiColumn[];
    stateColumns: StateColumn[] | undefined;
    alignVertical: AlignVertical;
    alignHorizontal: AlignHorizontal;
    subColumns: ApiColumn[];
    transforms?: ApiDrivenTableTransforms;
    inlineActions?: ApiDriverTableInlineAction[];
}

export default function DesktopTableBody({
    userDefinedVisibleColumns,
    subColumns,
    numberOfRecords,
    alignHorizontal,
    alignVertical,
    stateColumns,
    transforms,
    inlineActions,
}: DesktopTableBodyProps) {
    const getSubColumns = useMemo(
        () =>
            userDefinedVisibleColumns.reduce((acc, column) => {
                acc[column.key] = subColumns.filter((c) => c.relation_key === column.key);
                return acc;
            }, {} as Record<string, FleetOwnerPortalService.ApiColumn[]>),
        [userDefinedVisibleColumns, subColumns],
    );

    const columns = useMemo((): FleetOwnerPortalService.ApiColumn[] => {
        if (!inlineActions || inlineActions.length === 0) {
            return userDefinedVisibleColumns;
        }
        return [
            ...userDefinedVisibleColumns,
            {
                key: "actions",
                title: "",
                type: FleetOwnerPortalService.ListColumnType.STRING,
                cells: [],
                accessibility: FleetOwnerPortalService.Accessibility.VISIBLE,
            },
        ];
    }, [inlineActions, userDefinedVisibleColumns]);

    const shouldHideStateIndicator = useMemo((): boolean => {
        // If tier is shown, state indicator should be hidden
        return columns.some((column) => column.key === "tier");
    }, [columns]);

    const shouldShowTierTooltip = (cols: ApiColumn[], rowIdx: number) => {
        const tierColumn = cols.find((col) => col.key === "tier");
        return tierColumn?.cells.findIndex((cell: unknown) => cell !== null && cell !== undefined) === rowIdx;
    };

    return (
        <>
            {Array.from({length: numberOfRecords}).map((_, rowIdx) => (
                <Table.Row key={generateSimpleKey(rowIdx)}>
                    {columns.map((column) => {
                        if (column.key === "actions" && inlineActions && inlineActions.length > 0) {
                            return (
                                <Table.Cell key={`action - ${column.cells[rowIdx]}`} align={AlignHorizontal.RIGHT}>
                                    <div className="flex items-center justify-end gap-1">
                                        {inlineActions?.map((action) => action.render(rowIdx))}
                                    </div>
                                </Table.Cell>
                            );
                        }

                        return (
                            <Cell
                                column={column}
                                valueAtIdx={rowIdx}
                                stateColumns={stateColumns}
                                alignVertical={alignVertical}
                                alignHorizontal={alignHorizontal}
                                subColumns={getSubColumns[column.key]}
                                key={`${column.key} - ${column.cells[rowIdx]}`}
                                isReferenceCell={isReferenceColumn(column)}
                                shouldHideStateIndicator={shouldHideStateIndicator}
                                shouldShowTierTooltip={shouldShowTierTooltip(columns, rowIdx)}
                                transform={transforms ? transforms[column.key] : undefined}
                            />
                        );
                    })}
                </Table.Row>
            ))}
        </>
    );
}

function Cell(
    cellProps: CellProps & {
        subColumns: ApiColumn[];
        shouldShowTierTooltip?: boolean;
        shouldHideStateIndicator: boolean;
        isReferenceCell?: boolean;
        stateColumns?: StateColumn[];
        transform?: Transform;
    },
): JSX.Element {
    const stateColumn = cellProps.stateColumns?.find((sc) => sc.relation_key === cellProps.column.key);
    const verticalAlignStyle = cellProps.alignVertical === AlignVertical.TOP ? "content-start align-top" : "";
    const referenceCellStyle = `${verticalAlignStyle} text-left`;

    if (cellProps.isReferenceCell) {
        return (
            <Table.Cell overrideClassName={referenceCellStyle}>
                <ReferenceCell {...cellProps} stateColumn={stateColumn} displayAsLink />
                <SubContentRenderer {...cellProps} />
            </Table.Cell>
        );
    }

    if (cellProps.transform) {
        return (
            <Table.Cell overrideClassName={verticalAlignStyle} align={cellProps.alignHorizontal}>
                {cellProps.transform.renderCellOnDesktop(cellProps)}
            </Table.Cell>
        );
    }

    return (
        <Table.Cell overrideClassName={verticalAlignStyle} align={cellProps.alignHorizontal}>
            <CellRenderer {...cellProps} />
            <SubContentRenderer {...cellProps} />
        </Table.Cell>
    );
}
