export enum FetchStatus {
    Init = "init",
    Success = "success",
    Loading = "loading",
    Error = "error",
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any -- disable this rule until we figure out how to type files
export type Files = any;

export type UseFetch<T, R> = UseFetchSuccess<T, R> | UseFetchLoading<T, R> | UseFetchError<T, R> | UseFetchInit<T>;
type ReturnFetch<T> = ((body: T, files?: Files) => Promise<void>) | null;

interface UseFetchInit<T> {
    status: FetchStatus.Init;
    data: null;
    fetch: ReturnFetch<T>;
    error: FetchError;
}

interface UseFetchSuccess<T, R> {
    status: FetchStatus.Success;
    data: R;
    fetch: ReturnFetch<T>;
    error: FetchError;
}

interface UseFetchLoading<T, R> {
    status: FetchStatus.Loading;
    data: R | null;
    fetch: ReturnFetch<T>;
    error: FetchError;
}

interface UseFetchError<T, R> {
    status: FetchStatus.Error;
    data: R | null;
    fetch: ReturnFetch<T>;
    error: FetchError;
}

export interface ValidatorResult {
    property?: string | number;
    error: string;
    children?: ValidatorResult[];
}

export interface FormValidatorResult {
    attribute: string;
    message: string;
}

export interface FetchError {
    message: string;
    validationErrors: ValidatorResult[];
}

export interface CreateUseFetchProps<T> {
    api: T | null;
}
