import {FC} from "react";

import {useI18n} from "common/hooks/useI18n";

import {Alert} from "@bolteu/kalep-react";

export interface DriverRegistrationDeclinedAlertProps {
    declineReasons: string[] | undefined;
}

export const DriverRegistrationDeclinedAlert: FC<DriverRegistrationDeclinedAlertProps> = ({declineReasons}) => {
    const {i18n} = useI18n();

    if (!declineReasons) {
        return null;
    }

    return (
        <Alert severity="error">
            <p className="mb-2 font-semibold">{i18n("auth.app.fleet.driver-registrations.declined-application")}</p>
            <p className="text-secondary">
                <ul>
                    {declineReasons.map((reason) => (
                        <li key={reason}>{reason}</li>
                    ))}
                </ul>
            </p>
        </Alert>
    );
};
