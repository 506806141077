import LoadingSpinner from "common/components/spinner";
import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Grid, Link} from "@bolteu/kalep-react";

import InfoBlock from "./components/InfoBlock";

import GetCompanyCampaignDetailsResponse = FleetOwnerPortalService.GetCampaignDetailsResponse;
import CampaignStatus = FleetOwnerPortalService.CampaignStatus;

interface CampaignRequirementsProps {
    data: GetCompanyCampaignDetailsResponse | null;
}

const CampaignRequirements = ({data}: CampaignRequirementsProps) => {
    const {i18n} = useI18n();

    if (!data) {
        return <LoadingSpinner show />;
    }

    const DisclaimerLink = () => (
        <Link target="_blank" href={i18n("auth.app.fleet.campaigns.details.requirements.disclaimer_link.link")}>
            {i18n("auth.app.fleet.campaigns.details.requirements.disclaimer_link.text")}
        </Link>
    );

    return (
        <Grid columns={12}>
            <Grid.Item colSpan={7}>
                <Grid columns={7} gap={7}>
                    <Grid.Item colSpan={7}>
                        <p>{data.description}</p>
                    </Grid.Item>
                    {data.summary_block && (
                        <Grid.Item colSpan={4}>
                            <InfoBlock title={data.summary_block.title} items={data.summary_block.items} />
                        </Grid.Item>
                    )}
                    <Grid.Item colSpan={4}>
                        <p className="text-secondary text-sm">
                            {i18n("auth.app.fleet.campaigns.details.requirements.disclaimer", {
                                disclaimerLink: <DisclaimerLink />,
                            })}
                        </p>
                    </Grid.Item>
                </Grid>
            </Grid.Item>
            <Grid.Item colSpan={1}>
                <>{/* Empty gap */}</>
            </Grid.Item>
            <Grid.Item colSpan={4}>
                <div className="flex flex-col gap-4">
                    {data.status !== CampaignStatus.UPCOMING && (
                        <InfoBlock isIsland title={data.bonus_block.title} items={data.bonus_block.items} />
                    )}
                    <InfoBlock isIsland title={data.conditions_block.title} items={data.conditions_block.items} />
                </div>
            </Grid.Item>
        </Grid>
    );
};

export default CampaignRequirements;
