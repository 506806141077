import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {Hint} from "./Hint";
import {UseFormValueType} from "./types";

interface NumberProps {
    useFormValue: UseFormValueType;
    fieldConfig: FleetOwnerRegistrationService.NumberField;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

const Number = ({useFormValue, fieldConfig, onBlur}: NumberProps) => {
    const {NumberField} = useFormValue;
    const {name, label, placeholder, unit, hint, required: isRequired, disabled: isTextDisabled} = fieldConfig;

    return (
        <NumberField
            name={name as never}
            label={label}
            placeholder={placeholder}
            required={isRequired}
            helperText={<Hint hint={hint} />}
            onBlur={onBlur}
            unit={unit}
            unitPosition="start"
            disabled={isTextDisabled}
        />
    );
};

export {Number};
