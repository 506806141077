import {useCallback, useMemo} from "react";
import {CircleFlag} from "react-circle-flags";

import countries, {CountryCode} from "common/geo/constants/countries";
import {useI18n} from "common/hooks/useI18n";

import {OptionProps, SelectOption} from "@bolteu/kalep-react";

export function useAreaCodeOptions() {
    const {i18n} = useI18n();

    const countryData = useMemo(() => {
        const translateCountry = (code: CountryCode, name: string) => i18n(`countries.${code}`) || name;
        return Object.fromEntries(
            countries.map((c) => [
                c.code,
                {countryName: translateCountry(c.code, c.name), areaCode: `+${c.primaryAreaCode}`},
            ]),
        );
    }, [i18n]);

    const options = useMemo(() => {
        return Object.entries(countryData).map(([countryCode, data]) => {
            return {
                title: data.areaCode,
                value: countryCode,
            } as SelectOption;
        });
    }, [countryData]);

    const renderOption = useCallback(
        ({key, option, onClick}: OptionProps) => {
            const countryCode = option.value?.toString();
            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions -- Currently there is a strict typing in Kalep that you can only use <li>
                <li key={key} onClick={onClick}>
                    {countryCode && (
                        <div className="flex min-w-[250px] max-w-[350px] flex-row items-center justify-between gap-2 p-4">
                            <div className="flex flex-row">
                                <CircleFlag countryCode={countryCode} height={24} width={24} />
                                <span className="ml-3">{countryData[countryCode].countryName}</span>
                            </div>
                            <span className="text-base text-neutral-700">{option.title}</span>
                        </div>
                    )}
                    {countryCode === null && <></>}
                </li>
            );
        },
        [countryData],
    );

    return {options, renderOption, countryData};
}
