import {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {CommonRoute} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import NoAuthLayout from "features/account/components/NoAuthLayout";

import {Button} from "@bolteu/kalep-react";

import {useForgotPasswordForm} from "./hooks/useForgotPasswordForm";

const ForgotPassword = () => {
    const {i18n} = useI18n();
    const [resetLinkSentToEmail, setResetLinkSentToEmail] = useState<string | null>(null);
    const navigate = useNavigate();

    const Form = useForgotPasswordForm(setResetLinkSentToEmail);

    useEffect(() => {
        const pageTitle = i18n("forgot-password.page_title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    const goToLogin = useCallback(() => {
        navigate(CommonRoute.AUTH);
    }, [navigate]);

    const title = resetLinkSentToEmail ? i18n("forgot-password.link_sent") : i18n("forgot-password.title");
    const description = resetLinkSentToEmail ? (
        <>
            {i18n("forgot-password.email_sent_to")} <span className="font-semibold">{resetLinkSentToEmail}</span>
        </>
    ) : (
        <>{i18n("forgot-password.info_text")}</>
    );
    const returnLogInTopMargin = resetLinkSentToEmail ? "mt-2" : "mt-1";

    return (
        <NoAuthLayout gap="small">
            <h1 className="text-2xl font-semibold">{title}</h1>
            <p className="mb-3 w-full text-center text-neutral-700">{description}</p>
            {resetLinkSentToEmail === null && Form}
            <Button
                variant="secondary"
                onClick={goToLogin}
                overrideClassName={`max-w-[356px] ${returnLogInTopMargin}`}
                fullWidth
            >
                {i18n("forgot-password.return_to_login")}
            </Button>
        </NoAuthLayout>
    );
};

export default ForgotPassword;
