import {useCallback, useEffect, useState} from "react";

import {FetchStatus} from "common/hooks/useFetch";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {SelectOption} from "@bolteu/kalep-react";

interface Props {
    fetchStatus: FetchStatus;
    fetchData: FleetPortalOrderService.GetDriversForAssignmentResponse | null;
    selectedDriverId?: number | null;
    onChange: (driverId?: number) => void;
}

export const useAssigmentDrivers = ({fetchData, fetchStatus, selectedDriverId, onChange}: Props) => {
    const [driversOptions, setDriversOptions] = useState<SelectOption[] | null>(null);

    useEffect(() => {
        if (fetchStatus === FetchStatus.Success && fetchData) {
            setDriversOptions(fetchData.list.map((driver) => ({title: driver.name, value: driver.id})));
        }
    }, [fetchData, fetchStatus]);

    useEffect(() => {
        if (selectedDriverId && driversOptions && !driversOptions.find((option) => option.value === selectedDriverId)) {
            onChange(undefined);
        }
    }, [driversOptions, onChange, selectedDriverId]);

    const onDriverChange = useCallback(
        (newValue: SelectOption | SelectOption[] | null) => {
            let driverId: number | undefined;
            if (newValue === null) {
                driverId = undefined;
            } else {
                const newDriver = Array.isArray(newValue) ? newValue[0] : newValue;
                driverId = newDriver.value as number;
            }
            onChange(driverId);
        },
        [onChange],
    );

    return {onDriverChange, driversOptions};
};
