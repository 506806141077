import {useContext, useEffect} from "react";

import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import {useTabs} from "common/components/tab/useTabs";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";

import {FeaturesContextProvider} from "../../../../FeaturesProvider";
import ApiSettings from "./components/ApiSettings/ApiSettings";
import GeneralSettings from "./components/GeneralSettings/GeneralSettings";
import {useTabOptions} from "./hooks/useTabOptions";

export enum SettingsTab {
    General = "general",
    API = "api",
}

const SettingsPage = () => {
    const {i18n} = useI18n();
    const features = useContext(FeaturesContextProvider);

    const tabs = useTabOptions(features?.enterprise_api ?? false);
    const {activeTab, TabSelector} = useTabs(tabs, SettingsTab.General);

    useEffect(() => {
        const pageTitle = i18n("auth.app.settings.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    return (
        <Page>
            <div className="mb-6 flex max-w-6xl flex-col gap-4">
                <ResponsiveHeader type={ResponsiveHeaderType.MainPage} text={i18n("auth.app.settings.title")} />
            </div>
            <div className="mb-6">
                <TabSelector />
            </div>
            {activeTab === SettingsTab.General && <GeneralSettings />}
            {activeTab === SettingsTab.API && <ApiSettings />}
        </Page>
    );
};

export default SettingsPage;
