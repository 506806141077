import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {CreateDrawerType, CreateOrderData, StepType} from "../../types";
import {AssignmentDetails} from "./steps/AssignmentDetails";
import {RideDetails} from "./steps/RideDetails";
import {RouteDetails} from "./steps/RouteDetails";

interface Props {
    data: CreateOrderData;
    onChange: (data: Partial<CreateOrderData>) => void;
    validationErrors: {[key in StepType]?: FleetPortalOrderService.ValidationError[]};
    drawerType: CreateDrawerType;
    activeStep: StepType;
    geoLocation: GeolocationPosition | null;
}

export const Steps = ({data, onChange, validationErrors, drawerType, activeStep, geoLocation}: Props) => {
    switch (activeStep) {
        case 0:
            return (
                <RouteDetails
                    data={data}
                    onChange={onChange}
                    validationErrors={validationErrors[0]}
                    drawerType={drawerType}
                    geoLocation={geoLocation}
                />
            );
        case 1:
            return (
                <RideDetails
                    data={data}
                    onChange={onChange}
                    validationErrors={validationErrors[1]}
                    drawerType={drawerType}
                />
            );
        case 2:
            return (
                <AssignmentDetails
                    data={data}
                    onChange={onChange}
                    validationErrors={validationErrors[2]}
                    drawerType={drawerType}
                />
            );
        default:
            return UnreachableCode.never(activeStep, null);
    }
};
