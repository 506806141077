import {useCallback, useEffect, useState} from "react";

import ManageDocument from "common/components/document/DocumentUpload/ManageDocument";
import {DocumentType} from "common/components/document/useDocumentsList";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";

import {CarApplicationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

interface UploadVehicleDocumentProps {
    documentType: DocumentType;
    vehicleApplicationId: number;
    error?: string;
}

const uploadFunction = (
    api: Api,
    body: CarApplicationService.UploadCarApplicationDocumentRequest,
    files: CarApplicationService.Files,
) => api.carApplication.v2UploadCarApplicationDocument(body, files);

const deleteFunction = (api: Api, body: CarApplicationService.DeleteCarApplicationDocumentRequest) =>
    api.carApplication.v2DeleteCarApplicationDocument(body);

const UploadVehicleDocument = ({documentType, vehicleApplicationId, error}: UploadVehicleDocumentProps) => {
    const {i18n} = useI18n();
    const {fetch: upload, data: uploadData, status: uploadStatus} = useFetch(uploadFunction);
    const {fetch: deleteFile, status: deleteStatus} = useFetch(deleteFunction);

    const {
        title: label,
        description: hint,
        is_required: isRequired,
        is_expiry_required: isExpiryRequired,
    } = documentType;

    const [file, setFile] = useState<CarApplicationService.UploadCarApplicationDocumentResponse | null>(null);

    const [errorMessage, setErrorMessage] = useState<string | undefined>(error);

    useEffect(() => {
        setErrorMessage(error);
    }, [error, setErrorMessage]);

    useEffect(() => {
        if (uploadStatus === FetchStatus.Success) {
            setFile((previousFile) => ({
                ...previousFile,
                document_id: uploadData.document_id,
                document_name: uploadData.document_name,
                document_expires: uploadData.document_expires ?? null,
            }));
        }
        if (uploadStatus === FetchStatus.Error) {
            setErrorMessage(i18n("api.default_error"));
        }
    }, [uploadStatus, setFile, i18n, uploadData]);

    const onConfirmClick = useCallback(
        async (selectedFile: File | null, expiryDate?: string) => {
            if (!selectedFile) {
                setErrorMessage(i18n("api.error.FILE_SIZE_EMPTY"));
                return;
            }

            setErrorMessage(undefined);

            if (!vehicleApplicationId || !upload) {
                return;
            }

            await upload(
                {
                    car_application_id: vehicleApplicationId,
                    ...(expiryDate ? {expires: expiryDate} : {}),
                },
                {
                    [documentType.name]: selectedFile,
                },
            );
        },
        [documentType.name, vehicleApplicationId, upload, i18n],
    );

    const onDeleteClick = useCallback(
        async (documentId: number) => {
            if (!vehicleApplicationId || !deleteFile) {
                return;
            }

            await deleteFile({
                car_application_id: vehicleApplicationId,
                document_id: documentId,
            });
            setFile(null);
        },
        [deleteFile, vehicleApplicationId, setFile],
    );

    return (
        <ManageDocument
            isExpiryRequired={isExpiryRequired}
            isRequired={isRequired}
            uploadingStatus={uploadStatus}
            deletingStatus={deleteStatus}
            documentExpires={file?.document_expires ?? undefined}
            documentId={file?.document_id ?? undefined}
            documentName={file?.document_name ?? undefined}
            error={errorMessage}
            hint={hint}
            label={label}
            onDelete={onDeleteClick}
            onUpload={onConfirmClick}
        />
    );
};

export default UploadVehicleDocument;
