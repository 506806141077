import {twJoin} from "tailwind-merge";

import {FleetOwnerVerificationNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";

interface TextVariableProps {
    value: FleetOwnerVerificationNoAuthService.TextVariable | undefined;
}

const TextVariable = ({value}: TextVariableProps) => {
    if (!value) {
        return null;
    }

    const {value: text, styles} = value;

    const styleClasses = (styles ?? []).map((style) => {
        if (style === FleetOwnerVerificationNoAuthService.TextStyle.BOLD) {
            return "font-semibold";
        }
        return "";
    });

    return <span className={twJoin(styleClasses)}>{text}</span>;
};

interface CustomTextProps {
    value: FleetOwnerVerificationNoAuthService.CustomText;
}

export const CustomText = ({value}: CustomTextProps): React.ReactElement => {
    const {text, variables} = value;

    if (variables.length === 0) {
        return <span>{text}</span>;
    }

    const variablesSet = new Set(variables.map((v) => v.key));

    const transformedText = text
        .split(/\${(\w+)}/g)
        .filter(Boolean)
        .map((part): [part: string | JSX.Element, key: string] => {
            if (variablesSet.has(part)) {
                return [<TextVariable value={variables.find((v) => v.key === part)} />, part];
            }
            return [part, part];
        })
        .map(([part, key]) => <span key={key}>{part}</span>);

    return <span>{transformedText}</span>;
};
