import {useContext} from "react";
import {useIntl} from "react-intl";

import {CountryCode} from "common/geo/constants/countries";
import {useI18n} from "common/hooks/useI18n";
import {apiTimestampToDate} from "common/util/apiTimestampToDate";
import {hasExpired, isExpiring} from "common/util/dateExpiryUtil";
import {format} from "date-fns";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {countriesHaveTransportLicenseExpiry} from "features/companies/vehicles/config/transportLicenseConfig";
import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

export function useTransportLicenseExpiryData({vehicle}: {vehicle: FleetOwnerPortalService.GetCarResponse}) {
    const intl = useIntl();
    const {i18n} = useI18n();
    const country = useContext(AccountContextProvider)?.getFleet()?.country ?? "";

    const shouldShowTransportLicenseExpires =
        countriesHaveTransportLicenseExpiry.includes(country as CountryCode) &&
        vehicle.car_transport_licence_expires !== null;

    if (!shouldShowTransportLicenseExpires || !vehicle.car_transport_licence_expires) {
        return null;
    }

    const date = apiTimestampToDate(vehicle.car_transport_licence_expires);
    const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];
    const formattedDate = format(date, "P", {locale: dateFnsLocale});
    if (hasExpired(date)) {
        return {
            description: i18n("auth.app.fleet.driver-profile.documents.row.expired", {date: formattedDate}),
            classes: "text-red-700",
        };
    }

    if (isExpiring(date)) {
        return {
            description: i18n("auth.app.fleet.driver-profile.documents.row.expiring", {date: formattedDate}),
            classes: "text-red-700",
        };
    }

    return {
        description: i18n("auth.app.fleet.driver-profile.documents.row.expiring", {date: formattedDate}),
        classes: "",
    };
}
