import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import Pagination from "common/components/Pagination";
import {useBrowserGeoLocation} from "common/geo/hooks/useBrowserGeoLocation";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, Period} from "common/services/period";
import {getCurrentDate} from "common/util/dateUtil";
import {getIsoDate} from "common/util/FormatUtil";
import {addDays} from "date-fns";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {useSnackbar} from "@bolteu/kalep-react";

import {ScheduledRidesContextProvider} from "../ScheduledRidesContextProvider";
import {AssignOrderData, AssignScheduledRideDialog} from "./components/AssignScheduledRideDialog";
import {CancelScheduledRideDialog} from "./components/CancelScheduledRideDialog";
import {CreateOrderDrawer} from "./components/create-drawer";
import {ScheduledRidesTable} from "./components/ScheduledRidesTable";
import {TableFiltersAndActions} from "./components/TableFiltersAndActions";
import {DATE_RANGE_LIMIT_DAYS, SCHEDULED_RIDES_TABLE_MAX_LIMIT} from "./constants";
import {CreateDrawerType} from "./types";

const zeroPageNum = 0;

const getScheduledRidesFunction = (api: Api, body: FleetPortalOrderService.GetScheduledRidesRequest) =>
    api.fleetPortalOrder.getScheduledRides(body);

const ScheduledRides = () => {
    const {i18n} = useI18n();
    const schedulingLimits = useContext(ScheduledRidesContextProvider).getSchedulingLimits();
    const [pageNum, setPageNum] = useState(0);
    const cursorPerPage = useRef<Record<number, string | undefined>>({[zeroPageNum]: undefined});
    const [driverSearchValue, setDriverSearchValue] = useState<number[]>([]);
    const [selectedPeriod, setSelectedPeriod] = useState<ApiPeriod | undefined>();
    const [scheduledRideIdToDelete, setScheduledRideIdToDelete] = useState<number | null>(null);
    const [assignOrderData, setAssignOrderData] = useState<AssignOrderData | null>(null);
    const [createOrderDrawerType, setCreateOrderDrawerType] = useState<CreateDrawerType | null>(null);
    const snackbar = useSnackbar();
    const navigate = useNavigate();
    const {getLiveMapPath} = useAbsolutePath();
    const geoLocation = useBrowserGeoLocation({watchPosition: false});

    const {
        data: scheduledRides,
        fetch: scheduledRidesFetch,
        status: scheduledRidesStatus,
    } = useFetch(getScheduledRidesFunction);

    useEffect(() => {
        // To set initial period from config
        setSelectedPeriod({
            period: Period.CUSTOM,
            startDate: getCurrentDate(),
            endDate: addDays(
                getCurrentDate(),
                Math.min(schedulingLimits.maximum_date_in_future_days, DATE_RANGE_LIMIT_DAYS - 1),
            ),
        });
    }, [schedulingLimits.maximum_date_in_future_days]);

    const getRequestDates = useCallback((period: ApiPeriod) => {
        return period.period === Period.CUSTOM
            ? {start: period.startDate, end: period.endDate}
            : apiPeriodToDates(period);
    }, []);

    const getScheduledRides = useCallback(
        async (newPageNum: number) => {
            if (!scheduledRidesFetch || !selectedPeriod) {
                return;
            }
            const {start, end} = getRequestDates(selectedPeriod);
            const cursor = newPageNum === 0 ? undefined : cursorPerPage.current[newPageNum - 1];
            scheduledRidesFetch({
                cursor,
                limit: SCHEDULED_RIDES_TABLE_MAX_LIMIT,
                start: getIsoDate(start),
                end: getIsoDate(end),
                ...(driverSearchValue.length && {driver_ids: driverSearchValue}),
            });
        },
        [driverSearchValue, getRequestDates, scheduledRidesFetch, selectedPeriod],
    );

    useEffect(() => {
        if (scheduledRidesStatus === FetchStatus.Success) {
            cursorPerPage.current = {...cursorPerPage.current, [pageNum]: scheduledRides.cursor};
        }
    }, [pageNum, scheduledRides?.cursor, scheduledRidesStatus]);

    useEffect(() => {
        cursorPerPage.current = {[zeroPageNum]: undefined};
        setPageNum(0);
        getScheduledRides(0);
    }, [getScheduledRides]);

    const onCloseCancelDialog = useCallback(() => {
        setScheduledRideIdToDelete(null);
    }, []);

    const onCancel = useCallback(() => {
        setScheduledRideIdToDelete(null);
        getScheduledRides(pageNum);
    }, [getScheduledRides, pageNum]);

    const onCloseEditDialog = useCallback(() => {
        setAssignOrderData(null);
    }, []);

    const onCloseCreateDialog = useCallback(() => {
        setCreateOrderDrawerType(null);
    }, []);

    const onEdit = useCallback(() => {
        setAssignOrderData(null);
        getScheduledRides(pageNum);
    }, [getScheduledRides, pageNum]);

    const navigateToLiveMap = useCallback(() => {
        navigate(getLiveMapPath());
    }, [getLiveMapPath, navigate]);

    const onCreateScheduledOrder = useCallback(() => {
        getScheduledRides(pageNum);
        snackbar.add(
            {
                description: i18n("auth.app.orders.scheduled_rides.scheduled-order-created"),
                dismissible: false,
            },
            {timeout: 4000},
        );
    }, [getScheduledRides, i18n, pageNum, snackbar]);

    const onCreateInstantOrder = useCallback(() => {
        snackbar.add(
            {
                title: i18n("auth.app.orders.scheduled_rides.instant-order-created"),
                description: i18n("auth.app.orders.scheduled_rides.instant-order-created-description"),
                dismissible: false,
                actions: [
                    {
                        label: i18n("auth.app.orders.scheduled_rides.instant-order-created-action"),
                        onClick: navigateToLiveMap,
                    },
                ],
            },
            {timeout: 6000},
        );
    }, [i18n, navigateToLiveMap, snackbar]);

    const onCreate = useCallback(
        (type: CreateDrawerType) => {
            setCreateOrderDrawerType(null);
            switch (type) {
                case CreateDrawerType.SCHEDULE:
                    onCreateScheduledOrder();
                    break;
                case CreateDrawerType.INSTANT:
                    onCreateInstantOrder();
                    break;
                default:
                    UnreachableCode.never(type);
            }
        },
        [onCreateInstantOrder, onCreateScheduledOrder],
    );

    const onPageChange = useCallback(
        async (page: number) => {
            if (page === pageNum) {
                return;
            }
            getScheduledRides(page);
            setPageNum(page);
        },
        [getScheduledRides, pageNum],
    );

    const onCreateOrderClick = useCallback(
        (type: CreateDrawerType) => () => {
            setCreateOrderDrawerType(type);
        },
        [],
    );

    if (scheduledRidesStatus === FetchStatus.Error) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }

    const AssignOrderDialog = assignOrderData ? (
        <AssignScheduledRideDialog onClose={onCloseEditDialog} onEdit={onEdit} data={assignOrderData} />
    ) : null;

    const CreateOrderDrawerWrapper = createOrderDrawerType ? (
        <CreateOrderDrawer
            type={createOrderDrawerType}
            onClose={onCloseCreateDialog}
            onCreate={onCreate}
            geoLocation={geoLocation}
        />
    ) : null;

    const isLoading = [FetchStatus.Init, FetchStatus.Loading].includes(scheduledRidesStatus);

    // Always show next page available if cursor exist and keep already visited page numbers
    const maxPageNum = Math.max(pageNum, ...Object.keys(cursorPerPage.current).map((val) => Number(val)));
    const totalCount = (maxPageNum + (cursorPerPage.current[maxPageNum] ? 2 : 1)) * SCHEDULED_RIDES_TABLE_MAX_LIMIT;

    return (
        <div className="flex w-full flex-col gap-6">
            <CancelScheduledRideDialog
                orderdId={scheduledRideIdToDelete}
                show={!!scheduledRideIdToDelete}
                onClose={onCloseCancelDialog}
                onCancel={onCancel}
            />
            {AssignOrderDialog}
            {CreateOrderDrawerWrapper}
            <TableFiltersAndActions
                selectedPeriod={selectedPeriod}
                onSearchChange={setDriverSearchValue}
                onPeriodChange={setSelectedPeriod}
                onCreateOrderClick={onCreateOrderClick}
            />
            <ScheduledRidesTable
                scheduledRidesResponse={scheduledRides ?? {columns: [], order_ids: []}}
                isLoading={isLoading}
                editScheduledRide={setAssignOrderData}
                deleteScheduledRide={setScheduledRideIdToDelete}
            />
            <Pagination
                currentPage={pageNum}
                pageSize={SCHEDULED_RIDES_TABLE_MAX_LIMIT}
                totalCount={totalCount}
                onPageChange={onPageChange}
                disabled={isLoading}
            />
        </div>
    );
};

export default ScheduledRides;
