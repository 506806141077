import {FC, useCallback} from "react";

import {EventName} from "common/constants/events";
import {useCopyToClipboard} from "common/hooks/useCopyToClipboard";
import {useTracking} from "common/hooks/useTracking";

import {IconButton} from "@bolteu/kalep-react";
import {Copy} from "@bolteu/kalep-react-icons";

interface CopyIconButtonProps {
    value: string;
    eventOnClick?: EventName;
}

const CopyIconButton: FC<CopyIconButtonProps> = ({value, eventOnClick}) => {
    const {trackEvent} = useTracking();
    const {copyToClipboard} = useCopyToClipboard();

    const onClick = useCallback(() => {
        copyToClipboard(value);

        if (eventOnClick) {
            trackEvent(eventOnClick);
        }
    }, [value, trackEvent, eventOnClick, copyToClipboard]);

    return (
        <IconButton
            variant="ghost"
            aria-label="Copy to clipboard"
            size="sm"
            icon={<Copy />}
            onClick={onClick}
            value="copyIconBtn"
        />
    );
};

export default CopyIconButton;
