/**
 * Example switch use case
 * switch( prop ) {
 *     case Enum.Val1
 *      return "A"
 *     case Enum.Val2
 *      return "B"
 *     default
 *      return new UnreachableCode(prop, "C")
 * }
 */
export class UnreachableCode {
    /**
     * Will give compilation error, if code might be reached (new enum value added, for example)
     *
     * @param _input value used for the switch
     * @param defaultValue value the function will return at runtime. (No Error is thrown).
     */
    public static never<T>(_input: never, defaultValue?: T): T {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- intended ignore
        // @ts-ignore
        return defaultValue;
    }
}
