import {FC} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {ListItem} from "@bolteu/kalep-react";

import SubFleet = FleetOwnerService.SubFleet;

interface Props {
    subFleet: SubFleet;
}

export const SubFleetListItem: FC<Props> = ({subFleet}) => {
    const {i18n} = useI18n();

    return (
        <ListItem
            key={subFleet.id}
            label={subFleet.name}
            overrideClassName="border-t border-neutral-300 px-0 gap-0"
            description={`${subFleet.driver_count} ${i18n("common.drivers")}`}
        />
    );
};
