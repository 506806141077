import {useCallback, useContext, useEffect} from "react";
import {useIntl} from "react-intl";

import {EventName} from "common/constants/events";
import {MimeTypes} from "common/constants/mimeTypes";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {ApiPeriod, filePeriod} from "common/services/period";
import {cleanStringForFileName, downloadUtf8File} from "common/util/downloadUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button} from "@bolteu/kalep-react";

import {mapBaseListRequest} from "../utils/mapBaseListRequest";

import GetCampaignListCSVRequest = FleetOwnerPortalService.GetCampaignListCSVRequest;
import CampaignFilters = FleetOwnerPortalService.CampaignFilters;

interface DownloadCSVButtonProps {
    filters: CampaignFilters;
    period: ApiPeriod;
}

const fetchFunction = (api: Api, body: GetCampaignListCSVRequest) =>
    api.fleetOwnerPortal.fleetIncentivesGetCampaignListCSV(body);

const DownloadCSVButton = ({filters, period}: DownloadCSVButtonProps) => {
    const intl = useIntl();
    const {i18n} = useI18n();

    const {fetch, data, status} = useFetch(fetchFunction);
    const {trackEvent} = useTracking();

    const account = useContext(AccountContextProvider);
    const companyName = account.selectedCompany?.company.name;

    const onButtonClick = useCallback(() => {
        if (fetch) {
            fetch(mapBaseListRequest(intl.locale, period, filters));
        }
    }, [fetch, intl.locale, filters, period]);

    useEffect(() => {
        if (companyName && data && status === FetchStatus.Success) {
            const periodStr = filePeriod(period, intl.locale);
            const cleanCompanyName = cleanStringForFileName(companyName);
            const fileName = `${i18n("auth.app.fleet.campaigns.list.csv_title")}-${periodStr}-${cleanCompanyName}`;
            downloadUtf8File(data.csv, MimeTypes.CSV, fileName);

            trackEvent(EventName.CampaignsListReportDownloadSuccessful);
        }
    }, [data, status, companyName, i18n, intl.locale, period, trackEvent]);

    useEffect(() => {
        if (status === FetchStatus.Error) {
            trackEvent(EventName.CampaignsListReportDownloadFailed);
        }
    }, [status, trackEvent]);

    return (
        <Button variant="secondary" loading={status === FetchStatus.Loading} onClick={onButtonClick}>
            {i18n("common.download")}
        </Button>
    );
};

export default DownloadCSVButton;
