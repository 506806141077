import {useCallback, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {FetchStatus} from "common/hooks/useFetch";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Spinner} from "@bolteu/kalep-react";

import {DeleteDialog} from "./components/DeleteDialog";
import {EditShiftDialog} from "./components/EditShiftDialog";
import {NoShifts} from "./components/NoShifts";
import {useShifts} from "./hooks/useShifts";

export interface Props {
    shifts: FleetShiftManagementService.ShiftsResponse | null;
    shiftGetStatus: FetchStatus;
    getShifts: () => void;
}

export const Planning = ({shifts, shiftGetStatus, getShifts}: Props) => {
    const [shiftIdToDelete, setShiftIdToDelete] = useState<number | null>(null);
    const [editShift, setEditShift] = useState<FleetShiftManagementService.ShiftWithAllocationSummary | null>(null);

    const onCloseDeleteDialog = useCallback(() => {
        setShiftIdToDelete(null);
    }, []);

    const onDelete = useCallback(() => {
        setShiftIdToDelete(null);
        getShifts();
    }, [getShifts]);

    const onShiftCardDeleteClick = useCallback((shiftId: number) => {
        setShiftIdToDelete(shiftId);
    }, []);

    const onShiftEdited = useCallback(() => {
        getShifts();
    }, [getShifts]);

    const onEditShiftClick = useCallback((shift: FleetShiftManagementService.ShiftWithAllocationSummary) => {
        setEditShift(shift);
    }, []);

    const closeEditDialog = useCallback(() => {
        setEditShift(null);
    }, []);

    const shiftsList = useShifts({
        shifts: shifts?.list,
        fetchStatus: shiftGetStatus,
        onDeleteClick: onShiftCardDeleteClick,
        onEditClick: onEditShiftClick,
    });

    let Body: JSX.Element | null = null;
    if ([FetchStatus.Init, FetchStatus.Loading].includes(shiftGetStatus)) {
        Body = (
            <div className="m-auto mt-16 flex justify-center">
                <Spinner />
            </div>
        );
    } else if (shiftGetStatus === FetchStatus.Error) {
        Body = <ErrorView type={ErrorViewType.SomethingWentWrong} onActionClick={getShifts} />;
    } else if (!shiftsList) {
        Body = <NoShifts />;
    } else {
        Body = shiftsList;
    }

    return (
        <>
            <DeleteDialog
                show={!!shiftIdToDelete}
                onClose={onCloseDeleteDialog}
                onDelete={onDelete}
                shiftId={shiftIdToDelete}
            />
            {editShift && (
                <EditShiftDialog closeDialog={closeEditDialog} onShiftEdited={onShiftEdited} shift={editShift} />
            )}
            {Body}
        </>
    );
};
