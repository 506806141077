interface TopBarTailwindClassNameConfig {
    topBarHeightClass: string;
    topBarHeightPx: number;
    tableFixedHeaderClass: string;
    sideBarTopClass: string;
}

const TOPBAR_HEIGHT_TAILWIND_CONFIG: Record<string, TopBarTailwindClassNameConfig> = {
    height0: {
        topBarHeightClass: "h-0",
        topBarHeightPx: 0,
        tableFixedHeaderClass: "[&_thead]:sticky [&_thead]:top-0 [&_thead]:bg-layer-floor-1",
        sideBarTopClass: "top-0",
    },
    height14: {
        topBarHeightClass: "h-14",
        topBarHeightPx: 56,
        tableFixedHeaderClass: "[&_thead]:sticky [&_thead]:top-14 [&_thead]:bg-layer-floor-1",
        sideBarTopClass: "top-14",
    },
    height16: {
        topBarHeightClass: "h-16",
        topBarHeightPx: 64,
        tableFixedHeaderClass: "[&_thead]:sticky [&_thead]:top-16 [&_thead]:bg-layer-floor-1",
        sideBarTopClass: "top-16",
    },
};

const TABLE_FIXED_COLUMN_CLASSNAME = `[&_thead]:z-[1] [&_th:nth-child(1)]:sticky [&_th:nth-child(1)]:left-0 [&_th:nth-child(1)]:bg-layer-floor-1 [&_td:nth-child(1)]:sticky [&_td:nth-child(1)]:left-0 [&_td:nth-child(1)]:bg-layer-floor-1`;

export type {TopBarTailwindClassNameConfig};
export {TOPBAR_HEIGHT_TAILWIND_CONFIG, TABLE_FIXED_COLUMN_CLASSNAME};
