import {useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import {generatePath} from "react-router-dom";

import DataAndMeasuresCell from "common/components/table/DataAndMeasuresCell";
import {CustomizableTableHeaders} from "common/components/table/TableWithCustomizableColumns";
import {Dash} from "common/constants";
import {FleetRoute} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";
import {format} from "date-fns";
import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip, GhostButton, Tooltip} from "@bolteu/kalep-react";
import {CardOutlined, CashDriverOutlined} from "@bolteu/kalep-react-icons";
import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";

import {OrderRouteCell} from "../../components/OrderRouteCell";

import OrdersTableData = FleetOwnerReportingService.FleetPortalV2Order;

enum EndingStatus {
    DriverBooking = "driver_booking",
    WaitingDriverConfirmation = "waiting_driver_confirmation",
    ClientCancelled = "client_cancelled",
    DriverDidNotRespond = "driver_did_not_respond",
    DriverRejected = "driver_rejected",
    DriverCancelledAfterAccept = "driver_cancelled_after_accept",
    DriverAccepted = "driver_accepted",
    DriverArrivedToClient = "driver_arrived_to_client",
    ClientDidNotShow = "client_did_not_show",
    DrivingWithClient = "driving_with_client",
    ArrivedToDestination = "arrived_to_destination",
    Finished = "finished",
    PaymentBookingFailed = "payment_booking_failed",
    NoTaxiProvided = "no_taxi_provided",
}

enum PaymentMethod {
    Cash = "cash",
    Card = "card",
    AppPayment = "app_payment",
}

const isCancelled = (status: string) => {
    return [
        EndingStatus.ClientDidNotShow,
        EndingStatus.ClientCancelled,
        EndingStatus.DriverDidNotRespond,
        EndingStatus.DriverRejected,
        EndingStatus.DriverCancelledAfterAccept,
    ]
        .map((val) => String(val))
        .includes(status);
};

export function useTableColumns() {
    const intl = useIntl();
    const {i18n} = useI18n();

    const goToDriverProfile = useCallback(
        (id: string) => () => {
            window.open(generatePath(FleetRoute.DRIVER, {id}));
        },
        [],
    );

    return useMemo<CustomizableTableHeaders<OrdersTableData & {id: number}>>(() => {
        return {
            accepted_time: {
                label: i18n("common.date"),
                renderCell: (item: OrdersTableData) => {
                    const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];
                    const date = format(item.accepted_time * 1000, "P", {locale: dateFnsLocale});
                    const time = format(item.accepted_time * 1000, "HH:mm", {locale: dateFnsLocale});
                    const timeAssigned = format(item.driver_assigned_time * 1000, "HH:mm:ss", {
                        locale: dateFnsLocale,
                    });

                    return (
                        <DefaultCellContent compact>
                            <Tooltip
                                content={i18n("auth.app.orders.history.assigned_at", {time: timeAssigned})}
                                placement="top"
                            >
                                <div className="flex h-full w-full flex-col justify-center">
                                    <div className="text-primary antialiased">{date}</div>
                                    <div className="text-secondary whitespace-nowrap text-xs antialiased">{time}</div>
                                </div>
                            </Tooltip>
                        </DefaultCellContent>
                    );
                },
            },
            driver: {
                label: i18n("common.driver"),
                renderCell: (item: OrdersTableData) => (
                    <DefaultCellContent>
                        <GhostButton
                            onClick={goToDriverProfile(String(item.driver_id))}
                            overrideClassName="hover:underline"
                        >
                            {`${item.driver_first_name} ${item.driver_last_name}` || Dash.EM}
                        </GhostButton>
                    </DefaultCellContent>
                ),
                nonConfigurable: true,
            },
            car_reg_number: {
                label: i18n("auth.app.fleet.vehicles.license_plate"),
                renderCell: (item: OrdersTableData) => (
                    <DefaultCellContent>{item.car_reg_number || Dash.EM}</DefaultCellContent>
                ),
                hideByDefault: true,
            },
            route: {
                label: i18n("auth.app.orders.history.route"),
                renderCell: (item: OrdersTableData) => (
                    <DefaultCellContent>
                        <OrderRouteCell stops={item.order_stops} />
                    </DefaultCellContent>
                ),
                width: "20rem",
            },
            category: {
                label: i18n("auth.app.fleet.live-map.category"),
                renderCell: (item: OrdersTableData) => (
                    <DefaultCellContent>{item.search_category?.name || Dash.EM}</DefaultCellContent>
                ),
            },
            status: {
                label: i18n("auth.app.fleet.vehicles.status"),
                renderCell: (item: OrdersTableData) => (
                    <DefaultCellContent>
                        <div>
                            <Chip
                                label={i18n(`order.status.${item.order_try_state as EndingStatus}`)}
                                appearance={isCancelled(item.order_try_state) ? "danger" : "neutral"}
                                variant="outline"
                                size="sm"
                            />
                        </div>
                    </DefaultCellContent>
                ),
            },
            ride_distance: {
                label: i18n("auth.app.orders.incoming.distance"),
                renderCell: (item: OrdersTableData) => (
                    <DataAndMeasuresCell
                        data={item.ride_distance ? Number(item.ride_distance.toFixed(1)) : Dash.EM}
                        measure={item.ride_distance_unit}
                    />
                ),
                align: "right",
            },
            tip: {
                label: i18n("auth.app.fleet.reports.header.tips"),
                renderCell: (item: OrdersTableData) => (
                    <DataAndMeasuresCell data={item.tip || Dash.EM} measure={item.currency || ""} />
                ),
                align: "right",
            },
            total_price: {
                label: i18n("auth.app.orders.incoming.price"),
                align: "right",
                renderCell: (item: OrdersTableData) => {
                    return (
                        <div className="flex flex-nowrap items-center justify-end">
                            <DataAndMeasuresCell
                                data={item.total_price ? Number(item.total_price.toFixed(2)) : Dash.EM}
                                measure={item.currency || ""}
                                className="w-fit"
                            />
                            <Tooltip
                                content={
                                    item.payment_method
                                        ? i18n(
                                              `auth.app.invoices.rider-invoices.payment-methods.${
                                                  item.payment_method as PaymentMethod
                                              }`,
                                          )
                                        : Dash.EM
                                }
                                placement="top"
                            >
                                <div className="text-secondary flex items-center">
                                    {!item.payment_method && <div className="text-tertiary text-sm">{Dash.EM}</div>}
                                    {item.payment_method === "cash" && <CashDriverOutlined size="sm" />}
                                    {["app_payment", "card"].includes(item.payment_method || "") && (
                                        <CardOutlined size="sm" />
                                    )}
                                </div>
                            </Tooltip>
                        </div>
                    );
                },
                width: "5rem",
            },
        };
    }, [i18n, intl.locale, goToDriverProfile]);
}
