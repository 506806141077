import {useCallback} from "react";

import {useI18n} from "common/hooks/useI18n";

import {Button} from "@bolteu/kalep-react";

export interface RenderChips<TFilterType> {
    (filterKey: keyof TFilterType): () => void;
}

interface FilteredByProps<TFilterType> {
    currentFilterValues: TFilterType;
    setFilters: (filters: TFilterType) => void;
    initialFilterValues: TFilterType;
    renderChips: (onRemove: RenderChips<TFilterType>) => (JSX.Element | null)[];
}
// eslint-disable-next-line @typescript-eslint/ban-types -- didn't find any better alternatives for iterating over object keys
function FilteredBy<TFilterType extends Object>({
    currentFilterValues,
    setFilters,
    initialFilterValues,
    renderChips,
}: FilteredByProps<TFilterType>) {
    const {i18n} = useI18n();

    const handleClearFiltersButtonClick = useCallback(() => {
        setFilters(initialFilterValues);
    }, [setFilters, initialFilterValues]);

    const onFilterRemove = useCallback(
        (key: keyof TFilterType) => {
            const defaultValue = initialFilterValues[key];
            setFilters({...currentFilterValues, [key]: defaultValue});
        },
        [currentFilterValues, setFilters, initialFilterValues],
    );
    const getFilterRemoveHandlerByFilterKey = useCallback(
        (key: keyof TFilterType) => {
            return () => onFilterRemove(key);
        },
        [onFilterRemove],
    );

    const chipList = renderChips(getFilterRemoveHandlerByFilterKey).filter(Boolean);
    if (!chipList.length) {
        return null;
    }

    return (
        <div className="flex items-center gap-4">
            <span className="text-neutral-700">{i18n("common.filters.filtered_by")}</span>
            <div className="flex gap-2">{chipList}</div>
            <Button variant="tertiary" size="sm" onClick={handleClearFiltersButtonClick}>
                {i18n("common.clear_all")}
            </Button>
        </div>
    );
}

export default FilteredBy;
