import {FC} from "react";

import Icon from "assets/icons/car-driver.svg?react";
import {ResponsiveDrawer} from "common/components/drawer/ResponsiveDrawer";
import {useI18n} from "common/hooks/useI18n";

import {Button, Drawer} from "@bolteu/kalep-react";

export interface DriverRegistrationsInfoProps {
    closePage: () => void;
    isOpen: boolean;
}

const DriverRegistrationsInfo: FC<DriverRegistrationsInfoProps> = ({closePage, isOpen}) => {
    const {i18n} = useI18n();

    return (
        <ResponsiveDrawer
            isOpen={isOpen}
            title={i18n("auth.app.fleet.driver-registrations.proposed-candidates.title")}
            onRequestClose={closePage}
        >
            <Drawer.Content>
                <div className="flex flex-col gap-6">
                    <Icon />
                    <p>{i18n("auth.app.fleet.info_page.main_body")} </p>
                    <div className="flex flex-col gap-2">
                        <h2 className="text-lg font-semibold">{i18n("auth.app.fleet.info_page.sub_title1")}</h2>
                        <p>{i18n("auth.app.fleet.info_page.sub_body1")}</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h2 className="text-lg font-semibold">{i18n("auth.app.fleet.info_page.sub_title2")}</h2>
                        <p>{i18n("auth.app.fleet.info_page.sub_body2")}</p>
                        <p>{i18n("auth.app.fleet.info_page.sub_body2_1")}</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h2 className="text-lg font-semibold">{i18n("auth.app.fleet.info_page.sub_title3")}</h2>
                        <p>{i18n("auth.app.fleet.info_page.sub_body3")}</p>
                    </div>
                    <div className="flex flex-col gap-2">
                        <h2 className="text-lg font-semibold">{i18n("auth.app.fleet.info_page.sub_title4")}</h2>
                        <p>{i18n("auth.app.fleet.info_page.sub_body4")}</p>
                        <p>{i18n("auth.app.fleet.info_page.sub_body4_1")}</p>
                    </div>
                    <div className="mb-2 flex justify-end">
                        <Button variant="secondary" onClick={closePage}>
                            {i18n("auth.app.fleet.info_page.close")}
                        </Button>
                    </div>
                </div>
            </Drawer.Content>
        </ResponsiveDrawer>
    );
};

export default DriverRegistrationsInfo;
