import {FC, useCallback, useState} from "react";

import AvatarIcon from "assets/icons/avatar.svg?react";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {useI18n} from "common/hooks/useI18n";

import {FleetDriverInvitationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert, Chip, GhostButton, Typography} from "@bolteu/kalep-react";

import DriverInvitationDetails from "./DriverInvitationDetails";
import ShareInvitationLinkDialog from "./ShareInvitationLinkDialog";
import InvitedDriver = FleetDriverInvitationService.InvitedDriver;

interface DriverInvitationBodyProps {
    data: InvitedDriver;
}

const DriverInvitationBody: FC<DriverInvitationBodyProps> = ({data}) => {
    const {i18n} = useI18n();

    const [isOpen, setIsOpen] = useState(false);

    const handleCloseDialog = useCallback(() => {
        setIsOpen(false);
    }, []);

    const openLinkSharingDialog = useCallback(async () => {
        setIsOpen(true);
    }, []);

    return (
        <>
            <div className="mt-6 flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                <div className="flex flex-row">
                    <AvatarIcon />
                    <div className="flex flex-col pl-6">
                        <ResponsiveHeader type={ResponsiveHeaderType.InnerPage} text={data.email} />
                        <div className="flex flex-row gap-x-2 pt-4">
                            <Chip
                                label={i18n(`auth.app.fleet.driver-registrations.status.invited`)}
                                size="sm"
                                color="neutral"
                                variant="filled"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col pt-8 md:w-1/2">
                <Alert severity="info" title={i18n("auth.app.fleet.driver-registrations.invitation.invitation-sent")}>
                    <div className="flex flex-col gap-4">
                        {i18n("auth.app.fleet.driver-registrations.invitation.information")}
                        <GhostButton onClick={openLinkSharingDialog}>
                            <Typography
                                variant="body-primary"
                                fontWeight="semibold"
                                fontSize="text-base"
                                color="promo-primary"
                            >
                                {i18n("auth.app.fleet.driver-registrations.invitation.share-invitation-link")}
                            </Typography>
                        </GhostButton>
                    </div>
                </Alert>
            </div>
            <div className="flex w-full flex-col pt-8">
                <div className="flex w-full flex-row flex-wrap justify-between gap-x-20">
                    <div className="mb-8 flex w-full flex-col md:w-1/2 md:max-w-[480px]">
                        {data && <DriverInvitationDetails driverInvitation={data} />}
                    </div>
                </div>
            </div>
            <ShareInvitationLinkDialog isOpen={isOpen} closeDialog={handleCloseDialog} link={data.invitation_link} />
        </>
    );
};

export default DriverInvitationBody;
