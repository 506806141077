import {useContext, useEffect, useMemo} from "react";
import {useIntl} from "react-intl";

import {Dash} from "common/constants";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {formatNumber} from "common/util/FormatUtil";
import {format} from "date-fns";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";

export interface BoltToFleetInvoiceData {
    id: number;
    public_id: string;
    date: string;
    due_date: string;
    sum: string;
    currency: string;
}

const fetchBoltInvoices = (api: Api) => api.fleetOwnerReporting.getTaxifyInvoices();

export const useBoltInvoices = () => {
    const intl = useIntl();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const {data, fetch, status} = useFetch(fetchBoltInvoices);

    useEffect(() => {
        if (!fetch || !fleet) {
            return;
        }
        fetch({});
    }, [fetch, fleet]);

    const invoices = useMemo<BoltToFleetInvoiceData[]>(() => {
        if (status !== FetchStatus.Success) {
            return [];
        }
        const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];
        return data.list.map((d) => {
            const invoiceDate = new Date(d.invoice_date * 1000);
            const dueDate = d.due_date ? new Date(d.due_date * 1000) : null;

            return {
                id: d.id,
                public_id: d.public_id,
                date: format(invoiceDate, "P", {locale: dateFnsLocale}),
                due_date: dueDate ? format(dueDate, "P", {locale: dateFnsLocale}) : Dash.EM,
                sum: formatNumber(intl, d.sum_with_vat, {minFractionDigits: 2, maxFractionDigits: 2}),
                currency: d.currency,
            };
        });
    }, [data, intl, status]);

    const hasHoldingFleetInvoicing = data?.holding_fleet_invoicing ?? false;

    return {
        invoices,
        hasHoldingFleetInvoicing,
        isLoading: [FetchStatus.Loading, FetchStatus.Init].includes(status),
    };
};
