import {useCallback, useEffect} from "react";
import {useParams} from "react-router-dom";

import {useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Breadcrumb} from "@bolteu/kalep-react";

import AlertMessage from "./AlertMessage";
import {ApplicationDriverDocuments} from "./ApplicationDriverDocuments";
import {DriverDetails} from "./DriverDetails";
import {useBreadcrumbs} from "./hooks/useBreadcrumbs";
import {VehicleApplicationDetailsHeader} from "./VehicleApplicationDetailsHeader";

const getListingApplicationsFetchFunction = (
    api: Api,
    request: VehicleMarketplaceService.GetApplicationWithPartnerRequest,
) => api.vehicleMarketplace.vehicleApplicationGet(request);

const acceptApplicationFn = (api: Api, application: VehicleMarketplaceService.AcceptApplicationRequest) => {
    return api.vehicleMarketplace.vehicleApplicationAccept(application);
};

const declineApplicationFn = (api: Api, application: VehicleMarketplaceService.DeclineApplicationRequest) => {
    return api.vehicleMarketplace.vehicleApplicationDecline(application);
};

function ViewApplicationPage() {
    const {applicationId} = useParams();
    const {fetch: getApplicationFetch, data: application} = useFetch(getListingApplicationsFetchFunction);

    const {fetch: acceptApplication} = useFetch(acceptApplicationFn);
    const {fetch: declineApplication} = useFetch(declineApplicationFn);

    const breadcrumbs = useBreadcrumbs(
        application?.listing_id,
        `${application?.vehicle_manufacturer} ${application?.vehicle_model_name}`,
        `${application?.first_name} ${application?.last_name}`,
    );

    const onApproveApplicationDialogApprove = useCallback(async () => {
        if (acceptApplication && applicationId && getApplicationFetch) {
            await acceptApplication({application_id: Number(applicationId)});
            getApplicationFetch({application_id: Number(applicationId)});
        }
    }, [acceptApplication, applicationId, getApplicationFetch]);

    const onDeclineApplicationDialogDecline = useCallback(async () => {
        if (declineApplication && applicationId && getApplicationFetch) {
            await declineApplication({application_id: Number(applicationId)});
            getApplicationFetch({application_id: Number(applicationId)});
        }
    }, [declineApplication, applicationId, getApplicationFetch]);

    useEffect(() => {
        if (getApplicationFetch) {
            getApplicationFetch({application_id: Number(applicationId)});
        }
    }, [getApplicationFetch, applicationId]);

    return (
        <div className="flex w-full flex-col gap-5 px-4 py-10 md:px-20">
            <Breadcrumb items={breadcrumbs} />
            {application && (
                <VehicleApplicationDetailsHeader
                    application={application}
                    onApplicationApproved={onApproveApplicationDialogApprove}
                    onApplicationDeclined={onDeclineApplicationDialogDecline}
                />
            )}

            <div className="mt-10 flex w-full flex-col gap-10 md:flex-row md:justify-between">
                <div className="flex w-full flex-col gap-6 md:w-2/4">
                    {application && <AlertMessage application={application} />}
                    <div className="flex w-2/3 flex-col gap-6">
                        {application && <DriverDetails application={application} />}
                    </div>
                </div>
                <div className="w-full md:w-1/3">
                    {application && <ApplicationDriverDocuments application={application} />}
                </div>
            </div>
        </div>
    );
}

export default ViewApplicationPage;
