import {useCompanyDocumentFunctions} from "common/components/document/entity/useCompanyDocumentFunctions";
import {useDriverDocumentFunctions} from "common/components/document/entity/useDriverDocumentFunctions";
import {useVehicleApplicationDocumentFunctions} from "common/components/document/entity/useVehicleApplicationDocumentFunctions";
import {useVehicleDocumentFunctions} from "common/components/document/entity/useVehicleDocumentFunctions";
import {DocumentOwner, DocumentOwnerType} from "common/components/document/useDocumentsList";

export const useEntityDocumentFunctions = (owner: DocumentOwner) => {
    const driverDocumentFunctions = useDriverDocumentFunctions(owner.id);
    const vehicleDocumentFunctions = useVehicleDocumentFunctions(owner.id);
    const vehicleApplicationDocumentFunctions = useVehicleApplicationDocumentFunctions(owner.id);
    const companyDocumentFunctions = useCompanyDocumentFunctions();

    switch (owner.type) {
        case DocumentOwnerType.Driver:
            return driverDocumentFunctions;
        case DocumentOwnerType.Vehicle:
            return vehicleDocumentFunctions;
        case DocumentOwnerType.Company:
            return companyDocumentFunctions;
        default:
            return vehicleApplicationDocumentFunctions;
    }
};
