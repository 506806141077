import {useCallback, useMemo} from "react";

import ApiDrivenTable from "common/components/table/ApiDrivenTable";
import {
    ApiDrivenTableTransforms,
    ApiDriverTableInlineAction,
    CellProps,
} from "common/components/table/ApiDrivenTable/types";
import {useI18n} from "common/hooks/useI18n";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {IconButton, Menu, MenuItem, MenuItemProps, Spinner} from "@bolteu/kalep-react";
import {MoreIos} from "@bolteu/kalep-react-icons";

import {OrderRouteCellApiDriven} from "../../components/OrderRouteCell";
import {SCHEDULED_RIDES_TABLE_ID} from "../constants";
import {AssignOrderData} from "./AssignScheduledRideDialog";
import {NoScheduledRides} from "./NoScheduledRides";

import EnumKey = FleetPortalOrderService.EnumKey;

type CreatedByEnumKeys = EnumKey.CREATED_BY_RIDER | EnumKey.CREATED_BY_FLEET;

interface Props {
    scheduledRidesResponse: FleetPortalOrderService.GetScheduledRidesResponse;
    isLoading: boolean;
    editScheduledRide: (assignOrderData: AssignOrderData) => void;
    deleteScheduledRide: (scheduledRideId: number) => void;
}

enum ScheduledRideAction {
    ASSIGN = "assign",
    CANCEL = "cancel",
}

export const ScheduledRidesTable = ({
    scheduledRidesResponse,
    editScheduledRide,
    deleteScheduledRide,
    isLoading,
}: Props) => {
    const {i18n} = useI18n();

    const onAssignClick = useCallback(
        (assignOrderData: AssignOrderData) => () => {
            editScheduledRide(assignOrderData);
        },
        [editScheduledRide],
    );

    const onCancelClick = useCallback(
        (scheduledRideId: number) => () => {
            deleteScheduledRide(scheduledRideId);
        },
        [deleteScheduledRide],
    );

    const renderMenuLabelDanger = useCallback((props: MenuItemProps) => {
        return <div className="text-danger-primary bolt-font-body-m-regular">{props.label}</div>;
    }, []);

    const onClickStub = useCallback(() => {}, []);

    const inlineActions = useMemo((): ApiDriverTableInlineAction[] => {
        const getAssignDriverLabel = (driverId?: string): string => {
            if (driverId) {
                return i18n("auth.app.orders.scheduled_rides.change-driver");
            }
            return i18n("auth.app.orders.scheduled_rides.assign-driver");
        };
        return [
            {
                render: (columnIndex) => {
                    const {columns, order_ids: orderIds} = scheduledRidesResponse;
                    const orderId = orderIds[columnIndex];
                    const createdByEnumKey: CreatedByEnumKeys | undefined = columns.find(
                        (column) => column.key === "created_by",
                    )?.cells[columnIndex];
                    const driverId: string | undefined = columns.find((column) => column.key === "driver")?.cells[
                        columnIndex
                    ].id;

                    const assignOderData: AssignOrderData = {
                        orderId,
                        assignment_type: driverId
                            ? FleetPortalOrderService.AssignmentType.MANUAL
                            : FleetPortalOrderService.AssignmentType.AUTOMATIC,
                        driverId: driverId ? Number(driverId) : undefined,
                    };

                    return (
                        <Menu
                            key={orderId}
                            menuButton={
                                <IconButton
                                    onClick={onClickStub}
                                    icon={<MoreIos />}
                                    aria-label="Row actions"
                                    variant="ghost"
                                    size="sm"
                                />
                            }
                            direction="top"
                            overrideClassName="w-[280px]"
                            portal
                            boundingBoxPadding="0 16 0 0"
                        >
                            <MenuItem
                                value={ScheduledRideAction.ASSIGN}
                                onClick={onAssignClick(assignOderData)}
                                label={getAssignDriverLabel(driverId)}
                            />
                            {createdByEnumKey === FleetPortalOrderService.EnumKey.CREATED_BY_FLEET && (
                                <MenuItem
                                    value={ScheduledRideAction.CANCEL}
                                    onClick={onCancelClick(orderId)}
                                    label={i18n("auth.app.orders.scheduled_rides.cancel-order")}
                                    renderLabel={renderMenuLabelDanger}
                                />
                            )}
                        </Menu>
                    );
                },
            },
        ];
    }, [i18n, onAssignClick, onCancelClick, onClickStub, renderMenuLabelDanger, scheduledRidesResponse]);

    const transforms = useMemo((): ApiDrivenTableTransforms => {
        const transformRoute = (props: CellProps) => {
            const cellValue = props.column.cells[props.valueAtIdx];
            if (typeof cellValue === "object" && typeof cellValue[0] === "string") {
                const stops = (cellValue as string[]).map((stop, index) => ({
                    id: index,
                    address: stop,
                    arrived_at: null,
                    departed_at: null,
                }));
                return <OrderRouteCellApiDriven stops={stops} />;
            }
            return <></>;
        };
        return {route: {renderCellOnMobile: transformRoute, renderCellOnDesktop: transformRoute}};
    }, []);

    if (isLoading) {
        return (
            <div className="mt-10 flex w-full items-center justify-center">
                <Spinner />
            </div>
        );
    }

    const {columns} = scheduledRidesResponse;
    return (
        <ApiDrivenTable
            tableId={SCHEDULED_RIDES_TABLE_ID}
            columns={columns}
            numberOfRecords={columns[0]?.cells.length ?? 0}
            transforms={transforms}
            inlineActions={inlineActions}
        >
            <NoScheduledRides />
        </ApiDrivenTable>
    );
};
