import {Dash} from "common/constants";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {IconButton, Tooltip} from "@bolteu/kalep-react";
import {ArrowDown, ArrowUp, InfoCircleOutlined} from "@bolteu/kalep-react-icons";

interface Props {
    metric: FleetOwnerPortalService.MetricCard;
}

const ApiDrivenMetricCard = ({metric}: Props) => {
    const valueTextColor = metric.value === null ? "text-secondary" : "text-primary";
    const valueOrDash = metric.value === null ? Dash.EN : metric.value;

    const TrendIcon = () => {
        if (!metric.secondary.trend_behavior || metric.value === 0) {
            return null;
        }

        let trendColorClass = "";
        switch (metric.secondary.trend_behavior) {
            case FleetOwnerPortalService.NumberTrendBehavior.POSITIVE_VALUE_AS_IMPROVEMENT:
                trendColorClass = metric.value > 0 ? "text-action-secondary" : "text-danger-secondary";
                break;
            case FleetOwnerPortalService.NumberTrendBehavior.NEGATIVE_VALUE_AS_IMPROVEMENT:
                trendColorClass = metric.value < 0 ? "text-action-secondary" : "text-danger-secondary";
                break;
            default:
                UnreachableCode.never(metric.secondary.trend_behavior);
        }

        if (metric.value > 0) {
            return (
                <div className={trendColorClass}>
                    <ArrowUp className="pb-1" width={16} height={16} />
                </div>
            );
        }

        return (
            <div className={trendColorClass}>
                <ArrowDown className="pb-1" width={16} height={16} />
            </div>
        );
    };

    return (
        <div className="flex h-[116px] min-w-[227px] max-w-[288px] flex-1 flex-col justify-between gap-0.5 rounded-lg border border-neutral-400 p-4">
            <div className="flex flex-col gap-1">
                <div className="flex items-center justify-between gap-2">
                    <div className="text-secondary text-sm">{metric.name}</div>
                    {metric.description && (
                        <Tooltip content={metric.description} placement="top">
                            <IconButton
                                overrideClassName="h-[28px] w-[28px]"
                                aria-label="tooltip"
                                variant="ghost"
                                icon={<InfoCircleOutlined />}
                            />
                        </Tooltip>
                    )}
                </div>
                <div className={`flex gap-0.5 ${valueTextColor}`}>
                    <span className="text-xl font-semibold">{valueOrDash}</span>
                    {metric.unit && <span>{metric.unit}</span>}
                </div>
            </div>
            {metric.secondary.value && (
                <div className="flex gap-0.5 text-xs">
                    <TrendIcon />
                    <span>{metric.secondary.value}</span>
                    {metric.secondary.unit && <span>{metric.secondary.unit}</span>}
                </div>
            )}
        </div>
    );
};

export default ApiDrivenMetricCard;
