import {FC} from "react";
import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";

import {Link} from "@bolteu/kalep-react";

const TinFaqLink: FC = () => {
    const intl = useIntl();
    const {i18n} = useI18n();

    let {locale} = intl;
    // Support blog has 2 english versions, so they use US and GB distinction. Other languages use 2-letter codes.
    if (!["en-us", "en-gb"].includes(locale)) {
        locale = locale.substring(0, 2);
    }
    const prefix = "https://support.taxify.eu/hc/";
    const suffix = "/articles/9123735670418-DAC7-and-required-partner-details";
    const url = prefix + locale + suffix;
    return (
        <>
            <div className="text-secondary text-sm"> {i18n("auth.app.company-settings.tin.valid_tax_info")}</div>
            <Link href={url} rel="noreferrer noopener" target="_blank">
                <span className="text-sm">{i18n("signup.step4.form.tax_number_faq_link")}</span>
            </Link>
        </>
    );
};

export default TinFaqLink;
