import {Location} from "react-router-dom";

import queryString from "query-string";

export const getReturnPath = (location: Location): string | null => {
    const query = queryString.parse(location.search);
    return query.to as string | null;
};

export const createReturnUrl = (to: string): string => {
    return queryString.stringify({to});
};
