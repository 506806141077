import {FC} from "react";

import {Island} from "@bolteu/kalep-react";

export interface CodeIslandProps {
    code: string;
}

const CodeIsland: FC<CodeIslandProps> = ({code}) => {
    return (
        <Island>
            <div className="flex flex-row whitespace-pre-wrap text-sm">
                <code>{code}</code>
            </div>
        </Island>
    );
};

export default CodeIsland;
