import {FC, useCallback, useState} from "react";

import ConfirmActionDialog, {
    ConfirmActionDialogVariant,
} from "common/components/confirmActionDialog/ConfirmActionDialog";

import {Button} from "@bolteu/kalep-react";

interface CredentialsViewInfoBlockProps {
    title: string;
    description: string;
    isLoading: boolean;
    buttonText: string;
    onButtonClick: () => void;
}

const CredentialsViewInfoBlock: FC<CredentialsViewInfoBlockProps> = ({
    title,
    description,
    isLoading,
    buttonText,
    onButtonClick,
}) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const openDialog = useCallback(() => {
        setIsDialogOpen(true);
    }, []);

    const closeDialog = useCallback(() => {
        setIsDialogOpen(false);
    }, []);

    return (
        <div>
            <p className="font-semibold">{title}</p>
            <p className="text-secondary text-sm">{description}</p>
            <div className="mt-5">
                <Button variant="secondary" onClick={openDialog}>
                    {buttonText}
                </Button>
            </div>
            <ConfirmActionDialog
                isOpen={isDialogOpen}
                isLoading={isLoading}
                closeDialog={closeDialog}
                titleText={title}
                descriptionText={description}
                confirmButtonText={buttonText}
                confirmButtonVariant={ConfirmActionDialogVariant.Primary}
                confirmButtonAction={onButtonClick}
            />
        </div>
    );
};

export default CredentialsViewInfoBlock;
