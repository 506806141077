import {FC} from "react";

import {Cross} from "@bolteu/kalep-react-icons";

export interface CloseButtonProps {
    onClick: () => void;
    disabled?: boolean;
    light?: boolean;
}

const CloseButton: FC<CloseButtonProps> = ({onClick, disabled = false, light = false}) => {
    return (
        <button
            type="button"
            onClick={onClick}
            disabled={disabled}
            className={`br flex h-9 w-9 items-center justify-center rounded-full ${light ? "" : "bg-neutral-200"}`}
            aria-label="Close"
        >
            <Cross size="md" className={`${light ? "text-secondary" : "text-neutral-900"}`} />
        </button>
    );
};

export default CloseButton;
