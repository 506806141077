import {localStorageService} from "./LocalStorageService";

const SmsAnnouncementStorageKey = "SMSAnnouncement";

export const setIsShownSmsAnnouncement = (): void => {
    localStorageService.setItem(SmsAnnouncementStorageKey, "true");
};

export const getIsShowSmsAnnouncement = (): boolean => {
    const urlParams = new URLSearchParams(window.location.search);
    const originQuery = urlParams.get("origin");
    const mediumQuery = urlParams.get("medium");
    if (originQuery !== "sms" || mediumQuery !== "notification") {
        return false;
    }

    const isSmsShown = localStorageService.getItem(SmsAnnouncementStorageKey);

    return !isSmsShown;
};
