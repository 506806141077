import {useCallback, useContext, useEffect, useState} from "react";
import {Link as RouterLink} from "react-router-dom";

import {EventName} from "common/constants/events";
import {useAuthentication} from "common/hooks/useAuthentication";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {ApplicationContextProvider} from "features/app/appStateProvider";

import {FormState, useForm} from "@bolteu/kalep-form-react";
import {Typography} from "@bolteu/kalep-react";

interface LoginForm {
    email: string;
    password: string;
}

export function EmailUsernameForm() {
    const {i18n} = useI18n();
    const [isWaitingTransition, setIsWaitingTransition] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const appState = useContext(ApplicationContextProvider);
    const accountState = useContext(AccountContextProvider);
    const {makeLogin} = useAuthentication();
    const {trackEvent} = useTracking();

    const {Form, TextField, Button} = useForm<LoginForm, Record<never, never>>({email: "", password: ""});

    // When isAuthError === true and user navigates to login page
    useEffect(() => {
        if (appState.isAuthError && isPageLoading) {
            appState.setIsAuthError(false);
        }
        setIsPageLoading(false);
    }, [appState, isPageLoading, setIsPageLoading]);

    const handleSubmit = useCallback(
        async (state: FormState<LoginForm>) => {
            if (appState && accountState) {
                const isLoginSuccessful = await makeLogin(state.data.email, state.data.password);
                if (isLoginSuccessful) {
                    trackEvent(EventName.LoginCompleted);
                    setIsWaitingTransition(true);
                }
            }
        },
        [trackEvent, appState, accountState, makeLogin],
    );

    return (
        <>
            <Form
                onSubmit={handleSubmit}
                className="flex w-full max-w-[356px] flex-col gap-4"
                disabled={isWaitingTransition}
            >
                <TextField
                    id="email"
                    name="email"
                    type="text"
                    label=""
                    placeholder={i18n("login.email_placeholder")}
                    required
                    autoComplete="on"
                />
                <TextField
                    id="current-password"
                    name="password"
                    type="password"
                    label=""
                    placeholder={i18n("login.password_placeholder")}
                    required
                    autoComplete="current-password"
                />
                {appState.isAuthError && (
                    <Typography variant="body-primary" fontSize="text-sm">
                        <div className="text-danger-primary"> {i18n("api.error.FLEET_OWNER_NOT_FOUND")} </div>
                    </Typography>
                )}
                <RouterLink to="/forgotPassword" className="mb-8 text-green-700 hover:underline">
                    {i18n("forgot-password.title")}
                </RouterLink>
                <Button fullWidth submit>
                    {i18n("login.log_in")}
                </Button>
            </Form>
        </>
    );
}
