import {useCallback, useEffect, useState} from "react";

import {useI18n} from "common/hooks/useI18n";
import {localStorageService} from "common/services/LocalStorageService";
import {useLocalStorage} from "@fleet/common/hooks/useLocalStorage";

import {FormState, useForm} from "@bolteu/kalep-form-react";
import {OnlyKeysOfType} from "@bolteu/kalep-form-react/build/utils/RecursiveGet";
import {Drawer, IconButton} from "@bolteu/kalep-react";
import {Settings} from "@bolteu/kalep-react-icons";

import {ResponsiveDrawer} from "../drawer/ResponsiveDrawer";
import {CustomizableTableHeader, CustomizableTableHeaders, TableColumnSettings} from "./TableWithCustomizableColumns";

interface TableCustomizationDrawerProps<Entity> {
    tableId: string;
    columns: CustomizableTableHeaders<Entity>;
}

const TableCustomizationDrawer = <Entity,>({tableId, columns}: TableCustomizationDrawerProps<Entity>) => {
    const {i18n} = useI18n();

    const [value, setValue] = useLocalStorage<TableColumnSettings>(
        localStorageService.getTableSettingsKey(tableId),
        {},
    );

    const shownColumns: CustomizableTableHeaders<Entity> = Object.entries(columns)
        .filter(([, column]) => {
            const customizableColumn = column as CustomizableTableHeader<Entity>;
            return !customizableColumn.nonConfigurable;
        })
        .reduce((acc, [columnKey, column]) => {
            const customizableColumn = column as CustomizableTableHeader<Entity>;

            const isShown: boolean = value[columnKey] ?? !customizableColumn.hideByDefault;

            return {...acc, [columnKey]: isShown};
        }, {});

    const [isOpen, setIsOpen] = useState(false);

    const {Form, Button, CheckboxField, setData} = useForm(shownColumns);

    useEffect(() => {
        setData(shownColumns);
    }, [setData, shownColumns]);

    const handleFiltersClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleFilterButtonClick = useCallback(() => {
        setIsOpen(true);
    }, []);

    const handleFormSubmit = useCallback(
        async ({data: formData}: FormState<CustomizableTableHeaders<Entity>>) => {
            setValue(Object.entries(formData).reduce((acc, [key, selected]) => ({...acc, [key]: selected}), {}));
            setIsOpen(false);
        },
        [setIsOpen, setValue],
    );

    const handleCancelFiltersButtonClick = useCallback(() => {
        setIsOpen(false);
    }, []);

    return (
        <div>
            <IconButton
                overrideClassName="flex h-12 w-12"
                shape="square"
                size="lg"
                icon={<Settings />}
                onClick={handleFilterButtonClick}
                aria-label="Open settings"
                value="tableSettingIconBtn"
            />
            <Form onSubmit={handleFormSubmit}>
                <ResponsiveDrawer isOpen={isOpen} title={i18n("common.settings")} onRequestClose={handleFiltersClose}>
                    <Drawer.Content>
                        <div className="flex flex-col gap-4">
                            <h3 className="font-semibold">{i18n("common.show_columns")}</h3>
                            {Object.entries(columns)
                                .filter(([key]) => Object.hasOwn(shownColumns, key))
                                .map(
                                    ([key, column]) =>
                                        column && (
                                            <div className="pt-3" key={key}>
                                                <CheckboxField
                                                    name={
                                                        key as OnlyKeysOfType<
                                                            CustomizableTableHeaders<Entity>,
                                                            boolean | undefined
                                                        >
                                                    }
                                                    label={(column as CustomizableTableHeader<Entity>).label as string}
                                                />
                                            </div>
                                        ),
                                )}
                        </div>
                    </Drawer.Content>
                    <Drawer.Footer>
                        <Button submit>{i18n("common.apply")}</Button>
                        <Button variant="secondary" onClick={handleCancelFiltersButtonClick}>
                            {i18n("common.cancel")}
                        </Button>
                    </Drawer.Footer>
                </ResponsiveDrawer>
            </Form>
        </div>
    );
};

export default TableCustomizationDrawer;
