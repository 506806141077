import {useCallback, useEffect, useState} from "react";
import Select, {SingleValue} from "react-select";

import {AreaCodeOption, useAreaCodeReactSelectOptions} from "common/geo/hooks/useAreaCodeReactSelectOptions";
import {twMerge} from "tailwind-merge";
import {useId} from "@react-aria/utils";

export interface AreaCodeSelectorProps {
    handlePhoneAreaCode: (areaCode: string) => void;
    defaultValue?: string;
    label?: string;
    required?: boolean;
}

/* TODO - Replace Select from react-select with Kalep Combobox once https://taxify.atlassian.net/browse/KALEP-563 
   is done to render flag in selected option */
export const AreaCodeSelector = ({handlePhoneAreaCode, defaultValue, label, required}: AreaCodeSelectorProps) => {
    const {options, renderOption} = useAreaCodeReactSelectOptions();

    const [value, setValue] = useState<AreaCodeOption | undefined | null>(options[0]);

    useEffect(() => {
        const defaultOption = options.find((option) => option.value === defaultValue);
        setValue(defaultOption);
        handlePhoneAreaCode(defaultOption?.label || "");
    }, [defaultValue, handlePhoneAreaCode, options]);

    const id = useId();

    const onChange = useCallback(
        (newValue: SingleValue<AreaCodeOption>) => {
            setValue(newValue);
            handlePhoneAreaCode(newValue?.label || "");
        },
        [handlePhoneAreaCode],
    );

    const optionValue = useCallback((option: AreaCodeOption) => option.name, []);

    return (
        <div className="flex flex-col gap-2">
            {label && (
                <label
                    htmlFor={id}
                    className={twMerge(
                        "w-fit font-sans text-sm font-semibold",
                        required && "after:text-danger-primary after:content-['_*']",
                    )}
                >
                    {label}
                </label>
            )}
            <Select
                id={id}
                options={options}
                value={value}
                formatOptionLabel={renderOption}
                getOptionValue={optionValue}
                className="area-code-selector"
                classNamePrefix="area-code-selector"
                classNames={{
                    indicatorSeparator: () => "hidden",
                    valueContainer: () => "!p-0",
                    indicatorsContainer: () => "",
                    container: () => "w-full",
                    control: (state) =>
                        twMerge(
                            "!rounded-md h-12 !bg-neutral-secondary !border-0 !shadow-none !outline-none",
                            state.isFocused && "!border-action-primary !border-2",
                        ),
                    dropdownIndicator: () => twMerge("!text-primary"),
                    menu: () => "!w-max !min-w-full",
                    option: () => "h-14 !p-2 hover:!cursor-pointer",
                }}
                onChange={onChange}
                required={required}
            />
        </div>
    );
};
