import {useSearchParams} from "react-router-dom";

import {useI18n} from "common/hooks/useI18n";
import NoAuthLayout from "features/account/components/NoAuthLayout";
import {Confirmation} from "features/account/components/Verification/Confirmation";

const VerificationConfirmation = () => {
    const {i18n} = useI18n();

    const [searchParams] = useSearchParams();
    const username: string | undefined = searchParams.get("username") ?? undefined;

    return (
        <NoAuthLayout gap="small">
            {!username && (
                <h1 className="text-2xl font-semibold">{i18n("auth.app.error-view.something-went-wrong.title")}</h1>
            )}
            {username && <Confirmation username={username} />}
        </NoAuthLayout>
    );
};

export default VerificationConfirmation;
