import {useCallback} from "react";

import {useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";

import {FleetInboxService} from "@bolteu/bolt-server-api-fleet-owner-portal";

const getNotificationsFunction = (api: Api, maxAgeDays?: number) =>
    api.fleetInbox.fleetInboxGetNotifications({...(maxAgeDays ? {max_age_days: maxAgeDays} : {})});
const setNotificationsReadFunction = (api: Api, body: FleetInboxService.SetNotificationsReadRequest) =>
    api.fleetInbox.fleetInboxSetNotificationsRead(body);
const setAllNotificationsReadFunction = (api: Api) => api.fleetInbox.fleetInboxSetAllNotificationsRead();

export const useNotificationApi = () => {
    const {fetch, data, status} = useFetch(getNotificationsFunction);
    const {fetch: setReadFetch} = useFetch(setNotificationsReadFunction);
    const {fetch: setAllReadFetch} = useFetch(setAllNotificationsReadFunction);

    const getNotifications = useCallback(
        (maxAgeDays?: number) => {
            fetch?.(maxAgeDays);
        },
        [fetch],
    );

    const setNotificationsReadAndReload = useCallback(
        async (notifications: FleetInboxService.LocalizedPortalNotification[]) => {
            const ids = notifications.filter((n) => n.read_timestamp === null).map((notification) => notification.id);
            if (ids.length) {
                await setReadFetch?.({notification_ids: ids});
                getNotifications();
            }
        },
        [setReadFetch, getNotifications],
    );

    const setAllNotificationsReadAndReload = useCallback(async () => {
        await setAllReadFetch?.({});
        getNotifications();
    }, [setAllReadFetch, getNotifications]);

    return {
        getNotifications,
        data,
        status,
        setNotificationsReadAndReload,
        setAllNotificationsReadAndReload,
    };
};
