import {ValidationError} from "@fleet/common/errors/validationError";
import {FetchError} from "@fleet/common/hooks/useFetch";

import {FleetOwnerVerificationNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";

interface ErrorData {
    text?: string;
}

interface CaptchaParameters {
    site_key: string;
}

interface CaptchaChallenge {
    type: FleetOwnerVerificationNoAuthService.CaptchaChallengeType;
    parameters: CaptchaParameters;
}

interface CaptchaErrorData {
    challenge: CaptchaChallenge;
}

interface CatpchaError {
    data: CaptchaErrorData;
}

interface SimpleServerError {
    code: number;
    message: string;
}

interface ErrorDataResponse extends SimpleServerError {
    error_data: ErrorData;
}

interface FormErrorData extends SimpleServerError {
    data: ErrorsData;
}

interface ErrorsData {
    errors: AttributeErrorData[];
}

interface AttributeErrorData {
    attribute: string;
    message: string;
}

interface CaptchaResponse extends SimpleServerError {
    error_data: CatpchaError;
}

interface ServerErrorWithCaptcha {
    response: CaptchaResponse;
}

interface ServerError {
    response: SimpleServerError;
}

interface ServerErrorWithData {
    response: ErrorDataResponse;
}

interface FormValidationServerErrorWithData {
    response: FormErrorData;
}

export function isError(e: unknown): e is Error {
    return typeof e === "object" && e !== null && e instanceof Error;
}

export function isValidationError(e: unknown): e is ValidationError {
    return isError(e) && "validatorResults" in e && e.validatorResults !== undefined;
}

export const FLEET_OWNER_CAR_APPLICATION_VALIDATION_FAILED = 5219;
export const FORM_VALIDATION_FAILED = 25813;

export function isCarFormValidationError(e: unknown): e is FormValidationServerErrorWithData {
    const errorResponse = (e as FormValidationServerErrorWithData)?.response;

    return (
        FLEET_OWNER_CAR_APPLICATION_VALIDATION_FAILED === errorResponse?.code && !!errorResponse?.data?.errors?.length
    );
}

export function isDynamicFormValidationError(e: unknown): e is FormValidationServerErrorWithData {
    const errorResponse = (e as FormValidationServerErrorWithData)?.response;

    return FORM_VALIDATION_FAILED === errorResponse?.code && !!errorResponse?.data?.errors?.length;
}

export function isServerError(error: unknown): error is ServerError {
    const errorResponse = (error as ServerErrorWithData)?.response;
    return errorResponse?.code !== undefined && errorResponse?.message !== undefined;
}

export function isServerErrorWithData(error: unknown): error is ServerErrorWithData {
    const errorResponse = (error as ServerErrorWithData)?.response;
    return (
        errorResponse?.code !== undefined &&
        errorResponse?.message !== undefined &&
        errorResponse?.error_data !== undefined
    );
}

export const REQUIRED_BOT_CHALLENGE_RESPONSE_CODE = 734;

export function isCaptchaError(error: unknown): error is ServerErrorWithCaptcha {
    const errorResponse = (error as ServerErrorWithCaptcha)?.response;
    return (
        errorResponse?.code === REQUIRED_BOT_CHALLENGE_RESPONSE_CODE &&
        errorResponse?.error_data?.data?.challenge?.parameters?.site_key !== undefined
    );
}

export function isCompanyRegistrationCompletedError(fetchError: FetchError): boolean {
    return fetchError && fetchError.message === "COMPANY_REGISTRATION_COMPLETE";
}
