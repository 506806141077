import {createContext, useCallback, useContext, useEffect, useMemo} from "react";

import {useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {AccountContextProvider} from "features/account/accountStateProvider";

export interface MatchesState {
    isAvailable: boolean;
    hasOptInChance: boolean;
    isInactiveWithMatches: boolean;
    refreshState: () => Promise<void>;
}

const MatchesContextProvider = createContext<MatchesState | null>(null);
MatchesContextProvider.displayName = "MatchesContextProvider";

const fetchFunction = (api: Api) => api.leadToFleet.leadmatcherIsAvailableForFleet();

const MatchesProvider = ({children}: {children: React.ReactNode}) => {
    const accountState = useContext(AccountContextProvider);
    const {data, fetch} = useFetch(fetchFunction);

    const fleet = accountState.getFleet();

    const getIsAvailableData = useCallback(async () => {
        if (fetch && fleet) {
            fetch({});
        }
    }, [fetch, fleet]);

    useEffect(() => {
        getIsAvailableData();
    }, [getIsAvailableData]);

    const contextValue = useMemo(
        () => ({
            isAvailable: data?.is_available ?? false,
            hasOptInChance: data?.can_opt_in ?? false,
            isInactiveWithMatches: data?.is_inactive_with_matches ?? false,
            refreshState: getIsAvailableData,
        }),
        [data, getIsAvailableData],
    );

    return <MatchesContextProvider.Provider value={contextValue}>{children}</MatchesContextProvider.Provider>;
};

export {MatchesContextProvider, MatchesProvider};
