import {useCallback, useContext} from "react";

import {AppApiClient} from "common/services/apiClients/fleetOwnerPortalApi";
import {AppJwtAuthStrategy} from "common/services/apiClients/strategies/AppJwtAuthStrategy";
import {requestNewAccessToken, restoreRefreshToken} from "common/services/authService";
import {UseFleetOwnerForSignupResponse} from "features/account/pages/Verification/types";
import {VerificationAuthContextProvider} from "features/account/pages/Verification/VerificationAuthProvider";

import {FleetOwnerVerificationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

const fleetOwnerApi = new AppApiClient();

export const useFleetOwnerForSignup = () => {
    const refreshToken = restoreRefreshToken() ?? undefined;
    const {isAuthInitDone, setIsAuthInitDone} = useContext(VerificationAuthContextProvider);

    const updateAccessToken = useCallback(async () => {
        if (!refreshToken) {
            throw new Error("No refresh token provided");
        }

        const token = await requestNewAccessToken(refreshToken, undefined, undefined).catch((e) => {
            return Promise.reject(e);
        });

        return Promise.resolve(token);
    }, [refreshToken]);

    const initAuthApi = useCallback(async () => {
        let hasApiCallSucceeded = false;
        try {
            const accessToken = await requestNewAccessToken(refreshToken, undefined, undefined);

            const authStrategy = new AppJwtAuthStrategy(
                () => accessToken,
                updateAccessToken,
                () => {},
            );
            fleetOwnerApi.init(authStrategy, undefined, undefined, undefined);
            setIsAuthInitDone(true);
            hasApiCallSucceeded = true;
        } catch (e) {
            hasApiCallSucceeded = false;
            setIsAuthInitDone(false);
        }
        return hasApiCallSucceeded;
    }, [refreshToken, setIsAuthInitDone, updateAccessToken]);

    const fetchUseFleetOwnerForSignup = useCallback(
        async (
            body:
                | FleetOwnerVerificationService.UseFleetOwnerCheck
                | FleetOwnerVerificationService.ContinueWithFleetOwner
                | FleetOwnerVerificationService.CreateNewAccount,
        ): Promise<UseFleetOwnerForSignupResponse> => {
            if (refreshToken) {
                if (!isAuthInitDone) {
                    const hasApiCallSucceeded = await initAuthApi();
                    if (!hasApiCallSucceeded) {
                        return undefined;
                    }
                }
                return fleetOwnerApi.fleetOwnerVerification.useFleetOwnerForSignup(body);
            }
            return undefined;
        },
        [initAuthApi, isAuthInitDone, refreshToken],
    );

    return {fetchUseFleetOwnerForSignup};
};
