import {useI18n} from "common/hooks/useI18n";

import {Typography} from "@bolteu/kalep-react";
import {Scheduling} from "@bolteu/kalep-react-icons";

export const NoScheduledRides = () => {
    const {i18n} = useI18n();

    return (
        <div className="m-auto my-10 flex justify-center md:my-16">
            <div className="flex max-w-xs flex-col items-center gap-2">
                <Scheduling className="text-tertiary mb-2" width={64} height={64} />
                <Typography fontSize="text-lg" fontWeight="semibold">
                    {i18n("auth.app.orders.scheduled_rides.no-table-data-title")}
                </Typography>
                <Typography color="secondary" align="center">
                    {i18n("auth.app.orders.scheduled_rides.no-table-data-description")}
                </Typography>
            </div>
        </div>
    );
};
