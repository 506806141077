import {PaymentMethod} from "common/constants/PaymentMethod";
import {useI18n} from "common/hooks/useI18n";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Tooltip, Typography} from "@bolteu/kalep-react";
import {BusinessOutlined, CardOutlined, CashDriverOutlined, MobilePaymentOutlined} from "@bolteu/kalep-react-icons";

import EnumKey = FleetOwnerPortalService.EnumKey;

interface Props {
    value: string;
    valueMap: FleetOwnerPortalService.EnumValueMap;
    isMobile?: boolean;
}

type PaymentEnumKeys = EnumKey.CASH | EnumKey.CARD_TERMINAL | EnumKey.IN_APP | EnumKey.BUSINESS;

const paymentEnumKeyToPaymentMethod = (enumKey: PaymentEnumKeys): PaymentMethod => {
    switch (enumKey) {
        case EnumKey.CASH:
            return PaymentMethod.CASH;
        case EnumKey.CARD_TERMINAL:
            return PaymentMethod.CARD;
        case EnumKey.IN_APP:
            return PaymentMethod.APP_PAYMENT;
        case EnumKey.BUSINESS:
            return PaymentMethod.BUSINESS;
        default:
            return UnreachableCode.never(enumKey, PaymentMethod.APP_PAYMENT);
    }
};

const PaymentMethodCell = ({
    enumKey,
    enumValue,
    isMobile,
}: {
    enumKey: PaymentEnumKeys;
    enumValue: string;
    isMobile?: boolean;
}) => {
    const {i18n} = useI18n();

    if (isMobile) {
        return (
            <div>
                <Typography variant="body-tabular-m-regular" fontSize="text-sm">
                    {enumValue}
                </Typography>
            </div>
        );
    }

    const paymentMethod = paymentEnumKeyToPaymentMethod(enumKey);

    const getPaymentIcon = (payment: PaymentMethod) => {
        switch (payment) {
            case PaymentMethod.CASH:
                return <CashDriverOutlined size="sm" />;
            case PaymentMethod.CARD:
                return <CardOutlined size="sm" />;
            case PaymentMethod.APP_PAYMENT:
                return <MobilePaymentOutlined size="sm" />;
            case PaymentMethod.BUSINESS:
                return <BusinessOutlined size="sm" />;
            default:
                return UnreachableCode.never(payment, <MobilePaymentOutlined size="sm" />);
        }
    };

    return (
        <div className="w-fit">
            <Tooltip content={i18n(`payment-methods.${paymentMethod}`)} placement="top">
                <div className="text-secondary flex w-full items-center">{getPaymentIcon(paymentMethod)}</div>
            </Tooltip>
        </div>
    );
};

export const EnumCell = ({value, valueMap, isMobile}: Props) => {
    const DefaultRender = <Typography fontSize="text-sm">{valueMap[value]}</Typography>;
    const enumKey = value as EnumKey;
    switch (enumKey) {
        case EnumKey.CASH:
        case EnumKey.CARD_TERMINAL:
        case EnumKey.IN_APP:
        case EnumKey.BUSINESS: {
            return <PaymentMethodCell enumKey={enumKey} isMobile={isMobile} enumValue={valueMap[enumKey]} />;
        }
        case EnumKey.CREATED_BY_FLEET:
        case EnumKey.CREATED_BY_RIDER:
            return DefaultRender;
        default:
            return UnreachableCode.never(enumKey, DefaultRender);
    }
};
