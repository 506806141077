import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {TabName} from "..";
import {isFilterValueDefault} from "./isFilterValueDefault";

import CampaignFilters = FleetOwnerPortalService.CampaignFilters;

export function areAllFilterValuesDefault(activeTab: TabName, filters: CampaignFilters) {
    return Object.entries(filters).every(([key, value]) =>
        isFilterValueDefault(activeTab, key as keyof CampaignFilters, value as CampaignFilters[keyof CampaignFilters]),
    );
}
