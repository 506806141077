import {Link, LinkProps} from "react-router-dom";

import Logo from "assets/icons/logo.svg?react";

export interface LogoLinkProps {
    to: LinkProps["to"];
}

export const LogoLink = ({to}: LogoLinkProps) => {
    return (
        <Link to={to} className="block pt-1">
            <Logo className="h-8 w-[114px] translate-y-1 scale-y-105 md:h-7 md:translate-y-0 md:scale-y-100" />
        </Link>
    );
};
