interface BackdropProps {
    isVisible: boolean;
    onClick?: () => void;
}

// TODO: Create Backdrop component in Kalep
export const BackDrop = ({isVisible, onClick}: BackdropProps) => {
    return (
        <div
            role="presentation"
            onClick={onClick}
            className={`bg-transparent-700 fixed inset-y-0 left-0 z-20 xl:hidden  ${isVisible ? "right-0" : ""}`}
        />
    );
};
