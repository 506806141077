import {useContext} from "react";
import {Navigate, useLocation} from "react-router-dom";

import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {SupportWidgetContextProvider} from "SupportWidgetProvider";

const SupportRedirect = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const {getHomePath} = useAbsolutePath();
    const {setSelectedCaseId} = useContext(SupportWidgetContextProvider);

    const id = Number(params.get("selected_case_id"));
    setSelectedCaseId(id);

    return <Navigate to={getHomePath()} />;
};

export default SupportRedirect;
