import * as React from "react";

import {Dash} from "common/constants";

import {KalepIcon} from "@bolteu/kalep-react-icons";

interface Props {
    svg?: React.FC<KalepIcon>;
    title: string;
    mainValue: string;
    isLoading: boolean;
}

export const StatCard: React.FC<Props> = ({svg, title, mainValue, isLoading}) => {
    const Svg = svg;
    let valueStr = mainValue;
    let valueColor = "text-primary";

    if (valueStr === Dash.EN) {
        valueColor = "text-content-tertiary";
    } else if (Number(valueStr) < 0) {
        valueColor = "text-danger-primary";
        valueStr = valueStr.replace(Dash.HYPHEN, Dash.EN);
    }

    let content = <div className={`h-10 text-[28px] font-semibold ${valueColor}`}>{valueStr}</div>;
    if (isLoading) {
        content = <div className="block h-[42px] w-20 rounded-sm bg-neutral-200" />;
    }

    return (
        <div className="flex min-w-[160px] max-w-[240px] grow flex-col justify-between gap-2 rounded-lg border border-neutral-400 p-4">
            <div className="flex items-center gap-2">
                {Svg && (
                    <div className="flex h-6 w-6 shrink-0 items-center justify-center rounded-full bg-purple-100">
                        <Svg size="xs" className="text-purple-900" />
                    </div>
                )}
                <div className="text-xs">{title}</div>
            </div>
            {content}
        </div>
    );
};
