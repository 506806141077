import {FC} from "react";

import Icon from "assets/icons/live-map/empty-list-icon.svg?react";

interface EmptyListPlaceholderProps {
    title?: string;
    subtext?: React.ReactNode;
}

const EmptyListPlaceholder: FC<EmptyListPlaceholderProps> = ({title, subtext}) => {
    return (
        <div className="mt-28 w-full p-6">
            <div className="m-auto flex w-80 flex-col items-center text-center">
                <Icon className="mb-5" />
                <div className="text-xl font-semibold text-neutral-900">{title}</div>
                <div className="text-base font-normal leading-snug text-neutral-700">{subtext}</div>
            </div>
        </div>
    );
};

export default EmptyListPlaceholder;
