import {UnreachableCode} from "utils/UnreachableCode";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-vehicle-marketplace";

import VehicleColor = VehicleMarketplaceService.CarColor;

export const getVehicleColorClasses = (color: VehicleColor | string | null): string => {
    const vehicleColor = color as VehicleColor | null;
    switch (vehicleColor) {
        case VehicleColor.White:
            return "bg-white border border-neutral-300";
        case VehicleColor.Black:
            return "bg-[#000000]";
        case VehicleColor.Blue:
            return "bg-[#0000ff]";
        case VehicleColor.Green:
            return "bg-[#008000]";
        case VehicleColor.Yellow:
            return "bg-[#ffff00]";
        case VehicleColor.Red:
            return "bg-[#ff0000]";
        case VehicleColor.Purple:
            return "bg-[#800080]";
        case VehicleColor.Gray:
            return "bg-[#808080]";
        case VehicleColor.Brown:
            return "bg-[#a52a2a]";
        case VehicleColor.Beige:
            return "bg-[#f5f5dc]";
        case VehicleColor.Pink:
            return "bg-[#ffc0cb]";
        case VehicleColor.Orange:
            return "bg-[#ffa500]";
        case VehicleColor.Gold:
            return "bg-[#ffd700]";
        case VehicleColor.Silver:
            return "bg-[#c0c0c0]";
        case VehicleColor.Bronze:
            return "bg-[#cd7f32]";
        case VehicleColor.Maroon:
            return "bg-[#800000]";
        case VehicleColor.Wine:
            return "bg-[#722f37]";
        case VehicleColor.WineRed:
            return "bg-[#58181f]";
        case VehicleColor.SkyBlue:
            return "bg-[#87ceeb]";
        case VehicleColor.LightBlue:
            return "bg-[#add8e6]";
        case VehicleColor.PearlWhite:
            return "bg-[#f8f6f0]";
        case VehicleColor.Pearl:
            return "bg-[#e2dfd2]";
        case VehicleColor.DarkGrey:
            return "bg-[#a9a9a9]";
        case VehicleColor.NavyBlue:
            return "bg-[#000080]";
        case null:
            return "bg-white";
        default:
            // If string type is not matched to any other its resolved here
            return UnreachableCode.never(vehicleColor, "bg-white");
    }
};
