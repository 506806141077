import {useCallback, useContext, useState} from "react";

import {FixedHeaderTable} from "common/components/styledComponent/FixedHeaderTable";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {HoldingFleetInvoicesTableData} from "../hooks/useInvoices";
import {useTableColumns} from "../hooks/useTableColumns";
import {InvoiceDetailsDialog} from "./InvoiceDetailsDialog";
import NoInvoices from "./NoInvoices";
import {SubFleetsList} from "./SubFleetsList";

interface Props {
    items: HoldingFleetInvoicesTableData[];
}

const MainContent = ({items}: Props) => {
    const [invoiceDetailsToBeShown, setInvoiceDetailsToBeShown] = useState<HoldingFleetInvoicesTableData | null>(null);
    const subFleets = useContext(AccountContextProvider)?.getHoldingFleet()?.sub_fleets;

    const handleInvoiceDetailsClick = useCallback(
        (item: HoldingFleetInvoicesTableData) => () => {
            setInvoiceDetailsToBeShown(item);
        },
        [],
    );

    const closeDetails = useCallback(() => {
        setInvoiceDetailsToBeShown(null);
    }, []);

    const columns = useTableColumns(handleInvoiceDetailsClick);

    let Content = <NoInvoices />;
    if (items.length) {
        Content = (
            <>
                <FixedHeaderTable aria-label="Enterprise invoices" columns={columns} items={items} />
                {invoiceDetailsToBeShown !== null && (
                    <InvoiceDetailsDialog invoice={invoiceDetailsToBeShown} onClose={closeDetails} />
                )}
            </>
        );
    }

    return (
        <>
            <div className="w-full min-w-[450px] lg:max-w-[550px] xl:max-w-[760px]">{Content}</div>
            {!!subFleets?.length && <SubFleetsList subFleets={subFleets} />}
        </>
    );
};

export default MainContent;
