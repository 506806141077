import {FC, useCallback, useContext, useEffect, useMemo} from "react";

import {CheckboxActionEvent, EventName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {FeaturesContextProvider} from "FeaturesProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Checkbox} from "@bolteu/kalep-react";
import FleetOwnerConsent = FleetOwnerPortalService.FleetOwnerConsent;
import Channel = FleetOwnerPortalService.Channel;
import Consent = FleetOwnerPortalService.Consent;

const getConsentsFetchFunction = (api: Api, query: FleetOwnerPortalService.GetFleetOwnerConsentsRequest) =>
    api.fleetOwnerPortal.getFleetOwnerConsents(query);
const changeConsentsFetchFunction = (api: Api, query: FleetOwnerPortalService.ChangeFleetOwnerConsentsRequest) =>
    api.fleetOwnerPortal.changeFleetOwnerConsents(query);

const NotificationPreferences: FC = () => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();
    const features = useContext(FeaturesContextProvider);

    const {
        fetch: getConsentsFetch,
        status: getConsentsStatus,
        data: getConsentsData,
    } = useFetch(getConsentsFetchFunction);
    const {fetch: changeConsentsFetch, status: changeConsentsStatus} = useFetch(changeConsentsFetchFunction);
    const isLoading = [getConsentsStatus, changeConsentsStatus].includes(FetchStatus.Loading);

    useEffect(() => {
        if (getConsentsFetch) {
            getConsentsFetch({});
        }
    }, [getConsentsFetch]);

    useEffect(() => {
        if (changeConsentsStatus === FetchStatus.Success && getConsentsFetch) {
            getConsentsFetch({});
            trackEvent(EventName.CheckboxClicked, {
                action: CheckboxActionEvent.TogglePortalNotifications,
            });
        }
    }, [changeConsentsStatus, getConsentsFetch, i18n, trackEvent]);

    const isSubscribed = (consent: FleetOwnerConsent, channel: Channel) => {
        return consent.channels.find((ch) => ch.type === channel)?.value ?? false;
    };

    const isSupportWidgetFeatureEnabled = !!features?.support_widget;

    const changeConsent = useCallback(
        (fleetOwnerConsent: FleetOwnerConsent, channel: Channel) => () => {
            if (changeConsentsFetch && fleetOwnerConsent.consent) {
                changeConsentsFetch({
                    channel,
                    value: !isSubscribed(fleetOwnerConsent, channel),
                    consent: fleetOwnerConsent.consent,
                });
            }
        },
        [changeConsentsFetch],
    );

    const hasPortalChannel = useMemo(
        () =>
            getConsentsData?.consents.some((consent) =>
                consent.channels.some((channel) => channel.type === Channel.PORTAL_NOTIFICATIONS),
            ),
        [getConsentsData],
    );

    const hasSmsChannel = useMemo(
        () =>
            getConsentsData?.consents.some((consent) =>
                consent.channels.some((channel) => channel.type === Channel.SMS),
            ),
        [getConsentsData],
    );

    const hasEmailChannel = useMemo(
        () =>
            getConsentsData?.consents.some((consent) =>
                consent.channels.some((channel) => channel.type === Channel.EMAIL),
            ),
        [getConsentsData],
    );

    const isShowSmsConsent = useCallback(
        (consent: Consent | null) => hasSmsChannel && consent === Consent.FLEET_OWNER_SUPPLY_UPDATES,
        [hasSmsChannel],
    );

    const isShowEmailConsent = useCallback(
        (consent: Consent | null) => hasEmailChannel && consent === Consent.FLEET_OWNER_SUPPORT,
        [hasEmailChannel],
    );

    const isShowPortalConsent = useCallback(
        (consent: Consent | null) =>
            consent &&
            hasPortalChannel &&
            [Consent.FLEET_OWNER_SUPPLY_UPDATES, Consent.FLEET_OWNER_PRODUCT_ANNOUNCEMENTS].includes(consent),
        [hasPortalChannel],
    );

    const getHeader = () => (
        <div className="flex">
            <div className="text-primary flex-1 font-semibold">
                {i18n("auth.app.settings.notification_preferences.activity")}
            </div>
            {hasPortalChannel && (
                <div className="text-primary w-24 flex-none items-center font-semibold">
                    {i18n("auth.app.settings.notification_preferences.channels.portal")}
                </div>
            )}
            {hasSmsChannel && (
                <div className="text-primary w-24 flex-none items-center font-semibold">
                    {i18n("auth.app.settings.notification_preferences.channels.sms")}
                </div>
            )}
            {hasEmailChannel && isSupportWidgetFeatureEnabled && (
                <div className="text-primary w-24 flex-none items-center font-semibold">
                    {i18n("auth.app.settings.notification_preferences.channels.email")}
                </div>
            )}
        </div>
    );

    const getPortalConsentCheckbox = (consent: FleetOwnerPortalService.FleetOwnerConsent) => (
        <>
            {isShowPortalConsent(consent.consent) ? (
                <Checkbox
                    disabled={isLoading}
                    checked={isSubscribed(consent, Channel.PORTAL_NOTIFICATIONS)}
                    onChange={changeConsent(consent, Channel.PORTAL_NOTIFICATIONS)}
                />
            ) : (
                <Checkbox disabled />
            )}
        </>
    );

    const getSmsConsentCheckbox = (consent: FleetOwnerPortalService.FleetOwnerConsent) => (
        <>
            {isShowSmsConsent(consent.consent) ? (
                <Checkbox
                    disabled={isLoading}
                    checked={isSubscribed(consent, Channel.SMS)}
                    onChange={changeConsent(consent, Channel.SMS)}
                />
            ) : (
                <Checkbox disabled />
            )}
        </>
    );

    const getEmailConsentCheckbox = (consent: FleetOwnerPortalService.FleetOwnerConsent) => (
        <>
            {isShowEmailConsent(consent.consent) ? (
                <Checkbox
                    disabled={isLoading}
                    checked={isSubscribed(consent, Channel.EMAIL)}
                    onChange={changeConsent(consent, Channel.EMAIL)}
                />
            ) : (
                <Checkbox disabled />
            )}
        </>
    );

    return (
        <div className="flex max-w-xl flex-col gap-4">
            {getHeader()}
            {getConsentsData?.consents.map((consent) => {
                return (
                    <div className="flex" key={consent.consent}>
                        <div className="flex-1">
                            <div className="text-primary">{consent.name}</div>
                            <div className="text-secondary max-w-sm text-sm">{consent.description}</div>
                        </div>
                        {hasPortalChannel && <div className="w-24 flex-none">{getPortalConsentCheckbox(consent)}</div>}
                        {hasSmsChannel && <div className="w-24 flex-none">{getSmsConsentCheckbox(consent)}</div>}
                        {hasEmailChannel && isSupportWidgetFeatureEnabled && (
                            <div className="w-24 flex-none">{getEmailConsentCheckbox(consent)}</div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default NotificationPreferences;
