const countriesWithCashDisabled = [
    "dk", // Denmark
    "nl", // Netherlands - not cashless, but “They are using cash infrastructure but in reality this is only for card terminals in the cars“ - Sergii Moliavko (PM)
    "ca", // Canada
    "au", // Australia
    "pt", // Portugal
    "gb", // United Kingdom
    "se", // Sweden
    "no", // Norway
];

export const isCompanyCountryUsingCash = (country: string) => {
    return !countriesWithCashDisabled.includes(country);
};
