import Bowser from "bowser";
import {v4} from "uuid";

export const DeviceUUIDKey = "uuid";

export class CommonBrowserInfo {
    constructor(
        private readonly getItem: (key: string) => string | null,
        private readonly setItem: (key: string, value: string) => void | null,
    ) {}

    private getDeviceName() {
        return Bowser.parse(window.navigator.userAgent).browser.name || "unknown";
    }

    private getDeviceOSInfo() {
        return Bowser.parse(window.navigator.userAgent).os.version?.toString() || "unknown";
    }

    private getUserAgent() {
        return window.navigator.userAgent;
    }

    private getDeviceUniqueId() {
        const authKey = this.getItem(DeviceUUIDKey);
        if (authKey) {
            return authKey;
        }

        const deviceId = v4();
        this.setItem(DeviceUUIDKey, deviceId);

        return deviceId;
    }

    public getDeviceInfo() {
        return {
            device_uuid: this.getDeviceUniqueId(),
            device_name: this.getDeviceName(),
            device_os_version: this.getDeviceOSInfo(),
            user_agent: this.getUserAgent(),
        };
    }
}
