import {FC, ReactNode, useContext} from "react";

import {useI18n} from "common/hooks/useI18n";
import {FeaturesContextProvider} from "FeaturesProvider";

import {Link} from "@bolteu/kalep-react";

interface CustomerSupportContactProps {
    customerSupportEmail: string | null;
}

const CustomerSupportContact: FC<CustomerSupportContactProps> = ({customerSupportEmail}) => {
    const features = useContext(FeaturesContextProvider);

    const {i18n} = useI18n();

    let customerSupportText: string | ReactNode = i18n(
        "auth.app.company-settings.contact_support_block.no_customer_support_email",
    );

    if (features?.support_widget) {
        customerSupportText = i18n("auth.app.company-settings.contact_support_block.support_widget_enabled");
    } else if (customerSupportEmail) {
        customerSupportText = i18n("auth.app.company-settings.contact_support_block.customer_support_email_provided", {
            email: <Link href={`mailto:${customerSupportEmail}`}>{customerSupportEmail}</Link>,
        });
    }

    return <p className="text-secondary text-sm">{customerSupportText}</p>;
};

export default CustomerSupportContact;
