import ConfirmActionDialog, {
    ConfirmActionDialogVariant,
} from "common/components/confirmActionDialog/ConfirmActionDialog";
import {useI18n} from "common/hooks/useI18n";

interface Props {
    isOpen: boolean;
    close: () => void;
    confirmActivation: () => void;
    isLoading: boolean;
}

const ActivateConfirmationDialog = ({isOpen, close, confirmActivation, isLoading}: Props) => {
    const {i18n} = useI18n();

    const titleText = i18n("auth.app.fleet.vehicle_marketplace.listings.view.activate_application_dialog.title");
    const descriptionText = i18n(
        "auth.app.fleet.vehicle_marketplace.listings.view.activate_application_dialog.description",
    );
    const confirmActivationText = i18n(
        "auth.app.fleet.vehicle_marketplace.listings.view.activate_application_dialog.confirm_button_text",
    );

    return (
        <ConfirmActionDialog
            closeDialog={close}
            confirmButtonAction={confirmActivation}
            confirmButtonVariant={ConfirmActionDialogVariant.Primary}
            confirmButtonText={confirmActivationText}
            descriptionText={descriptionText}
            isLoading={isLoading}
            isOpen={isOpen}
            titleText={titleText}
        />
    );
};

export default ActivateConfirmationDialog;
