import {FC, useCallback, useEffect, useState} from "react";
import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";
import {ApiPeriod, apiPeriodToDates, datesToApiPeriod} from "common/services/period";
import {getCurrentDate} from "common/util/dateUtil";
import {addDays, addMonths, differenceInCalendarDays, isSameYear, subMonths} from "date-fns";

import {DatePicker} from "@bolteu/kalep-react";

const START_DATE_DIFF_MONTHS = 16;
const SAME_YEAR_DATE_FORMAT = "MMM d";
const DIFFERENT_YEAR_DATE_FORMAT = "MMM d, y";

export enum AllowedDatePeriod {
    Past = "past",
    Future = "future",
}

interface CustomPeriodPickerProps {
    period: ApiPeriod;
    onDatesChange: (period: ApiPeriod) => void;
    dateRangeLimitDays: number;
    onErrorStateChange?: (hasError: boolean) => void;
    allowedDatePeriod?: AllowedDatePeriod;
    allowedDateIntervalMonths?: number;
    allowedDateIntervalDays?: number;
}

const CustomPeriodPicker: FC<CustomPeriodPickerProps> = ({
    onDatesChange,
    period,
    dateRangeLimitDays,
    onErrorStateChange,
    allowedDatePeriod = AllowedDatePeriod.Past,
    allowedDateIntervalMonths = START_DATE_DIFF_MONTHS,
    allowedDateIntervalDays,
}) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const [dates, setDates] = useState<{start: Date; end: Date | null}>({
        start: getCurrentDate(),
        end: getCurrentDate(),
    });

    const [isDateRangeTooBig, setIsDateRangeTooBig] = useState(false);

    const minDate =
        allowedDatePeriod === AllowedDatePeriod.Past
            ? subMonths(getCurrentDate(), allowedDateIntervalMonths)
            : getCurrentDate();
    let maxDate =
        allowedDatePeriod === AllowedDatePeriod.Past
            ? getCurrentDate()
            : addMonths(getCurrentDate(), allowedDateIntervalMonths);
    if (allowedDateIntervalDays) {
        maxDate =
            allowedDatePeriod === AllowedDatePeriod.Past
                ? getCurrentDate()
                : addDays(getCurrentDate(), allowedDateIntervalDays);
    }

    const setError = useCallback(
        (isError: boolean) => {
            if (onErrorStateChange) {
                onErrorStateChange(isError);
            }
            setIsDateRangeTooBig(isError);
        },
        [onErrorStateChange],
    );

    useEffect(() => {
        setError(false);
        setDates(apiPeriodToDates(period));
    }, [period, setError]);

    const onStartDateChange = useCallback(
        (start: Date) => {
            setError(false);
            setDates({start, end: null});
        },
        [setError],
    );

    const onEndDateChange = useCallback(
        (start: Date, end: Date) => {
            let isError = false;

            if (differenceInCalendarDays(end, start) + 1 > dateRangeLimitDays) {
                setError(true);
                isError = true;
            } else {
                setError(false);
            }

            if (isError) {
                setDates({start, end});
            } else {
                onDatesChange(datesToApiPeriod(start, end));
            }
        },
        [onDatesChange, dateRangeLimitDays, setError],
    );

    const onDateChange = useCallback(
        async (newRange: Date | Date[] | null) => {
            if (!Array.isArray(newRange)) {
                return;
            }
            const [start, end] = newRange;

            if (!end) {
                onStartDateChange(start);
            } else {
                onEndDateChange(start, end);
            }
        },
        [onStartDateChange, onEndDateChange],
    );

    const datePickerHelperText = () => {
        if (isDateRangeTooBig) {
            return i18n("auth.app.fleet.reports.date_picker.date_range_too_big_helper_text", {
                maxRange: dateRangeLimitDays,
            });
        }
        return undefined;
    };

    const hasDatesInSameYear = !dates.end || isSameYear(dates.start, dates.end);

    return (
        <div className={`${hasDatesInSameYear ? " sm:w-60" : "sm:w-72"} overflow-hidden`}>
            <DatePicker
                isRange
                startValue={dates.start}
                endValue={dates.end}
                onChange={onDateChange}
                minDate={minDate}
                maxDate={maxDate}
                helperText={datePickerHelperText()}
                format={hasDatesInSameYear ? SAME_YEAR_DATE_FORMAT : DIFFERENT_YEAR_DATE_FORMAT}
                locale={intl.locale}
                isError={isDateRangeTooBig}
                fullWidth
            />
        </div>
    );
};

export default CustomPeriodPicker;
