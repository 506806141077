import {FC, ReactNode} from "react";

import {useI18n} from "common/hooks/useI18n";
import {TranslationKeys} from "config/translations";

import {Driver} from "@bolteu/kalep-react-icons";

interface NoResultsProps {
    titleKey?: string;
    showActionComponent?: boolean;
    subTitleKey?: string;
    actionComponent?: ReactNode;
}

const NoResults: FC<NoResultsProps> = ({showActionComponent = false, titleKey, subTitleKey, actionComponent}) => {
    const {i18n} = useI18n();

    const title = titleKey ? i18n(titleKey as TranslationKeys) : i18n("common.no_results.title");
    const subTitle = subTitleKey ? i18n(subTitleKey as TranslationKeys) : null;

    return (
        <div className="mt-24 flex flex-col items-center justify-center gap-2 md:mt-40">
            <Driver className="mb-2 h-[64px] w-[64px]" />
            <h5 className="max-w-[420px] text-center text-xl font-semibold text-neutral-900">{title}</h5>
            {subTitle && <p className="max-w-[420px] text-center text-neutral-700">{subTitle}</p>}
            {showActionComponent && actionComponent && <div className="mt-6">{actionComponent}</div>}
        </div>
    );
};

export default NoResults;
