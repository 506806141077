import Header, {HeaderSize} from "common/components/header/Header";
import {I18nFn, useI18n} from "common/hooks/useI18n";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {Stepper, Typography} from "@bolteu/kalep-react";

import {TOTAL_STEPS} from "../../constants";
import {StepType} from "../../types";

interface Props {
    activeStep: StepType;
}

const getHeaderText = (activeStep: StepType, i18n: I18nFn) => {
    switch (activeStep) {
        case 0:
            return i18n("auth.app.orders.scheduled_rides.create-dialog.step-1-title");
        case 1:
            return i18n("auth.app.orders.scheduled_rides.create-dialog.step-2-title");
        case 2:
            return i18n("auth.app.orders.scheduled_rides.create-dialog.step-3-title");
        default:
            return UnreachableCode.never(activeStep, "");
    }
};

const getDescription = (activeStep: StepType, i18n: I18nFn) => {
    switch (activeStep) {
        case 0:
            return i18n("auth.app.orders.scheduled_rides.create-dialog.next-step", {
                nextStepName: i18n("auth.app.orders.scheduled_rides.create-dialog.step-2-title"),
            });
        case 1:
            return i18n("auth.app.orders.scheduled_rides.create-dialog.next-step", {
                nextStepName: i18n("auth.app.orders.scheduled_rides.create-dialog.step-3-title"),
            });
        case 2:
            return i18n("auth.app.orders.scheduled_rides.create-dialog.last-step");
        default:
            return UnreachableCode.never(activeStep, "");
    }
};

export const StepHeader = ({activeStep}: Props) => {
    const {i18n} = useI18n();

    return (
        <div className="mb-6">
            <Header size={HeaderSize.ExtraSmall} text={getHeaderText(activeStep, i18n)} className="mb-2" />
            <div className="flex flex-col gap-4">
                <Typography color="secondary" fontSize="text-sm">
                    {getDescription(activeStep, i18n)}
                </Typography>
                <Stepper steps={TOTAL_STEPS} value={activeStep} />
            </div>
        </div>
    );
};
