import {useCallback, useEffect, useState} from "react";

import {ButtonActionEvent, EventName} from "common/constants/events";
import {useTracking} from "common/hooks/useTracking";

import {FleetInboxService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {ExpandButtonProps} from "../components/NotificationListItem";
import {dummyGroupNotifications, expandGroup, groupNotifications, NotificationGroup} from "../groupHelper";

export const useGroups = (
    notifications: FleetInboxService.LocalizedPortalNotification[],
    setNotificationsAsRead: (notifications: FleetInboxService.LocalizedPortalNotification[]) => void,
    newUi: boolean,
) => {
    const [groups, setGroups] = useState<NotificationGroup[]>([]);
    const {trackEvent} = useTracking();

    useEffect(() => {
        setGroups((currentGroups) =>
            newUi ? groupNotifications(notifications, currentGroups) : dummyGroupNotifications(notifications),
        );
    }, [newUi, notifications]);

    const setNotificationsAsReadWrapper = useCallback(
        (list: FleetInboxService.LocalizedPortalNotification[]) => () => {
            setNotificationsAsRead(list);
        },
        [setNotificationsAsRead],
    );

    const getExpandButtonProps = useCallback(
        (group: NotificationGroup): ExpandButtonProps | undefined => {
            if (group.notifications.length < 2) {
                return undefined;
            }

            return {
                hiddenCount: group.notifications.length - 1,
                onExpand: () => {
                    if (group.isExpanded) {
                        return;
                    }
                    setGroups((prevGroups) => expandGroup(prevGroups, group.notifications[0]));
                    setNotificationsAsRead(group.notifications);
                    trackEvent(EventName.ButtonClicked, {action: ButtonActionEvent.NotificationGroupExpanded});
                },
            };
        },
        [setNotificationsAsRead, trackEvent],
    );

    return {groups, setNotificationsAsReadWrapper, getExpandButtonProps};
};
