import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {ApiDrivenTableTransforms} from "./types";

import ApiColumn = FleetOwnerPortalService.ApiColumn;
import ListColumnType = FleetOwnerPortalService.ListColumnType;

export function generateSimpleKey(rowIdx: number) {
    return `${rowIdx}-${Date.now()}`;
}

export function isColumnCustomizable(column: ApiColumn): boolean {
    return (
        ![ListColumnType.DATE, ListColumnType.STATE, ListColumnType.REFERENCE_V2, ListColumnType.STATUS].includes(
            column.type,
        ) && !column.relation_key
    );
}

export function isColumnVisible(column: ApiColumn): boolean {
    return ![ListColumnType.STATE].includes(column.type) && !column.relation_key;
}

export function isReferenceColumn(column: ApiColumn): boolean {
    return column.type === ListColumnType.REFERENCE_V2;
}

export function isDateColumn(column: ApiColumn): boolean {
    return column.type === ListColumnType.DATE;
}

export function isStatusColumn(column: ApiColumn): boolean {
    return column.type === ListColumnType.STATUS;
}

export function isStateColumn(column: ApiColumn): boolean {
    return column.type === ListColumnType.STATE;
}

export function hasRenderers(column: ApiColumn, transforms?: ApiDrivenTableTransforms): boolean {
    return (
        column.type !== ListColumnType.CUSTOM ||
        Boolean(column.type === ListColumnType.CUSTOM && transforms && transforms[column.key])
    );
}
