import {FC, useCallback} from "react";

import {SelectPeriodOption, usePeriodSelectorOptions} from "common/hooks/usePeriodSelectorOptions";
import {ApiPeriod} from "common/services/period";

import {Button, Menu, MenuItem, MenuItemClickEvent} from "@bolteu/kalep-react";
import {Check, ChevronDown} from "@bolteu/kalep-react-icons";

interface ReportsPageHeaderProps {
    period: ApiPeriod;
    selectPeriodOptions: SelectPeriodOption[];
    onSelectionChange: (apiPeriod: ApiPeriod | undefined) => void;
    excludeCustomRange?: boolean;
}

const renderCheck = () => {
    return <Check size="sm" className="text-primary" />;
};

const PeriodSelect: FC<ReportsPageHeaderProps> = ({
    period,
    selectPeriodOptions,
    onSelectionChange,
    excludeCustomRange = false,
}) => {
    const {selectOptions, selectionToPeriodMap, getSelectOption} = usePeriodSelectorOptions(
        selectPeriodOptions,
        excludeCustomRange,
    );

    const selectedPeriodOption = getSelectOption(period);

    const handlePeriodSelection = useCallback(
        (event: MenuItemClickEvent) => {
            const newPeriod = selectionToPeriodMap.get(event.value as string);
            onSelectionChange(newPeriod);
        },
        [onSelectionChange, selectionToPeriodMap],
    );

    return (
        <div className="max-w-[240px] whitespace-nowrap">
            <Menu
                menuButton={
                    <Button variant="secondary">
                        {selectedPeriodOption.title} <ChevronDown size="sm" />
                    </Button>
                }
                overrideClassName="w-[240px]"
            >
                {selectOptions.map((option) => (
                    <MenuItem
                        key={option.value}
                        value={option.value ?? ""}
                        onClick={handlePeriodSelection}
                        label={option.title}
                        renderEndSlot={option.value === selectedPeriodOption.value ? renderCheck : undefined}
                        disabled={option.disabled}
                    />
                ))}
            </Menu>
        </div>
    );
};

export default PeriodSelect;
