import {FC, useContext, useEffect, useMemo, useState} from "react";

import countries, {CountryCode} from "common/geo/constants/countries";
import {useI18n} from "common/hooks/useI18n";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {TinInlineEdit} from "./TinInlineEdit";

export interface TinData {
    id: number | undefined;
    country: string;
    countryName: string;
    tin: string | undefined;
}

interface TinInformationProps {
    tinsResponse: FleetOwnerPortalService.TaxIdentificationNumberWithId[];
    refreshData: () => void;
}

const TinInformation: FC<TinInformationProps> = ({tinsResponse, refreshData}) => {
    const {i18n} = useI18n();

    const fleet = useContext(AccountContextProvider)?.getFleet();

    const countryOptions = useMemo(() => {
        const translateCountry = (code: CountryCode, name: string) => i18n(`countries.${code}`) || name;
        return countries.map((x) => ({title: translateCountry(x.code, x.name), value: x.code}));
    }, [i18n]);

    const [tinData, setTinData] = useState<TinData[] | null>(null);

    useEffect(() => {
        let tins: TinData[] = [];

        const companyCountry = fleet?.country;
        if (!companyCountry) {
            return;
        }
        const emptyTin = {
            id: undefined,
            country: companyCountry,
            countryName: countryOptions.find((x) => x.value === companyCountry)?.title || companyCountry,
            tin: undefined,
        };

        if (tinsResponse.length) {
            tins = tinsResponse.map((tin) => ({
                ...tin,
                countryName: countryOptions.find((x) => x.value === tin.country)?.title || tin.country,
            }));

            if (tins.every((x) => x.country !== companyCountry)) {
                tins = [emptyTin, ...tins];
            }
        } else {
            tins = [];
        }
        setTinData(tins);
    }, [tinsResponse, fleet, countryOptions]);

    return <TinInlineEdit countryOptions={countryOptions} tinData={tinData} onSubmit={refreshData} />;
};

export default TinInformation;
