import {useMemo} from "react";

import {DriverLocationHistoryItem} from "./useDriverLocationHistory";

/**
 * Calculate the bearing between two points on the Earth's surface.
 *
 * @param startLat - The latitude of the starting point.
 * @param startLng - The longitude of the starting point.
 * @param endLat - The latitude of the ending point.
 * @param endLng - The longitude of the ending point.
 * @returns The bearing in degrees.
 */
function calculateBearing(startLat: number, startLng: number, endLat: number, endLng: number) {
    const startLatRad = toRadians(startLat);
    const startLngRad = toRadians(startLng);
    const endLatRad = toRadians(endLat);
    const endLngRad = toRadians(endLng);

    // Calculate the difference in longitude
    const dLng = endLngRad - startLngRad;

    // Calculate the y and x components of the bearing

    const y = Math.sin(dLng) * Math.cos(endLatRad);
    const x =
        Math.cos(startLatRad) * Math.sin(endLatRad) - Math.sin(startLatRad) * Math.cos(endLatRad) * Math.cos(dLng);

    // Calculate the bearing in radians and convert to degrees

    let bearing = Math.atan2(y, x);
    bearing = toDegrees(bearing);

    // Normalize the bearing to be within 0-360 degrees
    return (bearing + 360) % 360;
}

function toRadians(degrees: number) {
    return degrees * (Math.PI / 180);
}

function toDegrees(radians: number) {
    return radians * (180 / Math.PI);
}

/**
 * Calculate the bearing for a driver based on their location history.
 *
 * @param driverLocationHistory - An array of DriverLocationHistoryItem objects.
 * @returns The bearing in degrees.
 */
function calculateBearingForDriver(driverLocationHistory: DriverLocationHistoryItem[]) {
    if (driverLocationHistory.length < 2) {
        return 0;
    }

    const bearing = calculateBearing(
        driverLocationHistory[driverLocationHistory.length - 2].point.lat,
        driverLocationHistory[driverLocationHistory.length - 2].point.lng,
        driverLocationHistory[driverLocationHistory.length - 1].point.lat,
        driverLocationHistory[driverLocationHistory.length - 1].point.lng,
    );

    return bearing;
}

export function useCalculateDriverDirection(driverLocationHistory: DriverLocationHistoryItem[]) {
    return useMemo(() => {
        const driverToHistory = new Map<number, DriverLocationHistoryItem[]>();

        driverLocationHistory.forEach((item) => {
            const {driverId} = item;
            if (!driverToHistory.has(driverId)) {
                driverToHistory.set(driverId, []);
            }
            driverToHistory.get(driverId)?.push(item);
        });

        const driverDirections = new Map<number, number>();

        driverToHistory.forEach((history) => {
            if (history.length < 2) {
                return;
            }

            const bearing = calculateBearingForDriver(history);
            driverDirections.set(history[0].driverId, bearing);
        });

        return driverDirections;
    }, [driverLocationHistory]);
}
