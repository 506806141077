import {FC} from "react";
import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";
import {convertToNegative} from "common/util/FormatUtil";

import {formatTransactionNumber} from "../../utils/formatTransactionNumber";
import {GetGraphsData} from "./getGraphsData";

interface NetEarningsTableProps {
    data: GetGraphsData;
    cashInHand: number;
    currency: string;
}

const NetEarningsTable: FC<NetEarningsTableProps> = ({data, cashInHand, currency}) => {
    const {i18n} = useI18n();
    const intl = useIntl();

    const netEarnings = cashInHand + data.income.total - data.outcome.total;

    return (
        <div>
            <div className="flex justify-between py-2">
                <span>{i18n("auth.app.fleet.balance.transactions_list.earnings.cash_payment.title")}</span>
                <div>
                    <span className="pr-[2px]">{formatTransactionNumber(intl, cashInHand)}</span>
                    <span className="text-content-secondary text-xs">{currency}</span>
                </div>
            </div>
            <div className="flex justify-between py-2">
                <span>{i18n("auth.app.fleet.balance.transactions.card.earnings")}</span>
                <div>
                    <span className="pr-[2px]">{formatTransactionNumber(intl, data.income.total)}</span>
                    <span className="text-content-secondary text-xs">{currency}</span>
                </div>
            </div>
            <div className="flex justify-between py-2">
                <span>{i18n("auth.app.fleet.balance.transactions.card.expenses")}</span>
                <div>
                    <span className="pr-[2px]">
                        {formatTransactionNumber(intl, convertToNegative(data.outcome.total))}
                    </span>
                    <span className="text-content-secondary text-xs">{currency}</span>
                </div>
            </div>
            <div className="my-1 border-t border-neutral-300" />
            <div className="flex justify-between py-2">
                <span>{i18n("auth.app.fleet.balance.transactions.card.header.net_earnings")}</span>
                <div>
                    <span className="pr-[2px]">{formatTransactionNumber(intl, netEarnings)}</span>
                    <span className="text-content-secondary text-xs">{currency}</span>
                </div>
            </div>
        </div>
    );
};

export default NetEarningsTable;
