import {Company} from "common/constants/types";

export function useSearchedCompanies(search: string, companies?: Company[]) {
    if (!companies) {
        return [];
    }
    if (search.length) {
        return companies.filter(({name}) => name.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
    }
    return companies;
}
