import {useEffect, useRef, useState} from "react";
import ReactDOM from "react-dom";

import {combine} from "@atlaskit/pragmatic-drag-and-drop/combine";
import {
    DropTargetGetFeedbackArgs,
    ElementDragPayload,
    ElementDragType,
} from "@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types";
import {draggable, dropTargetForElements} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import {setCustomNativeDragPreview} from "@atlaskit/pragmatic-drag-and-drop/element/set-custom-native-drag-preview";
import {attachClosestEdge, Edge, extractClosestEdge} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import {DropIndicator} from "@atlaskit/pragmatic-drag-and-drop-react-drop-indicator/box";

import {DraggableState, DraggableType, DraggingEvent, DragPayload} from "./types";

interface Props<Item> {
    getItemData: () => Item;
    isItemDataSame: (draggedItem: DragPayload<Item>) => boolean;
    className?: string;
    children: React.ReactNode;
    dragPreviewElement?: React.ReactNode;
    dragHandleRef?: React.RefObject<HTMLElement>;
}

const getDataAsRecordType = (data: unknown): Record<string, unknown> => {
    if (typeof data === "object") {
        return data as Record<string, unknown>;
    }
    throw new Error("getInitialItemData should return an object");
};

const getSource = <ItemType,>(source: ElementDragPayload): DragPayload<ItemType> => {
    if (typeof source.data === "object") {
        return source as DragPayload<ItemType>;
    }
    throw new Error("Source data invalid");
};

export const DraggableListItem = <ItemType,>({
    className,
    children,
    dragPreviewElement = null,
    dragHandleRef,
    getItemData,
    isItemDataSame,
}: Props<ItemType>) => {
    const draggableItemRef = useRef(null);
    const [draggableState, setDraggableState] = useState<DraggableState>({type: DraggableType.IDLE});
    const [closestEdge, setClosestEdge] = useState<Edge | null>(null);

    useEffect(() => {
        const draggableElement = draggableItemRef.current;
        const dragHandleElement = dragHandleRef?.current;
        if (!draggableElement) {
            return () => {};
        }

        const data = getDataAsRecordType(getItemData());

        return combine(
            draggable({
                element: dragHandleElement ?? draggableElement,
                getInitialData: () => data,
                onDragStart: () => setDraggableState({type: DraggableType.DRAGGING}),
                onDrop: () => setDraggableState({type: DraggableType.IDLE}),
                onGenerateDragPreview: dragPreviewElement
                    ? ({nativeSetDragImage}) => {
                          setCustomNativeDragPreview({
                              nativeSetDragImage,
                              getOffset: (args: {container: HTMLElement}) => {
                                  const boundingClientRect = args.container.getBoundingClientRect();
                                  return {x: boundingClientRect.width, y: boundingClientRect.height - 20};
                              },
                              render({container}: {container: HTMLElement}) {
                                  setDraggableState({type: DraggableType.PREVIEW, container});
                                  return () => setDraggableState({type: DraggableType.DRAGGING});
                              },
                          });
                      }
                    : undefined,
            }),
            dropTargetForElements({
                element: draggableElement,
                getData: ({input, element}: DropTargetGetFeedbackArgs<ElementDragType>) => {
                    return attachClosestEdge(data, {
                        input,
                        element,
                        allowedEdges: ["top", "bottom"],
                    });
                },
                onDragEnter: ({source, self}: DraggingEvent) => {
                    if (!isItemDataSame(getSource(source))) {
                        setClosestEdge(extractClosestEdge(self.data));
                    }
                },
                onDrag: ({source, self}: DraggingEvent) => {
                    if (!isItemDataSame(getSource(source))) {
                        setClosestEdge(extractClosestEdge(self.data));
                    }
                },
                onDragLeave: () => {
                    setClosestEdge(null);
                },
                onDrop: () => {
                    setClosestEdge(null);
                },
            }),
        );
    }, [dragHandleRef, getItemData, isItemDataSame, dragPreviewElement]);

    const fieldOpacity = draggableState.type === DraggableType.DRAGGING ? "opacity-30" : "";

    return (
        <>
            <div className={`${className} relative ${fieldOpacity}`} ref={draggableItemRef}>
                {children}
                {closestEdge && <DropIndicator edge={closestEdge} />}
            </div>
            {draggableState.type === DraggableType.PREVIEW &&
                ReactDOM.createPortal(dragPreviewElement, draggableState.container)}
        </>
    );
};
