import {useCallback, useMemo} from "react";
import {Link} from "react-router-dom";

import TableWithCustomizableColumns, {
    CustomizableTableHeaders,
} from "common/components/table/TableWithCustomizableColumns";
import {VehicleListingStatusChip} from "common/components/vehicles/VehicleListingStatusChip";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";
import {getDayMonthYearDate} from "common/util/FormatUtil";
import {TranslationKeys} from "config/translations";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";
import {TableItem} from "@bolteu/kalep-table-react";
import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";

import {offerTypeToReadable, RentingOutTableEmptyState} from "./RentingOutTable";

interface RentingOutFullTableProps {
    items: VehicleMarketplaceService.SearchCompanyListingsResponse;
    isLoading: boolean;
}

export interface RentingOutTableItem extends TableItem {
    status: VehicleMarketplaceService.ListingStatus;
    vehicle_manufacturer: string;
    vehicle_model_name: string;
    vehicle_colors: string[];
    vehicle_years: number;
    offer_price: number;
    offer_period: VehicleMarketplaceService.OfferPeriod;
    offer_type: VehicleMarketplaceService.OfferType;
    published_date: number;
    number_of_applicants: number;
    offer_price_currency: string;
}

export default function RentingOutFullTable({items, isLoading}: RentingOutFullTableProps) {
    const {i18n} = useI18n();
    const {getVehicleListingEditPath, getVehicleListingViewPath} = useAbsolutePath();

    const columns = useMemo<CustomizableTableHeaders<RentingOutTableItem>>(() => {
        return {
            model: {
                label: i18n("auth.app.fleet.vehicle_marketplace.listings.table.model"),
                renderCell: (item: RentingOutTableItem) => {
                    const linkToListing =
                        item.status === VehicleMarketplaceService.ListingStatus.DRAFT
                            ? getVehicleListingEditPath(Number(item.id))
                            : getVehicleListingViewPath(Number(item.id));

                    return (
                        <DefaultCellContent>
                            <div className="flex flex-col gap-2">
                                <Typography variant="body-m-regular">
                                    <Link className="hover:underline" to={linkToListing}>
                                        {item.vehicle_manufacturer} {item.vehicle_model_name}
                                    </Link>
                                </Typography>
                                <Typography variant="body-xs-regular">
                                    {item.vehicle_colors
                                        .map((color) => i18n(`vehicle.color.${color}` as TranslationKeys))
                                        .join(" / ")}
                                </Typography>
                            </div>
                        </DefaultCellContent>
                    );
                },
            },
            vehicle_years: {
                label: i18n("auth.app.fleet.vehicle_marketplace.listings.table.year"),
                renderCell: (item: RentingOutTableItem) => {
                    return (
                        <DefaultCellContent>
                            <Typography variant="body-m-regular">{item.vehicle_years}</Typography>
                        </DefaultCellContent>
                    );
                },
            },
            offer_price: {
                label: i18n("auth.app.fleet.vehicle_marketplace.listings.table.offer_price"),
                renderCell: (item: RentingOutTableItem) => {
                    if ((item.offer_price ?? 0) === 0 || !item.offer_period) {
                        return <div>-</div>;
                    }
                    return (
                        <DefaultCellContent>
                            {(item.offer_price ?? 0) > 0 && (
                                <div className="flex items-center gap-1">
                                    <Typography variant="body-tabular-m-regular">{item.offer_price}</Typography>
                                    <Typography variant="body-tabular-s-regular" color="secondary">
                                        {item.offer_price_currency}
                                    </Typography>
                                </div>
                            )}
                            <Typography variant="body-tabular-s-regular" color="secondary">
                                {i18n(
                                    `auth.app.fleet.vehicle_marketplace.listings.table.offer_period.${item.offer_period}`,
                                )}
                            </Typography>
                        </DefaultCellContent>
                    );
                },
            },
            offer_type: {
                label: i18n("auth.app.fleet.vehicle_marketplace.listings.table.payment_type"),
                renderCell: (item: RentingOutTableItem) => {
                    return <div>{offerTypeToReadable(item.offer_type, i18n)}</div>;
                },
            },
            published_date: {
                label: i18n("auth.app.fleet.vehicle_marketplace.listings.table.publish_date"),
                renderCell: (item: RentingOutTableItem) => {
                    if (!item.published_date) {
                        return <div>-</div>;
                    }
                    return <div>{getDayMonthYearDate(new Date(item.published_date * 1000))}</div>;
                },
            },
            applicants: {
                label: i18n("auth.app.fleet.vehicle_marketplace.listings.table.applicants"),
                renderCell: (item: RentingOutTableItem) => {
                    return <div>{item.number_of_applicants > 0 ? item.number_of_applicants : "-"}</div>;
                },
            },
            status: {
                label: i18n("auth.app.fleet.vehicle_marketplace.listings.table.status"),
                renderCell: (item: RentingOutTableItem) => {
                    return <VehicleListingStatusChip status={item.status} />;
                },
            },
        };
    }, [i18n, getVehicleListingEditPath, getVehicleListingViewPath]);

    const rentingOutTableEmptyState = useCallback(() => {
        return <RentingOutTableEmptyState />;
    }, []);

    return (
        <div className="mt-8">
            <TableWithCustomizableColumns
                id="renting-out-table"
                columns={columns as CustomizableTableHeaders<TableItem>}
                items={items.rows}
                aria-label="Renting out table"
                isLoading={isLoading}
                renderEmptyPlaceholder={rentingOutTableEmptyState}
            />
        </div>
    );
}
