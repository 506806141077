import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {UseFormValueType} from "./types";

interface CheckboxProps {
    useFormValue: UseFormValueType;
    fieldConfig: FleetOwnerRegistrationService.CheckBoxField;
}

const Checkbox = ({useFormValue, fieldConfig}: CheckboxProps) => {
    const {CheckboxField} = useFormValue;
    const {name, label, disabled: isDisabled} = fieldConfig;

    return (
        <div>
            <CheckboxField name={name as never} label={label} disabled={isDisabled} />
        </div>
    );
};

export {Checkbox};
