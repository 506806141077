import {FC, useCallback, useEffect, useState} from "react";

import FileUpload from "common/components/fileUpload";
import FileInfo from "common/components/fileUpload/FileInfo";
import {getFileContentAsText} from "common/components/fileUpload/util";
import {FileTypes} from "common/constants/mimeTypes";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {TranslationKeys} from "config/translations";

import {CarRentalPaymentService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {MAX_FILE_SIZE_BYTES} from "../constants";

const fetchFunction = (api: Api, body: CarRentalPaymentService.ProcessCarRentalPaymentUploadRequest) =>
    api.carRentalPayment.carRentalPaymentProcessUpload(body);

export interface FileData {
    file: File;
    fileContent: string;
}

export interface UploadFileProps {
    onFileUpload: (payments: CarRentalPaymentService.CarRentalPaymentUploadData[], fileContent: string) => void;
}

const UploadCarRentalPayments: FC<UploadFileProps> = ({onFileUpload}) => {
    const {i18n} = useI18n();
    const {fetch, data, status, error: fetchError} = useFetch(fetchFunction);

    const [errorMessage, setErrorMessage] = useState<string>("");
    const [fileInfo, setFileInfo] = useState<FileData | null>(null);

    const uploadFile = useCallback(
        async (fileData: FileData) => {
            if (!fetch) {
                return;
            }

            setFileInfo(fileData);
            fetch({
                csv: fileData.fileContent,
            });
        },
        [fetch],
    );

    useEffect(() => {
        if (fetchError.message) {
            setErrorMessage(i18n(`api.error.${fetchError.message}` as TranslationKeys, undefined, "api.default_error"));
        }
    }, [fetchError.message, i18n]);

    useEffect(() => {
        if (fileInfo && status === FetchStatus.Success) {
            onFileUpload(data.list, fileInfo.fileContent);
        }
    }, [status, data?.list, fileInfo, onFileUpload]);

    useEffect(() => {
        if (status === FetchStatus.Error) {
            setFileInfo(null);
        }
    }, [status]);

    const handleFileUpload = useCallback(
        async (file: File) => {
            const fileContent = await getFileContentAsText(file);
            if (fileContent === null) {
                setErrorMessage(i18n("auth.app.fleet.car-rentals.upload_file.file_error.reading_failed"));
                return;
            }
            await uploadFile({file, fileContent});
        },
        [i18n, uploadFile],
    );

    return (
        <div className="relative w-[270px] sm:w-[400px] md:w-[450px]">
            <h5 className="mb-2 font-semibold">{i18n("auth.app.fleet.car-rentals.upload_title")}</h5>
            {status !== FetchStatus.Loading && (
                <FileUpload
                    onFileUpload={handleFileUpload}
                    errorMessage={errorMessage}
                    setErrorMessage={setErrorMessage}
                    fileTypes={[FileTypes.CSV]}
                    maxFileSizeB={MAX_FILE_SIZE_BYTES}
                />
            )}
            {fileInfo !== null && <FileInfo file={fileInfo.file} />}
        </div>
    );
};

export default UploadCarRentalPayments;
