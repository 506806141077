import {UIEvent, useCallback, useMemo} from "react";

import {FleetInboxService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {MenuDivider, MenuItem} from "@bolteu/kalep-react";
import {MenuItemRenderProps} from "@bolteu/kalep-react/build/components/Menu";

import {useGroups} from "../hooks/useGroups";
import NotificationListItem, {ExpandButtonProps, TitleRow} from "./NotificationListItem";
import LocalizedPortalNotification = FleetInboxService.LocalizedPortalNotification;

const NotificationQuickViewList = ({
    notifications,
    setNotificationsAsRead,
    newUi,
}: {
    notifications: LocalizedPortalNotification[];
    setNotificationsAsRead: (notifications: LocalizedPortalNotification[]) => void;
    newUi: boolean;
}) => {
    const {groups, setNotificationsAsReadWrapper, getExpandButtonProps} = useGroups(
        notifications,
        setNotificationsAsRead,
        newUi,
    );

    const callToActionClick = useCallback(
        (notification: LocalizedPortalNotification, props: MenuItemRenderProps) => (event: UIEvent) => {
            setNotificationsAsReadWrapper([notification]);
            props.onClick({detail: event.detail});
        },
        [setNotificationsAsReadWrapper],
    );

    const getListItem = useCallback(
        (notification: LocalizedPortalNotification, titleRow?: TitleRow, expandButtonProps?: ExpandButtonProps) =>
            (props: MenuItemRenderProps) => {
                return (
                    <NotificationListItem
                        notification={notification}
                        onItemClick={setNotificationsAsReadWrapper([notification])}
                        onCallToActionClick={callToActionClick(notification, props)}
                        isPartOfMenu
                        titleRow={titleRow}
                        expandButton={expandButtonProps}
                        ref={props.ref}
                    />
                );
            },
        [callToActionClick, setNotificationsAsReadWrapper],
    );

    const notificationItems = useMemo(() => {
        return groups.flatMap((group) => {
            if (group.isExpanded) {
                return group.notifications.map((notification, index) => (
                    <div key={notification.id}>
                        <MenuItem
                            render={getListItem(
                                notification,
                                index === 0
                                    ? {
                                          isUnread: group.hasUnreadNotifications,
                                          groupSize: group.notifications.length,
                                      }
                                    : undefined,
                            )}
                        />
                        <div className="mx-6">
                            <MenuDivider />
                        </div>
                    </div>
                ));
            }

            return (
                <div key={group.notifications[0].id}>
                    <MenuItem
                        render={getListItem(
                            group.notifications[0],
                            {
                                isUnread: group.hasUnreadNotifications,
                                groupSize: group.notifications.length,
                            },
                            getExpandButtonProps(group),
                        )}
                    />
                    <div className="mx-6">
                        <MenuDivider />
                    </div>
                </div>
            );
        });
    }, [getExpandButtonProps, getListItem, groups]);

    return <>{notificationItems}</>;
};

export default NotificationQuickViewList;
