/* eslint eslint-comments/no-use: off -- content is hardcoded in driver-loyalty service */
/* eslint react/no-danger: off -- content is hardcoded in driver-loyalty service */
/* eslint @typescript-eslint/naming-convention: off -- content is hardcoded in driver-loyalty service */
export function formatContentHtml(contentText?: string): JSX.Element | null {
    if (!contentText) {
        return null;
    }

    return <div dangerouslySetInnerHTML={{__html: contentText}} />;
}
