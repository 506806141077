import {FC, useCallback, useState} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {useSnackbar} from "@bolteu/kalep-react";
import {Call, Copy, Mail} from "@bolteu/kalep-react-icons";

import {useFormOfWorkConfig} from "../../../hooks/useFormOfWorkConfig";
import DriverFormOfWorkDetails from "./DriverFormOfWorkDetails";
import DriverFormOfWorkDialog, {FormOfWorkDetails} from "./DriverFormOfWorkDialog";
import DriverProfileField from "./DriverProfileField";

import GetDriverResponse = FleetOwnerPortalService.GetDriverResponse;

interface DriverDetailsProps {
    driverData: GetDriverResponse;
    carRentalPaymentsEnabled?: boolean;
}

const DriverDetails: FC<DriverDetailsProps> = ({driverData, carRentalPaymentsEnabled}) => {
    const {i18n} = useI18n();
    const snackbar = useSnackbar();
    const formOfWorkConfig = useFormOfWorkConfig();

    const copyToClipboard = useCallback(
        (text: string) => () => {
            navigator.clipboard.writeText(text);
            snackbar.add({description: i18n("common.copied_to_clipboard")}, {timeout: 2000});
        },
        [snackbar, i18n],
    );

    const callDriver = useCallback(
        (text: string) => () => {
            window.open(`tel:${text}`);
        },
        [],
    );

    const mailDriver = useCallback(
        (text: string) => () => {
            window.open(`mailto:${text}`);
        },
        [],
    );

    const [formOfWork, setFormOfWork] = useState<FleetOwnerPortalService.FormOfWork | null>(driverData.form_of_work);
    const [contractNumber, setContractNumber] = useState<string | null>(driverData.contract_number);
    const [isFormOfWorkDialogOpen, setIsFormOfWorkDialogOpen] = useState(false);

    const onFormOfWorkDialogClosed = useCallback(() => setIsFormOfWorkDialogOpen(false), []);
    const onFormOfWorkDialogConfirmed = useCallback((details: FormOfWorkDetails) => {
        setFormOfWork(details.formOfWork);
        setContractNumber(details.contractNumber);
        setIsFormOfWorkDialogOpen(false);
    }, []);
    const openFormOfWorkDialog = useCallback(() => {
        setIsFormOfWorkDialogOpen(true);
    }, []);

    return (
        <div className="flex max-w-[480px] flex-col leading-6">
            <DriverProfileField title={i18n("auth.app.fleet.driver.first_name")} value={driverData.first_name} />
            <DriverProfileField title={i18n("auth.app.fleet.driver.last_name")} value={driverData.last_name} />
            <DriverProfileField title={i18n("auth.app.fleet.driver.username")} value={driverData.username} />
            <DriverProfileField
                title={i18n("auth.app.fleet.driver.email")}
                value={driverData.email}
                fieldAction={
                    driverData.email
                        ? {action: mailDriver(driverData.email), icon: <Mail />, label: i18n("common.email")}
                        : undefined
                }
            />
            <DriverProfileField
                title={i18n("auth.app.fleet.driver.phone")}
                value={driverData.phone}
                fieldAction={{action: callDriver(driverData.phone), icon: <Call />, label: i18n("common.call")}}
            />

            {formOfWorkConfig.length !== 0 && (
                <div>
                    <DriverFormOfWorkDetails
                        formOfWork={formOfWork}
                        contractNumber={contractNumber}
                        editDetails={openFormOfWorkDialog}
                    />
                    {isFormOfWorkDialogOpen && (
                        <DriverFormOfWorkDialog
                            onConfirmed={onFormOfWorkDialogConfirmed}
                            onClosed={onFormOfWorkDialogClosed}
                            driverId={driverData.id}
                            formOfWorkConfig={formOfWorkConfig}
                            initialFormOfWork={formOfWork}
                            initialContractNumber={contractNumber}
                        />
                    )}
                </div>
            )}

            {carRentalPaymentsEnabled && (
                <DriverProfileField
                    title={i18n("auth.app.fleet.driver.uuid")}
                    value={driverData.driver_uuid || ""}
                    fieldAction={{
                        action: copyToClipboard(driverData.driver_uuid || ""),
                        icon: <Copy />,
                        label: i18n("copy-to-clipboard-button.copy"),
                    }}
                    tooltip={i18n("auth.app.fleet.driver.uuid_tooltip")}
                />
            )}
            <DriverProfileField
                title={i18n("auth.app.fleet.driver.partner_uuid")}
                value={driverData.partner_uuid ?? ""}
                fieldAction={{
                    action: copyToClipboard(driverData.partner_uuid ?? ""),
                    icon: <Copy />,
                    label: i18n("copy-to-clipboard-button.copy"),
                }}
                tooltip={i18n("auth.app.fleet.driver.partner_uuid_tooltip")}
            />
        </div>
    );
};

export default DriverDetails;
