import {FC} from "react";

import {Dash} from "common/constants";

import {IconButton} from "@bolteu/kalep-react";
import {Edit as EditIcon} from "@bolteu/kalep-react-icons";

type BaseDisplayFieldProps = BaseDisplayFieldPropsNonEditable | BaseDisplayFieldPropsEditable;

interface BaseDisplayFieldPropsNonEditable {
    title: string;
    value: string | null;
    isEditable?: false;
}

interface BaseDisplayFieldPropsEditable {
    title: string;
    value: string | null;
    isEditable: true;
    onEditIconClick: () => void;
}

const BaseDisplayField: FC<BaseDisplayFieldProps> = (props) => {
    const {title, value, isEditable} = props;

    let valueStr = value;
    let valueColor = "text-primary";

    if (!value) {
        valueColor = "text-content-tertiary";
        valueStr = Dash.HYPHEN;
    }

    return (
        <div className="border-separator flex justify-between border-b border-solid py-3 last:border-0">
            <div>
                <p className="text-secondary text-sm">{title}</p>
                <p className={valueColor}>{valueStr}</p>
            </div>
            {isEditable && (
                <IconButton
                    icon={<EditIcon size="sm" />}
                    aria-label="Edit"
                    size="sm"
                    onClick={props.onEditIconClick}
                    value="editFieldIconBtn"
                />
            )}
        </div>
    );
};

export default BaseDisplayField;
