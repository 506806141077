import {useCallback} from "react";

import {Document, DocumentType} from "common/components/document/useDocumentsList";
import {Api} from "common/services/apiProvider";

import {CarApplicationService, FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

interface DocumentWithDocumentTypeId extends Document {
    document_type_id: number;
}

export const fetchDocumentSetFunction = async (api: Api): Promise<DocumentType[]> => {
    const resp = await api.carApplication.v2GetCarApplicationDocumentsSet({});
    return resp.list.map((doc) => ({
        ...doc,
        is_expiry_required: !!doc.is_expiry_required,
        is_required: !!doc.is_required,
    }));
};

const fetchDocumentsFunction = async (
    api: Api,
    query: CarApplicationService.GetCarApplicationDocumentsRequest,
): Promise<DocumentWithDocumentTypeId[]> => {
    const resp = await api.carApplication.v2GetCarApplicationDocuments(query);
    return resp.list;
};

const uploadFunction = (
    api: Api,
    body: CarApplicationService.UploadCarApplicationDocumentRequest,
    files: FleetOwnerPortalService.Files,
) => api.carApplication.v2UploadCarApplicationDocument(body, files);

export const useVehicleApplicationDocumentFunctions = (applicationId: number) => {
    const documentsFetchFunction = useCallback(
        async (api: Api) => {
            const documentSet = await fetchDocumentSetFunction(api);
            const documents = await fetchDocumentsFunction(api, {car_application_id: applicationId});
            return documentSet.map((documentType) => {
                const document = documents.find((doc) => doc.document_type_id === documentType.id);
                return {
                    ...(document
                        ? {
                              document: {
                                  id: document.id,
                                  expires: document.expires,
                                  status: document.status,
                              },
                          }
                        : {document: null}),
                    type: documentType,
                };
            });
        },
        [applicationId],
    );

    const documentUploadFunction = useCallback(
        async (api: Api, expiresIsoDate: string | null, files: FleetOwnerPortalService.Files) => {
            await uploadFunction(
                api,
                {car_application_id: applicationId, ...(expiresIsoDate && {expires: expiresIsoDate})},
                files,
            );
        },
        [applicationId],
    );

    return {
        documentsFetchFunction,
        documentUploadFunction,
    };
};
