import {Children, cloneElement, FC, forwardRef, useRef} from "react";
import type {PopoverProps as AriaPopoverProps} from "react-aria-components";
import {Dialog, OverlayArrow, Popover as AriaPopover, PopoverContext} from "react-aria-components";

import ArrowUpIcon from "assets/icons/arrow-up.svg?react";
import Header, {HeaderSize} from "common/components/header/Header";
import type {Placement} from "@react-aria/overlays";

import {Button, Island} from "@bolteu/kalep-react";
import {Cross} from "@bolteu/kalep-react-icons";

import styles from "./popover.module.css";

interface PopoverTriggerProps {
    isOpen: boolean;
    onClose: () => void;
    children?: JSX.Element[];
}

export const PopoverTrigger = ({isOpen, onClose, children}: PopoverTriggerProps) => {
    const triggerRef = useRef(null);
    if (!children) {
        return null;
    }

    const childrenWithRef = Children.map(children, (child) =>
        cloneElement(child, {
            ref: triggerRef,
            onClose,
        }),
    );

    return (
        <PopoverContext.Provider value={{triggerRef, isOpen, onOpenChange: onClose}}>
            {childrenWithRef}
        </PopoverContext.Provider>
    );
};

interface PopoverProps extends Omit<AriaPopoverProps, "children"> {
    text: string;
    header: string;
    onClose?: () => void;
    actionText?: string;
    onAction?: () => void;
    placement?: Placement;
}
const Popover: FC<PopoverProps> = forwardRef(({header, text, actionText, placement, onClose, onAction, ...rest}, _) => {
    let rotateClassName = "";
    if (placement?.startsWith("left")) {
        rotateClassName = "rotate-90";
    } else if (placement?.startsWith("right")) {
        rotateClassName = "-rotate-90";
    } else if (placement?.startsWith("top")) {
        rotateClassName = "rotate-180";
    }

    return (
        <AriaPopover placement={placement} {...rest} className={styles.container}>
            <OverlayArrow className={`${rotateClassName} ${styles.overlayArrow}`}>
                <ArrowUpIcon className={rotateClassName} />
            </OverlayArrow>
            <Dialog className={styles.innerContainer}>
                <Island elevation="floor-3">
                    <div className="mb-4 flex justify-end">
                        <Header size={HeaderSize.Small} text={header} />
                        <button type="button" onClick={onClose} aria-label="Close" className="self-start">
                            <Cross size="md" className="text-neutral-700" />
                        </button>
                    </div>
                    <p className="mb-2">{text}</p>
                    <Button onClick={onAction} variant="secondary">
                        {actionText}
                    </Button>
                </Island>
            </Dialog>
        </AriaPopover>
    );
});

export default Popover;
