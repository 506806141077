import {useContext} from "react";

import Header, {HeaderSize} from "common/components/header/Header";
import {VehicleListingStatusChip} from "common/components/vehicles/VehicleListingStatusChip";
import {getVehiclePictureByType} from "@fleet/common/utils/vehicle/getVehiclePictureByType";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {ListingContextProvider} from "./ListingInfoProvider";

import BodyType = VehicleMarketplaceService.CarModelBodyType;

const WizardSummary = () => {
    const {listing, listingPreviewImageUrl} = useContext(ListingContextProvider);

    const title = listing ? `${listing.data.vehicle_manufacturer} ${listing.data.vehicle_model_name}` : "";

    return (
        <div className="mb-2">
            <div className="mb-2">
                <img
                    src={
                        listingPreviewImageUrl ??
                        getVehiclePictureByType(listing?.data.model_body_type ?? BodyType.SEDAN)
                    }
                    alt="Vehicle"
                    className="max-h-36 w-full rounded object-cover"
                />
            </div>
            <Header size={HeaderSize.Small} text={title} className="mb-4" />
            {listing && <VehicleListingStatusChip status={listing?.data.status} />}
        </div>
    );
};

export default WizardSummary;
