import Bronze from "assets/icons/bronze.svg?react";
import BronzeInactive from "assets/icons/bronze-inactive.svg?react";
import Gold from "assets/icons/gold.svg?react";
import GoldInactive from "assets/icons/gold-inactive.svg?react";
import Platinum from "assets/icons/platinum.svg?react";
import PlatinumInactive from "assets/icons/platinum-inactive.svg?react";
import Silver from "assets/icons/silver.svg?react";
import SilverInactive from "assets/icons/silver-inactive.svg?react";
import {Tier} from "common/constants/types";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import TierStatus = FleetOwnerPortalService.TierStatus;

const LEVEL_ICONS = new Map<Tier, Record<TierStatus, JSX.Element>>([
    [0, {ACTIVE: <Bronze />, INACTIVE: <BronzeInactive />}],
    [1, {ACTIVE: <Silver />, INACTIVE: <SilverInactive />}],
    [2, {ACTIVE: <Gold />, INACTIVE: <GoldInactive />}],
    [3, {ACTIVE: <Platinum />, INACTIVE: <PlatinumInactive />}],
]);

export function useDriverRewardsIcons(tier: Tier, status: TierStatus) {
    return LEVEL_ICONS.get(tier)?.[status] || <></>;
}
