import {ReactNode} from "react";
import {Link} from "react-router-dom";

import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {BusinessOutlined, MenuOutlined, UserOutlined, VehicleOutlined} from "@bolteu/kalep-react-icons";

import {OnboardingStepProps, Section} from "../OnboardingStep";

type SectionDetail = {
    title: string;
    subTitle: string | ReactNode;
    actionButtonText: string;
    icon: JSX.Element;
};

export const useOnboardingStep = () => {
    const {i18n} = useI18n();
    const {getDriversPath, getVehiclesPath} = useAbsolutePath();

    const getSectionDetails = (
        section: Section,
        state: FleetOwnerRegistrationService.SectionStatus,
    ): SectionDetail | undefined => {
        if (section === "details") {
            return {
                title: i18n("company_registration.steps.company_details.title"),
                subTitle: i18n("company_registration.steps.company_details.sub_title"),
                actionButtonText: i18n("company_registration.steps.company_details.action_button_text"),
                icon: <BusinessOutlined />,
            };
        }
        if (section === "documents") {
            return {
                title: i18n("company_registration.steps.company_documents.title"),
                subTitle: i18n("company_registration.steps.company_documents.sub_title"),
                actionButtonText: i18n("company_registration.steps.company_documents.action_button_text"),
                icon: <MenuOutlined />,
            };
        }
        if (section === "drivers") {
            let actionButtonText = i18n("company_registration.steps.drivers.action_button_text");
            let subTitle: string | ReactNode = i18n("company_registration.steps.drivers.sub_title");
            if (state === FleetOwnerRegistrationService.SectionStatus.COMPLETE) {
                const DriversLink = () => (
                    <Link className="text-action-primary" to={getDriversPath()}>
                        {i18n("company_registration.steps.drivers.completed_sub_title.link")}
                    </Link>
                );

                actionButtonText = i18n("company_registration.steps.drivers.action_completed_button_text");
                subTitle = i18n("company_registration.steps.drivers.completed_sub_title.text", {
                    drivers_list_link: <DriversLink />,
                });
            }

            return {
                title: i18n("company_registration.steps.drivers.title"),
                subTitle,
                actionButtonText,
                icon: <UserOutlined />,
            };
        }
        if (section === "vehicles") {
            let actionButtonText = i18n("company_registration.steps.vehicles.action_button_text");
            let subTitle: string | ReactNode = i18n("company_registration.steps.vehicles.sub_title");
            if (state === FleetOwnerRegistrationService.SectionStatus.COMPLETE) {
                const VehiclesLink = () => (
                    <Link className="text-action-primary" to={getVehiclesPath()}>
                        {i18n("company_registration.steps.vehicles.completed_sub_title.link")}
                    </Link>
                );
                subTitle = i18n("company_registration.steps.vehicles.completed_sub_title.text", {
                    vehicles_list_link: <VehiclesLink />,
                });
                actionButtonText = i18n("company_registration.steps.vehicles.action_completed_button_text");
            }

            return {
                title: i18n("company_registration.steps.vehicles.title"),
                subTitle,
                actionButtonText,
                icon: <VehicleOutlined />,
            };
        }
        return undefined;
    };

    const getActiveStepProps = (
        sectionType: Section,
        section: FleetOwnerRegistrationService.SectionNotDeclined,
        onActionClick: () => void,
    ): OnboardingStepProps | null => {
        const {state} = section;
        const sectionText = getSectionDetails(sectionType, state);
        if (!sectionText) {
            return null;
        }

        const {title, subTitle, actionButtonText, icon} = sectionText;

        let props: Partial<OnboardingStepProps> = {
            section: sectionType,
            title,
            subTitle,
            state,
            icon,
        } as OnboardingStepProps;

        if (state === FleetOwnerRegistrationService.SectionStatus.NOT_SHOWN) {
            return null;
        }

        if (state === FleetOwnerRegistrationService.SectionStatus.DRAFT) {
            props = {
                ...props,
                footer: {
                    text: actionButtonText,
                    type: "BUTTON",
                    onActionClick,
                },
            };
        }

        if (state === FleetOwnerRegistrationService.SectionStatus.IN_REVIEW) {
            props = {
                ...props,
                footer: {
                    text: i18n("company_registration.in_review"),
                    type: "CHIP",
                },
            };
        }

        if (state === FleetOwnerRegistrationService.SectionStatus.ERROR) {
            props = {
                ...props,
                footer: {
                    text: actionButtonText,
                    type: "BUTTON",
                    onActionClick,
                },
                error: {
                    title: section.error_title,
                    description: section.error_description,
                },
            };
        }

        if (state === FleetOwnerRegistrationService.SectionStatus.COMPLETE) {
            const isSectionActionableWhenCompleted = sectionType === "drivers" || sectionType === "vehicles";
            if (isSectionActionableWhenCompleted) {
                props = {
                    ...props,
                    footer: {
                        text: actionButtonText,
                        type: "BUTTON",
                        onActionClick,
                    },
                };
            } else {
                props = {
                    ...props,
                    footer: {
                        text: i18n("company_registration.status_badges.completed"),
                        type: "CHIP",
                    },
                };
            }
        }

        return props as OnboardingStepProps;
    };

    const getDeclinedStepProps = (
        sectionType: Section,
        section: FleetOwnerRegistrationService.SectionDeclined,
    ): OnboardingStepProps | null => {
        const {state} = section;
        const sectionText = getSectionDetails(sectionType, state);
        if (!sectionText) {
            return null;
        }

        const {title, subTitle, icon} = sectionText;

        let props: Partial<OnboardingStepProps> = {
            section: sectionType,
            title,
            subTitle,
            state,
            icon,
        } as OnboardingStepProps;

        if (state === FleetOwnerRegistrationService.SectionStatus.DECLINED) {
            props = {
                ...props,
                footer: {
                    text: i18n("company_registration.status_badges.declined"),
                    type: "CHIP",
                },
            };
        }

        return props as OnboardingStepProps;
    };

    return {
        getActiveStepProps,
        getDeclinedStepProps,
    };
};
