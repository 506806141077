import {useDocumentsAlert} from "common/components/document/useDocumentsAlert";
import {useI18n} from "common/hooks/useI18n";

interface Props {
    expiredDocuments: boolean;
    declinedDocuments: boolean;
}

interface AlertData {
    title: string;
    description: string;
}

export const useVehicleAlert = ({expiredDocuments, declinedDocuments}: Props): AlertData => {
    const {i18n} = useI18n();
    const {titleText, descriptionText} = useDocumentsAlert({expiredDocuments, declinedDocuments});

    if (titleText && descriptionText) {
        return {title: titleText, description: descriptionText};
    }

    return {
        title: i18n("auth.app.fleet.vehicle.car_blocked"),
        description: i18n("common.contact_support"),
    };
};
