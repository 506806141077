import {useMemo} from "react";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Table, Tooltip} from "@bolteu/kalep-react";
import {InfoCircle} from "@bolteu/kalep-react-icons";

import DesktopTableBody from "./DesktopTableBody";
import {AlignHorizontal, AlignVertical, ApiDrivenTableTableProps} from "./types";
import {isDateColumn, isReferenceColumn} from "./util";

const ACTION_HEADER_WIDTH = 50;

export default function ApiDrivenTableDesktop(apiDrivenTableDesktopProps: ApiDrivenTableTableProps) {
    const {
        subColumns,
        stateColumns,
        alignVertical = AlignVertical.MIDDLE,
        alignHorizontal = AlignHorizontal.LEFT,
        userDefinedVisibleColumns,
        transforms,
        inlineActions,
        hiddenColumnTitleKeys,
    } = apiDrivenTableDesktopProps;

    const headerColumns = useMemo((): FleetOwnerPortalService.ApiColumn[] => {
        const filteredColumns = userDefinedVisibleColumns.map((column) => ({
            ...column,
            title: hiddenColumnTitleKeys.includes(column.key) ? "" : column.title,
        }));

        return inlineActions?.length
            ? [
                  ...filteredColumns,
                  {
                      key: "actions",
                      title: "",
                      type: FleetOwnerPortalService.ListColumnType.STRING,
                      cells: [],
                      accessibility: FleetOwnerPortalService.Accessibility.VISIBLE,
                  },
              ]
            : filteredColumns;
    }, [hiddenColumnTitleKeys, inlineActions, userDefinedVisibleColumns]);

    return (
        <Table>
            <Table.Header>
                <Table.HeaderRow>
                    {headerColumns.map((column) => (
                        <Table.HeaderCell
                            align={alignHorizontal}
                            key={column.key}
                            width={
                                column.key === "actions" && inlineActions
                                    ? inlineActions.length * ACTION_HEADER_WIDTH
                                    : undefined
                            }
                        >
                            <div
                                className={`align-center flex h-full w-full items-center ${
                                    !isReferenceColumn(column) &&
                                    !isDateColumn(column) &&
                                    alignHorizontal === AlignHorizontal.RIGHT
                                        ? "justify-end"
                                        : "justify-start"
                                } gap-x-1`}
                            >
                                {column.title}
                                {column.tooltip && (
                                    <Tooltip placement="left" content={column.tooltip}>
                                        <div>
                                            <InfoCircle width={14} height={14} />
                                        </div>
                                    </Tooltip>
                                )}
                            </div>
                        </Table.HeaderCell>
                    ))}
                </Table.HeaderRow>
            </Table.Header>
            <Table.Body>
                {apiDrivenTableDesktopProps.numberOfRecords !== 0 ? (
                    <DesktopTableBody
                        numberOfRecords={apiDrivenTableDesktopProps.numberOfRecords}
                        userDefinedVisibleColumns={userDefinedVisibleColumns}
                        stateColumns={stateColumns}
                        alignVertical={alignVertical}
                        alignHorizontal={alignHorizontal}
                        subColumns={subColumns}
                        transforms={transforms}
                        inlineActions={inlineActions}
                    />
                ) : (
                    <Table.Row>
                        <Table.Cell
                            colSpan={
                                apiDrivenTableDesktopProps.userDefinedVisibleColumns.length +
                                (inlineActions?.length ? 1 : 0)
                            }
                            overrideClassName="border-0"
                        >
                            {apiDrivenTableDesktopProps.emptyStateComponent}
                        </Table.Cell>
                    </Table.Row>
                )}
            </Table.Body>
        </Table>
    );
}
