import {useCallback, useContext, useEffect} from "react";

import LoadingSpinner from "common/components/spinner";
import {EventName, EventPropertiesDownloadType} from "common/constants/events";
import {MimeTypes} from "common/constants/mimeTypes";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {downloadBase64File} from "common/util/downloadUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {GhostButton} from "@bolteu/kalep-react";
import {Download} from "@bolteu/kalep-react-icons";

import {BoltToFleetInvoiceData} from "../hooks/useBoltInvoices";

interface Props {
    item: BoltToFleetInvoiceData;
}

const fetchBoltInvoicePdf = (api: Api, body: FleetOwnerReportingService.GetTaxifyInvoicePdfRequest) =>
    api.fleetOwnerReporting.getTaxifyInvoicePdf(body);

const DownloadPdf = ({item}: Props) => {
    const {i18n} = useI18n();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const {data, status, fetch} = useFetch(fetchBoltInvoicePdf);
    const {trackEvent} = useTracking();

    useEffect(() => {
        if (status === FetchStatus.Success && data.file) {
            const fileName = i18n("auth.app.invoices.taxify-invoices.pdf-filename").replace(
                "{{invoiceNo}}",
                item.public_id,
            );
            downloadBase64File(data.file, MimeTypes.PDF, fileName);
            trackEvent(EventName.FileDownloaded, {downloadType: EventPropertiesDownloadType.BoltToFleetPdf});
        }
    }, [data, i18n, item, status, trackEvent]);

    const downloadPdf = useCallback(() => {
        if (!fetch || !fleet) {
            return;
        }
        fetch({invoice_id: item.id});
    }, [fetch, fleet, item]);

    const isLoading = status === FetchStatus.Loading;

    return (
        <GhostButton onClick={downloadPdf} disabled={isLoading}>
            {!isLoading && <Download />}
            {isLoading && <LoadingSpinner show />}
        </GhostButton>
    );
};

export default DownloadPdf;
