import {ReactNode} from "react";
import {useIntl} from "react-intl";

import {format} from "date-fns";
import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";

import {FleetOwnerPortalService, FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {useGeneratedFileAction} from "./useGeneratedFileAction";
import {useGeneratedFileTitle} from "./useGeneratedFileTitle";

interface FileRowData {
    title: string;
    description: string;
    icon: ReactNode;
}

export const useGeneratedFileRow = (
    row: FleetOwnerReportingService.CompanyReportWithUrl,
    drivers: FleetOwnerPortalService.Driver[],
): FileRowData => {
    const intl = useIntl();
    const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];

    const icon = useGeneratedFileAction(row.status, row.url ?? "");
    const title = useGeneratedFileTitle(row, drivers);
    const description = format(new Date(row.created * 1000), "P, kk:mm", {locale: dateFnsLocale});

    return {
        title,
        description,
        icon,
    };
};
