import {useCallback, useContext, useEffect, useState} from "react";
import ReCaptcha from "react-google-recaptcha";
import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";
import {VerificationContextProvider} from "features/account/pages/Verification/VerificationProvider";

import {Spinner} from "@bolteu/kalep-react";

interface Props {
    siteKey: string;
    onCaptchaSubmit: (captchaToken: string | null) => void;
}

let captchaV2Timeout: number | undefined;

const CaptchaV2 = ({siteKey, onCaptchaSubmit}: Props) => {
    const {i18n} = useI18n();
    const language = useIntl().locale;
    const {setRequestCodeError} = useContext(VerificationContextProvider);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        return () => clearTimeout(captchaV2Timeout);
    }, []);

    const handleReCaptchaSolved = useCallback(
        (captchaToken: string | null) => {
            // Timeout for the regular captcha checkmark to be rendered fully
            captchaV2Timeout = window.setTimeout(() => {
                onCaptchaSubmit(captchaToken);
            }, 1000);
        },
        [onCaptchaSubmit],
    );

    const handleError = useCallback(() => {
        setRequestCodeError(i18n("auth.app.error-view.something-went-wrong.title"));
    }, [i18n, setRequestCodeError]);

    const handleLoaded = useCallback(() => {
        setIsLoading(false);
    }, [setIsLoading]);

    return (
        <div className="flex flex-col items-center gap-2">
            <h1 className="text-primary text-2xl font-semibold">{i18n("verification.captcha_security_check.title")}</h1>
            <div className="text-secondary mb-6 whitespace-pre-line text-center">
                <p>{i18n("verification.captcha_security_check.description")}</p>
            </div>
            <div className="flex h-20">
                {isLoading && <Spinner color="action-primary" />}
                <ReCaptcha
                    key={language}
                    sitekey={siteKey}
                    onChange={handleReCaptchaSolved}
                    onErrored={handleError}
                    asyncScriptOnLoad={handleLoaded}
                    type="image"
                    hl={language}
                />
            </div>
        </div>
    );
};

export default CaptchaV2;
