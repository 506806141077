import {FleetInboxService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import MessageType = FleetInboxService.MessageType;
import LocalizedPortalNotification = FleetInboxService.LocalizedPortalNotification;

export interface NotificationGroup {
    notifications: LocalizedPortalNotification[];
    isExpanded: boolean;
    hasUnreadNotifications: boolean;
}

/**
 * Groups notifications by type. Expects notifications to be sorted by date in descending order.
 * If currentGroups is provided, it will try to group the new notifications with the existing groups,
 * expecting new notifications list to be [...newNotifications, ...oldList]
 */
export function groupNotifications(
    notifications: LocalizedPortalNotification[],
    currentGroups?: NotificationGroup[],
): NotificationGroup[] {
    if (notifications.length === 0) {
        return [];
    }
    const {groups, initialGroup, oldestUngroupedNotificationIndex} = initialiseGrouping(notifications, currentGroups);

    let latestGroup = initialGroup;
    for (let i = oldestUngroupedNotificationIndex; i > -1; i--) {
        const notification = notifications[i];
        if (
            latestGroup.notifications.length === 0 ||
            (notification.type !== MessageType.CUSTOM && latestGroup.notifications[0].type === notification.type)
        ) {
            latestGroup.notifications.unshift(notification);
        } else {
            latestGroup = {notifications: [notification], isExpanded: false, hasUnreadNotifications: false};
            groups.unshift(latestGroup);
        }
        if (notification.read_timestamp === null) {
            latestGroup.hasUnreadNotifications = true;
        }
    }

    return groups;
}

function initialiseGrouping(notifications: LocalizedPortalNotification[], currentGroups?: NotificationGroup[]) {
    if (!currentGroups?.[0]?.notifications[0]) {
        const initialGroup = {notifications: [], isExpanded: false, hasUnreadNotifications: false};
        return {groups: [initialGroup], initialGroup, oldestUngroupedNotificationIndex: notifications.length - 1};
    }

    const groups = updatePreviouslyGroupedNotifications(notifications, currentGroups);
    const newestExistingNotificationIndex = groups[0].notifications[0].id;
    const oldestUngroupedNotificationIndex =
        notifications.findIndex((notification) => notification.id === newestExistingNotificationIndex) - 1;
    return {groups, initialGroup: groups[0], oldestUngroupedNotificationIndex};
}

function updatePreviouslyGroupedNotifications(
    notifications: LocalizedPortalNotification[],
    currentGroups: NotificationGroup[],
) {
    const notificationMap = new Map(notifications.map((notification) => [notification.id, notification]));
    return currentGroups.map((group) => {
        const updatedNotifications: LocalizedPortalNotification[] = [];
        let hasUnreadNotifications = false;
        group.notifications.forEach((notification) => {
            const updatedNotification = notificationMap.get(notification.id) ?? notification;
            updatedNotifications.push(updatedNotification);
            if (updatedNotification.read_timestamp === null) {
                hasUnreadNotifications = true;
            }
        });
        return {...group, notifications: updatedNotifications, hasUnreadNotifications};
    });
}

export function dummyGroupNotifications(notifications: LocalizedPortalNotification[]): NotificationGroup[] {
    return notifications.map((notification) => ({
        notifications: [notification],
        isExpanded: false,
        hasUnreadNotifications: notification.read_timestamp === null,
    }));
}

export function expandGroup(groups: NotificationGroup[], groupNotification: LocalizedPortalNotification) {
    return groups.map((group) => {
        if (group.notifications[0].id === groupNotification.id) {
            return {
                ...group,
                isExpanded: true,
            };
        }
        return group;
    });
}
