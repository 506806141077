import {IntlShape, useIntl} from "react-intl";

import CheckmarkList, {CheckmarkListRow} from "common/components/checkmarkList/CheckmarkList";
import {useI18n} from "common/hooks/useI18n";
import {formatTransactionNumber} from "features/companies/balance/utils/formatTransactionNumber";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import GetListingData = VehicleMarketplaceService.GetListingData;
import ListingFieldsTranslations = VehicleMarketplaceService.ListingFieldsTranslations;
import Maintenance = VehicleMarketplaceService.Maintenance;
import Insurance = VehicleMarketplaceService.Insurance;

function formatCurrencyValue(intl: IntlShape, value: number, currency: string) {
    return `${formatTransactionNumber(intl, value)}${currency}`;
}

function getIncludedInPricingList(
    intl: IntlShape,
    listingData: GetListingData,
    listingDataTranslations: ListingFieldsTranslations,
) {
    const includedList: CheckmarkListRow[] = [];

    if (listingData.pricing_fields?.insurance && listingData.pricing_fields.insurance === Insurance.YES) {
        const deductibleSum = listingData.pricing_fields.deductible_sum
            ? formatCurrencyValue(intl, listingData.pricing_fields.deductible_sum, listingData.currency_symbol)
            : null;

        includedList.push({
            text: listingDataTranslations.pricing_fields?.values.insurance?.key || "",
            subtext: deductibleSum
                ? `${listingDataTranslations.pricing_fields?.values.deductible_sum?.key}: ${deductibleSum}`
                : undefined,
        });
    }

    if (listingData.pricing_fields?.maintenance && listingData.pricing_fields.maintenance === Maintenance.YES) {
        includedList.push({
            text: listingDataTranslations.pricing_fields?.values.maintenance?.key || "",
        });
    }

    return includedList;
}

interface Props {
    listingData: GetListingData;
    listingDataTranslations: ListingFieldsTranslations;
}

const Pricing = ({listingData, listingDataTranslations}: Props) => {
    const intl = useIntl();
    const {i18n} = useI18n();

    if (!listingData.offer_type || !listingDataTranslations.pricing_fields || !listingData.pricing_fields) {
        return null;
    }

    const offerTypeText = i18n(
        `auth.app.fleet.vehicle_marketplace.listings.view.listing_data.headers.pricing.${listingData.offer_type}`,
    );
    const offerPeriodText = listingDataTranslations.pricing_fields.values.offer_period?.value;
    const depositSumText = listingDataTranslations.pricing_fields.values.deposit_sum?.key;

    const includedInPricingTitle = i18n(
        "auth.app.fleet.vehicle_marketplace.listings.view.listing_data.pricing.included_list_header",
    );
    const includedInPricingList = getIncludedInPricingList(intl, listingData, listingDataTranslations);

    return (
        <div className="flex flex-col gap-4">
            <p className="font-semibold">{offerTypeText}</p>
            {listingData.offer_price && (
                <div>
                    <p className="bolt-font-heading-m-accent">
                        {formatCurrencyValue(intl, listingData.offer_price, listingData.currency_symbol)}
                    </p>
                    <p className="text-secondary text-sm">{offerPeriodText}</p>
                </div>
            )}
            {listingData.pricing_fields.deposit_sum && (
                <div>
                    <p>
                        {formatCurrencyValue(intl, listingData.pricing_fields.deposit_sum, listingData.currency_symbol)}
                    </p>
                    <p className="text-secondary text-sm">{depositSumText}</p>
                </div>
            )}
            {Boolean(includedInPricingList.length) && (
                <div>
                    <p className="bolt-font-body-m-accent pb-2">{`${includedInPricingTitle}:`}</p>
                    <CheckmarkList list={includedInPricingList} />
                </div>
            )}
        </div>
    );
};

export default Pricing;
