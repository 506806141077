import {useCallback, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {CommonRoute} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";
import {FleetOwnerAuthNoAuthApiClient} from "common/services/apiClients/noAuthApiClient";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {isError, isValidationError} from "common/util/isErrorType";
import NoAuthLayout from "features/account/components/NoAuthLayout";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button} from "@bolteu/kalep-react";

import {DynamicForm} from "./DynamicForm";

enum TokenExpiredError {
    FLEET_OWNER_PASSWORD_RESET_TOKEN_USED = "FLEET_OWNER_PASSWORD_RESET_TOKEN_USED",
    FLEET_OWNER_PASSWORD_RESET_TOKEN_EXPIRED = "FLEET_OWNER_PASSWORD_RESET_TOKEN_EXPIRED",
}

const ResetPassword = () => {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const params = useParams();

    const [isPasswordResetFinished, setIsPasswordResetFinished] = useState(false);
    const [isTokenError, setIsTokenError] = useState(false);
    const [tokenErrorMessage, setTokenErrorMessage] = useState("");
    const [passwordField, setPasswordField] = useState<FleetOwnerRegistrationService.PasswordField>();

    useEffect(() => {
        FleetOwnerAuthNoAuthApiClient.getResetPasswordForm({
            token: params.token ?? "",
        }).then(
            (value) => {
                setPasswordField(value.password_field);
            },
            (error) => {
                setIsTokenError(true);
                if (isError(error) && Object.values<string>(TokenExpiredError).includes(error.message)) {
                    setTokenErrorMessage(i18n(`api.error.${error.message as TokenExpiredError}`));
                } else if (isValidationError(error)) {
                    setTokenErrorMessage(i18n("api.error.FLEET_OWNER_PASSWORD_RESET_INVALID_TOKEN"));
                } else {
                    setTokenErrorMessage(i18n("api.default_error"));
                }
            },
        );
    }, [i18n, params]);

    useEffect(() => {
        const pageTitle = i18n("reset-password.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    const goToLogin = useCallback(() => {
        navigate(CommonRoute.AUTH);
    }, [navigate]);

    const title = useCallback(() => {
        if (isPasswordResetFinished) {
            return i18n("reset-password.password_reset");
        }
        if (isTokenError) {
            return tokenErrorMessage;
        }
        return i18n("reset-password.title");
    }, [i18n, isPasswordResetFinished, isTokenError, tokenErrorMessage]);

    const isLoginButtonVisible = isTokenError || isPasswordResetFinished;
    const showForm = !isTokenError && !isPasswordResetFinished && passwordField;

    return (
        <NoAuthLayout gap="small">
            <h1 className="text-2xl font-semibold">{title()}</h1>
            {showForm && (
                <DynamicForm setIsPasswordResetFinished={setIsPasswordResetFinished} passwordField={passwordField} />
            )}
            {isLoginButtonVisible && (
                <Button variant="primary" onClick={goToLogin} overrideClassName="max-w-[356px] mt-2" fullWidth>
                    {i18n("login.log_in")}
                </Button>
            )}
        </NoAuthLayout>
    );
};

export default ResetPassword;
