import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import EntityPortalStatus = FleetOwnerPortalService.EntityPortalStatus;

export function shouldHideToggleDeactivationButton(
    entityPortalStatus: EntityPortalStatus,
    isDeactivatedByFleetOwner: boolean,
): boolean {
    return entityPortalStatus === EntityPortalStatus.Deactivated && !isDeactivatedByFleetOwner;
}
