import {useI18n} from "common/hooks/useI18n";

import {Button} from "@bolteu/kalep-react";
import {Vehicle} from "@bolteu/kalep-react-icons";

interface Props {
    openAddVehiclePage: () => void;
}

const NoVehicles = ({openAddVehiclePage}: Props) => {
    const {i18n} = useI18n();

    const title = i18n("auth.app.fleet.vehicles.no-vehicles-yet");
    const subTitle = i18n("auth.app.fleet.vehicles.vehicle-add");

    return (
        <div className="mt-24 flex flex-col items-center justify-center gap-2 md:mt-40">
            <Vehicle className="mb-2 h-[64px] w-[64px]" />
            <h5 className="max-w-[420px] text-center text-xl font-semibold text-neutral-900">{title}</h5>
            <p className="max-w-[420px] text-center text-neutral-700">{subTitle}</p>
            <div className="mt-6">
                <Button onClick={openAddVehiclePage}>{i18n("auth.app.fleet.dashboard.add-vehicle")}</Button>
            </div>
        </div>
    );
};

export default NoVehicles;
