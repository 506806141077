import {FC} from "react";

interface HighlightedTextProps {
    text: string;
    highlightedText?: string;
}

const HighlightedText: FC<HighlightedTextProps> = ({text, highlightedText}) => {
    function highlightText() {
        if (!highlightedText) {
            return <span>{text}</span>;
        }
        const parts = text.split(new RegExp(`(${highlightedText.replace(/[-/\\^$*+?.()|[\]{}]/g, "\\$&")})`, "gi"));
        return parts.map((part, i) =>
            part.toLowerCase() === highlightedText.toLowerCase() ? (
                // eslint-disable-next-line react/no-array-index-key -- no other unique key possible
                <span key={i} className="font-semibold">
                    {part}
                </span>
            ) : (
                part
            ),
        );
    }
    return <span>{highlightText()}</span>;
};

export default HighlightedText;
