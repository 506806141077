import {useCallback, useContext} from "react";
import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";
import {formatNumber} from "common/util/FormatUtil";
import {ScheduledRidesContextProvider} from "features/companies/orders/ScheduledRidesContextProvider";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Radio, RadioGroup, Typography} from "@bolteu/kalep-react";

import {CategorySkeleton} from "./CategorySkeleton";
import {RideInfo} from "./RideInfo";

interface Props {
    selectedCategoryId?: number;
    onChange: (category: {id?: number; price?: string}) => void;
    categories: FleetPortalOrderService.Category[];
    isLoading: boolean;
    categoryError?: string;
    isManualPriceEnabledAndEntered: boolean;
}

export const Categories = ({
    selectedCategoryId,
    onChange,
    categories,
    isLoading,
    categoryError,
    isManualPriceEnabledAndEntered,
}: Props) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const scheduledRidesConfig = useContext(ScheduledRidesContextProvider);

    const onCategoryChange = useCallback(
        (event) => {
            const categoryId = Number(event.target.value);
            const newCategory = categories.find((category) => category.id === categoryId);
            onChange({
                id: newCategory ? categoryId : undefined,
                price: newCategory ? String(newCategory.price_estimate) : undefined,
            });
        },
        [categories, onChange],
    );

    const defaultCategoryForInfo = categories.length ? categories[0] : undefined;
    const categoryForInfo = categories.find((category) => category.id === selectedCategoryId) ?? defaultCategoryForInfo;

    return (
        <>
            <Typography fontWeight="semibold" fontSize="text-sm">
                {i18n("auth.app.orders.scheduled_rides.create-dialog.ride-category")}
            </Typography>
            {categoryForInfo && <RideInfo category={categoryForInfo} />}
            <RadioGroup
                name="category"
                value={String(selectedCategoryId)}
                onChange={onCategoryChange}
                error={Boolean(categoryError)}
            >
                <div className="flex w-full flex-col gap-6">
                    {isLoading && (
                        <>
                            <CategorySkeleton />
                            <CategorySkeleton />
                        </>
                    )}
                    {!isLoading &&
                        categories.map((category) => (
                            <div key={category.id} className="flex w-full justify-between">
                                <Radio label={category.name} value={String(category.id)} />
                                <Typography
                                    fontWeight="semibold"
                                    color={isManualPriceEnabledAndEntered ? "tertiary" : "primary"}
                                    inlineStyle={
                                        isManualPriceEnabledAndEntered ? {textDecorationLine: "line-through"} : {}
                                    }
                                >{`${formatNumber(intl, category.price_estimate, {
                                    maxFractionDigits: 2,
                                    minFractionDigits: 2,
                                })} ${scheduledRidesConfig.getCurrency()}`}</Typography>
                            </div>
                        ))}
                </div>
            </RadioGroup>
        </>
    );
};
