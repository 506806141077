import {ReactNode, useCallback} from "react";

import LoadingSpinner from "common/components/spinner";
import {EventName, EventPropertiesDownloadType} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Tooltip} from "@bolteu/kalep-react";
import {Alert, Download} from "@bolteu/kalep-react-icons";

import CompanyReportStatus = FleetOwnerReportingService.CompanyReportStatus;

export const useGeneratedFileAction = (status: CompanyReportStatus, fileUrl: string): ReactNode => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();

    const loadingSpinner = <LoadingSpinner show parentClassName="overflow-hidden" />;

    const handleDownloadClicked = useCallback(() => {
        trackEvent(EventName.FileDownloaded, {downloadType: EventPropertiesDownloadType.FleetToRiderGeneratedFile});
    }, [trackEvent]);

    switch (status) {
        case CompanyReportStatus.IN_PROGRESS:
        case CompanyReportStatus.PENDING:
            return loadingSpinner;
        case CompanyReportStatus.READY:
            return (
                <Tooltip content={i18n("common.download")} placement="top">
                    <a href={fileUrl} rel="noreferrer" onClick={handleDownloadClicked}>
                        <Download />
                    </a>
                </Tooltip>
            );
        case CompanyReportStatus.ERROR:
            return <Alert className="text-red-500" />;
        default:
            UnreachableCode.never(status);
    }

    return loadingSpinner;
};
