import {useI18n} from "common/hooks/useI18n";

import {Receipt} from "@bolteu/kalep-react-icons";

const NoOrders = () => {
    const {i18n} = useI18n();

    const title = i18n("auth.app.orders.incoming.no-orders");

    return (
        <div className="mt-24 flex flex-col items-center justify-center gap-2 md:mt-40">
            <Receipt className="mb-2 h-[64px] w-[64px]" />
            <h5 className="max-w-[420px] text-center text-xl font-semibold text-neutral-900">{title}</h5>
        </div>
    );
};

export default NoOrders;
