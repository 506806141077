import {FC} from "react";
import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";
import {ApiPeriod} from "common/services/period";

import {formatPeriodWithoutYear} from "../../utils/formatPeriod";

interface TransactionsCardHeaderProps {
    isIncomeZero: boolean;
    isOutcomeZero: boolean;
    period: ApiPeriod;
}

const TransactionsCardHeader: FC<TransactionsCardHeaderProps> = ({isIncomeZero, isOutcomeZero, period}) => {
    const {i18n} = useI18n();
    const intl = useIntl();

    const headerIncomeText = !isIncomeZero && i18n("auth.app.fleet.balance.transactions.card.header.income");
    const headerOutcomeText = !isOutcomeZero && i18n("auth.app.fleet.balance.transactions.card.header.outcome");
    const headerIncomeOutcomeText = [headerIncomeText, headerOutcomeText].filter(Boolean).join("/");
    const headerText = i18n("auth.app.fleet.balance.transactions.card.header.income_outcome_for_date", {
        incomeOutcome: headerIncomeOutcomeText,
        date: formatPeriodWithoutYear(intl, period),
    });
    const descriptionText = i18n("auth.app.fleet.balance.transactions.card.description");

    return (
        <div className="flex flex-col gap-2">
            <span className="text-lg font-semibold">{headerText}</span>
            <span className="text-secondary text-sm">{descriptionText}</span>
        </div>
    );
};

export default TransactionsCardHeader;
