import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {useI18n} from "common/hooks/useI18n";
import {TranslationKeys} from "config/translations";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";
import {getVehicleColorClasses} from "@fleet/common/utils/vehicle/vehicleColorUtil";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip} from "@bolteu/kalep-react";
import {ChipColor} from "@bolteu/kalep-react/build/components/Chip/Chip.types";

interface Props {
    vehicle: FleetOwnerPortalService.GetCarResponse;
}

function getChipAppearance(status: FleetOwnerPortalService.EntityPortalStatus): ChipColor | null {
    switch (status) {
        case FleetOwnerPortalService.EntityPortalStatus.Blocked:
            return "danger";
        case FleetOwnerPortalService.EntityPortalStatus.Deactivated:
            return "neutral";
        case FleetOwnerPortalService.EntityPortalStatus.Active:
            return null;
        default:
            return UnreachableCode.never(status, null);
    }
}

const PageHeader = ({vehicle}: Props) => {
    const {i18n} = useI18n();

    const vehicleColorClasses = getVehicleColorClasses(vehicle.color);
    const isVehicleDeactivated = vehicle.portal_status === FleetOwnerPortalService.EntityPortalStatus.Deactivated;
    const deactivatedOpacity = isVehicleDeactivated ? 0.4 : 1;
    const chipAppearance = getChipAppearance(vehicle.portal_status);

    return (
        <div>
            <ResponsiveHeader
                disabled={isVehicleDeactivated}
                type={ResponsiveHeaderType.InnerPage}
                text={`${vehicle.model} (${vehicle.year})`}
            />
            <div className="mt-6 mb-8 flex flex-wrap items-center justify-start gap-4">
                <div className="flex flex-wrap gap-4" style={{opacity: deactivatedOpacity}}>
                    <span className="rounded-sm bg-neutral-200 py-[2px] px-2 font-semibold">{vehicle.reg_number}</span>
                    {vehicle.color !== null && vehicle.color.length !== 0 && (
                        <div className="flex items-center gap-2 text-sm">
                            <div className={`h-4 w-4 rounded-full ${vehicleColorClasses}`} />
                            <span>{i18n(`vehicle.color.${vehicle.color}` as TranslationKeys)}</span>
                        </div>
                    )}
                </div>
                {chipAppearance && (
                    <Chip
                        label={i18n(`vehicle.status.${vehicle.portal_status}`)}
                        appearance={chipAppearance}
                        size="sm"
                    />
                )}
            </div>
        </div>
    );
};

export default PageHeader;
