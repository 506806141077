import {FC} from "react";

import CodeIsland from "common/components/CodeIsland";
import {ResponsiveDrawer} from "common/components/drawer/ResponsiveDrawer";
import {useI18n} from "common/hooks/useI18n";

import {Button, Drawer, Link, Typography} from "@bolteu/kalep-react";

interface DocumentationProps {
    isOpen: boolean;
    handleClose: () => void;
}

const Documentation: FC<DocumentationProps> = ({isOpen, handleClose}: DocumentationProps) => {
    const {i18n} = useI18n();
    const tokenRequest =
        "curl --location --request POST 'https://oidc.bolt.eu/token' \\\n" +
        "     --header 'Content-Type: application/x-www-form-urlencoded' \\\n" +
        "     --data-urlencode 'client_id=YOUR_CLIENT_ID' \\\n" +
        "     --data-urlencode 'client_secret=YOUR_SECRET' \\\n" +
        "     --data-urlencode 'grant_type=client_credentials' \\\n" +
        "     --data-urlencode 'scope=fleet-integration:api'\n";

    const tokenResponse =
        "{\n" +
        '  "access_token": "YOUR_TOKEN",\n' +
        '  "expires_in": 600,\n' +
        '  "token_type": "Bearer",\n' +
        '  "scope": "fleet-integration:api"\n' +
        "}\n";

    const apiDocsUrl = "https://apidocs.bolt.eu/fleetIntegration/fleetIntegrationGatewayAuth/";

    return (
        <ResponsiveDrawer isOpen={isOpen} title="API documentation" size="lg" onRequestClose={handleClose}>
            <Drawer.Content>
                <div className="flex flex-col gap-6">
                    <Typography variant="body-primary">
                        Here on the API credentials page it is possible to generate the credentials that are needed for
                        making token requests described in the next section. When you click “Generate credentials” you
                        will receive Client ID and Secret values.
                    </Typography>

                    <div className="flex flex-col gap-2">
                        <Typography variant="title-secondary">Authentication</Typography>
                        <div className="flex flex-col gap-4">
                            <Typography variant="body-primary">
                                We use the OAuth2 Client Credentials flow to authorize your requests. Simply put, each
                                request needs to have an Authorization header with a Bearer token. You can get the token
                                from the endpoint below:
                            </Typography>
                            <CodeIsland code="https://oidc.bolt.eu/token" />
                            <Typography variant="body-primary">
                                You should send the credentials you generated through the Fleet Owner portal with a
                                request like request like this:
                            </Typography>
                            <CodeIsland code={tokenRequest} />
                            <Typography variant="body-primary">
                                And it will respond with this response if your client ID and secret are valid:
                            </Typography>
                            <CodeIsland code={tokenResponse} />
                            <Typography variant="body-primary">
                                As the tokens will expire in 10 minutes as of now, do not forget to implement a
                                mechanism to refresh the token before it expires to leave no room for failing requests
                                because of expired tokens.
                            </Typography>
                        </div>
                    </div>

                    <div className="flex flex-col gap-2">
                        <Typography variant="title-secondary">API Endpoints</Typography>
                        <Link target="_blank" href={apiDocsUrl}>
                            Swagger / OpenAPI Specs
                        </Link>
                    </div>
                </div>
            </Drawer.Content>
            <Drawer.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {i18n("common.close")}
                </Button>
            </Drawer.Footer>
        </ResponsiveDrawer>
    );
};

export default Documentation;
