import {useContext, useEffect, useMemo} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {isProvinceSelectionEnabled} from "features/companies/vehicles/config/isProvinceSelectionEnabled";

import {CarApplicationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {SelectOption} from "@bolteu/kalep-react";

const getRegistrationProvinces = (api: Api, query: CarApplicationService.GetProvincesRequest) =>
    api.carApplication.v2GetRegistrationProvinces(query);

export function useProvinces() {
    const accountState = useContext(AccountContextProvider);
    const fleet = accountState?.getFleet();

    const {data, fetch, status} = useFetch(getRegistrationProvinces);

    useEffect(() => {
        if (fetch && fleet && isProvinceSelectionEnabled(fleet.country)) {
            fetch({country: fleet.country});
        }
    }, [fetch, fleet]);

    const provinces = useMemo(() => {
        if (FetchStatus.Success === status) {
            return data?.list.map((p) => {
                return {
                    value: p.id,
                    title: `${p.local_name} (${p.name})`,
                } as SelectOption;
            });
        }
        return [];
    }, [data, status]);

    return {provinces, fetchStatus: status};
}
