import {FC, useCallback, useEffect} from "react";

import {ButtonActionEvent, EventName} from "common/constants/events";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";

import {FleetIntegrationControlService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button} from "@bolteu/kalep-react";

type FleetIntegrationCredentials = FleetIntegrationControlService.FleetIntegrationCredentials;

const fetchFunction = (api: Api) => api.fleetIntegrationControl.fleetIntegrationCreateOrRenewCredentials();

interface NoCredentialsViewProps {
    setCredentials: (credentials: FleetIntegrationCredentials) => void;
}

const NoCredentialsView: FC<NoCredentialsViewProps> = ({setCredentials}) => {
    const {i18n} = useI18n();
    const {data, status, fetch} = useFetch(fetchFunction);
    const {trackEvent} = useTracking();

    const onGenerateCredentials = useCallback(() => {
        if (fetch) {
            fetch({});
        }
    }, [fetch]);

    useEffect(() => {
        if (status === FetchStatus.Success && data) {
            setCredentials(data);
            trackEvent(EventName.ButtonClicked, {action: ButtonActionEvent.GenerateCredentials});
        }
    }, [data, status, setCredentials, trackEvent]);

    const generateCredentialsButtonText = i18n(
        "auth.app.fleet.api-credentials.no_credentials.generate_credentials_button_text",
    );

    const isLoading = status === FetchStatus.Loading;

    return (
        <div className="flex flex-col gap-6">
            <Button variant="secondary" loading={isLoading} onClick={onGenerateCredentials}>
                {generateCredentialsButtonText}
            </Button>
        </div>
    );
};

export default NoCredentialsView;
