import {FC} from "react";

import {useI18n} from "common/hooks/useI18n";

import {Button} from "@bolteu/kalep-react";
import {Route} from "@bolteu/kalep-react-icons";

import {DriverInfo} from "../types";
import {InfoRow} from "./InfoRow";

interface OrderDetailsProps {
    driverInfo: DriverInfo;
    showRoute?: () => void;
}

const OrderDetails: FC<OrderDetailsProps> = ({driverInfo: {driver, order}, showRoute}) => {
    const {i18n} = useI18n();

    if (!order) {
        return null;
    }

    return (
        <div className="mt-4 flex flex-col">
            <div className="font-semibold leading-tight text-neutral-900">
                {i18n("auth.app.fleet.live-map.order.title")}
            </div>
            <div>
                <InfoRow
                    title={i18n("auth.app.fleet.live-map.order.status")}
                    value={driver.order_state ? i18n(`order.status.${driver.order_state}`) : "-"}
                />
                {order?.address && (
                    <InfoRow title={i18n("auth.app.fleet.live-map.order.pickup-address")} value={order.address} />
                )}
                {order?.destination_address && (
                    <InfoRow
                        title={i18n("auth.app.fleet.live-map.order.destination-address")}
                        value={order.destination_address}
                    />
                )}
            </div>
            {!!showRoute && (
                <Button
                    variant="secondary"
                    size="sm"
                    startIcon={<Route />}
                    overrideClassName="mt-2"
                    onClick={showRoute}
                >
                    {i18n("auth.app.fleet.live-map.order.show_route")}
                </Button>
            )}
        </div>
    );
};

export default OrderDetails;
