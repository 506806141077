import {Location, NavigateFunction} from "react-router-dom";

import {EventName} from "common/constants/events";
import {CommonRoute} from "common/constants/routes";
import {browserInfo} from "common/util/BrowserInfo";
import {getPageNameFromRoute} from "common/util/routeUtils";
import {AccountState} from "features/account/accountStateProvider";
import {createReturnUrl, getReturnPath} from "@fleet/common/utils/routeUtil";

import {FleetOwnerAuthNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {FleetOwnerAuthNoAuthApiClient} from "./apiClients/noAuthApiClient";
import {localStorageService} from "./LocalStorageService";
import * as mixpanel from "./mixpanel";

export const ActiveCompanyStorage = "active_company_id"; // Used to sync company selection between FOPs
export const AuthKeyStorage = "auth";
export const LocaleCode = "locale";
export const RefreshToken = "refresh_token";
export const HasLoggedIn = "hasLoggedIn";
export const LocationItem = "ip_location_data";

export const removeStorageItems = () => {
    localStorageService.removeItems([AuthKeyStorage, ActiveCompanyStorage]);
    localStorageService.removeLegacyItems([RefreshToken]);
};

export const logout = async (accountState: AccountState, sendEvents: boolean) => {
    const language = localStorageService.getLegacyItem(LocaleCode) || undefined;
    const pageName = getPageNameFromRoute(window.location.pathname) || undefined;

    removeStorageItems();

    if (sendEvents) {
        await mixpanel
            .trackEvent({
                eventName: EventName.LogoutCompleted,
                companyId: accountState.selectedCompany?.company?.id,
                language,
                pageName,
            })
            .finally(mixpanel.reset);
    } else {
        await mixpanel.reset();
    }
};

export const redirectToLogin = (navigate: NavigateFunction, location: Location) => {
    const returnUrl = getReturnPath(location);
    if (returnUrl) {
        const query = createReturnUrl(returnUrl);
        navigate(`${CommonRoute.AUTH}?${query}`);
    } else {
        navigate(CommonRoute.AUTH);
    }
};

export const requestNewRefreshToken = async (username: string, password: string): Promise<string> => {
    const {device_name, device_os_version, device_uuid} = browserInfo.getDeviceInfo();
    const authResponse = await FleetOwnerAuthNoAuthApiClient.startAuthentication(
        {} as unknown as {version: string}, // TODO: API should allow undefined version
        {
            username,
            password,
            device_name,
            device_os_version,
            device_uid: device_uuid,
        },
    );

    // TODO: implement 2FA later
    if (authResponse.type === FleetOwnerAuthNoAuthService.AuthResult.SUCCESS) {
        const token = authResponse.refresh_token;
        localStorageService.setLegacyItem(RefreshToken, token);
        localStorageService.setLegacyItem(HasLoggedIn, "true");

        return token;
    }

    throw new Error("Failed to get refresh token");
};

export const requestNewAccessToken = async (
    refreshToken: string | undefined,
    selectedCompanyId: number | undefined,
    selectedCompanyType: FleetOwnerAuthNoAuthService.CompanyIdentityType | undefined,
): Promise<string> => {
    if (!refreshToken) {
        throw new Error("No refresh token provided");
    }

    let company: FleetOwnerAuthNoAuthService.CompanyIdentity | undefined;
    if (selectedCompanyId && selectedCompanyType) {
        company = {
            company_id: selectedCompanyId,
            company_type: selectedCompanyType,
        };
    }

    const token = await FleetOwnerAuthNoAuthApiClient.getAccessToken({
        refresh_token: refreshToken,
        company,
    });

    return token.access_token;
};

export const restoreRefreshToken = () => localStorageService.getLegacyItem(RefreshToken);
