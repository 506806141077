import {useCallback, useContext, useEffect} from "react";
import {useIntl} from "react-intl";

import {MimeTypes} from "common/constants/mimeTypes";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {cleanStringForFileName, downloadUtf8File} from "common/util/downloadUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button} from "@bolteu/kalep-react";

import GetCampaignParticipantsCSVRequest = FleetOwnerPortalService.GetCampaignParticipantsCSVRequest;
import CampaignType = FleetOwnerPortalService.CampaignType;

interface Props {
    campaignId: number;
    campaignType: CampaignType;
    campaignName: string;
}

const fetchFunction = (api: Api, body: GetCampaignParticipantsCSVRequest) =>
    api.fleetOwnerPortal.fleetIncentivesGetCampaignParticipantsCSV(body);

const DownloadCSVButton = ({campaignId, campaignType, campaignName}: Props) => {
    const intl = useIntl();
    const {i18n} = useI18n();

    const {fetch, data, status} = useFetch(fetchFunction);

    const account = useContext(AccountContextProvider);
    const companyName = account.selectedCompany?.company.name;

    const onButtonClick = useCallback(() => {
        if (fetch) {
            fetch({campaign_id: campaignId, campaign_type: campaignType, language: intl.locale});
        }
    }, [fetch, campaignId, campaignType, intl.locale]);

    useEffect(() => {
        if (companyName && data && status === FetchStatus.Success) {
            const filePrefix = i18n("auth.app.fleet.campaigns.details.participants.csv_title");
            const cleanCompanyName = cleanStringForFileName(companyName);
            const cleanCampaignName = cleanStringForFileName(campaignName);
            const fileName = `${filePrefix}-${cleanCampaignName}-${cleanCompanyName}`;
            downloadUtf8File(data.csv, MimeTypes.CSV, fileName);
        }
    }, [data, status, companyName, campaignName, i18n, intl.locale]);

    return (
        <Button variant="secondary" loading={status === FetchStatus.Loading} onClick={onButtonClick}>
            {i18n("common.download")}
        </Button>
    );
};

export default DownloadCSVButton;
