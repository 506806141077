import {useEffect, useRef, useState} from "react";

import {combine} from "@atlaskit/pragmatic-drag-and-drop/combine";
import {DropTargetRecord} from "@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types";
import {
    dropTargetForElements,
    ElementDragPayload,
    monitorForElements,
} from "@atlaskit/pragmatic-drag-and-drop/element/adapter";
import {reorder} from "@atlaskit/pragmatic-drag-and-drop/reorder";
import {extractClosestEdge} from "@atlaskit/pragmatic-drag-and-drop-hitbox/closest-edge";
import {getReorderDestinationIndex} from "@atlaskit/pragmatic-drag-and-drop-hitbox/util/get-reorder-destination-index";

import {DragPayload, DropTarget} from "./types";

interface Props<Item> {
    list: Item[];
    isDraggedItemSame: (item: Item, draggedItem: DragPayload<Item>) => boolean;
    isTargetItemSame: (item: Item, targetItem: DropTarget<Item>) => boolean;
    onChange: (data: Item[]) => void;
    className?: string;
    children: React.ReactNode;
}

const getSource = <ItemType,>(source: ElementDragPayload): DragPayload<ItemType> => {
    if (typeof source.data === "object") {
        return source as DragPayload<ItemType>;
    }
    throw new Error("Source data invalid");
};

const getTarget = <ItemType,>(target: DropTargetRecord): DropTarget<ItemType> => {
    if (typeof target.data === "object") {
        return target as DropTarget<ItemType>;
    }
    throw new Error("Target data invalid");
};

export const DraggableList = <ItemType,>({
    className,
    children,
    list,
    onChange,
    isDraggedItemSame,
    isTargetItemSame,
}: Props<ItemType>) => {
    const draggableListRef = useRef(null);
    const [isDraggedOver, setIsDraggedOver] = useState(false);

    useEffect(() => {
        const columnElement = draggableListRef.current;
        if (!columnElement) {
            return () => {};
        }
        return combine(
            dropTargetForElements({
                element: columnElement,
                onDragStart: () => setIsDraggedOver(true),
                onDragEnter: () => setIsDraggedOver(true),
                onDragLeave: () => setIsDraggedOver(false),
                onDrop: () => setIsDraggedOver(false),
                getData: () => ({}),
            }),
            monitorForElements({
                onDrop: ({source, location}) => {
                    const target = location.current.dropTargets[0];
                    if (!target) {
                        return;
                    }
                    const sourceIndex = list.findIndex((item) => isDraggedItemSame(item, getSource(source)));
                    if (sourceIndex < 0) {
                        return;
                    }
                    const targetIndex = list.findIndex((item) => isTargetItemSame(item, getTarget(target)));
                    if (targetIndex < 0) {
                        return;
                    }

                    const closestEdgeOfTarget = extractClosestEdge(target.data);
                    const destinationIndex = getReorderDestinationIndex({
                        startIndex: sourceIndex,
                        indexOfTarget: targetIndex,
                        closestEdgeOfTarget,
                        axis: "vertical",
                    });
                    if (destinationIndex === sourceIndex) {
                        return;
                    }

                    const updatedItems = reorder({
                        list,
                        startIndex: sourceIndex,
                        finishIndex: destinationIndex,
                    });
                    onChange(updatedItems);
                },
            }),
        );
    }, [isDraggedItemSame, isTargetItemSame, list, onChange]);

    const isDraggedOverClasses = isDraggedOver ? "rounded bg-neutral-100" : "";

    return (
        <div className={`${className} ${isDraggedOverClasses}`} ref={draggableListRef}>
            {children}
        </div>
    );
};
