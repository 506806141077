import {FC, useCallback, useContext, useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import CloseButton from "common/components/CloseButton";
import {Page} from "common/components/page/Page";
import {CommonRoute} from "common/constants/routes";
import {isActiveFleet} from "common/constants/types";
import {FetchStatus} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

import EmptyNotificationList from "./components/EmptyNotificationList";
import Header from "./components/Header";
import NotificationFullViewList from "./components/NotificationFullViewList";
import {useNotificationApi} from "./hooks/useNotificationApi";

interface LocationState {
    exitToPreviousPage?: boolean;
}
const NotificationFullView: FC = () => {
    const company = useContext(AccountContextProvider).getFleet();
    const location = useLocation();
    const locationState = location.state as LocationState | undefined;
    const navigate = useNavigate();
    const {i18n} = useI18n();
    const {getNotifications, data, status, setNotificationsReadAndReload, setAllNotificationsReadAndReload} =
        useNotificationApi();

    useEffect(() => {
        const pageTitle = i18n("auth.app.notifications.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    const notifications = data?.list;
    const hasUnreadMessages = notifications?.some((notification) => !notification.read_timestamp) ?? false;

    useEffect(() => {
        if (isActiveFleet(company)) {
            getNotifications();
        }
    }, [getNotifications, company]);

    const onClose = useCallback(async () => {
        if (locationState?.exitToPreviousPage) {
            navigate(-1);
        } else {
            navigate(CommonRoute.HOME);
        }
    }, [locationState, navigate]);

    if (!isActiveFleet(company)) {
        return null;
    }

    const isEmptyList =
        [FetchStatus.Init, FetchStatus.Error].includes(status) || !notifications || notifications.length === 0;

    const view = isEmptyList ? (
        <EmptyNotificationList title="auth.app.notifications.no_notifications_yet" />
    ) : (
        <NotificationFullViewList
            notifications={notifications ?? []}
            setNotificationsRead={setNotificationsReadAndReload}
            newUi={data.new_ui}
        />
    );

    return (
        <Page>
            <div className="mx-auto w-full max-w-3xl list-none">
                <div className="mb-8 flex justify-end">
                    <CloseButton onClick={onClose} />
                </div>
                <Header
                    hasUnreadMessages={hasUnreadMessages}
                    onAllNotificationsSetRead={setAllNotificationsReadAndReload}
                    isPartOfMenu={false}
                />
                {view}
            </div>
        </Page>
    );
};

export default NotificationFullView;
