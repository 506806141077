import {createContext, useCallback, useContext, useEffect, useMemo, useState} from "react";

import {fetchDocumentSetFunction} from "common/components/document/entity/useVehicleApplicationDocumentFunctions";
import {EventName} from "common/constants/events";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {CarApplicationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import AddVehicleDocumentsPage from "../../add-vehicle/components/AddVehicleDocumentsPage";
import AddVehiclePage from "../../add-vehicle/components/AddVehiclePage";

export interface AddVehicleWithDocumentsPageProviderValue {
    isAddVehiclePageOpen: boolean;
    isVehicleDocumentsPageOpen: boolean;
    openAddVehiclePage: () => void;
}

const AddVehicleWithDocumentsPageContextProvider = createContext<AddVehicleWithDocumentsPageProviderValue>({
    isAddVehiclePageOpen: false,
    isVehicleDocumentsPageOpen: false,
    openAddVehiclePage: () => {},
});

AddVehicleWithDocumentsPageContextProvider.displayName = "AddVehicleWithDocumentsPageContextProvider";

interface AddVehiclePageProviderProps {
    children: React.ReactNode;
    onVehicleAdded: () => Promise<void>;
}

const AddVehicleWithDocumentsPageProvider = ({children, onVehicleAdded}: AddVehiclePageProviderProps) => {
    const {trackEvent} = useTracking();
    const accountState = useContext(AccountContextProvider);
    const fleet = accountState?.getFleet();
    const {i18n} = useI18n();
    const {data: documentSet, fetch} = useFetch(fetchDocumentSetFunction);

    const [isAddVehiclePageOpen, setIsAddVehiclePageOpen] = useState(false);
    const [isVehicleDocumentsPageOpen, setIsAddVehicleDocumentsPageOpen] = useState(false);
    const [vehicleApplicationId, setVehicleApplicationId] = useState<number | null>(null);

    useEffect(() => {
        if (fetch && isAddVehiclePageOpen && fleet) {
            fetch({});
        }
    }, [fetch, isAddVehiclePageOpen, fleet]);

    const openAddVehiclePage = useCallback(() => {
        trackEvent(EventName.AddNewVehicleButtonClicked);
        setIsAddVehiclePageOpen(true);
    }, [trackEvent]);

    const isVehicleHasDocumentsToUpload = useMemo(() => !!documentSet?.length, [documentSet]);

    const closeAddVehiclePage = useCallback(() => {
        setIsAddVehiclePageOpen(false);
    }, []);

    const closeAddVehicleDocumentsPage = useCallback(() => {
        setVehicleApplicationId(null);
        setIsAddVehicleDocumentsPageOpen(false);
    }, []);

    const submitButtonLabel = useMemo(() => {
        return isVehicleHasDocumentsToUpload ? i18n("common.continue") : i18n("auth.app.fleet.add-vehicle.add_vehicle");
    }, [i18n, isVehicleHasDocumentsToUpload]);

    const onVehicleAdd = useCallback(
        async (carApplication: CarApplicationService.CarApplicationModel) => {
            setIsAddVehiclePageOpen(false);
            if (isVehicleHasDocumentsToUpload) {
                setVehicleApplicationId(carApplication.id);
                setIsAddVehicleDocumentsPageOpen(true);
            } else {
                await onVehicleAdded();
            }
        },
        [onVehicleAdded, setIsAddVehicleDocumentsPageOpen, isVehicleHasDocumentsToUpload],
    );

    const contextValue: AddVehicleWithDocumentsPageProviderValue = {
        isAddVehiclePageOpen,
        isVehicleDocumentsPageOpen,
        openAddVehiclePage,
    };

    return (
        <AddVehicleWithDocumentsPageContextProvider.Provider value={contextValue}>
            {isAddVehiclePageOpen && (
                <AddVehiclePage
                    closePage={closeAddVehiclePage}
                    onVehicleAdded={onVehicleAdd}
                    submitButtonLabel={submitButtonLabel}
                />
            )}
            {isVehicleDocumentsPageOpen && vehicleApplicationId && (
                <AddVehicleDocumentsPage
                    closePage={closeAddVehicleDocumentsPage}
                    documentTypes={documentSet || []}
                    vehicleApplicationId={vehicleApplicationId}
                />
            )}
            {children}
        </AddVehicleWithDocumentsPageContextProvider.Provider>
    );
};

export {AddVehicleWithDocumentsPageProvider, AddVehicleWithDocumentsPageContextProvider};
