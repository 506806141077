import {FC} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert} from "@bolteu/kalep-react";

import BlockTranslationMarkdownOverride from "./BlockTranslationMarkdownOverride";

interface DriverActiveBlocksProps {
    blocks: FleetOwnerPortalService.DriverBlock[];
}

const DriverActiveBlocks: FC<DriverActiveBlocksProps> = ({blocks}) => {
    const {i18n} = useI18n();
    const activeBlocks = new Set(
        blocks.filter((block) => !block.end || block.end * 1000 > new Date().getTime()).map((x) => x.translation),
    );

    if (!activeBlocks.size) {
        activeBlocks.add(i18n("driver.blocked_title"));
    }

    return (
        <Alert severity="error" title={i18n("driver.blocked_title")}>
            <ul className={activeBlocks.size > 1 ? "list-disc" : ""}>
                {Array.from(activeBlocks).map((x) => (
                    <li key={x}>
                        <BlockTranslationMarkdownOverride translation={x} />
                    </li>
                ))}
            </ul>
        </Alert>
    );
};

export default DriverActiveBlocks;
