import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {Features} from "FeaturesProvider";

import {VehicleTabs} from "..";

export function useTabSelectorOptions(
    vehiclesCount: number,
    applicationsCount: number,
    rentingOutCount: number,
    features: Features | null,
): Tab<VehicleTabs>[] {
    const {i18n} = useI18n();

    const tabs: Tab<VehicleTabs>[] = [
        {
            id: VehicleTabs.Vehicles,
            title: `${i18n("auth.app.fleet.vehicles.title")}: ${vehiclesCount}`,
            trackingEventTabName: TabNameEvent.VehiclesPageVehicles,
        },
        {
            id: VehicleTabs.Applications,
            title: `${i18n("auth.app.fleet.vehicles.applications")}: ${applicationsCount}`,
            trackingEventTabName: TabNameEvent.VehiclesPageApplications,
        },
    ];

    if (features?.vehicle_marketplace) {
        tabs.push({
            id: VehicleTabs.RentingOut,
            title: `${i18n("auth.app.fleet.vehicles.renting_out")}: ${rentingOutCount}`,
            trackingEventTabName: TabNameEvent.VehiclesPageRentingOut,
        });
    }

    return tabs;
}
