import {KeyboardEvent, MouseEvent, useCallback} from "react";

import {GhostButton} from "@bolteu/kalep-react";

interface Props {
    onClick: (event: MouseEvent) => void;
    text: string;
    className?: string;
    disabled?: boolean;
}

export const TextButton = ({onClick, text, className, disabled}: Props) => {
    const onClickWrapper = useCallback(
        (event: MouseEvent | KeyboardEvent) => {
            onClick(event as MouseEvent);
        },
        [onClick],
    );

    return (
        <GhostButton
            overrideClassName={`text-action-primary hover:text-content-active-link-primary active:scale-975 font-semibold active:duration-100 active:ease-in-out ${className}`}
            onClick={onClickWrapper}
            disabled={disabled}
        >
            {text}
        </GhostButton>
    );
};
