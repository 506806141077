import {apiTimestampToDate} from "common/util/apiTimestampToDate";
import {hasExpired, isExpiring} from "common/util/dateExpiryUtil";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {DocumentData} from "./useDocumentsList";
import DocumentStatus = FleetOwnerPortalService.DocumentStatus;

enum SortOrderKey {
    Declined = "declined",
    Expired = "expired",
    Expiring = "expiring",
    Required = "required",
    InReview = "inReview",
    Approved = "approved",
    Optional = "optional",
}
const sortOrder = [
    SortOrderKey.Declined,
    SortOrderKey.Expired,
    SortOrderKey.Expiring,
    SortOrderKey.Required,
    SortOrderKey.InReview,
    SortOrderKey.Approved,
    SortOrderKey.Optional,
];

const sortOrderPredicates = {
    [SortOrderKey.Declined]: (document: DocumentData) => document.document?.status === DocumentStatus.DECLINED,
    [SortOrderKey.Expired]: (document: DocumentData) =>
        document.document?.expires &&
        document.document.status === DocumentStatus.VERIFIED &&
        hasExpired(apiTimestampToDate(document.document.expires)),
    [SortOrderKey.Expiring]: (document: DocumentData) =>
        document.document?.expires &&
        document.document.status === DocumentStatus.VERIFIED &&
        isExpiring(apiTimestampToDate(document.document.expires)),
    [SortOrderKey.Required]: (document: DocumentData) => !document.document && document.type.is_required,
    [SortOrderKey.InReview]: (document: DocumentData) => document.document?.status === DocumentStatus.UPLOADED,
    [SortOrderKey.Approved]: (document: DocumentData) => document.document?.status === DocumentStatus.VERIFIED,
    [SortOrderKey.Optional]: (document: DocumentData) => !document.document && !document.type.is_required,
};

function getDocumentSetSortOrder(document: DocumentData): number {
    for (let i = 0; i < sortOrder.length; i++) {
        const sortOrderKey = sortOrder[i];
        if (sortOrderPredicates[sortOrderKey](document)) {
            return i;
        }
    }

    // Nothing matches -> the lowest priority
    return sortOrder.length;
}

export function sortDocumentSet(left: DocumentData, right: DocumentData): number {
    const leftSortOrder = getDocumentSetSortOrder(left);
    const rightSortOrder = getDocumentSetSortOrder(right);

    return leftSortOrder - rightSortOrder;
}
