import {useCallback, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import LoadingSpinner from "common/components/spinner";
import {FetchStatus} from "common/hooks/useFetch";

import LoadMoreTable from "../components/LoadMoreTable";
import NoInvoices from "../components/NoInvoices";
import {TABLE_ROWS_PER_PAGE} from "../contants";
import {usePayouts} from "./hooks/usePayouts";
import {useTableColumns} from "./hooks/useTableColumns";

const Payouts = () => {
    const [dataOffset, setDataOffset] = useState<number>(0);
    const {payouts, status} = usePayouts(dataOffset);

    const columns = useTableColumns();

    const handleLoadMoreClick = useCallback(() => {
        setDataOffset(dataOffset + TABLE_ROWS_PER_PAGE);
    }, [dataOffset]);

    if ([FetchStatus.Loading, FetchStatus.Init].includes(status)) {
        return <LoadingSpinner show />;
    }
    if (FetchStatus.Error === status) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }
    if (!payouts.length && dataOffset === 0) {
        return <NoInvoices />;
    }

    return (
        <div className="w-5/12 min-w-[336px] pt-2">
            <LoadMoreTable
                items={payouts}
                columns={columns}
                aria-label="Payouts invoices"
                loadMore={handleLoadMoreClick}
                dataFetchStatus={status}
                clientSidePagination
            />
        </div>
    );
};

export default Payouts;
