import {useI18n} from "common/hooks/useI18n";

import {Search as SearchIcon} from "@bolteu/kalep-react-icons";

const NoParticipants = () => {
    const {i18n} = useI18n();

    const title = i18n("common.no_results.title");
    const description = i18n("common.no_results.description.keywords");

    return (
        <div className="mt-24 flex flex-col items-center justify-center gap-2 md:mt-20">
            <SearchIcon className="text-content-tertiary mb-2 h-16 w-16" />
            <h5 className="max-w-xs text-center text-xl font-semibold text-neutral-900">{title}</h5>
            <p className="max-w-xs text-center text-neutral-700">{description}</p>
        </div>
    );
};

export default NoParticipants;
