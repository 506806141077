import {useCallback} from "react";

import FilteredBy, {RenderChips} from "common/components/table/FilteredBy";
import {useI18n} from "common/hooks/useI18n";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {Chip} from "@bolteu/kalep-react";

import {defaultDriversFilters, DriverFilter, DriversFilterProps} from "./DriversFilter";

const DriversFilteredBy = ({filters, setFilters}: DriversFilterProps) => {
    const {i18n} = useI18n();

    const renderChips = useCallback(
        (getFilterOnRemove: RenderChips<DriverFilter>) =>
            Object.entries(filters).map(([key, value]) => {
                const filtersKey = key as keyof DriverFilter;
                const onRemove = getFilterOnRemove(filtersKey);

                let label = "";
                switch (filtersKey) {
                    case "status": {
                        const currentValue = value as DriverFilter["status"];
                        if (currentValue === defaultDriversFilters.status || !currentValue) {
                            return null;
                        }

                        label = i18n(`auth.app.fleet.engagement-dashboard.tooltip.${currentValue}`);
                        break;
                    }
                    default:
                        return UnreachableCode.never(filtersKey, null);
                }

                return <Chip key={key} size="md" onRemove={onRemove} label={label} />;
            }),
        [filters, i18n],
    );

    return (
        <FilteredBy
            setFilters={setFilters}
            currentFilterValues={filters}
            initialFilterValues={defaultDriversFilters}
            renderChips={renderChips}
        />
    );
};

export default DriversFilteredBy;
