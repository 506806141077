import {Link} from "react-router-dom";

import {BreadcrumbItem} from "@bolteu/kalep-react";

export const useReactRouterLinkForBreadcrumb = (item: BreadcrumbItem) => {
    const classes = item.href === "" ? "text-primary" : "text-link-primary hover:text-active-link-primary";
    return (
        <Link to={item.href ?? ""} className={`text-sm ${classes}`}>
            {item.title}
        </Link>
    );
};
