import {useCallback, useEffect, useMemo} from "react";

import {GoogleMapApi, Route} from "common/geo/components/google-maps/types";
import {
    DRIVER_POLYLINE_COLOR,
    DRIVER_POLYLINE_OPACITY,
    DRIVER_POLYLINE_WEIGHT,
} from "features/companies/live-map/constants";

import {DriverLocationHistoryItem} from "./useDriverLocationHistory";

function groupDriverLocationHistoryByDriverId(
    driverLocationHistory: DriverLocationHistoryItem[],
): Map<number, {lat: number; lng: number}[]> {
    const grouped = new Map<number, {lat: number; lng: number}[]>();
    driverLocationHistory.forEach((item) => {
        const {driverId} = item;
        if (!grouped.has(driverId)) {
            grouped.set(driverId, []);
        }
        grouped.get(driverId)?.push({
            lat: item.point.lat,
            lng: item.point.lng,
        });
    });
    return grouped;
}

export function useDriverMapPolylines(
    googleMapsApi: GoogleMapApi | undefined,
    driversLocationHistory: DriverLocationHistoryItem[],
    isDriverRoutesShown: boolean,
) {
    const driverLocationHistoryByDriverId = groupDriverLocationHistoryByDriverId(driversLocationHistory);

    const polylines = useMemo(() => {
        if (!isDriverRoutesShown || !googleMapsApi) {
            return [];
        }

        const driverRoutes: Route[] = [];

        driverLocationHistoryByDriverId.forEach((driverLocationHistory) => {
            const polyline = new googleMapsApi.maps.Polyline({
                path: driverLocationHistory,
                geodesic: true,
                strokeColor: DRIVER_POLYLINE_COLOR,
                strokeOpacity: DRIVER_POLYLINE_OPACITY,
                strokeWeight: DRIVER_POLYLINE_WEIGHT,
            });
            driverRoutes.push(polyline);
        });

        return driverRoutes;
    }, [driverLocationHistoryByDriverId, isDriverRoutesShown, googleMapsApi]);

    const addPolylines = useCallback(() => {
        if (!googleMapsApi) {
            return;
        }

        polylines.forEach((polyline) => {
            polyline.setMap(googleMapsApi.map);
        });
    }, [polylines, googleMapsApi]);

    const removePolylines = useCallback(() => {
        polylines.forEach((polyline) => {
            polyline.setMap(null);
        });
    }, [polylines]);

    useEffect(() => {
        if (isDriverRoutesShown) {
            addPolylines();
        }

        return removePolylines;
    }, [polylines, googleMapsApi, removePolylines, isDriverRoutesShown, addPolylines]);
}
