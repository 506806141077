import {useCallback, useState} from "react";

import {BottomSheet} from "common/components/bottomSheet/BottomSheet";
import {useI18n} from "common/hooks/useI18n";

import {GhostButton, ListItemLayout} from "@bolteu/kalep-react";

import {useVerificationOtherMethods} from "./hooks/useVerificationOtherMethods";

export const MobileOtherMethods = () => {
    const {i18n} = useI18n();
    const {otherMethodsItems, getIcon} = useVerificationOtherMethods();

    const [isMobileSheetVisible, setIsMobileSheetVisible] = useState(false);

    const handleGhostButtonClick = useCallback(() => {
        setIsMobileSheetVisible(true);
    }, []);

    const handleOnClose = useCallback(() => {
        setIsMobileSheetVisible(false);
    }, []);

    return (
        <>
            <GhostButton overrideClassName="text-action-primary" onClick={handleGhostButtonClick}>
                {i18n("login.phone.tryOtherMethods")}
            </GhostButton>
            <BottomSheet isOpen={isMobileSheetVisible} onClose={handleOnClose}>
                {otherMethodsItems.map((item) => (
                    <ListItemLayout
                        key={item.key}
                        primary={item.label}
                        renderStartSlot={getIcon(item.icon)}
                        onClick={item.onClick}
                    />
                ))}
            </BottomSheet>
        </>
    );
};
