import {CarApplicationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import ApplicationStatus = CarApplicationService.ApplicationStatus;

export enum DriverRegistrationPublicStatus {
    INCOMPLETE = "incomplete",
    IN_REVIEW = "in_review",
    DECLINED = "declined",
}

export const getPublicApplicationStatus = (
    status: ApplicationStatus,
): {label: DriverRegistrationPublicStatus; color: "promo" | "action" | "danger"} | null => {
    switch (status) {
        case ApplicationStatus.SUBMITTED:
        case ApplicationStatus.ONBOARDING:
        case ApplicationStatus.PENDING_COMPANY_APPROVAL:
        case ApplicationStatus.RESUBMITTED:
            return {label: DriverRegistrationPublicStatus.IN_REVIEW, color: "promo"};
        case ApplicationStatus.IN_PROGRESS:
        case ApplicationStatus.NEEDS_RESUBMISSION:
            return {label: DriverRegistrationPublicStatus.INCOMPLETE, color: "danger"};
        case ApplicationStatus.DECLINED:
        case ApplicationStatus.DECLINED_SILENTLY:
            return {label: DriverRegistrationPublicStatus.DECLINED, color: "danger"};
        default:
            return null;
    }
};
