import {FC, useCallback, useContext, useEffect, useRef} from "react";
import {useIntl} from "react-intl";

import {EventName, EventPropertiesDownloadType, Status} from "common/constants/events";
import {MimeTypes} from "common/constants/mimeTypes";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {
    ApiPeriod,
    apiPeriodToDates,
    filePeriod,
    getDatesStrFromDates,
    getPeriodName,
    Period,
} from "common/services/period";
import {cleanStringForFileName, downloadBase64File} from "common/util/downloadUtil";
import {getIsoDate} from "common/util/FormatUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button} from "@bolteu/kalep-react";

interface BalanceDownloadProps {
    period: ApiPeriod;
}

const fetchBalanceDownload = (api: Api, period: FleetOwnerPortalService.DatePeriodRequest) =>
    api.fleetOwnerPortal.getFleetBalanceDetailsPdf(period);

const BalanceDownload: FC<BalanceDownloadProps> = ({period}) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const account = useContext(AccountContextProvider);
    const {trackEvent} = useTracking();

    const {data, fetch, status, error} = useFetch(fetchBalanceDownload);
    const previousFetchStatus = useRef(status);

    useEffect(() => {
        if (previousFetchStatus.current === status) {
            return;
        }
        const dates = apiPeriodToDates(period);
        const datesStr = getDatesStrFromDates(dates.start, dates.end);
        const periodName = getPeriodName(period);
        const companyName = account.selectedCompany?.company.name;
        if (status === FetchStatus.Success && data?.base64 && companyName) {
            const periodStr = filePeriod(period, intl.locale);
            const cleanCompanyName = cleanStringForFileName(companyName);

            const fileName = `${i18n("auth.app.fleet.balance.title")}-${periodStr}-${cleanCompanyName}`;
            downloadBase64File(data.base64, MimeTypes.PDF, fileName);

            trackEvent(EventName.FileDownloaded, {
                downloadType: EventPropertiesDownloadType.BalancePdf,
                periodName,
                period: datesStr,
                status: Status.Ok,
            });
        } else if (status === FetchStatus.Error) {
            trackEvent(EventName.FileDownloaded, {
                downloadType: EventPropertiesDownloadType.BalancePdf,
                periodName,
                period: datesStr,
                status: Status.Error,
                errorMessage: error.message,
            });
        }
        previousFetchStatus.current = status;
    }, [account.selectedCompany?.company.name, data, error.message, i18n, intl.locale, period, status, trackEvent]);

    const downloadCsv = useCallback(() => {
        if (fetch) {
            const apiDates =
                period.period === Period.CUSTOM
                    ? {start: period.startDate, end: period.endDate}
                    : apiPeriodToDates(period);
            fetch({start_date: getIsoDate(apiDates.start), end_date: getIsoDate(apiDates.end)});
        }
    }, [fetch, period]);

    return (
        <Button variant="secondary" onClick={downloadCsv} loading={status === FetchStatus.Loading}>
            {i18n("common.download")}
        </Button>
    );
};

export default BalanceDownload;
