import {useContext, useEffect} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import LoadingSpinner from "common/components/spinner";
import {useTabs} from "common/components/tab/useTabs";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import BoltToFleetInvoices from "./bolt-to-fleet";
import CompensationsInvoices from "./compensations";
import FleetToRiderInvoices from "./fleet-to-rider";
import {useTabOptions} from "./hooks/useTabOptions";
import PayoutsComponent from "./payouts";

import BillingModel = FleetOwnerReportingService.BillingModel;

export enum InvoiceTab {
    FleetToRider = "fleet_to_rider",
    BoltInvoices = "bolt_invoices",
    Compensations = "compensations",
    Payouts = "payouts",
}

const fetchBillingModel = (api: Api) => api.fleetOwnerReporting.getBillingModel();

const InvoicesPage = () => {
    const {i18n} = useI18n();
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const {data: billingModel, fetch: getBillingModel, status: billingModelFetchStatus} = useFetch(fetchBillingModel);

    const tabs = useTabOptions(billingModel?.billing_model);
    const {activeTab, TabSelector} = useTabs(tabs, InvoiceTab.FleetToRider);

    useEffect(() => {
        const pageTitle = i18n("common.invoices");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    useEffect(() => {
        if (!fleet || !getBillingModel) {
            return;
        }
        getBillingModel({});
    }, [getBillingModel, fleet]);

    let Content: JSX.Element | null = null;
    switch (billingModelFetchStatus) {
        case FetchStatus.Init:
        case FetchStatus.Loading:
            Content = <LoadingSpinner show parentClassName="pt-5" />;
            break;
        case FetchStatus.Success: {
            const description =
                billingModel.billing_model === BillingModel.RESALE
                    ? i18n("auth.app.invoices.taxify-invoices.resale-model")
                    : i18n("auth.app.invoices.fleet-to-rider.description");

            Content = (
                <>
                    <div className="mb-6 flex max-w-6xl flex-col gap-4">
                        <ResponsiveHeader type={ResponsiveHeaderType.MainPage} text={i18n("common.invoices")} />
                        <p className="text-neutral-700">{description}</p>
                    </div>
                    <div className="mb-6">
                        <TabSelector />
                    </div>
                    {activeTab === InvoiceTab.FleetToRider && <FleetToRiderInvoices />}
                    {activeTab === InvoiceTab.BoltInvoices && <BoltToFleetInvoices />}
                    {activeTab === InvoiceTab.Compensations && <CompensationsInvoices />}
                    {activeTab === InvoiceTab.Payouts && <PayoutsComponent />}
                </>
            );
            break;
        }
        case FetchStatus.Error:
            Content = <ErrorView type={ErrorViewType.SomethingWentWrong} />;
            break;
        default:
    }

    return <Page>{Content}</Page>;
};

export default InvoicesPage;
