import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {NavLink, useLocation} from "react-router-dom";

import {clearAllBodyScrollLocks, disableBodyScroll, enableBodyScroll} from "body-scroll-lock";
import {useTopBarHeightTailwindConfig} from "common/hooks/useTopBarHeightTailwindConfig";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {useCompanySelector} from "features/account/components/CompanySelectorWithApplication/hooks/useCompanySelector";
import MobileCompanySelector from "features/account/components/MobileCompanySelector";

import {GhostButton, ListItemLayout, Menu, MenuItem} from "@bolteu/kalep-react";
import {Burger, ChevronRight, Cross, User} from "@bolteu/kalep-react-icons";

import {NavigationLink} from "../hooks/useNavigationLinks";
import {useProfileMenuItems} from "../hooks/useProfileMenuItems";

const NavGroupSeparator = ({className = ""}: {className?: string}) => (
    <div className={`border-separator m-2 h-1 border-t ${className}`} />
);

const SidebarProfileMenu = ({setOpen}: {setOpen: (isOpen: boolean) => void}) => {
    const accountState = useContext(AccountContextProvider);
    const profileMenuItems = useProfileMenuItems(() => setOpen(false));

    return (
        <Menu
            direction="right"
            align="start"
            portal
            menuButton={
                <button
                    type="button"
                    className="border-separator group flex h-20 w-full items-center justify-between border-t py-3 pl-6 pr-3 text-left hover:bg-neutral-100 md:hidden"
                >
                    <div className="flex items-center truncate">
                        <div className="flex h-9 w-9 shrink-0 items-center justify-center rounded-full bg-neutral-300">
                            <User size="sm" className="text-neutral-700" />
                        </div>
                        <div className="ml-3 truncate">
                            <div className="truncate text-base">{accountState.profile?.name}</div>
                            <div className="truncate text-sm text-neutral-700">{accountState.profile?.email}</div>
                        </div>
                    </div>

                    <div className="ml-3 shrink-0 transform-gpu transition-all group-hover:translate-x-1">
                        <ChevronRight />
                    </div>
                </button>
            }
            overrideClassName="w-60 max-h-[400px] overflow-auto"
        >
            {profileMenuItems.map(({label, action}) => (
                <MenuItem key={label} label={label} onClick={action} />
            ))}
        </Menu>
    );
};

interface SidebarLinkProps {
    link: NavigationLink;
    onClick: () => void;
}

const SidebarLink = ({link, onClick}: SidebarLinkProps) => {
    const resolveClassName = useCallback(
        ({isActive}) =>
            `block p-3 text-base text-neutral-900 leading-snug truncate rounded-md hover:no-underline hover:bg-transparent-300 focus:bg-transparent-300${
                isActive ? " bg-transparent-300 font-semibold" : ""
            }`,
        [],
    );

    const location = useLocation();

    return (
        <NavLink
            to={link.route}
            className={resolveClassName}
            onClick={onClick}
            state={{prevPath: location.pathname, prevSearch: location.search}}
        >
            {link.title}
        </NavLink>
    );
};

interface SidebarProps {
    isOpen: boolean;
    setOpen: (isOpen: boolean) => void;
    navigationLinks: NavigationLink[][];
    onLinkClick: () => void;
}

export const Sidebar = ({isOpen, setOpen, navigationLinks, onLinkClick}: SidebarProps) => {
    const openTailwind = !isOpen ? "-translate-x-full" : "";
    const ref = useRef<HTMLDivElement>(null);

    const {sideBarTopClass: SIDE_BAR_TOP_CLASS} = useTopBarHeightTailwindConfig();

    const [fleetLinksFirstGroup = [], fleetLinksCompanyGroup = [], fleetLinksOthersGroup = []] = navigationLinks;
    const renderLinks: NavigationLink[][] = [
        [...fleetLinksFirstGroup, ...fleetLinksOthersGroup],
        fleetLinksCompanyGroup,
    ];

    const accountState = useContext(AccountContextProvider);
    const selectedCompany = accountState.selectedCompany?.company;
    const [isCompanySelectorShown, setIsCompanySelectorShown] = useState(false);
    const {getDescription, getCompanyName} = useCompanySelector();
    const description = getDescription(selectedCompany, false, true);
    const selectedCompanyName = getCompanyName(selectedCompany);
    const hasCompanies = Boolean(accountState.profile?.fleets.length || accountState.profile?.holdingFleets.length);
    const hasRenderLinks = renderLinks.some((links) => links.length > 0);

    useEffect(() => {
        return () => {
            clearAllBodyScrollLocks();
        };
    }, []);

    const onCompanyClick = useCallback(() => {
        setIsCompanySelectorShown(true);
        if (ref.current) {
            disableBodyScroll(ref.current, {
                allowTouchMove: (el) => {
                    while (el && el !== document.body) {
                        if (el.id === "body-scroll-lock-ignore") {
                            return true;
                        }

                        // eslint-disable-next-line no-param-reassign -- invalid
                        el = el.parentElement as HTMLElement;
                    }
                    return false;
                },
            });
        }
    }, []);

    const onCompanySelectorClose = useCallback(() => {
        setIsCompanySelectorShown(false);
        setOpen(false);
        if (ref.current) {
            enableBodyScroll(ref.current);
        }
    }, [setOpen]);

    const renderRightArrow = useCallback(() => {
        return <ChevronRight className="text-content-tertiary" />;
    }, []);

    return (
        <div
            className={`scrolling-touch fixed ${SIDE_BAR_TOP_CLASS} bottom-0 left-0 z-30 box-border w-10/12 overflow-auto bg-white shadow transition-transform duration-300 ease-in-out sm:w-[330px] xl:hidden ${openTailwind}`}
        >
            <div className="relative h-full">
                <div className="flex min-h-full flex-col justify-between">
                    <div id="navigation" className="flex flex-col py-4 px-3">
                        {hasCompanies && (
                            <div className="grow md:hidden">
                                <GhostButton
                                    onClick={onCompanyClick}
                                    overrideClassName="block w-full text-base text-neutral-900 leading-snug truncate hover:bg-transparent-300 rounded-md"
                                >
                                    <ListItemLayout
                                        variant="base"
                                        primary={selectedCompanyName}
                                        secondary={description}
                                        renderEndSlot={renderRightArrow}
                                    />
                                </GhostButton>
                            </div>
                        )}
                        {hasRenderLinks && <NavGroupSeparator key="nav-separator" className="md:hidden" />}
                        <div className="grow">
                            {renderLinks
                                .filter((r) => r.length)
                                .map((linkGroup, i) => (
                                    // We have no better key than the index here
                                    <div key={`linkgroup-${i + 1}`}>
                                        {i > 0 && <NavGroupSeparator key="nav-separator" />}
                                        {linkGroup.map((link) => (
                                            <SidebarLink link={link} onClick={onLinkClick} key={link.route} />
                                        ))}
                                    </div>
                                ))}
                        </div>
                    </div>
                    <SidebarProfileMenu setOpen={setOpen} />
                </div>
            </div>
            <MobileCompanySelector isOpen={isCompanySelectorShown} onRequestClose={onCompanySelectorClose} ref={ref} />
        </div>
    );
};

interface SidebarButtonProps {
    isSidebarOpen: boolean;
    onClick: () => void;
}

export const SidebarButton = ({isSidebarOpen, onClick}: SidebarButtonProps) => {
    return (
        <button
            onClick={onClick}
            type="button"
            className="bg-neutral-secondary mr-3 flex items-center justify-center rounded-md p-3 text-neutral-900 outline-none md:bg-inherit xl:hidden"
        >
            {isSidebarOpen ? <Cross /> : <Burger />}
        </button>
    );
};
