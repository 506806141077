import {FC, useCallback, useContext, useEffect, useState} from "react";

import {FetchStatus} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {ValidatorResult} from "@fleet/common/hooks/useFetch";

import {FormState, useForm} from "@bolteu/kalep-form-react";
import {Grid} from "@bolteu/kalep-react";
import {Bin} from "@bolteu/kalep-react-icons";

import {useTin} from "../../hooks/useTin";
import {TinData} from "./TinInformation";

interface TinForm {
    country: string;
    tin: string;
}

export interface TaxIdentificationNumberEditRowItemProps {
    countryOptions: Array<{title: string; value: string}>;
    tinData?: TinData;
    onCancel: () => void;
    onSubmit: () => void;
}

const TaxIdentificationNumberEditRowItem: FC<TaxIdentificationNumberEditRowItemProps> = ({
    countryOptions,
    tinData,
    onCancel,
    onSubmit,
}) => {
    const {i18n} = useI18n();

    const isEditMode = !!tinData?.id;

    const defaultCountry = useContext(AccountContextProvider)?.getFleet()?.country;

    const {Form, TextField, SelectField, Button} = useForm<TinForm, Record<never, never>>({
        country: tinData?.country || defaultCountry || "",
        tin: tinData?.tin || "",
    });

    const [validatorResults, setValidatorResults] = useState<ValidatorResult[]>([]);

    const handleCancelClick = useCallback(() => {
        onCancel();
    }, [onCancel]);

    const {deleteTin, addTin, isLoading, createTinStatus, deleteTinStatus, updateTinStatus} = useTin();

    const handleSaveClick = useCallback(
        async (state: FormState<TinForm>) => {
            const {data} = state;
            const {country, tin} = data;
            const id = tinData?.id;

            setValidatorResults([]);
            if (tin.trim() === "") {
                setValidatorResults([{property: "tin", error: i18n("auth.app.company-settings.tin.error.empty")}]);
                return;
            }

            await addTin({id, country, tin});
        },
        [addTin, i18n, tinData?.id],
    );

    const handleDeleteClick = useCallback(async () => {
        const {id} = tinData || {};
        if (id) {
            await deleteTin(id);
        }
    }, [deleteTin, tinData]);

    useEffect(() => {
        if ([createTinStatus, updateTinStatus, deleteTinStatus].includes(FetchStatus.Success)) {
            onSubmit();
        }
    }, [createTinStatus, updateTinStatus, deleteTinStatus, onSubmit]);

    return (
        <Form className="my-4" onSubmit={handleSaveClick} validatorResults={validatorResults}>
            <Grid>
                <SelectField
                    name="country"
                    required
                    size="sm"
                    label=""
                    placeholder={i18n("auth.app.company-settings.tin.country_placeholder")}
                    options={countryOptions}
                />
                <TextField
                    name="tin"
                    required
                    label=""
                    size="sm"
                    placeholder={i18n("auth.app.company-settings.tin.enter")}
                />
                <div className="flex justify-between gap-4">
                    <div>
                        {isEditMode && (
                            <Button variant="secondary" size="sm" onClick={handleDeleteClick} disabled={isLoading}>
                                <Bin size="sm" />
                                {i18n("auth.app.company-settings.delete")}
                            </Button>
                        )}
                    </div>
                    <div className="flex gap-4 self-end">
                        <Button variant="secondary" size="sm" onClick={handleCancelClick} disabled={isLoading}>
                            {i18n("auth.app.company-settings.cancel")}
                        </Button>
                        <Button submit size="sm" disabled={isLoading}>
                            {i18n("auth.app.company-settings.save")}
                        </Button>
                    </div>
                </div>
            </Grid>
        </Form>
    );
};

export {TaxIdentificationNumberEditRowItem};
