import {useMemo} from "react";

import {FleetInboxService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {MenuDivider} from "@bolteu/kalep-react";

import {useGroups} from "../hooks/useGroups";
import NotificationListItem from "./NotificationListItem";

const NotificationFullViewList = ({
    notifications,
    setNotificationsRead,
    newUi,
}: {
    notifications: FleetInboxService.LocalizedPortalNotification[];
    setNotificationsRead: (notifications: FleetInboxService.LocalizedPortalNotification[]) => void;
    newUi: boolean;
}) => {
    const {groups, setNotificationsAsReadWrapper, getExpandButtonProps} = useGroups(
        notifications,
        setNotificationsRead,
        newUi,
    );

    const notificationItems = useMemo(() => {
        return groups.flatMap((group) => {
            if (group.isExpanded) {
                return group.notifications.map((notification, index) => (
                    <div key={notification.id}>
                        <NotificationListItem
                            notification={notification}
                            onItemClick={setNotificationsAsReadWrapper([notification])}
                            onCallToActionClick={setNotificationsAsReadWrapper([notification])}
                            isPartOfMenu={false}
                            titleRow={
                                index === 0
                                    ? {
                                          isUnread: group.hasUnreadNotifications,
                                          groupSize: group.notifications.length,
                                      }
                                    : undefined
                            }
                        />
                        <MenuDivider />
                    </div>
                ));
            }
            return (
                <div key={group.notifications[0].id}>
                    <NotificationListItem
                        notification={group.notifications[0]}
                        onItemClick={setNotificationsAsReadWrapper([group.notifications[0]])}
                        onCallToActionClick={setNotificationsAsReadWrapper([group.notifications[0]])}
                        isPartOfMenu={false}
                        titleRow={{
                            isUnread: group.hasUnreadNotifications,
                            groupSize: group.notifications.length,
                        }}
                        expandButton={getExpandButtonProps(group)}
                    />
                    <MenuDivider />
                </div>
            );
        });
    }, [getExpandButtonProps, groups, setNotificationsAsReadWrapper]);

    return <>{notificationItems}</>;
};

export default NotificationFullViewList;
