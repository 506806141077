export const vehicleColors = [
    "white",
    "black",
    "blue",
    "green",
    "yellow",
    "red",
    "purple",
    "gray",
    "brown",
    "beige",
    "pink",
    "orange",
    "gold",
    "silver",
    "bronze",
    "maroon",
    "wine",
    "wineRed",
    "skyBlue",
    "lightBlue",
    "pearlWhite",
    "pearl",
    "darkGrey",
    "navyBlue",
];
