import {createContext, useState} from "react";

interface NavigationBarData {
    isNavigationBarVisible: boolean;
    setIsNavigationBarVisible: (isVisible: boolean) => void;
}

const defaultContextValue: NavigationBarData = {
    isNavigationBarVisible: true,
    setIsNavigationBarVisible: () => {},
};

const NavigationBarVisbilityContextProvider = createContext<NavigationBarData>(defaultContextValue);
NavigationBarVisbilityContextProvider.displayName = "NavigationBarContextProvider";

const NavigationBarVisibilityProvider = ({children}: {children: React.ReactNode}) => {
    const [isNavigationBarVisible, setIsNavigationBarVisible] = useState(true);
    const contextValue: NavigationBarData = {isNavigationBarVisible, setIsNavigationBarVisible};

    return (
        <NavigationBarVisbilityContextProvider.Provider value={contextValue}>
            {children}
        </NavigationBarVisbilityContextProvider.Provider>
    );
};

export {NavigationBarVisibilityProvider, NavigationBarVisbilityContextProvider};
