import {twMerge} from "tailwind-merge";

interface PageProps {
    children: React.ReactNode;
    className?: string;
}

const Page = ({children, className}: PageProps) => {
    return (
        <div className="h-full w-full">
            <div
                className={twMerge(
                    "container mx-auto pt-6 mb-14 flex flex-col gap-y-6 px-6 sm:pt-4 lg:pt-10",
                    className,
                )}
            >
                {children}
            </div>
        </div>
    );
};

export {Page};
