import {createContext, useCallback, useState} from "react";
import {useSearchParams} from "react-router-dom";

import {EventName} from "common/constants/events";
import {useTracking} from "common/hooks/useTracking";

import CompleteRegistrationDialog from "../../add-driver/components/CompleteRegistrationDialog";
import InviteDriverDialog, {NewDriverRegistrationData} from "../../add-driver/components/InviteDriverDialog";

export interface DriverDialogValue {
    openInviteDriverDialog: () => void;
}

const DriverDialogContextProvider = createContext<DriverDialogValue>({
    openInviteDriverDialog: () => {},
});

const SEARCH_PARAM_OPEN_DIALOG_NAME = "open_invite_driver";

DriverDialogContextProvider.displayName = "DriverDialogContextProvider";

const DriverDialogProvider = ({children}: {children: React.ReactNode}) => {
    const [searchParams] = useSearchParams();
    const paramIsOpenDialog = searchParams.get(SEARCH_PARAM_OPEN_DIALOG_NAME);

    const [newDriverRegistrationData, setNewDriverRegistrationData] = useState<NewDriverRegistrationData | null>(null);
    const [isInviteDriverDialogOpen, setIsInviteDriverDialogOpen] = useState(!!paramIsOpenDialog);
    const [isCompleteRegistrationDialogOpen, setIsCompleteRegistrationDialogOpen] = useState(false);

    const {trackEvent} = useTracking();

    const openInviteDriverDialog = useCallback(() => {
        trackEvent(EventName.InviteDriverButtonClicked);
        setIsInviteDriverDialogOpen(true);
    }, [trackEvent, setIsInviteDriverDialogOpen]);

    const openCompleteRegistrationDialog = useCallback(
        (data: NewDriverRegistrationData) => {
            setIsInviteDriverDialogOpen(false);
            setNewDriverRegistrationData(data);
            setIsCompleteRegistrationDialogOpen(true);
        },
        [setIsInviteDriverDialogOpen, setIsCompleteRegistrationDialogOpen, setNewDriverRegistrationData],
    );

    const closeInviteDriverDialog = useCallback(() => {
        setIsInviteDriverDialogOpen(false);
    }, [setIsInviteDriverDialogOpen]);

    const closeCompleteRegistrationDialog = useCallback(() => {
        setNewDriverRegistrationData(null);
        setIsCompleteRegistrationDialogOpen(false);
    }, [setNewDriverRegistrationData, setIsCompleteRegistrationDialogOpen]);

    const contextValue: DriverDialogValue = {
        openInviteDriverDialog,
    };

    return (
        <DriverDialogContextProvider.Provider value={contextValue}>
            <InviteDriverDialog
                isOpen={isInviteDriverDialogOpen}
                closeDialog={closeInviteDriverDialog}
                openCompleteRegistrationDialog={openCompleteRegistrationDialog}
            />
            <CompleteRegistrationDialog
                isOpen={isCompleteRegistrationDialogOpen}
                closeDialog={closeCompleteRegistrationDialog}
                registrationData={newDriverRegistrationData}
            />
            {children}
        </DriverDialogContextProvider.Provider>
    );
};

export {DriverDialogProvider, DriverDialogContextProvider};
