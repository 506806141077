import React from "react";

import {useI18n} from "common/hooks/useI18n";
import {apiTimestampToDate} from "common/util/apiTimestampToDate";
import {hasExpired, isExpiring} from "common/util/dateExpiryUtil";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {DocumentData} from "./useDocumentsList";
import DocumentStatus = FleetOwnerPortalService.DocumentStatus;

interface Props {
    document: DocumentData;
}

export enum DocumentRowAction {
    None = "none",
    UploadButton = "upload_button",
    InReviewChip = "in_review_chip",
}

interface Result {
    shouldShowAlertIcon: boolean;
    action: DocumentRowAction;
    subText: React.ReactNode;
    isSubTextRedColor: boolean;
    shouldShowDescription: boolean;
}

export const useDocumentRow = ({document: {document, type}}: Props): Result => {
    const {i18n} = useI18n();

    if (!document) {
        if (type.is_required) {
            return {
                shouldShowAlertIcon: false,
                action: DocumentRowAction.UploadButton,
                subText: i18n("auth.app.fleet.driver-profile.documents.row.required"),
                isSubTextRedColor: true,
                shouldShowDescription: true,
            };
        }

        return {
            shouldShowAlertIcon: false,
            action: DocumentRowAction.UploadButton,
            subText: "",
            isSubTextRedColor: false,
            shouldShowDescription: true,
        };
    }

    if (document.status === DocumentStatus.UPLOADED) {
        return {
            shouldShowAlertIcon: false,
            action: DocumentRowAction.InReviewChip,
            subText: document.expires ? apiTimestampToDate(document.expires).toLocaleDateString() : "",
            isSubTextRedColor: false,
            shouldShowDescription: false,
        };
    }

    if (document.status === DocumentStatus.DECLINED) {
        return {
            shouldShowAlertIcon: true,
            action: DocumentRowAction.UploadButton,
            subText: i18n("auth.app.fleet.driver-profile.documents.row.rejected"),
            isSubTextRedColor: true,
            shouldShowDescription: true,
        };
    }

    if (document.expires) {
        const expirationDate = apiTimestampToDate(document.expires);

        if (hasExpired(expirationDate)) {
            return {
                shouldShowAlertIcon: true,
                action: DocumentRowAction.UploadButton,
                subText: i18n("auth.app.fleet.driver-profile.documents.row.expired", {
                    date: expirationDate.toLocaleDateString(),
                }),
                isSubTextRedColor: true,
                shouldShowDescription: true,
            };
        }

        if (isExpiring(expirationDate)) {
            return {
                shouldShowAlertIcon: false,
                action: DocumentRowAction.UploadButton,
                subText: i18n("auth.app.fleet.driver-profile.documents.row.expiring", {
                    date: expirationDate.toLocaleDateString(),
                }),
                isSubTextRedColor: true,
                shouldShowDescription: true,
            };
        }
    }

    return {
        shouldShowAlertIcon: false,
        action: DocumentRowAction.None,
        subText: document.expires ? apiTimestampToDate(document.expires).toLocaleDateString() : "",
        isSubTextRedColor: false,
        shouldShowDescription: false,
    };
};
