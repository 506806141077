import DataAndMeasuresCell from "common/components/table/DataAndMeasuresCell";
import {useI18n} from "common/hooks/useI18n";

import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";
import {TableHeaders} from "@bolteu/kalep-table-react/build/types";

import DownloadPdf from "../components/DownloadPdf";
import {BoltToFleetInvoiceData} from "./useBoltInvoices";

export function useTableColumns() {
    const {i18n} = useI18n();

    const columns: TableHeaders<BoltToFleetInvoiceData> = {
        public_id: {
            label: i18n("common.invoice_number"),
        },
        date: {
            label: i18n("common.date"),
        },
        due_date: {
            label: i18n("common.due_date"),
        },
        sum: {
            label: i18n("common.total_sum"),
            renderCell: (item: BoltToFleetInvoiceData) => (
                <DataAndMeasuresCell data={item.sum} measure={item.currency} />
            ),
            align: "right",
        },
        id: {
            label: "",
            renderCell: (item: BoltToFleetInvoiceData) => (
                <DefaultCellContent className="justify-center">
                    <DownloadPdf item={item} />
                </DefaultCellContent>
            ),
        },
    };

    return columns;
}
