import {FC, useCallback} from "react";

import {useCopyToClipboard} from "common/hooks/useCopyToClipboard";

import {IconButton, TextField} from "@bolteu/kalep-react";
import {Copy} from "@bolteu/kalep-react-icons";

import styles from "./style.module.css";

interface CredentialsBlockItemProps {
    label: string;
    value: string;
}

const CredentialsBlockItem: FC<CredentialsBlockItemProps> = ({label, value}) => {
    const {copyToClipboard} = useCopyToClipboard();

    const onClick = useCallback(() => {
        copyToClipboard(value);
    }, [copyToClipboard, value]);

    return (
        <div className="flex items-end gap-3">
            <TextField overrideClassName={styles["truncate-input"]} size="lg" label={label} value={value} />
            <div className="flex h-14 items-center">
                <IconButton variant="secondary" aria-label="Copy to clipboard" icon={<Copy />} onClick={onClick} />
            </div>
        </div>
    );
};

export default CredentialsBlockItem;
