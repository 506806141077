import React, {useContext} from "react";

import {CountryCode} from "common/geo/constants/countries";
import {useI18n} from "common/hooks/useI18n";
import {TranslationKeys} from "config/translations";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {countriesHaveTransportLicense} from "features/companies/vehicles/config/transportLicenseConfig";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {VisibleVehicleField} from "../components/VehicleData";
import {useTransportLicenseExpiryData} from "./useTransportLicenseExpiryData";

import Vehicle = FleetOwnerPortalService.GetCarResponse;

export interface VehicleDataRowValues {
    title: string;
    value: string;
    editable: boolean;
    description: React.ReactNode;
    descriptionClasses: string | undefined;
    isCopyable: boolean;
}

const isFieldEditable = (fieldName: string, editableFields: string[]) => {
    return editableFields.includes(fieldName);
};

const fieldsWithCopyButton = ["uuid"];

export function useVehicleDataRow({
    fieldName,
    vehicle,
}: {
    fieldName: VisibleVehicleField;
    vehicle: Vehicle;
}): VehicleDataRowValues | null {
    const {i18n} = useI18n();
    const country = useContext(AccountContextProvider)?.getFleet()?.country ?? "";

    const transportLicenseExpiryData = useTransportLicenseExpiryData({vehicle});

    const isVehicleFieldEditable = isFieldEditable(fieldName, vehicle.editable_fields);
    const shouldShowTransportLicense =
        countriesHaveTransportLicense.includes(country as CountryCode) &&
        (vehicle.car_transport_licence_number !== null || isVehicleFieldEditable);

    let translationKey: string = fieldName;
    let value = String(vehicle[fieldName] ?? "");
    let description: React.ReactNode;
    let descriptionClasses: string | undefined;

    if (fieldName === "reg_number") {
        translationKey = "license_plate";
    } else if (fieldName === "car_transport_licence_number") {
        if (!shouldShowTransportLicense) {
            return null;
        }
        translationKey = "transport_license_number";
        if (transportLicenseExpiryData) {
            description = transportLicenseExpiryData.description;
            descriptionClasses = transportLicenseExpiryData.classes;
        }
    } else if (fieldName === "car_group_name") {
        translationKey = "car_group";
    } else if (fieldName === "color") {
        if (!vehicle.color) {
            return null;
        }
        value = i18n(`vehicle.color.${vehicle.color}` as TranslationKeys);
    }

    const isFieldCopyable = Boolean(value && fieldsWithCopyButton.includes(fieldName)) && !isVehicleFieldEditable;

    return {
        title: i18n(`auth.app.fleet.vehicle.${translationKey}` as TranslationKeys),
        value,
        editable: isVehicleFieldEditable,
        description,
        descriptionClasses,
        isCopyable: isFieldCopyable,
    };
}
