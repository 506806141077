import {VehicleListingStatusChip} from "common/components/vehicles/VehicleListingStatusChip";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import ListingStatus = VehicleMarketplaceService.ListingStatus;
import GetListingData = VehicleMarketplaceService.GetListingData;

interface Props {
    listingData: GetListingData;
}

const PageHeader = ({listingData}: Props) => {
    const headerTextColor = listingData.status === ListingStatus.PUBLISHED ? "text-neutral-900" : "text-tertiary";

    return (
        <div className="flex flex-col items-start gap-4 md:flex-row md:items-center">
            <h1
                className={`leading-14 bolt-font-heading-s-accent font-semibold tracking-normal md:text-6xl ${headerTextColor}`}
            >
                {`${listingData.vehicle_model_name}, ${listingData.vehicle_years}`}
            </h1>
            <VehicleListingStatusChip status={listingData.status} />
        </div>
    );
};

export default PageHeader;
