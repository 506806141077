import {FleetRoute} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";

export function useBreadcrumbs(companyId?: number) {
    const {i18n} = useI18n();

    return [
        {
            href: `/${companyId}/${FleetRoute.DRIVERS}`,
            title: i18n("auth.app.fleet.drivers.title"),
        },
        {
            href: "",
            title: i18n("auth.app.fleet.driver-profile.title"),
        },
    ];
}
