import {useEffect, useState} from "react";

import tailwindConfig from "tailwindcss/defaultConfig";

type ScreenSize = "sm" | "md" | "lg" | "xl" | "2xl";

const getBreakPoints = (screens: Record<string, string>): Record<string, number> =>
    Object.entries(screens).reduce((acc, curr) => {
        const [key, value] = curr;
        return {
            ...acc,
            [key]: parseInt(value.replace("px", ""), 10),
        };
    }, {});

export const useTailwindViewport = () => {
    const {theme} = tailwindConfig;
    const breakpoints: Record<ScreenSize, number> = getBreakPoints(theme?.screens as Record<string, string>);

    const [viewport, setViewport] = useState<ScreenSize | null>(null);

    useEffect(() => {
        const handleResize = () => {
            const width = document.documentElement.clientWidth;

            if (width >= 0 && width < breakpoints.sm) {
                setViewport("sm");
            } else if (width >= breakpoints.sm && width < breakpoints.md) {
                setViewport("sm");
            } else if (width >= breakpoints.md && width < breakpoints.lg) {
                setViewport("md");
            } else if (width >= breakpoints.lg && width < breakpoints.xl) {
                setViewport("lg");
            } else if (width >= breakpoints.xl && width < breakpoints["2xl"]) {
                setViewport("xl");
            } else if (width >= breakpoints["2xl"]) {
                setViewport("2xl");
            }
        };

        handleResize();
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [breakpoints]);

    return viewport;
};
