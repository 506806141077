import {FC, ReactNode, useEffect} from "react";
import {useParams} from "react-router-dom";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import LoadingSpinner from "common/components/spinner";
import {FetchStatus} from "common/hooks/useFetch";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {Breadcrumb} from "@bolteu/kalep-react";

import {useBreadcrumbs} from "../../common/hooks/useBreadcrumbs";
import {useDriverInvitation} from "../hooks/useDriverInvitation";
import DriverInvitationBody from "./DriverInvitationBody";

const DriverInvitation: FC = () => {
    const params = useParams();
    const breadcrumbs = useBreadcrumbs();
    const driverInvitation = useDriverInvitation(String(params.id));

    useEffect(() => {
        if (driverInvitation.status === FetchStatus.Success) {
            document.title = getDocumentTitle(driverInvitation?.data?.email || "");
        }
    }, [driverInvitation]);

    let Body: ReactNode = null;
    switch (driverInvitation.status) {
        case FetchStatus.Init:
        case FetchStatus.Loading:
            Body = <LoadingSpinner show showLoadingText parentClassName="mt-9" />;
            break;
        case FetchStatus.Error:
            Body = <ErrorView type={ErrorViewType.NotFound} />;
            break;
        case FetchStatus.Success:
            Body = <DriverInvitationBody data={driverInvitation.data} />;
            break;
        default:
            UnreachableCode.never(driverInvitation);
    }

    return (
        <div className="container mx-auto flex h-full w-full flex-col px-6 py-8">
            <Breadcrumb items={breadcrumbs} />
            {Body}
        </div>
    );
};

export default DriverInvitation;
