import {FleetOwnerVerificationNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert} from "@bolteu/kalep-react";

import {CustomText} from "./CustomText";

type DescriptionProps = {
    description: string | FleetOwnerVerificationNoAuthService.Description;
};

export const Description = ({description}: DescriptionProps): React.ReactElement => {
    if (typeof description === "string") {
        return <span>{description}</span>;
    }

    if (description.type === FleetOwnerVerificationNoAuthService.DescriptionType.TEXT) {
        const {value} = description;
        return <span>{value}</span>;
    }

    if (description.type === FleetOwnerVerificationNoAuthService.DescriptionType.ALERT) {
        const {
            value: {type, text},
        } = description;
        return (
            <div className="pt-8 sm:px-16">
                <Alert severity={type} overrideClassName="text-left">
                    {text}
                </Alert>
            </div>
        );
    }

    if (description.type === FleetOwnerVerificationNoAuthService.DescriptionType.CUSTOM_TEXT) {
        const {value} = description;
        return <CustomText value={value} />;
    }

    return <></>;
};
