import {useI18n} from "common/hooks/useI18n";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert} from "@bolteu/kalep-react";

export default function AlertMessage({
    application,
}: {
    application: VehicleMarketplaceService.ApplicationWithPartnerAndListingDetails;
}) {
    const {i18n} = useI18n();

    switch (application.status) {
        case VehicleMarketplaceService.ApplicationStatus.PUBLISHED:
            return (
                <Alert
                    severity="info"
                    title={i18n("auth.app.fleet.vehicle_marketplace.applications.view.alert.new_application.title")}
                >
                    {i18n("auth.app.fleet.vehicle_marketplace.applications.view.alert.new_application.description")}
                </Alert>
            );
        case VehicleMarketplaceService.ApplicationStatus.DECLINED:
            return (
                <Alert
                    severity="info"
                    title={i18n(
                        "auth.app.fleet.vehicle_marketplace.applications.view.alert.declined_application.title",
                    )}
                >
                    {null /* TODO: replace with decline reason when implemented */}
                </Alert>
            );

        default:
            return null;
    }
}
