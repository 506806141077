import {useEffect} from "react";

import {useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";

const fetchFunction = (api: Api) => api.fleetOwnerPortal.driverDocumentsIsDriverDocumentUploadEnabled();

export const useDocumentFeature = () => {
    const {data, fetch} = useFetch(fetchFunction);

    useEffect(() => {
        if (fetch) {
            fetch({});
        }
    }, [fetch]);

    return {
        isFeatureEnabled: data?.driver_document_upload_enabled ?? false,
    };
};
