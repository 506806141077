import {useCallback, useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";

import {EventName, EventPropertiesDownloadType} from "common/constants/events";
import {MimeTypes} from "common/constants/mimeTypes";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {ApiPeriod, filePeriod, getPeriodName} from "common/services/period";
import {cleanStringForFileName, downloadBase64File} from "common/util/downloadUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {DriverEarningsService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button} from "@bolteu/kalep-react";

import {useDriverEarningsDownloadApi} from "../hooks/useDriverEarningsDowloadApi";

interface Props {
    period: ApiPeriod;
}

const DownloadEarnings = ({period}: Props) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const account = useContext(AccountContextProvider);
    const [base64File, setBase64File] = useState<string | null>(null);
    const downloadApi = useDriverEarningsDownloadApi(period);
    const {data: downloadedReport, fetch: downloadReport, status: downloadedReportStatus} = useFetch(downloadApi);
    const {trackEvent} = useTracking();

    useEffect(() => {
        if (downloadedReportStatus === FetchStatus.Success && downloadedReport.file) {
            setBase64File(downloadedReport.file);
        }
    }, [downloadedReport, downloadedReportStatus]);

    useEffect(() => {
        const companyName = account.selectedCompany?.company.name;
        if (base64File && companyName) {
            const periodStr = filePeriod(period, intl.locale);
            const cleanCompanyName = cleanStringForFileName(companyName);
            const fileName = `${i18n("auth.app.fleet.reports.title_earnings")}-${periodStr}-${cleanCompanyName}`;
            downloadBase64File(base64File, MimeTypes.CSV, fileName);
            setBase64File(null);
            trackEvent(EventName.FileDownloaded, {
                downloadType: EventPropertiesDownloadType.EarningsReportCsv,
                periodName: getPeriodName(period),
                period: periodStr,
            });
        }
    }, [
        account.selectedCompany?.company.name,
        base64File,
        downloadedReport,
        downloadedReportStatus,
        intl,
        i18n,
        period,
        trackEvent,
    ]);

    const downloadCsv = useCallback(() => {
        if (downloadReport) {
            downloadReport({format: DriverEarningsService.FileFormat.CSV});
        }
    }, [downloadReport]);

    return (
        <Button variant="secondary" onClick={downloadCsv} loading={downloadedReportStatus === FetchStatus.Loading}>
            {i18n("common.download")}
        </Button>
    );
};

export default DownloadEarnings;
