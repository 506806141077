import {twMerge} from "tailwind-merge";
import {ValidatorResult} from "@fleet/common/hooks/useFetch";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {Hint} from "./Hint";
import {UseFormValueType} from "./types";

interface PasswordProps {
    useFormValue: UseFormValueType;
    fieldConfig: FleetOwnerRegistrationService.PasswordField;
    validatorErrors: ValidatorResult[];
}

const Password = ({useFormValue, fieldConfig, validatorErrors}: PasswordProps) => {
    const {TextField} = useFormValue;
    const {name, label, placeholder, hint, required: isRequired, validation_rules, disabled: isDisabled} = fieldConfig;

    const validationRuleErrors = new Set(validatorErrors?.map((e) => e.property));

    return (
        <div className="flex flex-col gap-2">
            <TextField
                name={name as never}
                type="password"
                label={label}
                placeholder={placeholder}
                required={isRequired}
                helperText={<Hint hint={hint} />}
                disabled={isDisabled}
            />
            <ul className="list-inside list-disc text-sm">
                {validation_rules?.map(({text, type}) => {
                    const hasError = validationRuleErrors.has(type);

                    return (
                        <li key={text} className={twMerge(hasError && "text-danger-primary")}>
                            {text}
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export {Password};
