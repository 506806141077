import {twJoin} from "tailwind-merge";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Link} from "@bolteu/kalep-react";

interface HintProps {
    hint?: FleetOwnerRegistrationService.HintInfo;
}

const Hint = ({hint}: HintProps) => {
    if (!hint) {
        return null;
    }

    const {text, link} = hint;
    const className = "font-sans text-sm";

    if (!link) {
        return <div className={twJoin(className, "text-secondary")}>{text}</div>;
    }

    return (
        <div>
            <Link href={link} rel="noreferrer noopener" target="_blank" overrideClassName={className}>
                {text}
            </Link>
        </div>
    );
};

export {Hint};
