import {useCallback, useContext, useMemo} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";
import {VerificationContextProvider} from "features/account/pages/Verification/VerificationProvider";

import {FleetOwnerVerificationNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {
    CallOutlined as Call,
    KalepIcon,
    LockOutlined as Lock,
    LogoWhatsappOutlined as WhatsApp,
} from "@bolteu/kalep-react-icons";

import VerificationChannel = FleetOwnerVerificationNoAuthService.VerificationChannel;
import FopVerificationChannel = FleetOwnerVerificationNoAuthService.FopVerificationChannel;
import NonCodeVerificationChannel = FleetOwnerVerificationNoAuthService.NonCodeVerificationChannel;

interface OtherMethodItem {
    key: FopVerificationChannel | NonCodeVerificationChannel;
    label: string;
    icon: KalepIcon;
    onClick: () => void;
}

export const useVerificationOtherMethods = () => {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const {getVerificationPasswordPath} = useAbsolutePath();

    const params = useParams();
    const attemptKey = params.key;

    const {otpData: verificationData, requestCode} = useContext(VerificationContextProvider);

    const verificationChannels = useMemo(
        () =>
            new Set([
                ...(verificationData?.secondary_verification_code_channels
                    ? verificationData.secondary_verification_code_channels
                    : []),
                ...(verificationData?.secondary_verification_non_code_channels
                    ? verificationData.secondary_verification_non_code_channels.map((channel) => channel.type)
                    : []),
            ]),
        [
            verificationData?.secondary_verification_non_code_channels,
            verificationData?.secondary_verification_code_channels,
        ],
    );

    const isOnlyVoiceChannelEnabled = useMemo(
        () => verificationChannels.size === 1 && verificationChannels.has(VerificationChannel.VOICE),
        [verificationChannels],
    );

    const handleCallMe = useCallback(() => {
        requestCode({channel: VerificationChannel.VOICE});
    }, [requestCode]);

    const handleWhatsApp = useCallback(() => {
        requestCode({channel: VerificationChannel.WHATSAPP});
    }, [requestCode]);

    const otherMethodsItems = useMemo(() => {
        const menuItems: OtherMethodItem[] = [
            {
                key: VerificationChannel.WHATSAPP,
                label: i18n("login.phone.getWhatsAppCode"),
                icon: <WhatsApp />,
                onClick: handleWhatsApp,
            },
            {
                key: VerificationChannel.VOICE,
                label: i18n("login.phone.requestCallback"),
                icon: <Call />,
                onClick: handleCallMe,
            },
            {
                key: NonCodeVerificationChannel.PASSWORD,
                label:
                    verificationData?.secondary_verification_non_code_channels?.find(
                        (channel) => channel.type === NonCodeVerificationChannel.PASSWORD,
                    )?.text ?? "",
                icon: <Lock />,
                onClick: () => navigate(getVerificationPasswordPath(attemptKey ?? "")),
            },
        ];

        return menuItems.filter(({key}) => verificationChannels.has(key));
    }, [
        attemptKey,
        getVerificationPasswordPath,
        handleCallMe,
        handleWhatsApp,
        i18n,
        navigate,
        verificationChannels,
        verificationData?.secondary_verification_non_code_channels,
    ]);

    const getIcon = useCallback((icon: KalepIcon) => () => <>{icon}</>, []);

    return {otherMethodsItems, getIcon, isOnlyVoiceChannelEnabled, handleCallMe};
};
