import {useI18n} from "common/hooks/useI18n";

import {Receipt} from "@bolteu/kalep-react-icons";

interface Props {
    hasHoldingFleetInvoicing?: boolean;
}

const NoInvoices = ({hasHoldingFleetInvoicing}: Props) => {
    const {i18n} = useI18n();

    const defaultTitle = i18n("auth.app.invoices.no_invoices");
    const holdingFleetTitle = i18n("auth.app.invoices.taxify-invoices.sub-fleet.no-invoices.title");
    const holdingFleetDescription = i18n("auth.app.invoices.taxify-invoices.sub-fleet.no-invoices.description");

    return (
        <div className="mt-24 flex flex-col items-center justify-center gap-2 md:mt-40">
            <Receipt className="mb-2 h-[64px] w-[64px] text-neutral-600" />
            <h5 className="max-w-[420px] text-center text-xl font-semibold text-neutral-900">
                {hasHoldingFleetInvoicing ? holdingFleetTitle : defaultTitle}
            </h5>
            {hasHoldingFleetInvoicing && (
                <p className="max-w-[420px] text-center text-neutral-700">{holdingFleetDescription}</p>
            )}
        </div>
    );
};

export default NoInvoices;
