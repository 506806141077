import {FC, useCallback} from "react";
import {NavLink} from "react-router-dom";

import {EventName} from "common/constants/events";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";

import {GhostButton, Menu, MenuItem, MenuItemProps} from "@bolteu/kalep-react";
import {ArrowLeft, MoreIos} from "@bolteu/kalep-react-icons";

interface Props {
    driverId: number;
    onBackPressed: () => void;
    onTakeOfflineClicked: () => void;
    isSendOfflineEnabled: boolean;
    isDriverWaiting: boolean;
}

const RenderMenuLinkLabel: FC<MenuItemProps> = ({label, value: route}) => {
    const {trackEvent} = useTracking();

    const onClick = useCallback(() => trackEvent(EventName.LiveMapDriverProfileLinkClicked), [trackEvent]);

    return (
        <NavLink to={route as string} onClick={onClick}>
            {label}
        </NavLink>
    );
};

const RenderTakeOfflineMenuItem: FC<MenuItemProps> = ({label}) => {
    return <span className="text-red-500">{label}</span>;
};

const DriverDetailsHeader = ({
    onBackPressed,
    driverId,
    isSendOfflineEnabled,
    onTakeOfflineClicked,
    isDriverWaiting,
}: Props) => {
    const {i18n} = useI18n();
    const {getDriverDetailsPath} = useAbsolutePath();

    return (
        <div className="mb-4 flex flex-row items-center justify-between px-4 md:px-8">
            <GhostButton onClick={onBackPressed}>
                <ArrowLeft size="lg" />
            </GhostButton>
            <div>
                <Menu
                    menuButton={
                        <button className="flex" type="button">
                            <MoreIos className="rotate-90" />
                        </button>
                    }
                    overrideClassName="w-[200px]"
                >
                    <MenuItem
                        value={getDriverDetailsPath(driverId)}
                        label={i18n("common.driver_profile")}
                        renderLabel={RenderMenuLinkLabel}
                    />

                    {isSendOfflineEnabled && isDriverWaiting && (
                        <MenuItem
                            onClick={onTakeOfflineClicked}
                            value={getDriverDetailsPath(driverId)}
                            label={i18n("common.take_driver_offline")}
                            renderLabel={RenderTakeOfflineMenuItem}
                        />
                    )}
                </Menu>
            </div>
        </div>
    );
};

export default DriverDetailsHeader;
