import config from "config/index";
import {TranslationKeys} from "config/translations";
import {I18nFn as I18nFnGeneric, useI18nBranded} from "@fleet/common/hooks/useI18n";

export function useI18n() {
    const intl = useI18nBranded<TranslationKeys>(config.appBrand);
    return intl;
}

export interface I18nFn extends I18nFnGeneric<TranslationKeys> {}
