import {FC, useCallback, useState} from "react";

import {ResponsiveDrawer} from "common/components/drawer/ResponsiveDrawer";
import FileUpload from "common/components/fileUpload";
import {FileTypes} from "common/constants/mimeTypes";
import {useI18n} from "common/hooks/useI18n";

import {Button, Drawer} from "@bolteu/kalep-react";

import {MAX_FILE_SIZE_BYTES} from "./constants";
import ExpiryDatePicker from "./ExpiryDatePicker";
import PreviewDocument from "./PreviewDocument";

export interface UploadDocumentProps {
    onConfirmClick: (file: File | null, expiryDate: Date | null) => void;
    onClose: ({documentUploaded}: {documentUploaded: boolean}) => void;
    isDisabledFn?: (file: File | null, expiryDate: Date | null) => boolean;
    showExpiryDatePicker?: boolean;
    isLoading: boolean;
    error?: string;
    children?: React.ReactNode;
}

const UploadDocument: FC<UploadDocumentProps> = ({
    onConfirmClick,
    onClose,
    showExpiryDatePicker,
    isLoading,
    isDisabledFn,
    error,
    children,
}) => {
    const {i18n} = useI18n();

    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [selectedExpiryDate, setSelectedExpiryDate] = useState<Date | null>(null);
    const [fileUploadErrorMsg, setFileUploadErrorMessage] = useState<string>("");

    const onCloseWrapper = useCallback(() => {
        onClose({documentUploaded: false});
    }, [onClose]);

    const handleFileUpload = useCallback((file: File) => {
        setSelectedFile(file);
    }, []);

    const onConfirm = useCallback(() => {
        onConfirmClick(selectedFile, selectedExpiryDate);
    }, [onConfirmClick, selectedExpiryDate, selectedFile]);

    return (
        <ResponsiveDrawer isOpen onRequestClose={onCloseWrapper} title={i18n("upload_document.title")}>
            <Drawer.Content>
                <div className="flex shrink-0 flex-col gap-6">
                    <p>{i18n("upload_document.document_instructions")}</p>
                    {selectedFile === null && (
                        <FileUpload
                            onFileUpload={handleFileUpload}
                            errorMessage={fileUploadErrorMsg}
                            setErrorMessage={setFileUploadErrorMessage}
                            fileTypes={[FileTypes.IMAGE, FileTypes.PDF, FileTypes.DOCUMENT_CONTAINER]}
                            maxFileSizeB={MAX_FILE_SIZE_BYTES}
                        />
                    )}
                    {selectedFile !== null && <PreviewDocument document={selectedFile} />}
                    {children}
                    {showExpiryDatePicker && (
                        <ExpiryDatePicker onChange={setSelectedExpiryDate} date={selectedExpiryDate} />
                    )}
                    {error && <div className="text-danger-primary mt-2 font-sans text-sm">{error}</div>}
                </div>
            </Drawer.Content>
            <Drawer.Footer>
                <Button
                    onClick={onConfirm}
                    disabled={isDisabledFn && isDisabledFn(selectedFile, selectedExpiryDate)}
                    loading={isLoading}
                >
                    {i18n("common.confirm")}
                </Button>
                <Button variant="secondary" onClick={onCloseWrapper}>
                    {i18n("common.cancel")}
                </Button>
            </Drawer.Footer>
        </ResponsiveDrawer>
    );
};

export {UploadDocument};
