import {useCallback, useEffect, useMemo, useState} from "react";

import {debounce} from "lodash-es";

export function useUserMapChange() {
    const [hasUserRecentlyChangedMap, setHasUserRecentlyChangedMap] = useState(false);

    const userMapChangeFlagHandler = useMemo(() => debounce(() => setHasUserRecentlyChangedMap(false), 60_000), []);
    const handleUserChangedMap = useCallback(() => {
        setHasUserRecentlyChangedMap(true);
        userMapChangeFlagHandler();
    }, [userMapChangeFlagHandler]);

    useEffect(() => {
        return () => {
            userMapChangeFlagHandler.cancel();
        };
    }, [userMapChangeFlagHandler]);

    useEffect(() => {
        setHasUserRecentlyChangedMap(false);
    }, [setHasUserRecentlyChangedMap]);

    return {handleUserChangedMap, hasUserRecentlyChangedMap};
}
