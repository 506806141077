import {useCallback, useContext, useEffect, useMemo, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import {PeriodDatePicker} from "common/components/periodDatePicker";
import {AllowedDatePeriod} from "common/components/periodDatePicker/CustomPeriodPicker";
import {useTabs} from "common/components/tab/useTabs";
import {EventName} from "common/constants/events";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {SelectPeriodOption} from "common/hooks/usePeriodSelectorOptions";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, getDatesStrFromDates, getPeriodName} from "common/services/period";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import FilterDrawer from "features/companies/campaigns/list/components/FilterDrawer";
import {FeaturesContextProvider} from "FeaturesProvider";
import {debounce} from "lodash-es";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {TextField} from "@bolteu/kalep-react";

import CampaignsTable from "./components/CampaignsTable";
import DownloadCSVButton from "./components/DownloadCSVButton";
import FilteredBy from "./components/FilteredBy";
import {useTabSelectorOptions} from "./hooks/useTabSelectorOptions";
import {getDefaultFilters} from "./utils/getDefaultFilters";
import {getDefaultPeriod} from "./utils/getDefaultPeriod";

import CampaignFilters = FleetOwnerPortalService.CampaignFilters;

export type DrawerFilters = Omit<CampaignFilters, "name">;

export enum TabName {
    ActiveAndUpcoming = "active_and_upcoming",
    Finished = "finished",
}

const fetchFunction = (api: Api) => api.fleetOwner.portalGetCityListForFleet();

const CampaignsPage = () => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();

    const tabs = useTabSelectorOptions();

    const features = useContext(FeaturesContextProvider);

    const {data, fetch} = useFetch(fetchFunction);

    const {activeTab, TabSelector} = useTabs(tabs, TabName.ActiveAndUpcoming);

    const [nameInputValue, setNameInputValue] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [nameFilter, setNameFilter] = useState<string>("");
    const [drawerFilters, setDrawerFilters] = useState<DrawerFilters>(getDefaultFilters(activeTab));
    const [period, setPeriod] = useState<ApiPeriod>(getDefaultPeriod(activeTab));

    const selectedFilters: CampaignFilters = useMemo(
        () => ({name: nameFilter, ...drawerFilters}),
        [nameFilter, drawerFilters],
    );

    const cities = useMemo(() => data?.cities || [], [data]);
    const selectPeriodOptions = useMemo(
        () =>
            activeTab === TabName.ActiveAndUpcoming
                ? [SelectPeriodOption.ThisWeek, SelectPeriodOption.ThisMonth, SelectPeriodOption.NextMonth]
                : [SelectPeriodOption.LastWeek, SelectPeriodOption.LastMonth, SelectPeriodOption.ThisMonth],
        [activeTab],
    );
    const allowedDatePeriod =
        activeTab === TabName.ActiveAndUpcoming ? AllowedDatePeriod.Future : AllowedDatePeriod.Past;

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.campaigns.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    useEffect(() => {
        if (fetch) {
            fetch({});
        }
    }, [fetch]);

    useEffect(() => {
        setDrawerFilters(getDefaultFilters(activeTab));
        setPeriod(getDefaultPeriod(activeTab));
    }, [activeTab]);

    const setNameFilterDebounce = useMemo(
        () =>
            debounce((name: string) => {
                setNameFilter(name);
                setIsLoading(false);
            }, 500),
        [setNameFilter],
    );

    const onSearchChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            setNameInputValue(event.target.value);
            setIsLoading(true);
            setNameFilterDebounce(event.target.value);
        },
        [setNameFilterDebounce],
    );

    const onPeriodChange = useCallback(
        (newPeriod: ApiPeriod) => {
            setPeriod(newPeriod);
            const dates = apiPeriodToDates(newPeriod);

            trackEvent(EventName.CampaignsListPeriodPicked, {
                periodName: getPeriodName(newPeriod),
                period: getDatesStrFromDates(dates.start, dates.end),
            });
        },
        [setPeriod, trackEvent],
    );

    if (!features?.campaigns) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    return (
        <Page>
            <ResponsiveHeader type={ResponsiveHeaderType.MainPage} text={i18n("auth.app.fleet.campaigns.title")} />
            <TabSelector />
            <div className="flex flex-wrap justify-between gap-4">
                <div className="flex flex-[2] gap-4">
                    <TextField
                        type="search"
                        value={nameInputValue}
                        onChange={onSearchChange}
                        placeholder={i18n("auth.app.fleet.campaigns.list.search_placeholder")}
                    />
                    <FilterDrawer
                        activeTab={activeTab}
                        filters={drawerFilters}
                        setFilters={setDrawerFilters}
                        cities={cities}
                    />
                </div>
                <div className="flex flex-1 justify-between gap-4 whitespace-nowrap">
                    <PeriodDatePicker
                        period={period}
                        selectPeriodOptions={selectPeriodOptions}
                        onPeriodChange={onPeriodChange}
                        dateRangeLimitDays={31}
                        allowedDatePeriod={allowedDatePeriod}
                    />
                    <DownloadCSVButton key={activeTab} filters={selectedFilters} period={period} />
                </div>
            </div>
            <FilteredBy activeTab={activeTab} cities={cities} filters={drawerFilters} setFilters={setDrawerFilters} />
            <CampaignsTable
                // TODO: hack, could've used activeTab, but it's updated earlier than selectedFilters which results in wrong data
                key={selectedFilters.statuses?.join("")}
                activeTab={activeTab}
                filters={selectedFilters}
                period={period}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
            />
        </Page>
    );
};

export default CampaignsPage;
