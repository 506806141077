import {localStorageService} from "common/services/LocalStorageService";
import {useLocalStorage} from "@fleet/common/hooks/useLocalStorage";

import {CustomizableAccordionTableHeaders} from "../AccordionTableWithCustomizableColumns";
import {
    CommonCustomizableTableHeaderProps,
    CustomizableTableHeaders,
    TableColumnSettings,
} from "../TableWithCustomizableColumns";

type T<Entity> = CustomizableTableHeaders<Entity> | CustomizableAccordionTableHeaders<Entity>;

export function useShownColumns<Entity>(id: string, columns: T<Entity>) {
    const [value] = useLocalStorage<TableColumnSettings>(localStorageService.getTableSettingsKey(id), {});

    const shownColumns: T<Entity> = Object.entries(columns)
        .filter(([columnKey, column]) => {
            const customizableColumn = column as T<Entity> & CommonCustomizableTableHeaderProps;

            const isShown = Object.hasOwn(value, columnKey) ? value[columnKey] : !customizableColumn.hideByDefault;
            return isShown;
        })
        .reduce((acc, [key, column]) => ({...acc, [key]: column}), {});

    return shownColumns;
}
