import {useCallback, useState} from "react";

import {I18nFn, useI18n} from "common/hooks/useI18n";
import {getMonthDayYearTime} from "common/util/FormatUtil";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, Chip, MenuItem, Typography} from "@bolteu/kalep-react";
import {ChipAppearance} from "@bolteu/kalep-react/build/components/Chip/Chip.types";
import {Menu} from "@bolteu/kalep-react/build/components/Menu";
import {ChevronDown} from "@bolteu/kalep-react-icons";

import ApproveApplicationDialog from "./ApproveApplicationDialog";
import DeclineApplicationDialog from "./DeclineApplicationDialog";

interface VehicleApplicationDetailsHeaderProps {
    application: VehicleMarketplaceService.ApplicationWithPartnerAndListingDetails;
    onApplicationApproved: () => void;
    onApplicationDeclined: () => void;
}

export function applicationStatusToLabel(
    status: VehicleMarketplaceService.ApplicationStatus,
    i18n: I18nFn,
): string | null {
    switch (status) {
        case VehicleMarketplaceService.ApplicationStatus.PUBLISHED:
            return i18n("auth.app.fleet.vehicle_marketplace.applications.view.status.new");
        case VehicleMarketplaceService.ApplicationStatus.APPROVED:
            return i18n("auth.app.fleet.vehicle_marketplace.applications.view.status.approved");
        case VehicleMarketplaceService.ApplicationStatus.DECLINED:
            return i18n("auth.app.fleet.vehicle_marketplace.applications.view.status.declined");
        case VehicleMarketplaceService.ApplicationStatus.IN_PROGRESS:
            return i18n("auth.app.fleet.vehicle_marketplace.applications.view.status.in_progress");
        case VehicleMarketplaceService.ApplicationStatus.REJECTED:
            return i18n("auth.app.fleet.vehicle_marketplace.applications.view.status.rejected");
        default:
            return null;
    }
}

function applicationStatusToChipAppearance(status: VehicleMarketplaceService.ApplicationStatus): ChipAppearance {
    switch (status) {
        case VehicleMarketplaceService.ApplicationStatus.PUBLISHED:
            return "promo";
        case VehicleMarketplaceService.ApplicationStatus.APPROVED:
            return "action";
        case VehicleMarketplaceService.ApplicationStatus.REJECTED:
            return "neutral";
        case VehicleMarketplaceService.ApplicationStatus.IN_PROGRESS:
            return "warning";
        case VehicleMarketplaceService.ApplicationStatus.DECLINED:
            return "neutral";
        default:
            return "action";
    }
}

export function VehicleApplicationDetailsHeader({
    application,
    onApplicationApproved,
    onApplicationDeclined,
}: VehicleApplicationDetailsHeaderProps) {
    const {i18n} = useI18n();

    const [isApproveApplicationDialogOpen, setIsApproveApplicationDialogOpen] = useState(false);
    const [isDeclineApplicationDialogOpen, setIsDeclineApplicationDialogOpen] = useState(false);

    const openApproveApplicationDialog = useCallback(() => {
        setIsApproveApplicationDialogOpen(true);
    }, []);

    const closeApproveApplicationDialog = useCallback(() => {
        setIsApproveApplicationDialogOpen(false);
    }, []);

    const openDeclineApplicationDialog = useCallback(() => {
        setIsDeclineApplicationDialogOpen(true);
    }, []);

    const closeDeclineApplicationDialog = useCallback(() => {
        setIsDeclineApplicationDialogOpen(false);
    }, []);

    const onApproveApplicationDialogApprove = useCallback(() => {
        closeApproveApplicationDialog();
        onApplicationApproved();
    }, [onApplicationApproved, closeApproveApplicationDialog]);

    const onDeclineApplicationDialogDecline = useCallback(() => {
        closeDeclineApplicationDialog();
        onApplicationDeclined();
    }, [onApplicationDeclined, closeDeclineApplicationDialog]);

    return (
        <div className="flex w-full flex-col gap-4 md:flex-row md:justify-between">
            <div className="flex gap-2">
                <div className="flex flex-col gap-3">
                    <span className="text-[40px] font-semibold">
                        {application.first_name} {application.last_name}
                    </span>
                    <div className="flex flex-col gap-3 md:gap-3">
                        <div>
                            <Chip
                                label={applicationStatusToLabel(application.status, i18n) ?? ""}
                                size="sm"
                                appearance={applicationStatusToChipAppearance(application.status)}
                            />
                        </div>
                        <Typography variant="body-s-regular">
                            Submitted: {getMonthDayYearTime(new Date(application.submission_date * 1000))}
                        </Typography>
                    </div>
                </div>
            </div>
            <div className="flex items-center">
                {application.status === VehicleMarketplaceService.ApplicationStatus.DECLINED ||
                application.status === VehicleMarketplaceService.ApplicationStatus.REJECTED ||
                application.status === VehicleMarketplaceService.ApplicationStatus.APPROVED ? (
                    <></>
                ) : (
                    <Menu
                        menuButton={
                            <Button endIcon={<ChevronDown />}>
                                {i18n("auth.app.fleet.vehicle_marketplace.applications.view.set_status")}
                            </Button>
                        }
                    >
                        <MenuItem
                            label={i18n("auth.app.fleet.vehicle_marketplace.applications.view.change_status.approve")}
                            description={i18n(
                                "auth.app.fleet.vehicle_marketplace.applications.view.change_status.approve_description",
                            )}
                            onClick={openApproveApplicationDialog}
                        />
                        <MenuItem
                            label={i18n("auth.app.fleet.vehicle_marketplace.applications.view.change_status.decline")}
                            description={i18n(
                                "auth.app.fleet.vehicle_marketplace.applications.view.change_status.decline_description",
                            )}
                            onClick={openDeclineApplicationDialog}
                        />
                    </Menu>
                )}
            </div>
            <ApproveApplicationDialog
                isOpen={isApproveApplicationDialogOpen}
                onCancel={closeApproveApplicationDialog}
                onApprove={onApproveApplicationDialogApprove}
            />
            <DeclineApplicationDialog
                isOpen={isDeclineApplicationDialogOpen}
                onCancel={closeDeclineApplicationDialog}
                onDecline={onDeclineApplicationDialogDecline}
            />
        </div>
    );
}
