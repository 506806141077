import {FC} from "react";

export const Marker: FC<{
    /* eslint-disable @bolteu/react-redux/no-unused-prop-types -- Temporarily disabled until further usage */
    lat: number;
    lng: number;
    /* eslint-enable  */
    children: React.ReactNode;
}> = (props) => {
    return <span>{props.children}</span>;
};
