import {useCallback, useContext, useEffect} from "react";
import {useParams} from "react-router-dom";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import LoadingSpinner from "common/components/spinner";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {useReactRouterLinkForBreadcrumb} from "@fleet/common/hooks/useBreadcrumbRenderItem";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {CarApplicationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Breadcrumb} from "@bolteu/kalep-react";

import Application from "./components/Application";
import {useBreadcrumbs} from "./hooks/useBreadcrumbs";

const getVehicleApplicationFetch = (api: Api, query: CarApplicationService.GetCarApplicationRequest) =>
    api.carApplication.v2GetCarApplication(query);

const VehicleApplicationDetailsPage = () => {
    const fleetId = useContext(AccountContextProvider)?.getFleet()?.id;
    const {id: applicationId} = useParams();
    const breadcrumbs = useBreadcrumbs();

    const {status, data, fetch} = useFetch(getVehicleApplicationFetch);

    const loadApplication = useCallback(() => {
        if (!fetch || !applicationId || !fleetId) {
            return;
        }

        fetch({car_application_id: Number(applicationId)});
    }, [fetch, applicationId, fleetId]);

    useEffect(() => {
        loadApplication();
    }, [loadApplication]);

    useEffect(() => {
        if (status === FetchStatus.Success) {
            const viewTitle = `${data.model} (${data.year})`;
            document.title = getDocumentTitle(viewTitle);
        }
    }, [data, status]);

    let Body: React.ReactNode = null;
    switch (status) {
        case FetchStatus.Init:
        case FetchStatus.Loading:
            Body = <LoadingSpinner show showLoadingText parentClassName="mt-9" />;
            break;
        case FetchStatus.Error:
            Body = <ErrorView type={ErrorViewType.NotFound} />;
            break;
        case FetchStatus.Success:
            Body = <Application application={data} reloadApplication={loadApplication} />;
            break;
        default:
            UnreachableCode.never(status);
    }

    return (
        <div className="container mx-auto mt-6 flex w-full flex-col px-6 pt-4">
            <Breadcrumb items={breadcrumbs} renderItem={useReactRouterLinkForBreadcrumb} />
            {Body}
        </div>
    );
};

export default VehicleApplicationDetailsPage;
