import {useCallback} from "react";

import {IconButton} from "@bolteu/kalep-react";
import {Edit} from "@bolteu/kalep-react-icons";

import {TinData} from "./TinInformation";

export interface TaxIdentificationNumberProps {
    onEditClick: (tin: TinData) => void;
    tax: TinData;
}

const TaxIdentificationNumberRowItem = ({tax, onEditClick}: TaxIdentificationNumberProps) => {
    const handleEdit = useCallback(() => onEditClick(tax), [tax, onEditClick]);

    const {countryName, tin} = tax;

    return (
        <div className="my-3 flex h-12 items-center justify-between">
            <div>
                <div className="text-content-secondary text-sm">{countryName}</div>
                <div>{tin}</div>
            </div>
            <div>
                <IconButton icon={<Edit size="sm" />} aria-label="Edit" size="sm" onClick={handleEdit} />
            </div>
        </div>
    );
};

export default TaxIdentificationNumberRowItem;
