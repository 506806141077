import {useCallback, useRef, useState} from "react";

import {DEFAULT_MAP_CONFIG} from "common/geo/components/google-maps/constants";
import {useMapCommon} from "common/geo/components/google-maps/useMapCommon";

import {FleetOwnerPortalService, OrderFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {DriverInfo} from "../../types";
import {useCalculateDriverDirection} from "./useCalculateDriverDirection";
import {useDriverLocationHistory} from "./useDriverLocationHistory";
import useDriverMapMarkers from "./useDriverMapMarkers";
import {useUserMapChange} from "./useUserMapChange";
import {useZoomLevel} from "./useZoomLevel";

export function useMapView(
    drivers: FleetOwnerPortalService.DriverForFleetOwner[],
    selectedDriver: DriverInfo | null,
    previewDriverId: number | null,
    isMobileView: boolean,
    isMapView: boolean,
    lastDropoffAndWaitingTime: OrderFleetService.GetLastDropoffAndWaitingTimeResponse | null,
    isShowDriverRoutesEnabled: boolean,
    driverTrailPeriodMs: number,
    onDriverSelected: (driver: FleetOwnerPortalService.DriverForFleetOwner) => void,
    onDriverPreviewSelected?: (driverId: number | null) => void,
) {
    const [zoom, setZoom] = useState(DEFAULT_MAP_CONFIG.zoom);
    const {googleMapsApi, bounds, setBounds, onGoogleApiLoaded, getBbox} = useMapCommon();

    const automaticZoomLevel = useRef<number>();

    const selectDriverPreview = useCallback(
        (driverId: number | null) => {
            if (onDriverPreviewSelected !== undefined) {
                onDriverPreviewSelected(driverId);
            }
        },
        [onDriverPreviewSelected],
    );

    const {handleUserChangedMap, hasUserRecentlyChangedMap} = useUserMapChange();
    const driverLocationHistory = useDriverLocationHistory(
        drivers,
        selectedDriver,
        isShowDriverRoutesEnabled,
        driverTrailPeriodMs,
    );
    const driverDirections = useCalculateDriverDirection(driverLocationHistory);

    const markers = useDriverMapMarkers(
        drivers,
        driverLocationHistory,
        selectedDriver,
        driverDirections,
        previewDriverId,
        isMobileView,
        lastDropoffAndWaitingTime,
        zoom,
        bounds,
        selectDriverPreview,
        onDriverSelected,
    );

    const zoomLevel = useZoomLevel(
        googleMapsApi,
        drivers,
        hasUserRecentlyChangedMap,
        isMobileView,
        selectedDriver,
        lastDropoffAndWaitingTime,
        isMapView,
        setBounds,
        getBbox,
    );

    const handleZoomEnd = useCallback(
        (zoomValue: number) => {
            setZoom(zoomValue);
            setBounds(getBbox());
            if (zoomValue !== automaticZoomLevel.current) {
                handleUserChangedMap();
            }
        },
        [getBbox, handleUserChangedMap, automaticZoomLevel, setBounds],
    );

    const handleOnDrag = useCallback(() => {
        setBounds(getBbox());
        handleUserChangedMap();
    }, [getBbox, handleUserChangedMap, setBounds]);

    automaticZoomLevel.current = zoomLevel;

    return {
        hasUserRecentlyChangedMap,
        googleMapsApi,
        markers,
        automaticZoomLevel,
        setBounds,
        getBbox,
        onGoogleApiLoaded,
        handleZoomEnd,
        handleOnDrag,
        driverLocationHistory,
    };
}
