import {ApiPeriod, Period} from "common/services/period";
import {getYearMonth} from "common/util/FormatUtil";
import {subMonths} from "date-fns";

import {TabName} from "..";

export function getDefaultPeriod(activeTab: TabName): ApiPeriod {
    const thisMonth = getYearMonth(new Date());
    const previousMonth = getYearMonth(subMonths(new Date(), 1));

    const defaultMonth = activeTab === TabName.ActiveAndUpcoming ? thisMonth : previousMonth;
    return {period: Period.MONTH, month: defaultMonth};
}
