import {useCallback, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import LoadingSpinner from "common/components/spinner";
import {EventName} from "common/constants/events";
import {FetchStatus} from "common/hooks/useFetch";
import {useTracking} from "common/hooks/useTracking";

import LoadMoreTable from "../components/LoadMoreTable";
import NoInvoices from "../components/NoInvoices";
import {TABLE_ROWS_PER_PAGE} from "../contants";
import {useCompensations} from "./hooks/useCompensations";
import {useTableColumns} from "./hooks/useTableColumns";

const CompensationsInvoices = () => {
    const [dataOffset, setDataOffset] = useState<number>(0);
    const {invoices, status} = useCompensations(dataOffset);
    const {trackEvent} = useTracking();

    const columns = useTableColumns();

    const handleLoadMoreClick = useCallback(() => {
        setDataOffset(dataOffset + TABLE_ROWS_PER_PAGE);
        trackEvent(EventName.CompensationLoadMoreClicked);
    }, [dataOffset, trackEvent]);

    if ([FetchStatus.Loading, FetchStatus.Init].includes(status) && dataOffset === 0) {
        return <LoadingSpinner show />;
    }
    if (FetchStatus.Error === status) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }
    if (!invoices.length && dataOffset === 0) {
        return <NoInvoices />;
    }

    return (
        <div className="pt-2">
            <LoadMoreTable
                items={invoices}
                columns={columns}
                aria-label="Compensations invoices"
                hasFilters
                loadMore={handleLoadMoreClick}
                dataFetchStatus={status}
            />
        </div>
    );
};
export default CompensationsInvoices;
