import {FC} from "react";

import {useI18n} from "common/hooks/useI18n";

export interface ExpiresInProps {
    expiresInSeconds: number;
}

const timeLeftInSeconds = (expiresInSeconds: number) => {
    const dateNow = new Date();
    const expiryDate = new Date(expiresInSeconds * 1000);
    return (expiryDate.getTime() - dateNow.getTime()) / 1000;
};

const MINUTE_IN_SECONDS = 60;
const HOUR_IN_SECONDS = 3600;
const DAY_IN_SECONDS = 86_400;

const ExpiresIn: FC<ExpiresInProps> = ({expiresInSeconds}) => {
    const {i18n} = useI18n();

    const timeLeftSec = timeLeftInSeconds(expiresInSeconds);

    if (timeLeftSec >= DAY_IN_SECONDS) {
        return null;
    }

    let timeLeftString: React.ReactNode;
    let classes: string;
    if (timeLeftSec >= HOUR_IN_SECONDS) {
        const hours = Math.round(timeLeftSec / HOUR_IN_SECONDS);
        timeLeftString = i18n("auth.app.fleet.matches.expires_in_h", {hours});
        classes = "text-content-secondary";
    } else {
        const minutes = Math.round(timeLeftSec / MINUTE_IN_SECONDS);
        timeLeftString = i18n("auth.app.fleet.matches.expires_in_m", {minutes});
        classes = "text-danger-primary";
    }

    return <span className={`text-sm ${classes}`}>{timeLeftString}</span>;
};

export default ExpiresIn;
