import {ApiPeriod, apiPeriodToDates} from "common/services/period";

import {TabName} from "..";
import {getDefaultPeriod} from "./getDefaultPeriod";

export function isPeriodDefault(activeTab: TabName, period: ApiPeriod) {
    const defaultPeriod = getDefaultPeriod(activeTab);

    const datesPeriod = apiPeriodToDates(period);
    const defaultDatesPeriod = apiPeriodToDates(defaultPeriod);

    return (
        defaultDatesPeriod.start.getTime() === datesPeriod.start.getTime() &&
        defaultDatesPeriod.end.getTime() === datesPeriod.end.getTime()
    );
}
