import {useCallback} from "react";

import {useI18n} from "common/hooks/useI18n";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {LeadToFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {GhostButton, Tooltip} from "@bolteu/kalep-react";
import {Check, Cross} from "@bolteu/kalep-react-icons";

import ExpiresIn from "./ExpiresIn";

interface BtnProps {
    onClick: () => void;
    label: string;
    isPositive: boolean;
}

const CustomButton = ({onClick, label, isPositive}: BtnProps) => {
    let Icon = <Cross size="sm" className="text-danger-primary" />;
    let bgClass = "bg-danger-secondary";
    if (isPositive) {
        Icon = <Check size="sm" className="text-action-primary" />;
        bgClass = "bg-action-secondary";
    }

    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";
    const widthClass = isMobileView ? "w-20" : "w-15";

    return (
        <Tooltip content={label}>
            <GhostButton
                aria-label={label}
                onClick={onClick}
                overrideClassName={`px-5 rounded !flex justify-center items-center ${bgClass} ${widthClass}`}
            >
                {Icon}
            </GhostButton>
        </Tooltip>
    );
};

interface Props {
    match: LeadToFleetService.ActiveMatch;
    setRejectedMatch: (match: LeadToFleetService.ActiveMatch) => void;
    setAcceptedMatch: (match: LeadToFleetService.ActiveMatch) => void;
}

const Header = ({match, setAcceptedMatch, setRejectedMatch}: Props) => {
    const {i18n} = useI18n();

    const showAcceptDialog = useCallback(() => setAcceptedMatch(match), [match, setAcceptedMatch]);

    const showDeclineDialog = useCallback(() => setRejectedMatch(match), [match, setRejectedMatch]);

    return (
        <div className="flex w-full flex-col gap-3 p-4 md:flex-row md:items-center md:justify-between">
            <div className="flex flex-col">
                <h3 className="text-2xl font-semibold">{match.name}</h3>
                <ExpiresIn expiresInSeconds={match.expires_in_seconds} />
            </div>
            <div className="flex h-9 gap-2">
                <CustomButton onClick={showAcceptDialog} label={i18n("auth.app.fleet.matches.accept")} isPositive />
                <CustomButton
                    onClick={showDeclineDialog}
                    label={i18n("auth.app.fleet.matches.decline")}
                    isPositive={false}
                />
            </div>
        </div>
    );
};

export default Header;
