import {FleetRoute} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";
import {VehicleTabs} from "features/companies/vehicles/list-view";

export function useBreadcrumbs(fleetId?: number, vehicleName?: string, vehicleYear?: number) {
    const {i18n} = useI18n();

    return [
        {
            href: `/${fleetId}/${FleetRoute.VEHICLES}`,
            title: i18n("auth.app.sidebar.vehicles"),
        },
        {
            href: `/${fleetId}/${FleetRoute.VEHICLES}?tab=${VehicleTabs.RentingOut}`,
            title: i18n("auth.app.fleet.vehicles.renting_out"),
        },
        ...(vehicleName && vehicleYear
            ? [
                  {
                      href: "",
                      title: `${vehicleName} ${vehicleYear}`,
                  },
              ]
            : []),
    ];
}
