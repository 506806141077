import {useCallback, useContext} from "react";

import {PhoneNumber} from "common/components/phoneNumber/PhoneNumber";
import {useI18n} from "common/hooks/useI18n";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert, TextArea} from "@bolteu/kalep-react";

import {CreateDrawerType, Step2Data, Step3Data} from "../../../types";
import {AssignmentTypeRadioGroup} from "../../AssignmentTypeRadioGroup";
import {DriverComboBoxForOrderCreationAssignment} from "../DriverComboBoxForOrderCreationAssignment";

interface Props {
    data: Step2Data & Step3Data;
    onChange: (data: Partial<Step3Data>) => void;
    validationErrors?: FleetPortalOrderService.ValidationError[];
    drawerType: CreateDrawerType;
}

export const AssignmentDetails = ({drawerType, data, onChange, validationErrors}: Props) => {
    const {i18n} = useI18n();
    const fleetCountry = useContext(AccountContextProvider).getFleet()?.country;

    const onAssignmentTypeChange = useCallback(
        (assignmentType: FleetPortalOrderService.AssignmentType) => {
            onChange({assignment_type: assignmentType});
        },
        [onChange],
    );

    const onCommentChange = useCallback(
        (event) => {
            const comment = event.target.value;
            onChange({comment: comment || undefined});
        },
        [onChange],
    );

    const onPhoneNumberChange = useCallback(
        (phoneNumber: string) => {
            onChange({phone_number: phoneNumber});
        },
        [onChange],
    );

    const onAreaCodeChange = useCallback(
        (phoneAreaCode: string) => {
            onChange({phone_area_code: phoneAreaCode});
        },
        [onChange],
    );

    const onManualDriverIdChange = useCallback(
        (driverId?: number) => {
            onChange({driver_id: driverId});
        },
        [onChange],
    );

    const phoneError = validationErrors?.find((error) => error.property === "passenger_phone")?.error ?? null;
    const manualAssignmentDriverIdError = validationErrors?.find((error) => error.property === "driver_id")?.error;
    const isScheduledOrder = drawerType === CreateDrawerType.SCHEDULE;

    return (
        <div className="flex flex-col gap-4 pt-4">
            <AssignmentTypeRadioGroup value={data.assignment_type} onChange={onAssignmentTypeChange} />
            {data.assignment_type === FleetPortalOrderService.AssignmentType.MANUAL && (
                <>
                    <DriverComboBoxForOrderCreationAssignment
                        paymentMethod={data.payment_method}
                        categoryId={data.category_id}
                        selectedDriverId={data.driver_id}
                        onChange={onManualDriverIdChange}
                        validationError={manualAssignmentDriverIdError}
                    />
                    {isScheduledOrder && (
                        <Alert severity="info">{i18n("auth.app.orders.scheduled_rides.scheduled-ride-info")}</Alert>
                    )}
                </>
            )}
            {!isScheduledOrder && (
                <Alert severity="info">{i18n("auth.app.orders.scheduled_rides.create-dialog.instant-ride-info")}</Alert>
            )}
            <div className="mt-6">
                <PhoneNumber
                    phoneNumber={data.phone_number}
                    onAreaCodeChange={onAreaCodeChange}
                    onPhoneNumberChange={onPhoneNumberChange}
                    errorMessage={phoneError}
                    defaultAreaCodeCountry={fleetCountry}
                />
            </div>
            <TextArea
                label={i18n("auth.app.orders.scheduled_rides.comment-label")}
                placeholder={i18n("auth.app.orders.scheduled_rides.comment-placeholder")}
                value={data.comment ?? undefined}
                onChange={onCommentChange}
                fullWidth
                maxLength={128}
            />
        </div>
    );
};
