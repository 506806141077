import {useMemo} from "react";

import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, Period} from "common/services/period";
import {getIsoDate} from "common/util/FormatUtil";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

interface DownloadBody {
    format: FleetOwnerPortalService.FileFormat;
}

export function useVehiclesDownloadApi(
    period: ApiPeriod,
): (api: Api, {format}: DownloadBody) => Promise<FleetOwnerPortalService.GetCarsDownloadResponse> {
    return useMemo(() => {
        const dates =
            period.period === Period.CUSTOM ? {start: period.startDate, end: period.endDate} : apiPeriodToDates(period);
        return (api: Api, {format}: DownloadBody) => {
            return api.fleetOwnerPortal.getCarsDownload({
                start_date: getIsoDate(dates.start),
                end_date: getIsoDate(dates.end),
                format,
            });
        };
    }, [period]);
}
