import ProfileField from "common/components/ProfileField";
import {useI18n} from "common/hooks/useI18n";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";

export function DriverDetails({
    application,
}: {
    application: VehicleMarketplaceService.ApplicationWithPartnerAndListingDetails;
}) {
    const {i18n} = useI18n();
    return (
        <div className="flex flex-col gap-10">
            <div className="flex flex-col gap-1">
                <Typography variant="body-l-accent">
                    {i18n("auth.app.fleet.vehicle_marketplace.applications.view.contact_info")}
                </Typography>
                <div className="flex flex-col">
                    <ProfileField
                        title={i18n("auth.app.fleet.vehicle_marketplace.applications.view.first_name")}
                        value={application.first_name}
                    />
                    <ProfileField
                        title={i18n("auth.app.fleet.vehicle_marketplace.applications.view.last_name")}
                        value={application.last_name}
                    />
                    <ProfileField
                        title={i18n("auth.app.fleet.vehicle_marketplace.applications.view.email")}
                        value={application.email}
                    />
                    <ProfileField
                        title={i18n("auth.app.fleet.vehicle_marketplace.applications.view.phone_number")}
                        value={application.phone}
                    />
                </div>
            </div>
            <div className="flex flex-col gap-1">
                <Typography variant="body-l-accent">
                    {i18n("auth.app.fleet.vehicle_marketplace.applications.view.additional_info")}
                </Typography>
                <div className="flex flex-col">
                    <div className="flex flex-col gap-1">
                        {application.applicant_terms &&
                            Object.entries(application.applicant_terms).map(([key, value]) => (
                                <ProfileField title={key} value={value} />
                            ))}
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-5">
                {application.applicant_requirement_answers && (
                    <>
                        <Typography variant="body-l-accent">
                            {i18n("auth.app.fleet.vehicle_marketplace.applications.view.additional_questions")}
                        </Typography>
                        {Object.entries(application.applicant_requirement_answers).map(([question, answer]) => (
                            <AdditionalQuestion question={question} answer={answer} />
                        ))}
                    </>
                )}
            </div>
        </div>
    );
}

interface AdditionalQuestionProps {
    question: string;
    answer: string;
}
function AdditionalQuestion({question, answer}: AdditionalQuestionProps) {
    return (
        <div className="flex flex-col gap-1">
            <Typography variant="body-secondary">{question}</Typography>
            <Typography variant="body-m-regular">{answer}</Typography>
        </div>
    );
}
