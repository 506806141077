import {useEffect, useMemo, useRef, useState} from "react";

import {Marker} from "common/geo/components/google-maps/Marker";
import {BBox} from "common/geo/components/google-maps/types";
import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService, OrderFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Tooltip} from "@bolteu/kalep-react";

import {DriverInfo} from "../../types";
import {DriverMapMarker} from "../marker/DriverMapMarker";
import {LocationMapMarker} from "../marker/LocationMapMarker";
import {DriverLocationHistoryItem} from "./useDriverLocationHistory";

export default function useDriverMapMarkers(
    drivers: FleetOwnerPortalService.DriverForFleetOwner[],
    locationHistory: DriverLocationHistoryItem[],
    selectedDriver: DriverInfo | null,
    driverDirections: Map<number, number>,
    previewDriverId: number | null | undefined,
    isMobileView: boolean,
    lastDropoffAndWaitingTime: OrderFleetService.GetLastDropoffAndWaitingTimeResponse | null,
    zoom: number,
    bounds: BBox,
    selectDriverPreview: (driverId: number | null) => void,
    onDriverSelected: (driver: FleetOwnerPortalService.DriverForFleetOwner) => void,
) {
    const isDriverWaiting = selectedDriver?.driver.state === FleetOwnerPortalService.DriverState.WAITING_FOR_ORDERS;
    const dropOffLocationDivRef = useRef<HTMLDivElement | null>(null);
    const [isLastDropoffTooltipOpen, setIsLastDropoffTooltipOpen] = useState(false);
    const {i18n} = useI18n();

    useEffect(() => {
        let timeOutId: ReturnType<typeof setTimeout>;
        if (lastDropoffAndWaitingTime?.last_dropoff && isDriverWaiting) {
            timeOutId = setTimeout(() => {
                setIsLastDropoffTooltipOpen(true);
            }, 700);
        }

        return () => {
            setIsLastDropoffTooltipOpen(false);
            clearTimeout(timeOutId);
        };
    }, [lastDropoffAndWaitingTime, isDriverWaiting, zoom, bounds]);

    const markers = useMemo(() => {
        const driversArray = selectedDriver ? [selectedDriver.driver] : drivers;
        const markersList = driversArray.map((driver) => {
            const driverLocation = locationHistory.filter((location) => location.driverId === driver.id).pop();
            return (
                <Marker
                    key={driver.id}
                    lat={driverLocation?.point.lat || driver.lat}
                    lng={driverLocation?.point.lng || driver.lng}
                >
                    <DriverMapMarker
                        driver={driver}
                        selectDriver={onDriverSelected}
                        selectDriverPreview={selectDriverPreview}
                        isSelected={selectedDriver?.driver.id === driver.id}
                        isSelectedForPreview={previewDriverId === driver.id}
                        isMobileView={isMobileView}
                        driverDirection={driverDirections.get(driver.id)}
                    />
                </Marker>
            );
        });

        if (lastDropoffAndWaitingTime?.last_dropoff && isDriverWaiting) {
            markersList.push(
                <Marker
                    key={`${selectedDriver?.driver.order_id}-last-dropoff`}
                    lat={lastDropoffAndWaitingTime.last_dropoff?.last_dropoff_lat || 0}
                    lng={lastDropoffAndWaitingTime.last_dropoff?.last_dropoff_lng || 0}
                >
                    <Tooltip
                        content={i18n("auth.app.fleet.live-map.last-order.last-dropoff")}
                        isOpen={isLastDropoffTooltipOpen}
                        boundaryElement={dropOffLocationDivRef.current ? dropOffLocationDivRef.current : undefined}
                    >
                        <div ref={dropOffLocationDivRef} className="align-center flex flex-col p-3">
                            <LocationMapMarker type="last-dropoff" />
                        </div>
                    </Tooltip>
                </Marker>,
            );
        }

        if (selectedDriver?.order) {
            markersList.push(
                <Marker
                    key={`${selectedDriver.driver.order_id}-start`}
                    lat={selectedDriver.order.lat}
                    lng={selectedDriver.order.lng}
                >
                    <LocationMapMarker type="start" />
                </Marker>,
            );

            if (selectedDriver.order.destination_lat && selectedDriver.order.destination_lng) {
                markersList.push(
                    <Marker
                        key={`${selectedDriver.driver.order_id}-destination`}
                        lat={selectedDriver.order.destination_lat}
                        lng={selectedDriver.order.destination_lng}
                    >
                        <LocationMapMarker type="destination" />
                    </Marker>,
                );
            }
        }
        return markersList;
        // eslint-disable-next-line react-hooks/exhaustive-deps -- zoom and bounds, get markers update on map movement
    }, [
        drivers,
        selectedDriver,
        onDriverSelected,
        selectDriverPreview,
        previewDriverId,
        isMobileView,
        zoom,
        bounds,
        isLastDropoffTooltipOpen,
    ]);

    return markers;
}
