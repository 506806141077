import {useCallback, useEffect, useState} from "react";

import {Api} from "common/services/apiProvider";

import BaseDisplayField from "./BaseDisplayField";
import BaseEditDisplayField from "./BaseEditDisplayField";

type DisplayFieldProps<T extends string> = DisplayFieldPropsNonEditable | DisplayFieldPropsEditable<T>;

interface DisplayFieldPropsNonEditable {
    isEditable?: false;
    title: string;
    value: string | null;
    fieldName?: never;
    updateFetchFunction?: never;
}

interface DisplayFieldPropsEditable<T extends string> {
    isEditable: true;
    title: string;
    value: string | null;
    fieldName: T;
    updateFetchFunction: (api: Api, value: Record<T, string>) => Promise<void>;
}

const DisplayField = <T extends string>({
    isEditable,
    title,
    value,
    fieldName,
    updateFetchFunction,
}: DisplayFieldProps<T>) => {
    const [isEditing, setIsEditing] = useState(false);
    const [displayedValue, setDisplayedValue] = useState(value || "");

    const onEditIconClick = useCallback(() => {
        setIsEditing(true);
    }, []);

    const closeEditing = useCallback(() => {
        setIsEditing(false);
    }, []);

    const updateDisplayedValue = useCallback((newValue: string) => {
        setDisplayedValue(newValue);
    }, []);

    useEffect(() => {
        updateDisplayedValue(value || "");
    }, [updateDisplayedValue, value]);

    if (!isEditable) {
        return <BaseDisplayField title={title} value={value} />;
    }

    if (isEditing) {
        return (
            <BaseEditDisplayField
                title={title}
                displayedValue={displayedValue}
                updateDisplayedValue={updateDisplayedValue}
                fieldName={fieldName}
                updateFetchFunction={updateFetchFunction}
                closeEditing={closeEditing}
            />
        );
    }

    return <BaseDisplayField title={title} value={displayedValue} isEditable onEditIconClick={onEditIconClick} />;
};

export default DisplayField;
