import {useCallback, useEffect, useState} from "react";

import {MapHelper} from "common/geo/components/google-maps/MapHelper";
import {BBox, BoundsPadding, GoogleMapApi, Point} from "common/geo/components/google-maps/types";

import {FleetOwnerPortalService, OrderFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {DriverInfo} from "../../types";

export function useZoomLevel(
    googleMapsApi: GoogleMapApi | undefined,
    drivers: FleetOwnerPortalService.DriverForFleetOwner[],
    hasUserRecentlyChangedMap: boolean,
    isMobileView: boolean,
    selectedDriver: DriverInfo | null,
    lastDropoffAndWaitingTime: OrderFleetService.GetLastDropoffAndWaitingTimeResponse | null,
    isMapView: boolean,
    setBounds: (bounds: BBox) => void,
    getBbox: () => BBox,
) {
    const [zoomLevel, setZoomLevel] = useState<number | undefined>();

    const setDriversIntoView = useCallback(() => {
        if (googleMapsApi && drivers.length && (!hasUserRecentlyChangedMap || (isMobileView && selectedDriver))) {
            let points: Point[] = selectedDriver && !isMobileView ? [selectedDriver.driver] : [...drivers];
            if (isMobileView && isMapView && selectedDriver) {
                points = [selectedDriver.driver];
            }
            let mapPadding: BoundsPadding = {left: 500, top: 75, bottom: 75, right: 60};
            if (isMobileView) {
                mapPadding = 10;
            }
            if (selectedDriver?.order) {
                points.push({lat: selectedDriver.order.lat, lng: selectedDriver.order.lng});
                if (selectedDriver.order.destination_lat && selectedDriver.order.destination_lng) {
                    points.push({
                        lat: selectedDriver.order.destination_lat,
                        lng: selectedDriver.order.destination_lng,
                    });
                }
            }

            if (selectedDriver && lastDropoffAndWaitingTime?.last_dropoff) {
                points.push({
                    lat: lastDropoffAndWaitingTime.last_dropoff.last_dropoff_lat || 0,
                    lng: lastDropoffAndWaitingTime.last_dropoff.last_dropoff_lng || 0,
                });
            }

            MapHelper.fitBounds(googleMapsApi, points, mapPadding, !isMobileView);
            setZoomLevel(MapHelper.getZoom(googleMapsApi));
            setBounds(getBbox());
        }
    }, [
        googleMapsApi,
        drivers,
        hasUserRecentlyChangedMap,
        isMobileView,
        selectedDriver,
        isMapView,
        setBounds,
        getBbox,
        lastDropoffAndWaitingTime,
    ]);

    useEffect(() => {
        setDriversIntoView();
    }, [setDriversIntoView]);

    return zoomLevel;
}
