import {useCallback, useState} from "react";

import {ALL_DRIVERS_OPTION_VALUE} from "common/components/DriverFilter";
import {ApiPeriod, OngoingDay, Period} from "common/services/period";

import {SelectOption} from "@bolteu/kalep-react";

import {OrderHistorySearch} from "./components/OrderHistorySearch";
import {defaultOrdersFilters, OrderFilters} from "./components/OrdersFilter";
import OrdersTable from "./components/OrdersTable";

const defaultPeriod: ApiPeriod = {period: Period.ONGOING_DAY} as OngoingDay;

const OrderHistory = () => {
    const [selectedPeriod, setSelectedPeriod] = useState<ApiPeriod>(defaultPeriod);
    const [isInvalidPeriod, setIsInvalidPeriod] = useState<boolean>(false);
    const [orderFilters, setOrderFilters] = useState<OrderFilters>(defaultOrdersFilters);

    const onPeriodErrorStateChange = useCallback((hasError: boolean) => {
        setIsInvalidPeriod(hasError);
    }, []);

    const onPeriodChange = useCallback(
        (newPeriod: ApiPeriod) => {
            setSelectedPeriod(newPeriod);
        },
        [setSelectedPeriod],
    );

    const [selectedDriver, setDriver] = useState<SelectOption | null>(null);

    const onDriverChange = useCallback(
        (newDriver: SelectOption | null) => {
            setDriver(newDriver);
            if (newDriver?.value === ALL_DRIVERS_OPTION_VALUE) {
                setSelectedPeriod(defaultPeriod);
            }
        },
        [setDriver, setSelectedPeriod],
    );

    return (
        <div className="h-full w-full">
            <div className="container mx-auto mb-14 flex flex-col gap-y-6">
                <OrderHistorySearch
                    isInvalidPeriod={isInvalidPeriod}
                    onPeriodChange={onPeriodChange}
                    onPeriodErrorStateChange={onPeriodErrorStateChange}
                    selectedDriver={selectedDriver}
                    selectedPeriod={selectedPeriod}
                    setDriver={onDriverChange}
                    filters={orderFilters}
                    setFilters={setOrderFilters}
                />
                <OrdersTable selectedPeriod={selectedPeriod} selectedDriver={selectedDriver} filters={orderFilters} />
            </div>
        </div>
    );
};

export default OrderHistory;
