import {useCallback, useContext} from "react";

import {useI18n} from "common/hooks/useI18n";
import {ScheduledRidesContextProvider} from "features/companies/orders/ScheduledRidesContextProvider";

import {Switch, TextField, Typography} from "@bolteu/kalep-react";

interface Props {
    isManualPriceEnabled: boolean;
    onManualPriceSwitchChange: () => void;
    manualPrice: string;
    onManualPriceChange: (value: string) => void;
    validationError?: string;
}

export const ManualPrice = ({
    isManualPriceEnabled,
    onManualPriceChange,
    onManualPriceSwitchChange,
    manualPrice,
    validationError,
}: Props) => {
    const {i18n} = useI18n();
    const currency = useContext(ScheduledRidesContextProvider).getCurrency();

    const onManualPriceChangeWrapper = useCallback(
        (event) => {
            onManualPriceChange(event.target.value);
        },
        [onManualPriceChange],
    );

    const manualPriceInputOpacity = isManualPriceEnabled ? "opacity-100" : "opacity-0";

    return (
        <div className="flex flex-col gap-2">
            <div className="mt-8 flex items-center justify-between gap-4">
                <div className="flex items-center gap-4">
                    <Switch checked={isManualPriceEnabled} onChange={onManualPriceSwitchChange} />
                    <div className="flex flex-col">
                        <div className="text-sm font-semibold">
                            {`${i18n("auth.app.orders.scheduled_rides.create-dialog.set-custom-price")} `}
                        </div>
                        <Typography color="secondary" fontSize="text-sm">
                            {i18n("auth.app.orders.scheduled_rides.optional")}
                        </Typography>
                    </div>
                </div>
                <div className={`max-w-[160px] ${manualPriceInputOpacity}`}>
                    <TextField
                        value={manualPrice}
                        onChange={onManualPriceChangeWrapper}
                        type="number"
                        prefix={currency}
                        fullWidth
                        error={Boolean(validationError)}
                    />
                </div>
            </div>
            {validationError && (
                <Typography color="danger-primary" fontSize="text-sm">
                    {validationError}
                </Typography>
            )}
        </div>
    );
};
