import {useCallback, useState} from "react";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import VehicleDataRow from "./VehicleDataRow";

export type VisibleVehicleField = keyof Omit<
    FleetOwnerPortalService.GetCarResponse,
    "editable_fields" | "status" | "id" | "phone" | "has_card_payment" | "car_transport_licence_expires"
>;

export interface EditFieldState {
    fieldName?: VisibleVehicleField;
    isShown: boolean;
}

interface Props {
    vehicle: FleetOwnerPortalService.GetCarResponse;
}

// The order the fields are shown
const fieldsToBeShown: VisibleVehicleField[] = [
    "model",
    "year",
    "reg_number",
    "car_transport_licence_number",
    "color",
    "seats",
    "uuid",
];

const VehicleData = ({vehicle}: Props) => {
    const [editField, setEditField] = useState<VisibleVehicleField | null>(null);

    const clearEditField = useCallback(() => setEditField(null), []);

    return (
        <div className="flex w-full max-w-[480px] flex-col">
            {fieldsToBeShown.map((fieldName) => (
                <VehicleDataRow
                    key={fieldName}
                    fieldName={fieldName}
                    vehicle={vehicle}
                    editingField={editField}
                    startEditing={setEditField}
                    stopEditing={clearEditField}
                />
            ))}
        </div>
    );
};

export default VehicleData;
