import React, {useCallback} from "react";

import {twMerge} from "tailwind-merge";
import {useId} from "@react-aria/utils";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Checkbox} from "@bolteu/kalep-react";

import {Hint} from "./Hint";
import {UseFormValueType} from "./types";

interface RadioProps {
    useFormValue: UseFormValueType;
    fieldConfig: FleetOwnerRegistrationService.CheckBoxGroupField;
    error?: string;
}

const CheckboxGroup = ({useFormValue, fieldConfig, error}: RadioProps) => {
    const {setData, useFieldValue} = useFormValue;
    const cbGroup = useFieldValue(fieldConfig.name) as string[] | undefined;

    const {name, label, hint, required: isRequired, options, disabled: isDisabled} = fieldConfig;
    const id = useId();

    const onChange = useCallback(
        (option: FleetOwnerRegistrationService.Option) => (checked: React.ChangeEvent<HTMLInputElement>) => {
            if (cbGroup) {
                const newValues = checked.target.checked
                    ? [...cbGroup, option.value]
                    : cbGroup.filter((value) => value !== option.value);
                setData({[name]: newValues});
            } else {
                setData({[name]: [option.value]});
            }
        },
        [setData, cbGroup, name],
    );

    return (
        <div>
            <label
                htmlFor={id}
                className={twMerge(
                    "w-fit bolt-font-body-s-accent",
                    isDisabled ? " text-tertiary" : "text-primary",
                    isRequired && "after:text-danger-primary after:content-['_*']",
                )}
            >
                {label}
            </label>
            <div className="mt-2 mb-4">
                <Hint hint={hint} />
            </div>
            <ul className="list-none" id={id}>
                {options.map((option) => {
                    return (
                        <li key={option.value} className="pb-6 last:pb-0">
                            <Checkbox
                                name={name as never}
                                label={option.label}
                                disabled={isDisabled}
                                onChange={onChange(option)}
                                checked={cbGroup?.includes(option.value)}
                            />
                        </li>
                    );
                })}
            </ul>
            <div className={twMerge("mt-2 font-sans text-sm", error && "text-danger-primary")}>{error}</div>
        </div>
    );
};

export {CheckboxGroup};
