import {useCallback, useContext, useEffect, useState} from "react";

import {FullScreenLoadingSpinner} from "common/components/spinner";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import NoAuthLayout from "features/account/components/NoAuthLayout";
import CaptchaWrapper from "features/account/components/Verification/captcha/CaptchaWrapper";
import CodeInput from "features/account/components/Verification/CodeInput";

import {FleetOwnerVerificationNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Alert, Button} from "@bolteu/kalep-react";

import {VerificationContextProvider, VerificationProvider} from "./VerificationProvider";

import VerificationChannel = FleetOwnerVerificationNoAuthService.VerificationChannel;
import CaptchaChallengeType = FleetOwnerVerificationNoAuthService.CaptchaChallengeType;

const VerificationPage = () => {
    const {i18n} = useI18n();
    const [shouldCallApiOnRender, setShouldCallApiOnRender] = useState(true);
    const {requestCode, onPageLoad, captchaData, requestCodeError, isLoading, otpData} =
        useContext(VerificationContextProvider);

    useEffect(() => {
        const pageTitle = i18n("verification.page_title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    useEffect(() => {
        // TOOD https://taxify.atlassian.net/browse/DRX-12170 if complete, try to improve to not have shouldCallApiOnRender state
        // Not sure if can be improved as provider constantly changes even empty dependency array useEffect will rerender
        if (shouldCallApiOnRender && !captchaData) {
            // Should be called on page load only
            onPageLoad();
        }
        setShouldCallApiOnRender(false);
    }, [shouldCallApiOnRender, captchaData, onPageLoad]);

    const handleTryAgain = useCallback(() => {
        requestCode({channel: VerificationChannel.SMS});
    }, [requestCode]);

    const shouldShowCodeInput = !requestCodeError && otpData;
    const shouldShowCaptcha = !requestCodeError && captchaData;
    const shouldShowLoading =
        !requestCodeError && !otpData && (isLoading || captchaData?.type === CaptchaChallengeType.RECAPTCHA_NATIVE);

    let Content;
    const Spinner = shouldShowLoading ? <FullScreenLoadingSpinner /> : null;
    if (requestCodeError) {
        Content = (
            <>
                <Alert severity="error">
                    <p className="font-semibold">{requestCodeError}</p>
                </Alert>
                <Button onClick={handleTryAgain}>{i18n("auth.app.error-view.something-went-wrong.action_text")}</Button>
            </>
        );
    } else if (shouldShowCodeInput) {
        Content = <CodeInput otpData={otpData} />;
    } else if (shouldShowCaptcha) {
        Content = (
            <>
                <CaptchaWrapper key={captchaData.siteKey} captchaData={captchaData} /> {Spinner}
            </>
        );
    } else if (Spinner) {
        return Spinner;
    }

    return <NoAuthLayout gap="large">{Content}</NoAuthLayout>;
};

const VerificationPageWithProviders = () => (
    <VerificationProvider>
        <VerificationPage />
    </VerificationProvider>
);
export default VerificationPageWithProviders;
