import {DragEvent, RefObject, useCallback, useState} from "react";

import {useI18n} from "common/hooks/useI18n";

interface FileDragAndDropParams {
    setErrorMessage: (msg: string) => void;
    checkAndUploadFile: (file: File) => void;
    formOverlayRef: RefObject<HTMLDivElement>;
}

export function useFileDragAndDrop({setErrorMessage, checkAndUploadFile, formOverlayRef}: FileDragAndDropParams) {
    const {i18n} = useI18n();
    const [isFileOverForm, setIsFileOverForm] = useState(false);

    const handleDrop = useCallback(
        (event: DragEvent<HTMLElement>) => {
            event.preventDefault();
            setIsFileOverForm(false);
            const {dataTransfer} = event;
            if (!dataTransfer.types.length || dataTransfer.types[0] !== "Files") {
                return;
            }
            if (dataTransfer.files.length > 1) {
                setErrorMessage(i18n("auth.app.fleet.car-rentals.upload_file.file_error.multiple_files"));
                return;
            }

            checkAndUploadFile(dataTransfer.files[0]);
            dataTransfer.clearData();
        },
        [checkAndUploadFile, i18n, setErrorMessage],
    );

    const handleDragOver = useCallback((event: DragEvent<HTMLElement>) => {
        event.preventDefault();
    }, []);

    const handleDragEnter = useCallback(
        (event: DragEvent<HTMLElement>) => {
            event.preventDefault();
            const {dataTransfer} = event;
            if (!dataTransfer.types.length || dataTransfer.types[0] !== "Files") {
                return;
            }
            // Should only set when drag entering the form, not the overlay on top of the form
            if (event.target !== formOverlayRef.current) {
                setIsFileOverForm(true);
                setErrorMessage("");
            }
        },
        [formOverlayRef, setErrorMessage],
    );

    const handleDragLeave = useCallback(
        (event: DragEvent<HTMLElement>) => {
            event.preventDefault();
            // should only set when drag leaving the overlay which is shown on top of the form
            if (event.target === formOverlayRef.current) {
                setIsFileOverForm(false);
            }
        },
        [formOverlayRef],
    );

    return {handleDrop, handleDragOver, handleDragEnter, handleDragLeave, isFileOverForm};
}
