export class CommonLocalStorageService {
    private keyPrefix: string;

    constructor(keyPrefixToUse: string) {
        this.keyPrefix = keyPrefixToUse;
    }

    public getItemKey(key: string) {
        return `${this.keyPrefix}${key}`;
    }

    // Arrow function is used to avoid binding issues in BrowserInfo
    public setItem = (key: string, value: string) => localStorage.setItem(this.getItemKey(key), value);

    // Arrow function is used to avoid binding issues in BrowserInfo
    public getItem = (key: string) => localStorage.getItem(this.getItemKey(key));

    public removeItem(key: string) {
        return localStorage.removeItem(this.getItemKey(key));
    }

    public removeItems(keys: string[]) {
        return keys.forEach((key) => localStorage.removeItem(this.getItemKey(key)));
    }

    public clear() {
        localStorage.clear();
    }
}
