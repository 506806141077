import {createContext, useCallback, useContext, useEffect, useState} from "react";

import {ApiContextProvider} from "common/services/apiProvider";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";

type ConfigEnabled = Omit<FleetPortalOrderService.PortalScheduledRidesConfigEnabled, "is_enabled"> & {is_enabled: true};
type ConfigDisabled = Omit<FleetPortalOrderService.PortalScheduledRidesConfigDisabled, "is_enabled"> & {
    is_enabled: false;
};

export type ScheduledRidesConfig = ConfigEnabled | ConfigDisabled;
export type ScheduledRidesContext = {
    isEnabled: boolean;
    isInstantRidesEnabled: boolean;
    isOrderCreationEnabled: boolean;
    isDropoffAddressRequired: boolean;
    setIsDropoffAddressRequired: (value: boolean) => void;
    getCurrency: () => string;
    getOrderCreationPaymentMethods: () => FleetPortalOrderService.CreateOrderPaymentMethod[];
    getManualPricing: () => FleetPortalOrderService.ManualPricing;
    getSchedulingLimits: () => FleetPortalOrderService.SchedulingLimits;
};

// Default are just for the initial state, should be overwritten by the actual values from API
const defaultConfig: ScheduledRidesConfig = {is_enabled: false};
const defaultManualPricing: FleetPortalOrderService.ManualPricing = {
    is_enabled: false,
    max_base_price_multiplier: 1,
    min_base_price_multiplier: 1,
};
const defaultSchedulingLimits: FleetPortalOrderService.SchedulingLimits = {
    maximum_date_in_future_days: 90,
    minimum_time_in_future_minutes: 30,
};

const ScheduledRidesContextProvider = createContext<ScheduledRidesContext>({
    isEnabled: false,
    isInstantRidesEnabled: false,
    isOrderCreationEnabled: false,
    getCurrency: () => "",
    getOrderCreationPaymentMethods: () => [],
    getManualPricing: () => defaultManualPricing,
    getSchedulingLimits: () => defaultSchedulingLimits,
    isDropoffAddressRequired: true,
    setIsDropoffAddressRequired: () => {},
});
ScheduledRidesContextProvider.displayName = "ScheduledRidesContextProvider";

const ScheduledRidesProvider = ({children}: {children: React.ReactNode}) => {
    const [config, setConfig] = useState<ScheduledRidesConfig>(defaultConfig);
    const [isDropoffAddressRequired, setIsDropoffAddressRequired] = useState(true);
    const api = useContext(ApiContextProvider);
    const fleet = useContext(AccountContextProvider).getFleet();

    const fetchConfig = useCallback(async () => {
        setConfig(defaultConfig);
        if (!api || !fleet) {
            return;
        }
        try {
            const response = await api.fleetPortalOrder.getScheduledRidesConfig();
            if (response.is_enabled === FleetPortalOrderService.Enabled.TRUE) {
                setConfig({
                    is_enabled: true,
                    is_order_creation_enabled: response.is_order_creation_enabled,
                    is_instant_ride_creation_enabled: response.is_instant_ride_creation_enabled,
                    manual_pricing: response.manual_pricing,
                    create_order_payment_methods: response.create_order_payment_methods,
                    currency: response.currency,
                    scheduling_limits: response.scheduling_limits,
                });
            }
        } catch (e) {
            // Ignore as state is defaultConfig
        }
    }, [api, fleet]);

    useEffect(() => {
        fetchConfig();
    }, [fetchConfig]);

    const getOrderCreationPaymentMethods = useCallback((): FleetPortalOrderService.CreateOrderPaymentMethod[] => {
        return config.is_enabled ? config.create_order_payment_methods : [];
    }, [config]);

    const getCurrency = useCallback((): string => {
        return config.is_enabled ? config.currency : "";
    }, [config]);

    const getManualPricing = useCallback((): FleetPortalOrderService.ManualPricing => {
        return config.is_enabled ? config.manual_pricing : defaultManualPricing;
    }, [config]);

    const getSchedulingLimits = useCallback((): FleetPortalOrderService.SchedulingLimits => {
        return config.is_enabled ? config.scheduling_limits : defaultSchedulingLimits;
    }, [config]);

    const contextValue: ScheduledRidesContext = {
        isEnabled: config.is_enabled,
        isInstantRidesEnabled: config.is_enabled ? config.is_instant_ride_creation_enabled : false,
        isOrderCreationEnabled: config.is_enabled ? config.is_order_creation_enabled : false,
        getCurrency,
        getOrderCreationPaymentMethods,
        getManualPricing,
        getSchedulingLimits,
        isDropoffAddressRequired,
        setIsDropoffAddressRequired,
    };

    return (
        <ScheduledRidesContextProvider.Provider value={contextValue}>{children}</ScheduledRidesContextProvider.Provider>
    );
};

export {ScheduledRidesProvider, ScheduledRidesContextProvider};
