import {FC} from "react";

import {ResponsiveDrawer} from "common/components/drawer/ResponsiveDrawer";
import {useI18n} from "common/hooks/useI18n";
import {formatContentHtml} from "common/util/formatContentHtml";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, Drawer, Link, Typography} from "@bolteu/kalep-react";
import GetRewardsDetailsResponse = FleetOwnerPortalService.GetRewardsDetailsResponse;
import RewardsBlockFooter = FleetOwnerPortalService.RewardsBlockFooter;

interface GlossaryProps {
    isOpen: boolean;
    handleClose: () => void;
    content: GetRewardsDetailsResponse;
}

export interface GlossaryItemProps {
    title: string;
    description?: string;
    footer?: RewardsBlockFooter;
    header_items?: string[];
    items: [string, string][];
}

const GlossaryItem = ({title, description, footer, header_items, items}: GlossaryItemProps) => {
    const getAlignmentClass = (colIndex: number): string => {
        if (colIndex === 0) {
            return "flex-1 text-left";
        }
        return "flex-1 text-right font-semibold";
    };

    return (
        <div className="mt-6 flex flex-col gap-2">
            <Typography fontSize="text-lg" fontWeight="semibold">
                {title}
            </Typography>
            {description && <div className="my-3">{formatContentHtml(description)}</div>}

            {header_items && (
                <>
                    <div className="flex justify-between gap-4 font-semibold">
                        {header_items.map((header, index) => (
                            <div className={getAlignmentClass(index)}>{header}</div>
                        ))}
                    </div>
                    <div className="border-separator my-1 h-0 border-b-[1px]" />
                </>
            )}

            {items && (
                <div className="flex flex-col gap-1">
                    {items.map((row) => (
                        <>
                            <div className="flex py-2">
                                {row.map((col, colIndex) => (
                                    <div className={getAlignmentClass(colIndex)}>{col}</div>
                                ))}
                            </div>
                            <div className="border-separator my-1 h-0 border-b-[1px]" />
                        </>
                    ))}
                </div>
            )}

            {footer && (
                <Link target="_blank" href={footer.link}>
                    {footer.title}
                </Link>
            )}
        </div>
    );
};

const RewardsOverviewGlossary: FC<GlossaryProps> = ({isOpen, handleClose, content}) => {
    const {i18n} = useI18n();

    return (
        <ResponsiveDrawer isOpen={isOpen} title={content.details_title} onRequestClose={handleClose}>
            <Drawer.Content>
                <div className="mt-3 flex flex-col gap-6">{formatContentHtml(content.details_description)}</div>

                <div className="flex flex-col gap-6">
                    {content.blocks.map((block) => (
                        <GlossaryItem
                            title={block.title}
                            description={block.description}
                            footer={block.footer}
                            header_items={block.header_items}
                            items={(block.items as [string, string][]) || []}
                        />
                    ))}
                </div>

                <div className="my-10 flex flex-col gap-6">
                    <Link target="_blank" href={content.details_footer.link}>
                        {content.details_footer.title}
                    </Link>
                </div>
            </Drawer.Content>

            <Drawer.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {i18n("common.close")}
                </Button>
            </Drawer.Footer>
        </ResponsiveDrawer>
    );
};

export {RewardsOverviewGlossary};
