import {FC, useCallback, useState} from "react";

import CodeIsland from "common/components/CodeIsland";
import {ResponsiveDrawer} from "common/components/drawer/ResponsiveDrawer";
import {useI18n} from "common/hooks/useI18n";
import Markdown, {MarkdownToJSX} from "markdown-to-jsx";

import {Button, Drawer, GhostButton, Typography} from "@bolteu/kalep-react";

import styles from "./style.module.css";

interface GlossarySectionProps {
    content: string;
}

const GlossarySection: FC<GlossarySectionProps> = ({content}) => {
    const {i18n} = useI18n();

    const [isGlossaryOpen, setIsGlossaryOpen] = useState(false);

    const handleGlossaryOpen = useCallback(() => {
        setIsGlossaryOpen(true);
    }, []);

    const handleGlossaryClose = useCallback(() => {
        setIsGlossaryOpen(false);
    }, []);

    const glossaryLinkText = i18n("auth.app.fleet.campaigns.details.participants.glossary.link_text");
    const glossaryLinkButton = (
        <GhostButton onClick={handleGlossaryOpen}>
            <span className="text-action-primary">{glossaryLinkText}</span>
        </GhostButton>
    );
    const glossaryText = i18n("auth.app.fleet.campaigns.details.participants.glossary.text", {
        glossary_link: glossaryLinkButton,
    });

    const glossaryTitle = i18n("common.glossary");
    const glossaryCloseText = i18n("common.close");

    const markdownOptions: MarkdownToJSX.Options = {
        overrides: {
            h1: {
                component: ({children}) => <Typography variant="title-secondary">{children}</Typography>,
            },
            p: {
                component: ({children}) => <Typography variant="body-primary">{children}</Typography>,
            },
            ul: {
                props: {
                    className: "list-disc pl-6",
                },
            },
            li: {
                component: ({children}) => (
                    <li>
                        <Typography variant="body-primary">{children}</Typography>
                    </li>
                ),
            },
            code: {
                component: ({children}) => <CodeIsland code={children as string} />,
            },
        },
    };

    return (
        <div>
            <p>{glossaryText}</p>
            <ResponsiveDrawer
                isOpen={isGlossaryOpen}
                title={glossaryTitle}
                size="lg"
                onRequestClose={handleGlossaryClose}
            >
                <Drawer.Content>
                    <div className={styles.glossary}>
                        <Markdown options={markdownOptions}>{content}</Markdown>
                    </div>
                </Drawer.Content>
                <Drawer.Footer>
                    <Button variant="secondary" onClick={handleGlossaryClose}>
                        {glossaryCloseText}
                    </Button>
                </Drawer.Footer>
            </ResponsiveDrawer>
        </div>
    );
};

export default GlossarySection;
