import {useIntl} from "react-intl";

import {formatTransactionNumber} from "../../utils/formatTransactionNumber";

interface TransactionsListRowProps {
    name: string;
    value: number;
    currencySymbol: string;
}

const TransactionsListRow = ({name, value, currencySymbol}: TransactionsListRowProps) => {
    const intl = useIntl();

    const formattedValue = formatTransactionNumber(intl, value);

    return (
        <div className="flex justify-between p-3">
            <span>{name}</span>
            <div>
                <span className="pr-[2px]">{formattedValue}</span>
                <span className="text-content-secondary text-xs">{currencySymbol}</span>
            </div>
        </div>
    );
};

export default TransactionsListRow;
