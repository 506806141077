import {FC} from "react";

import FixedTooltip from "common/components/fixedTooltip/FixedTooltip";
import {useI18n} from "common/hooks/useI18n";

const DriverRewardsTooltip: FC<{linkTo?: string}> = ({linkTo}) => {
    const {i18n} = useI18n();

    return (
        <FixedTooltip
            title={i18n("auth.app.fleet.driver.driver_loyalty_tooltip.title")}
            content={i18n("auth.app.fleet.driver.driver_loyalty_tooltip.content")}
            buttonTitle={i18n("auth.app.fleet.driver.driver_loyalty_tooltip.button.title")}
            targetId="tier-tooltip"
            featureId="drivers-table"
            linkTo={linkTo}
        />
    );
};

export default DriverRewardsTooltip;
