import {FC} from "react";

import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button} from "@bolteu/kalep-react";
import {Edit} from "@bolteu/kalep-react-icons";

import DriverProfileField from "./DriverProfileField";

interface DriverFormOfWorkDetailsProps {
    formOfWork: FleetOwnerPortalService.FormOfWork | null;
    contractNumber: string | null;
    editDetails: () => void;
}

const DriverFormOfWorkDetails: FC<DriverFormOfWorkDetailsProps> = ({formOfWork, contractNumber, editDetails}) => {
    const {i18n} = useI18n();

    if (formOfWork) {
        let details = i18n(`auth.app.fleet.driver-profile.form_of_work_details.${formOfWork}`);
        if (contractNumber) {
            details += ` / ${contractNumber}`;
        }
        return (
            <DriverProfileField
                title={i18n("auth.app.fleet.driver-profile.form_of_work_details.title")}
                value={details}
                fieldAction={{action: editDetails, icon: <Edit />, label: i18n("common.edit")}}
            />
        );
    }
    return (
        <div className="flex flex-row justify-between py-4">
            <Button variant="secondary" onClick={editDetails}>
                {i18n("auth.app.fleet.driver-profile.form_of_work_details.add_details")}
            </Button>
        </div>
    );
};

export default DriverFormOfWorkDetails;
