import {useEffect} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import LoadingSpinner from "common/components/spinner";
import {FetchStatus} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";

import MainContent from "./components/MainContent";
import {useInvoices} from "./hooks/useInvoices";

const HoldingFleetInvoices = () => {
    const {i18n} = useI18n();
    const {invoices, fetchStatus} = useInvoices();

    useEffect(() => {
        const pageTitle = i18n("common.invoices");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    let Content: JSX.Element | null = null;
    switch (fetchStatus) {
        case FetchStatus.Init:
        case FetchStatus.Loading:
            Content = <LoadingSpinner show parentClassName="pt-12 w-full" />;
            break;
        case FetchStatus.Success:
            Content = <MainContent items={invoices} />;
            break;
        case FetchStatus.Error:
            Content = <ErrorView type={ErrorViewType.SomethingWentWrong} />;
            break;
        default:
    }

    return (
        <Page>
            <ResponsiveHeader type={ResponsiveHeaderType.MainPage} text={i18n("common.invoices")} />
            <p className="mt-4 mb-10 min-w-[450px] text-base text-neutral-700 lg:max-w-[550px] xl:max-w-[760px]">
                {i18n("auth.app.invoices.holding-fleet.invoices_table.description")}
            </p>
            <div className="mb-8 flex w-full flex-wrap justify-between gap-x-5 gap-y-8">{Content}</div>
        </Page>
    );
};

export default HoldingFleetInvoices;
