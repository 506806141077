import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";

import {getStyledKalepComponent} from ".";

interface Props {
    className?: string;
    compact?: boolean;
    children: React.ReactNode;
}

export function AccordionTableCellContent(props: Props) {
    const StyledCellContent = getStyledKalepComponent(DefaultCellContent, "text-base text-primary");
    return <StyledCellContent {...props} />;
}
