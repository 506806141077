import {FC} from "react";

import {Alert as AlertIcon, Check} from "@bolteu/kalep-react-icons";

interface ScoreSectionProps {
    label: string;
    unit?: string;
    current: number;
    required: number;
}

const ScoreSection: FC<ScoreSectionProps> = ({label, unit, current, required}) => {
    const isWarning = current >= required ? "" : "text-danger-primary";

    return (
        <div className="flex flex-col">
            <p className="text-secondary text-sm">{`${label} ${unit ? `(${unit})` : ""}`}</p>
            <p>
                <span className={`font-semibold ${isWarning}`}>{current}</span>
                <span className="text-secondary">/{required}</span>
                {current >= required ? (
                    <Check size="sm" className="text-action-secondary ml-2" />
                ) : (
                    <AlertIcon size="sm" className="text-danger-primary ml-2" />
                )}
            </p>
        </div>
    );
};

export default ScoreSection;
