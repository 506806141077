import {useI18n} from "common/hooks/useI18n";

import {Button, Dialog} from "@bolteu/kalep-react";

interface Props {
    isOpen: boolean;
    onCancel: () => void;
    onApprove: () => void;
}

export default function ApproveApplicationDialog({isOpen, onCancel, onApprove}: Props) {
    const {i18n} = useI18n();
    return (
        <Dialog
            title={i18n("auth.app.fleet.vehicle_marketplace.applications.view.dialog.approve.title")}
            isOpen={isOpen}
            onRequestClose={onCancel}
        >
            <Dialog.Content>
                <div className="text-base text-neutral-700">
                    {i18n("auth.app.fleet.vehicle_marketplace.applications.view.dialog.approve.description")}
                </div>
            </Dialog.Content>
            <Dialog.Footer>
                <div className="flex flex-wrap items-center justify-end gap-4">
                    <Button variant="secondary" onClick={onCancel}>
                        {i18n("auth.app.fleet.vehicle_marketplace.applications.view.dialog.cancel")}
                    </Button>
                    <Button onClick={onApprove} variant="primary">
                        {i18n("auth.app.fleet.vehicle_marketplace.applications.view.dialog.approve.approve_request")}
                    </Button>
                </div>
            </Dialog.Footer>
        </Dialog>
    );
}
