import {FC, useCallback, useRef} from "react";

import {SelectPeriodOption} from "common/hooks/usePeriodSelectorOptions";
import {ApiPeriod} from "common/services/period";

import CustomPeriodPicker, {AllowedDatePeriod} from "./CustomPeriodPicker";
import PeriodSelect from "./PeriodSelect";

interface PeriodDatePickerProps {
    period: ApiPeriod;
    selectPeriodOptions: SelectPeriodOption[];
    onPeriodChange: (apiPeriod: ApiPeriod) => void;
    dateRangeLimitDays: number;
    onErrorStateChange?: (hasError: boolean) => void;
    allowedDatePeriod?: AllowedDatePeriod;
    allowedDateIntervalMonths?: number;
    allowedDateIntervalDays?: number;
}
/*
 *  This component combines the PeriodSelect and CustomDatePicker components.
 */
const PeriodDatePicker: FC<PeriodDatePickerProps> = ({
    period,
    selectPeriodOptions,
    onPeriodChange,
    dateRangeLimitDays,
    onErrorStateChange,
    allowedDatePeriod,
    allowedDateIntervalMonths,
    allowedDateIntervalDays,
}) => {
    const dateWrapperRef = useRef<HTMLDivElement>(null);

    const onPeriodSelect = useCallback(
        (periodValue: ApiPeriod | undefined) => {
            /**
             * A hackish workaround to trigger Datepicker when "Custom" is chosen in the period select.
             * TODO https://taxify.atlassian.net/browse/KALEP-237 can open datepicker programatically
             */
            if (periodValue === undefined) {
                if (dateWrapperRef.current) {
                    const datePickerInput = dateWrapperRef.current.querySelector("input");
                    if (datePickerInput) {
                        datePickerInput.click();
                    }
                }
            } else {
                onPeriodChange(periodValue);
            }
        },
        [onPeriodChange],
    );

    return (
        <div className="flex items-start gap-2 sm:gap-4">
            <PeriodSelect
                period={period}
                selectPeriodOptions={selectPeriodOptions}
                onSelectionChange={onPeriodSelect}
            />
            <div ref={dateWrapperRef}>
                <CustomPeriodPicker
                    period={period}
                    onDatesChange={onPeriodChange}
                    dateRangeLimitDays={dateRangeLimitDays}
                    onErrorStateChange={onErrorStateChange}
                    allowedDatePeriod={allowedDatePeriod}
                    allowedDateIntervalMonths={allowedDateIntervalMonths}
                    allowedDateIntervalDays={allowedDateIntervalDays}
                />
            </div>
        </div>
    );
};

export {PeriodDatePicker, PeriodSelect};
