import {FC} from "react";

import {useI18n} from "common/hooks/useI18n";
import {TranslationKeys} from "config/translations";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Island} from "@bolteu/kalep-react";
import {InfoCircle} from "@bolteu/kalep-react-icons";

const UNKNOWN_ENTITY_INACTIVE_DATE = "2000-01-01";

interface DeactivatedEntityIslandProps {
    deactivationDetails: FleetOwnerPortalService.DeactivationDetails | null;
    title_key: TranslationKeys;
    deactivated_by_fleet_text_key: TranslationKeys;
    deactivated_by_bolt_text_key: TranslationKeys;
    deactivated_by_bolt_date_unknown_text_key: TranslationKeys;
}

const DeactivatedEntityIsland: FC<DeactivatedEntityIslandProps> = ({
    deactivationDetails,
    title_key,
    deactivated_by_fleet_text_key,
    deactivated_by_bolt_text_key,
    deactivated_by_bolt_date_unknown_text_key,
}) => {
    const {i18n} = useI18n();

    if (!deactivationDetails) {
        return null;
    }

    const titleText = i18n(title_key);

    let deactivatedTextKey = deactivated_by_fleet_text_key;

    if (!deactivationDetails.is_deactivated_by_fleet_owner) {
        const isDateUnknown =
            !deactivationDetails.deactivated_since ||
            new Date(deactivationDetails.deactivated_since) <= new Date(UNKNOWN_ENTITY_INACTIVE_DATE);
        if (isDateUnknown) {
            deactivatedTextKey = deactivated_by_bolt_date_unknown_text_key;
        } else {
            deactivatedTextKey = deactivated_by_bolt_text_key;
        }
    }
    const deactivatedByText = i18n(deactivatedTextKey, {
        deactivated_on_date: deactivationDetails.deactivated_since,
    });

    return (
        <Island padding={4}>
            <div className="flex gap-4">
                <InfoCircle />
                <div className="flex flex-col gap-2">
                    <p className="text-sm font-semibold">{titleText}</p>
                    <p className="text-secondary text-sm">{deactivatedByText}</p>
                </div>
            </div>
        </Island>
    );
};

export default DeactivatedEntityIsland;
