import {useCallback, useContext, useEffect} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {ScheduledRidesContextProvider} from "features/companies/orders/ScheduledRidesContextProvider";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {useCreateOrderValidations} from "../../../hooks/useCreateOrderValidations";
import {CreateDrawerType, Step1Data, Step2Data} from "../../../types";
import {getApiAddressFromAddress} from "../../../util";
import {Categories} from "../categories";
import {ManualPrice} from "../ManualPrice";
import {PaymentMethods} from "../PaymentMethods";

interface Props {
    data: Step1Data & Step2Data;
    onChange: (data: Partial<Step2Data>) => void;
    validationErrors?: FleetPortalOrderService.ValidationError[];
    drawerType: CreateDrawerType;
}

const getCategoriesFunction = (api: Api, body: FleetPortalOrderService.GetCategoriesForOrderCreationRequest) =>
    api.fleetPortalOrder.getCategoriesForOrderCreation(body);

export const RideDetails = ({drawerType, data, onChange, validationErrors}: Props) => {
    const scheduledRidesContext = useContext(ScheduledRidesContextProvider);
    const {fetch: getCategoriesFetch, status: getCategoriesStatus, data: categories} = useFetch(getCategoriesFunction);

    const {validateManualPricing} = useCreateOrderValidations(drawerType);

    const getCategories = useCallback(() => {
        if (getCategoriesFetch) {
            const hasNoDropoffs = data.dropoffs.every((dropoff) => !dropoff.address_name);
            const dropoffs: FleetPortalOrderService.Address[] | undefined = hasNoDropoffs
                ? undefined
                : data.dropoffs.map((dropoff) => getApiAddressFromAddress(dropoff));
            getCategoriesFetch({
                payment_method: data.payment_method,
                pickup: getApiAddressFromAddress(data.pickup),
                dropoffs: dropoffs || [],
            });
        }
    }, [data.dropoffs, data.payment_method, data.pickup, getCategoriesFetch]);

    useEffect(() => {
        getCategories();
    }, [getCategories]);

    useEffect(() => {
        if (data.category_id && categories) {
            const selectedCategory = categories.list.find((category) => category.id === data.category_id);
            if (!selectedCategory) {
                onChange({category_id: undefined, category_price: undefined});
            }
        }
    }, [categories, data.category_id, onChange]);

    const onPaymentMethodChange = useCallback(
        (newPaymentMethod: FleetPortalOrderService.CreateOrderPaymentMethod) => {
            onChange({payment_method: newPaymentMethod});
        },
        [onChange],
    );

    const onCategoryChange = useCallback(
        (category: {id?: number; price?: string}) => {
            onChange({
                category_id: category.id,
                category_price: category.price,
            });
        },
        [onChange],
    );

    const onManualPriceChange = useCallback(
        (price: string) => {
            onChange({manual_price: price});
        },
        [onChange],
    );

    const onManualPriceSwitchChange = useCallback(() => {
        onChange({is_manual_price_enabled: !data.is_manual_price_enabled});
    }, [data.is_manual_price_enabled, onChange]);

    const isLoadingCategories = [FetchStatus.Init, FetchStatus.Loading].includes(getCategoriesStatus);

    const manualPrice = data.manual_price !== undefined ? data.manual_price : data.category_price ?? "";
    const isManualPriceEnabledAndEntered = data.is_manual_price_enabled && data.manual_price !== undefined;
    const categoryError = validationErrors?.find((error) => error.property === "category_id")?.error;
    const manualPriceError =
        validationErrors?.find((error) => error.property === "manual_price")?.error ??
        validateManualPricing(data.is_manual_price_enabled, data.manual_price, data.category_price)?.error;

    return (
        <div className="flex flex-col gap-4">
            <PaymentMethods selectedPaymentMethod={data.payment_method} onChange={onPaymentMethodChange} />
            <Categories
                selectedCategoryId={data.category_id}
                onChange={onCategoryChange}
                categories={categories?.list ?? []}
                isLoading={isLoadingCategories}
                categoryError={categoryError}
                isManualPriceEnabledAndEntered={isManualPriceEnabledAndEntered}
            />
            {!!scheduledRidesContext.getManualPricing().is_enabled && (
                <ManualPrice
                    isManualPriceEnabled={data.is_manual_price_enabled}
                    onManualPriceChange={onManualPriceChange}
                    onManualPriceSwitchChange={onManualPriceSwitchChange}
                    manualPrice={manualPrice}
                    validationError={manualPriceError}
                />
            )}
        </div>
    );
};
