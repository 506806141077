import {FC, useCallback} from "react";

import {useI18n} from "common/hooks/useI18n";

import {Button, Dialog, Typography} from "@bolteu/kalep-react";

export interface ConfirmationDialogProps {
    show: boolean;
    close: () => void;
    confirm: () => void;
    isExitingDialog: boolean;
    closeAndExit: () => void;
}

const ConfirmationDialog: FC<ConfirmationDialogProps> = ({show, isExitingDialog, close, closeAndExit, confirm}) => {
    const {i18n} = useI18n();

    const description = isExitingDialog
        ? i18n("auth.app.fleet.shifts.assign_shift.confirm_dialog.description_exit")
        : i18n("auth.app.fleet.shifts.assign_shift.confirm_dialog.description");

    const cancel = isExitingDialog
        ? i18n("auth.app.fleet.shifts.assign_shift.confirm_dialog.exit")
        : i18n("auth.app.fleet.shifts.assign_shift.confirm_dialog.cancel");

    const closeDialog = useCallback(() => {
        if (isExitingDialog) {
            closeAndExit();
        } else {
            close();
        }
    }, [close, closeAndExit, isExitingDialog]);

    return (
        <Dialog
            title={i18n("auth.app.fleet.shifts.assign_shift.confirm_dialog.title")}
            isOpen={show}
            onRequestClose={close}
            variant="alert"
        >
            <Dialog.Content>
                <div className="mx-5">
                    <Typography align="center">{description}</Typography>
                </div>
            </Dialog.Content>
            <Dialog.Footer>
                <Button variant="primary" onClick={confirm} fullWidth overrideClassName="sm:max-w-[192px]">
                    {i18n("auth.app.fleet.shifts.assign_shift.confirm_dialog.confirm")}
                </Button>
                <Button variant="secondary" onClick={closeDialog} fullWidth overrideClassName="sm:max-w-[192px]">
                    {cancel}
                </Button>
            </Dialog.Footer>
        </Dialog>
    );
};

export default ConfirmationDialog;
