import {FC} from "react";
import {useIntl} from "react-intl";

import {Tooltip} from "@bolteu/kalep-react";

import {formatTransactionNumber} from "../../utils/formatTransactionNumber";

interface TransactionCardGraphSectionProps {
    name: string;
    value: number;
    colorClass: string;
    width: number;
    isMobileView: boolean;
    currencySymbol: string;
}

const TransactionCardGraphSection: FC<TransactionCardGraphSectionProps> = ({
    name,
    value,
    colorClass,
    width,
    isMobileView,
    currencySymbol,
}) => {
    const intl = useIntl();

    const content = <div key={name} style={{flex: width}} className={`${colorClass} h-2 min-w-[8px]`} />;

    if (isMobileView) {
        return content;
    }

    const formattedValue = formatTransactionNumber(intl, value);
    const tooltipValue = `${name} ${formattedValue}${currencySymbol}`;

    return <Tooltip content={tooltipValue}>{content}</Tooltip>;
};

export default TransactionCardGraphSection;
