import {FC, useContext, useEffect} from "react";

import {MobileSectionSeparator} from "common/components/mobileSectionSeparator/MobileSectionSeparator";
import {CountryCode} from "common/geo/constants/countries";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates} from "common/services/period";
import {getIsoDate} from "common/util/FormatUtil";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal/types/latest/src/service/FleetOwnerPortalService";

import {AccountContextProvider} from "../../../account/accountStateProvider";
import InformationBlock from "./InformationBlock";
import TransactionsCard from "./TransactionsCard/TransactionsCard";
import TransactionsHeader from "./TransactionsHeader";
import TransactionsList from "./TransactionsList/TransactionsList";

const fetchFunction = (api: Api, query: FleetOwnerPortalService.DatePeriodRequest) =>
    api.fleetOwnerPortal.getFleetBalanceDetails(query);

interface TransactionsProps {
    period: ApiPeriod;
    onPeriodChange: (newPeriod: ApiPeriod) => void;
}

const Transactions: FC<TransactionsProps> = ({period, onPeriodChange}) => {
    const accountState = useContext(AccountContextProvider);
    const fleet = accountState.getFleet();

    const {fetch, data, status} = useFetch(fetchFunction);

    /*
    Info block with the taxable revenue includes a list of earnings (dynamic by country and selected period).
     */
    const isInformationBlockShown =
        fleet?.country && [CountryCode.Germany, CountryCode.Belgium].includes(fleet.country);

    useEffect(() => {
        if (fetch) {
            const dates = apiPeriodToDates(period);
            fetch({
                start_date: getIsoDate(dates.start),
                end_date: getIsoDate(dates.end),
            });
        }
    }, [fetch, period]);

    const isLoading = status !== FetchStatus.Success;

    return (
        <div className="flex flex-col">
            <div className="py-5">
                <TransactionsHeader period={period} onPeriodChange={onPeriodChange} />
            </div>
            <div className="flex flex-col gap-x-10 md:flex-row">
                <div className="mb-4 pt-2 md:mb-0 md:w-2/3">
                    <TransactionsList period={period} data={data} isLoading={isLoading} />
                    {isInformationBlockShown && <InformationBlock />}
                </div>
                <MobileSectionSeparator />
                <div className="mt-1 pt-4 md:mt-0 md:w-1/3">
                    <TransactionsCard period={period} data={data} isLoading={isLoading} />
                </div>
            </div>
        </div>
    );
};

export default Transactions;
