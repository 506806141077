import {createContext, useState} from "react";

export interface VerificationAuthState {
    isAuthInitDone: boolean;
    setIsAuthInitDone: (isAuthInitDone: boolean) => void;
}

const defaultState: VerificationAuthState = {
    isAuthInitDone: false,
    setIsAuthInitDone: () => {},
};

export const VerificationAuthContextProvider = createContext<VerificationAuthState>(defaultState);
VerificationAuthContextProvider.displayName = "VerificationAuthContextProvider";

export const VerificationAuthProvider = ({children}: {children: React.ReactNode}) => {
    const [isAuthInitDone, setIsAuthInitDone] = useState<boolean>(false);

    return (
        <VerificationAuthContextProvider.Provider
            value={{
                isAuthInitDone,
                setIsAuthInitDone,
            }}
        >
            {children}
        </VerificationAuthContextProvider.Provider>
    );
};
