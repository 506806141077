import {Typography} from "@bolteu/kalep-react";

interface Props {
    value: string;
    isSubCell?: boolean;
}

export const StringCell = ({value, isSubCell}: Props) => {
    const fontSize = isSubCell ? "text-xs" : "text-sm";
    const color = isSubCell ? "secondary" : undefined;
    return (
        <Typography variant="body-tabular-m-regular" fontSize={fontSize} color={color}>
            {value}
        </Typography>
    );
};
