import {useCallback, useEffect, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {ApiPeriod, apiPeriodToDates, OngoingDay, Period} from "common/services/period";
import {getIsoDate} from "common/util/FormatUtil";

import {DriverSessionHistoryService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import ActivityLogTable from "./components/ActivityLogTable";

import GetSessionsRequest = DriverSessionHistoryService.GetSessionsRequest;

const MAX_PAGE_SIZE = 50;

const getSessionsFetchFunction = (api: Api, body: GetSessionsRequest) => api.driverSessionHistory.getSessions(body);

const ActivityLog = () => {
    const [pageNum, setPageNum] = useState(0);
    const [driverSearchValue, setDriverSearchValue] = useState<number[]>([]);
    const [selectedPeriod, setSelectedPeriod] = useState<ApiPeriod>({period: Period.ONGOING_DAY} as OngoingDay);

    const {
        data: sessionColumns,
        fetch: sessionColumnsFetch,
        status: getSessionColumnsStatus,
    } = useFetch(getSessionsFetchFunction);

    const totalCount = sessionColumns?.total_rows ?? 0;

    const isLoading = [FetchStatus.Init, FetchStatus.Loading].includes(getSessionColumnsStatus);

    const getRequestDates = useCallback((period: ApiPeriod) => {
        return period.period === Period.CUSTOM
            ? {start: period.startDate, end: period.endDate}
            : apiPeriodToDates(period);
    }, []);

    const getSessionsData = useCallback(() => {
        if (sessionColumnsFetch) {
            const {start, end} = getRequestDates(selectedPeriod);
            const offset = pageNum * MAX_PAGE_SIZE;
            sessionColumnsFetch({
                offset,
                limit: MAX_PAGE_SIZE,
                start_date: getIsoDate(start),
                end_date: getIsoDate(end),
                ...(driverSearchValue && {driver_ids: driverSearchValue}),
            });
        }
    }, [sessionColumnsFetch, getRequestDates, selectedPeriod, pageNum, driverSearchValue]);

    useEffect(() => {
        getSessionsData();
    }, [getSessionsData]);

    if (getSessionColumnsStatus === FetchStatus.Error) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }

    return (
        <ActivityLogTable
            columnsResponse={sessionColumns}
            totalCount={totalCount}
            currentPage={pageNum}
            pageSize={MAX_PAGE_SIZE}
            onPageChange={setPageNum}
            onSearchChange={setDriverSearchValue}
            onPeriodChange={setSelectedPeriod}
            selectedDrivers={driverSearchValue}
            selectedPeriod={selectedPeriod}
            isLoading={isLoading}
        />
    );
};

export default ActivityLog;
