import {TableItem, TableProps} from "@bolteu/kalep-table-react";
import {TableColumnHeader, TableHeaderColumnKeys} from "@bolteu/kalep-table-react/build/types";

import {FixedHeaderTable} from "../styledComponent/FixedHeaderTable";
import {useShownColumns} from "./hooks/useShownColumns";

export interface TableColumnSettings {
    [key: string]: boolean;
}

export interface CommonCustomizableTableHeaderProps {
    /**
     * If present and set to true, the column is hidden by default if no other settings were applied
     */
    hideByDefault?: boolean;
    /**
     * If present and set to true, the column can't be managed in settings and is always shown
     */
    nonConfigurable?: boolean;
}

export interface CustomizableTableHeader<Entity>
    extends TableColumnHeader<Entity>,
        CommonCustomizableTableHeaderProps {}

export type CustomizableTableHeaders<Entity> = {
    [ColumnKey in TableHeaderColumnKeys<Entity>]?: CustomizableTableHeader<Entity>;
};

export interface CustomizableTableProps<Entity extends TableItem> extends TableProps<Entity> {
    id: string;
    columns: CustomizableTableHeaders<Entity>;
}

export default function TableWithCustomizableColumns<Entity extends TableItem>({
    id,
    ...props
}: CustomizableTableProps<Entity>) {
    const {columns} = props;
    const shownColumns = useShownColumns(id, columns);

    return <FixedHeaderTable {...props} columns={shownColumns} />;
}
