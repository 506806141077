import {FleetRoute} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";

export function useBreadcrumbs(listingId?: number, listingCarName?: string, partnerName?: string) {
    const {i18n} = useI18n();

    if (!listingId || !listingCarName || !partnerName) {
        return [];
    }

    return [
        {
            href: `../../../${FleetRoute.VEHICLES}`,
            title: i18n("auth.app.sidebar.vehicles"),
        },
        {
            href: `../../../${FleetRoute.VEHICLES}?tab=renting_out`,
            title: i18n("auth.app.fleet.vehicle_marketplace.applications.view.listed_breadcrumb"),
        },
        {
            href: `../details/${listingId}?tab=rental_requests`,
            title: listingCarName,
        },
        {
            href: "",
            title: partnerName,
        },
    ];
}
