import {Tab} from "common/components/tab/useTabs";
import {TabNameEvent} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";

import {DriverTabs} from "..";

export function useTabSelectorOptions(
    isLoading: boolean,
    driverCount?: number,
    driverRegistrationCount?: number,
): Tab<DriverTabs>[] {
    const {i18n} = useI18n();

    if (isLoading) {
        return [];
    }

    return [
        {
            id: DriverTabs.Drivers,
            title: `${i18n("auth.app.fleet.engagement-dashboard.fleet_drivers")}${
                driverCount !== undefined ? `: ${driverCount}` : ""
            }`,
            trackingEventTabName: TabNameEvent.DriversPageDashboard,
        },
        {
            id: DriverTabs.Registrations,
            title: `${i18n("auth.app.fleet.engagement-dashboard.applications")}${
                driverCount !== undefined ? `: ${driverRegistrationCount}` : ""
            }`,
            trackingEventTabName: TabNameEvent.VehiclesPageApplications,
        },
    ];
}
