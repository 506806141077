import {FC} from "react";

import Markdown from "markdown-to-jsx";

import {Link} from "@bolteu/kalep-react";

import styles from "./style.module.css";

interface BlockTranslationMarkdownOverrideProps {
    translation: string;
}

const BlockTranslationMarkdownOverride: FC<BlockTranslationMarkdownOverrideProps> = ({translation}) => {
    return (
        <div>
            <span>&quot;</span>
            <span className={styles.block}>
                <Markdown
                    options={{
                        overrides: {
                            a: {
                                component: (props: {children: React.ReactNode; href: string}) => (
                                    <Link href={props.href}>{props.children}</Link>
                                ),
                            },
                            p: {
                                component: ({children}) => <span>{children}</span>,
                            },
                        },
                        forceInline: true,
                    }}
                >
                    {translation}
                </Markdown>
            </span>
            <span>&quot;</span>
        </div>
    );
};

export default BlockTranslationMarkdownOverride;
