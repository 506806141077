import CopyIconButton from "common/components/copyIconButton/CopyIconButton";

import {IconButton} from "@bolteu/kalep-react";
import {Edit} from "@bolteu/kalep-react-icons";

import {VehicleDataRowValues} from "../hooks/useVehicleDataRow";

interface Props {
    fieldData: VehicleDataRowValues;
    onEditClick: () => void;
}

const DataRow = ({fieldData, onEditClick}: Props) => {
    return (
        <>
            <div>
                <h4 className="text-neutral-700">{fieldData.title}</h4>
                <p className="">{fieldData.value}</p>
                {fieldData.description && (
                    <p className={`mt-2 text-sm text-neutral-700 ${fieldData.descriptionClasses}`}>
                        {fieldData.description}
                    </p>
                )}
            </div>
            {fieldData.editable && <IconButton aria-label="Edit" icon={<Edit />} size="sm" onClick={onEditClick} />}
            {fieldData.isCopyable && <CopyIconButton value={fieldData.value} />}
        </>
    );
};

export default DataRow;
