import {ApiPeriod, apiPeriodToDates, datesToUnixTimestamps} from "common/services/period";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import GetCampaignListRequest = FleetOwnerPortalService.GetCampaignListRequest;
import GetCampaignListCSVRequest = FleetOwnerPortalService.GetCampaignListCSVRequest;
import CampaignFilters = FleetOwnerPortalService.CampaignFilters;

export function mapBaseListRequest(
    locale: string,
    period: ApiPeriod,
    filters: CampaignFilters,
): GetCampaignListRequest | GetCampaignListCSVRequest {
    const datesPeriod = apiPeriodToDates(period);
    const unixPeriod = datesToUnixTimestamps(datesPeriod.start, datesPeriod.end);

    return {
        language: locale,
        period: unixPeriod,
        filters: {
            ...filters,
            // In UI it's "Hide (special location)", in API it's "Show"
            polygon_location: filters.polygon_location ? !filters.polygon_location : undefined,
        },
    };
}
