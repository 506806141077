import Icon from "assets/icons/support.svg?react";
import {useI18n} from "common/hooks/useI18n";

import {Island, Link, Typography} from "@bolteu/kalep-react";

const FleetFeedback = ({fleetSupportLink}: {fleetSupportLink: string | undefined}) => {
    const {i18n} = useI18n();

    if (!fleetSupportLink) {
        return <></>;
    }

    return (
        <Island className="mt-6 max-w-3xl">
            <div className="flex flex-row justify-between">
                <div className="flex flex-col gap-2">
                    <Typography variant="body-m-accent" fontWeight="semibold">
                        {i18n("auth.app.fleet.vehicle_marketplace.listings.add.feedback.title")}
                    </Typography>
                    <Typography variant="body-s-regular" color="secondary">
                        {i18n("auth.app.fleet.vehicle_marketplace.listings.add.feedback.description", {
                            feedback_link: (
                                <Link href={fleetSupportLink} target="_blank">
                                    {i18n("auth.app.fleet.vehicle_marketplace.listings.add.feedback.action")}
                                </Link>
                            ),
                        })}
                    </Typography>
                </div>
                <div className="flex items-center justify-items-center">
                    <Icon />
                </div>
            </div>
        </Island>
    );
};

export default FleetFeedback;
