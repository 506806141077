import {useI18n} from "common/hooks/useI18n";

import {Link} from "@bolteu/kalep-react";

import {getVehicleMarketplaceListingUrl} from "../../utils/getVehicleMarketplaceListingUrl";

interface Props {
    listingId: number;
    city: string;
}

const ViewListingInVMLink = ({listingId, city}: Props) => {
    const {i18n} = useI18n();

    return (
        <Link href={getVehicleMarketplaceListingUrl(city, listingId)} target="_blank" overrideClassName="font-semibold">
            {i18n("auth.app.fleet.vehicle_marketplace.listings.view.view_listing_in_vm_link_text")}
        </Link>
    );
};

export default ViewListingInVMLink;
