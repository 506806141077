import {FC, useCallback} from "react";

import {useI18n} from "common/hooks/useI18n";
import Markdown from "markdown-to-jsx";

import {Button, Chip} from "@bolteu/kalep-react";
import {Alert, Upload} from "@bolteu/kalep-react-icons";

import {DocumentRowAction, useDocumentRow} from "./useDocumentRow";
import {DocumentData, DocumentType} from "./useDocumentsList";

interface Props {
    document: DocumentData;
    isUploadAllowed: boolean;
    onUploadClick: (documentType?: DocumentType) => void;
}

export const DocumentRow: FC<Props> = ({document, isUploadAllowed, onUploadClick}) => {
    const {i18n} = useI18n();

    const handleUploadClick = useCallback(() => {
        onUploadClick(document.type);
    }, [document.type, onUploadClick]);

    const {shouldShowAlertIcon, action, subText, isSubTextRedColor, shouldShowDescription} = useDocumentRow({document});

    return (
        <div className="my-1">
            <div className="flex items-center justify-between space-x-3">
                {shouldShowAlertIcon && <Alert className="text-red-500" />}
                <div className="flex-1">
                    <p>{document.type.title}</p>
                    <p className={`text-sm ${isSubTextRedColor ? "text-red-500" : "text-secondary"}`}>{subText}</p>
                </div>
                <div>
                    {isUploadAllowed && action === DocumentRowAction.UploadButton && (
                        <Button
                            size="sm"
                            variant="secondary"
                            overrideClassName="w-[36px] h-[36px]"
                            onClick={handleUploadClick}
                        >
                            <Upload size="sm" className="absolute" />
                        </Button>
                    )}
                    {action === DocumentRowAction.InReviewChip && (
                        <Chip color="promo" label={i18n("auth.app.fleet.driver-profile.documents.row.in_review")} />
                    )}
                </div>
            </div>
            {shouldShowDescription && (
                <div className="text-secondary mt-2 whitespace-pre-wrap break-words text-sm">
                    <Markdown
                        options={{
                            overrides: {
                                a: {
                                    props: {
                                        className: "text-link-primary",
                                    },
                                },
                                p: {
                                    props: {
                                        className: "mb-4",
                                    },
                                },
                            },
                        }}
                    >
                        {document.type.description}
                    </Markdown>
                </div>
            )}
        </div>
    );
};
