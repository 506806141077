import {FC, useCallback, useState} from "react";

import {useI18n} from "common/hooks/useI18n";

import {Button} from "@bolteu/kalep-react";

import {TaxIdentificationNumberEditRowItem} from "./TaxIdentificationNumberEditRowItem";
import TaxIdentificationNumberRowItem from "./TaxIdentificationNumberRowItem";
import TinFaqLink from "./TinFaqLink";
import {TinData} from "./TinInformation";

export interface TinInlineEditProps {
    countryOptions: Array<{title: string; value: string}>;
    tinData: TinData[] | null;
    onSubmit: () => void;
}

export const TinInlineEdit: FC<TinInlineEditProps> = ({tinData, countryOptions, onSubmit}) => {
    const {i18n} = useI18n();
    const [isEditingNew, setIsEditingNew] = useState(false);
    const [editTin, setEditTin] = useState<string | null>(null);

    const handleAddTaxIdNumberClick = useCallback(() => {
        setIsEditingNew(true);
    }, []);

    const handleCancelAddTaxIdNumberClick = useCallback(() => {
        setIsEditingNew(false);
    }, []);

    const handleEditTaxIdNumberClick = useCallback((tin: TinData) => {
        setEditTin(tin.country);
    }, []);

    const handleCancelEditTaxIdNumberClick = useCallback(() => {
        setEditTin(null);
    }, []);

    const handleFormSubmit = useCallback(() => {
        setEditTin(null);
        setIsEditingNew(false);
        onSubmit();
    }, [onSubmit]);

    if (!tinData) {
        return null;
    }

    const hasTinData = tinData.length > 0;

    return (
        <div>
            <p className="font-semibold tracking-normal text-neutral-900">
                {i18n("auth.app.company-settings.tin.title")}
            </p>
            <TinFaqLink />
            {!hasTinData &&
                (isEditingNew ? (
                    <TaxIdentificationNumberEditRowItem
                        countryOptions={countryOptions}
                        onCancel={handleCancelAddTaxIdNumberClick}
                        onSubmit={handleFormSubmit}
                    />
                ) : (
                    <div className="mt-4">
                        <Button onClick={handleAddTaxIdNumberClick}>{i18n("auth.app.company-settings.tin.add")}</Button>
                    </div>
                ))}
            {hasTinData && (
                <div className="flex flex-col">
                    <div>
                        {tinData.map((tin) => (
                            <div key={tin?.id || tin.country} className="border-separator border-b">
                                {tin.country === editTin ? (
                                    <TaxIdentificationNumberEditRowItem
                                        countryOptions={countryOptions}
                                        onCancel={handleCancelEditTaxIdNumberClick}
                                        tinData={tin}
                                        onSubmit={handleFormSubmit}
                                    />
                                ) : (
                                    <TaxIdentificationNumberRowItem
                                        tax={tin}
                                        onEditClick={handleEditTaxIdNumberClick}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                    {isEditingNew ? (
                        <TaxIdentificationNumberEditRowItem
                            countryOptions={countryOptions}
                            onCancel={handleCancelAddTaxIdNumberClick}
                            onSubmit={handleFormSubmit}
                        />
                    ) : (
                        <button
                            type="button"
                            onClick={handleAddTaxIdNumberClick}
                            className="text-active-link-primary mt-3 inline-block self-start"
                        >
                            {i18n("auth.app.company-settings.tin.add-more")}
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};
