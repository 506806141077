import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import DriverRegistrationLogStatus = FleetOwnerPortalService.DriverRegistrationLogStatus;
import {DriverInvitationStatus, DriverRegistrationStatus} from "../../features/companies/drivers/list-view";

enum DriverRegistrationPublicStatus {
    NotSubmitted = "not_submitted",
    Incomplete = "incomplete",
    InReview = "in_review",
    Onboarding = "onboarding",
    Accepted = "accepted",
    Declined = "declined",
    Deleted = "deleted",
    Invited = "invited",
}

export const getDriverRegistrationStatus = (
    status: DriverRegistrationStatus,
): {
    label: DriverRegistrationPublicStatus | DriverInvitationStatus;
    color: "neutral" | "promo" | "action" | "danger";
} => {
    switch (status) {
        case DriverRegistrationLogStatus.IN_PROGRESS:
            return {label: DriverRegistrationPublicStatus.NotSubmitted, color: "promo"};
        case DriverRegistrationLogStatus.SUBMITTED:
        case DriverRegistrationLogStatus.DOCUMENTS_RESUBMITTED:
        case DriverRegistrationLogStatus.PENDING_COMPANY_APPROVAL:
            return {label: DriverRegistrationPublicStatus.InReview, color: "promo"};
        case DriverRegistrationLogStatus.ONBOARDING:
            return {label: DriverRegistrationPublicStatus.Onboarding, color: "promo"};
        case DriverRegistrationLogStatus.ACCEPTED:
            return {label: DriverRegistrationPublicStatus.Accepted, color: "action"};
        case DriverRegistrationLogStatus.DOCUMENTS_NEED_RESUBMISSION:
            return {label: DriverRegistrationPublicStatus.Incomplete, color: "danger"};
        case DriverRegistrationLogStatus.DECLINED:
        case DriverRegistrationLogStatus.DECLINED_SILENTLY:
            return {label: DriverRegistrationPublicStatus.Declined, color: "danger"};
        case DriverRegistrationLogStatus.DELETED:
            return {label: DriverRegistrationPublicStatus.Deleted, color: "danger"};
        case DriverInvitationStatus.INVITED:
            return {label: DriverRegistrationPublicStatus.Invited, color: "neutral"};
        default:
            return UnreachableCode.never(status);
    }
};
