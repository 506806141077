import {Skeleton} from "@bolteu/kalep-react";

export const CategorySkeleton = () => {
    return (
        <div className="flex flex-1 gap-2">
            <div className="shrink-0">
                <Skeleton variant="circular" width={24} />
            </div>
            <div className="flex flex-1 flex-col">
                <Skeleton variant="text" />
            </div>
            <div className="flex flex-1 justify-end">
                <Skeleton variant="text" width={60} />
            </div>
        </div>
    );
};
