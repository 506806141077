import {DEFAULT_MAP_CONFIG} from "common/geo/components/google-maps/constants";
import {GoogleMaps} from "common/geo/components/google-maps/GoogleMaps";

import {Address} from "../../../types";
import {useMap} from "./useMap";

interface Props {
    pickup: Address;
    dropoffs: Address[];
    geoLocation: GeolocationPosition | null;
}

export const RouteDetailsMap = ({pickup, dropoffs, geoLocation}: Props) => {
    const {onGoogleApiLoaded, markers} = useMap(pickup, dropoffs, geoLocation);

    return (
        <div className="mt-10 h-64 w-full overflow-hidden rounded-md">
            <GoogleMaps
                options={{
                    mapTypeId: "roadmap",
                    clickableIcons: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                    maxZoom: 16,
                    zoomControlOptions: {position: 3},
                    zoomControl: false,
                    disableDefaultUI: false,
                }}
                defaultCenter={DEFAULT_MAP_CONFIG.center}
                defaultZoom={DEFAULT_MAP_CONFIG.zoom}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={onGoogleApiLoaded}
            >
                {markers}
            </GoogleMaps>
        </div>
    );
};
