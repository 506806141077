import {useI18n} from "common/hooks/useI18n";
import {twMerge} from "tailwind-merge";

import {Step} from "../../utils/types";

const WizardStep = ({
    isCompletedStep,
    isCurrent,
    totalSteps,
}: {
    isCompletedStep: boolean;
    isCurrent: boolean;
    totalSteps: number;
}) => {
    return (
        <div
            style={{flex: 1 / totalSteps, backgroundColor: `${isCurrent ? "#127042" : ""}`}}
            className={twMerge(
                "h-1 min-w-[8px] rounded",
                isCompletedStep && "bg-action-primary",
                !isCurrent && !isCompletedStep && "bg-neutral-secondary-hard",
            )}
        />
    );
};

interface Props {
    steps: Step[];
    currentStep?: Step;
}

const MobileWizardStepper = ({steps, currentStep}: Props) => {
    const {i18n} = useI18n();
    const nextStepIndex = steps.findIndex((step) => step.id === currentStep?.id) + 1;
    const nextStep = steps[nextStepIndex];

    return (
        <div>
            {nextStep && (
                <div className="text-content-secondary">
                    {i18n("auth.app.fleet.vehicle_marketplace.listings.add.next_step", {
                        step: nextStep.title,
                    })}
                </div>
            )}
            <div className="my-2 flex w-full gap-2 overflow-hidden">
                {steps.map((step) => (
                    <WizardStep
                        key={step.id}
                        isCompletedStep={step.is_completed}
                        isCurrent={step.id === currentStep?.id}
                        totalSteps={steps.length}
                    />
                ))}
            </div>
            <div className="text-content-secondary">
                {i18n("auth.app.fleet.vehicle_marketplace.listings.add.step_number", {
                    current: steps.findIndex((step) => step.id === currentStep?.id) + 1,
                    total: steps.length,
                })}
            </div>
        </div>
    );
};

export default MobileWizardStepper;
