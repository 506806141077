import {useMemo} from "react";

import {Marker} from "common/geo/components/google-maps/Marker";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {AddressDestination, AddressPickup, PinDestination} from "@bolteu/kalep-react-icons";

import {Address, AddressType} from "../../../types";

export const LocationMapMarker = ({type}: {type: AddressType}) => {
    const latLngInMiddleOfIcon = "-translate-x-[42%] -translate-y-[42%]";
    const pickupAndStopClasses = `absolute ${latLngInMiddleOfIcon} drop-shadow-md`;
    const desinationClasses = "absolute -translate-x-[42%] -translate-y-full drop-shadow-md";
    switch (type) {
        case AddressType.Pickup:
            return <AddressPickup className={pickupAndStopClasses} />;
        case AddressType.Stop:
            return <AddressDestination className={pickupAndStopClasses} />;
        case AddressType.Dropoff:
            return <PinDestination className={desinationClasses} />;
        default:
            return UnreachableCode.never(type, <AddressPickup className={pickupAndStopClasses} />);
    }
};

export const useAddressMarkers = (pickup: Address, dropoffs: Address[]) => {
    const markers = useMemo(() => {
        const markersList: JSX.Element[] = [];
        if (pickup.lat && pickup.lng) {
            markersList.push(
                <Marker key={pickup.key} lat={pickup.lat} lng={pickup.lng}>
                    <LocationMapMarker type={AddressType.Pickup} />
                </Marker>,
            );
        }
        if (dropoffs.some((dropoff) => dropoff.lat && dropoff.lng)) {
            dropoffs.forEach((dropoff, index) => {
                if (dropoff.lat && dropoff.lng) {
                    markersList.push(
                        <Marker key={dropoff.key} lat={dropoff.lat} lng={dropoff.lng}>
                            <LocationMapMarker
                                type={dropoffs.length - 1 === index ? AddressType.Dropoff : AddressType.Stop}
                            />
                        </Marker>,
                    );
                }
            });
        }
        return markersList;
    }, [pickup, dropoffs]);

    return markers;
};
