import {useCallback} from "react";
import {useLocation, useNavigate} from "react-router-dom";

import {CommonRoute} from "common/constants/routes";
import {useAuthentication} from "common/hooks/useAuthentication";
import {useI18n} from "common/hooks/useI18n";
import {checkIfAddCompanyRoute} from "common/util/routeUtils";

type ProfileMenuItems = ProfileMenuItem[];
interface ProfileMenuItem {
    label: string;
    action: () => void;
}

export function useProfileMenuItems(onMenuSelect?: () => void): ProfileMenuItems {
    const {i18n} = useI18n();
    const {makeLogout} = useAuthentication();
    const location = useLocation();

    const navigate = useNavigate();

    const onLogoutClick = useCallback(() => {
        if (onMenuSelect) {
            onMenuSelect();
        }
        makeLogout(true);
    }, [makeLogout, onMenuSelect]);

    const onSettingsClick = useCallback(() => {
        if (onMenuSelect) {
            onMenuSelect();
        }
        navigate(CommonRoute.SETTINGS);
    }, [navigate, onMenuSelect]);

    const isAddCompanyRoute = checkIfAddCompanyRoute(location.pathname);

    return [
        ...(!isAddCompanyRoute
            ? [
                  {
                      label: i18n("auth.app.settings.title"),
                      action: onSettingsClick,
                  },
              ]
            : []),
        {
            label: i18n("common.log_out"),
            action: onLogoutClick,
        },
    ];
}
