import {useCallback, useEffect} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, Dialog, Typography} from "@bolteu/kalep-react";

const fetchFunction = (api: Api, body: FleetShiftManagementService.ShiftRequest) =>
    api.fleetShiftManagement.deleteShift(body);

interface Props {
    show: boolean;
    onClose: () => void;
    onDelete: () => void;
    shiftId: number | null;
}

export const DeleteDialog = ({show, onClose, onDelete, shiftId}: Props) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();
    const {fetch, status, error} = useFetch(fetchFunction);

    const onDeleteClick = useCallback(async () => {
        if (fetch && shiftId) {
            fetch({shift_id: shiftId});
        }
    }, [fetch, shiftId]);

    useEffect(() => {
        if (status === FetchStatus.Success) {
            onDelete();
        }
    }, [status, error.message, trackEvent, onDelete, onClose]);

    const isApiLoading = status === FetchStatus.Loading;

    return (
        <Dialog
            title={i18n("auth.app.fleet.shifts.planning.delete_dialog.title")}
            isOpen={show}
            onRequestClose={onClose}
            variant="alert"
        >
            <Dialog.Content>
                <Typography align="center">
                    {i18n("auth.app.fleet.shifts.planning.delete_dialog.description")}
                </Typography>
            </Dialog.Content>
            <Dialog.Footer>
                <Button
                    variant="danger"
                    onClick={onDeleteClick}
                    loading={isApiLoading}
                    fullWidth
                    overrideClassName="sm:max-w-[192px]"
                >
                    {i18n("common.delete")}
                </Button>
                <Button
                    variant="secondary"
                    onClick={onClose}
                    disabled={isApiLoading}
                    fullWidth
                    overrideClassName="sm:max-w-[192px]"
                >
                    {i18n("common.cancel")}
                </Button>
            </Dialog.Footer>
        </Dialog>
    );
};
