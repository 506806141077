import {useId} from "@react-aria/utils";

import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {Icon} from "./Icon";

interface InfoTextProps {
    fieldConfig: FleetOwnerRegistrationService.InfoTextField;
}

const InfoText = ({fieldConfig}: InfoTextProps) => {
    const {label, icon, value} = fieldConfig;

    const prefix = icon ? (
        <span>
            <Icon config={icon} />
        </span>
    ) : null;

    const id = useId();
    return (
        <>
            <label htmlFor={id} className="bolt-font-body-s-accent text-primary w-fit">
                {label}
            </label>
            <div id={id} className="text-primary mt-6">
                {prefix} {value}
            </div>
        </>
    );
};

export {InfoText};
