import {
    BaseEventPayload,
    DropTargetLocalizedData,
    DropTargetRecord,
    ElementDragPayload,
    ElementDragType,
} from "@atlaskit/pragmatic-drag-and-drop/dist/types/internal-types";

export enum DraggableType {
    IDLE = "idle",
    PREVIEW = "preview",
    DRAGGING = "dragging",
}

interface DraggableStateBase {
    type: DraggableType;
}

interface IdleState extends DraggableStateBase {
    type: DraggableType.IDLE;
}

interface DraggingState extends DraggableStateBase {
    type: DraggableType.DRAGGING;
}

interface PreviewState extends DraggableStateBase {
    type: DraggableType.PREVIEW;
    container: HTMLElement;
}

export type DraggableState = IdleState | DraggingState | PreviewState;

export type DraggingEvent = BaseEventPayload<ElementDragType> & DropTargetLocalizedData;

export type DragPayload<T> = ElementDragPayload & {data: T};

export type DropTarget<T> = DropTargetRecord & {data: T};
