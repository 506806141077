import React, {useCallback, useContext, useEffect, useState} from "react";

import {Company, CompanyType, Fleet, HoldingFleet, isFleet, isHoldingFleet} from "common/constants/types";
import {ThirdPartyContextProvider} from "features/app/appThirdPartyProvider";

export interface Profile {
    id: number;
    email: string;
    name: string;
    firstName: string;
    lastName: string;
    fleets: Fleet[];
    holdingFleets: HoldingFleet[];
    companyRegistrationHash?: string;
}

export interface SelectedCompany {
    company: Company;
    type: CompanyType;
}

export interface AccountState {
    profile: Profile | null;
    setProfile: (profile: Profile | null) => void;
    selectedCompany: SelectedCompany | null;
    setSelectedCompany: (company: SelectedCompany | null) => void;
    getFleet: () => Fleet | undefined;
    getHoldingFleet: () => HoldingFleet | undefined;
}

const defaultContextValue = {
    profile: null,
    setProfile: () => {},
    selectedCompany: null,
    setSelectedCompany: () => {},
    getFleet: () => undefined,
    getHoldingFleet: () => undefined,
    companyRegistrationHash: null,
};
const AccountContextProvider = React.createContext<AccountState>(defaultContextValue);
AccountContextProvider.displayName = "AccountContextProvider";

const AccountProvider = ({children}: {children: React.ReactNode}) => {
    const {
        bugsnag: {bugsnagLeaveBreadcrumb, bugsnagSetUser},
    } = useContext(ThirdPartyContextProvider);

    const [profile, setProfile] = useState<Profile | null>(null);
    const [selectedCompany, setSelectedCompany] = useState<SelectedCompany | null>(null);

    useEffect(() => {
        if (profile?.id) {
            bugsnagSetUser(String(profile.id));
        }
    }, [profile, bugsnagSetUser]);

    useEffect(() => {
        bugsnagLeaveBreadcrumb("company selected", {
            company: {type: selectedCompany?.type, id: selectedCompany?.company.id},
        });
    }, [selectedCompany, bugsnagLeaveBreadcrumb]);

    const getFleet = useCallback(() => {
        if (selectedCompany?.type === CompanyType.FLEET_COMPANY && isFleet(selectedCompany?.company)) {
            return selectedCompany.company;
        }
        return undefined;
    }, [selectedCompany]);

    const getHoldingFleet = useCallback(() => {
        if (selectedCompany?.type === CompanyType.HOLDING_FLEET && isHoldingFleet(selectedCompany?.company)) {
            return selectedCompany.company;
        }
        return undefined;
    }, [selectedCompany]);

    return (
        <AccountContextProvider.Provider
            value={{
                profile,
                setProfile,
                selectedCompany,
                setSelectedCompany,
                getFleet,
                getHoldingFleet,
            }}
        >
            {children}
        </AccountContextProvider.Provider>
    );
};

export {AccountContextProvider, AccountProvider};
