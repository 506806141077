import {FC, useEffect, useState} from "react";

import FileInfo from "common/components/fileUpload/FileInfo";
import {getFileUrl} from "common/components/fileUpload/util";
import {useI18n} from "common/hooks/useI18n";

interface ImagePreviewProps {
    document: File;
    onFailure: () => void;
}

const ImagePreview: FC<ImagePreviewProps> = ({document, onFailure}) => {
    const {i18n} = useI18n();
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    useEffect(() => {
        const getUrl = async () => {
            try {
                const url = await getFileUrl(document);
                setFileUrl(url);
            } catch (e) {
                onFailure();
            }
        };

        getUrl();
    }, [document, onFailure]);

    if (fileUrl === null) {
        return <FileInfo file={document} />;
    }

    return <img src={fileUrl} alt={i18n("upload_document.document_preview_alt")} className="w-full object-contain" />;
};

export default ImagePreview;
