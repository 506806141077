import {I18nFn} from "common/hooks/useI18n";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

export enum MimeTypes {
    PDF = "application/pdf",
    CSV = "text/csv",
    ASIC = "application/vnd.etsi.asic-e+zip",
    // IMAGES FROM https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types#image_types
    GIF = "image/gif",
    JPEG = "image/jpeg",
    PNG = "image/png",
    APNG = "image/apng",
    WEBP = "image/webp",
    AVIF = "image/avif",
    AVIF_SEQ = "image/avif-sequence", // another mime type for the avif https://mimetype.io/image/avif
    SVG = "image/svg+xml",
    // iOS (at least) formats
    HEIF = "image/heif",
    HEIC = "image/heic",
}

export enum AllowedExtensions {
    EDOC = "edoc",
}

export enum FileTypes {
    IMAGE = "image",
    PDF = "pdf",
    CSV = "csv",
    DOCUMENT_CONTAINER = "document_container",
}

const IMAGE_MIME_TYPES = [
    MimeTypes.GIF,
    MimeTypes.JPEG,
    MimeTypes.PNG,
    MimeTypes.APNG,
    MimeTypes.WEBP,
    MimeTypes.AVIF,
    MimeTypes.AVIF_SEQ,
    MimeTypes.SVG,
    MimeTypes.HEIF,
    MimeTypes.HEIC,
];

const IMAGE_FILE_TYPES_FOR_ERROR = [".jpg", ".jpeg", ".png", ".gif"];

export const getMimeTypes = (fileTypes: FileTypes[]): MimeTypes[] => {
    const mimeTypes: MimeTypes[] = [];
    for (const fileType of fileTypes) {
        switch (fileType) {
            case FileTypes.IMAGE:
                mimeTypes.push(...IMAGE_MIME_TYPES);
                break;
            case FileTypes.PDF:
                mimeTypes.push(MimeTypes.PDF);
                break;
            case FileTypes.CSV:
                mimeTypes.push(MimeTypes.CSV);
                break;
            case FileTypes.DOCUMENT_CONTAINER:
                mimeTypes.push(MimeTypes.ASIC);
                break;
            default:
                UnreachableCode.never(fileType);
        }
    }
    return mimeTypes;
};

export const getFileTypeError = (fileTypes: FileTypes[], i18n: I18nFn): string => {
    if (fileTypes.length === 1 && fileTypes[0] === FileTypes.CSV) {
        return i18n("upload_file.support_csv");
    }

    if (fileTypes.includes(FileTypes.IMAGE) && fileTypes.includes(FileTypes.PDF)) {
        return i18n("upload_file.support_images_pdfs", {
            fileTypes: [...IMAGE_FILE_TYPES_FOR_ERROR, ".pdf"].join(", "),
        });
    }

    if (fileTypes.includes(FileTypes.IMAGE)) {
        return i18n("upload_file.support_images", {
            fileTypes: IMAGE_FILE_TYPES_FOR_ERROR.join(", "),
        });
    }

    throw Error("Unsupported file type combo");
};
