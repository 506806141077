import {useCallback} from "react";

import {useI18n} from "common/hooks/useI18n";

import {GhostButton, Menu, MenuItem} from "@bolteu/kalep-react";

import {useVerificationOtherMethods} from "./hooks/useVerificationOtherMethods";

export const OtherMethods = () => {
    const {i18n} = useI18n();

    const {otherMethodsItems, getIcon} = useVerificationOtherMethods();

    const handleGhostButtonClick = useCallback(() => {}, []);

    return (
        <>
            <div className="inline-block">
                <Menu
                    menuButton={
                        <GhostButton overrideClassName="text-action-primary" onClick={handleGhostButtonClick}>
                            {i18n("login.phone.tryOtherMethods")}
                        </GhostButton>
                    }
                >
                    {otherMethodsItems.map((item) => (
                        <MenuItem
                            key={item.key}
                            label={item.label}
                            renderStartSlot={getIcon(item.icon)}
                            onClick={item.onClick}
                        />
                    ))}
                </Menu>
            </div>
        </>
    );
};
