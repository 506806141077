import FilledCircle from "assets/icons/filled_circle.svg?react";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";

import EntityState = FleetOwnerPortalService.EntityState;
import StateColumn = FleetOwnerPortalService.StateColumn;

interface StateDisplayIndicatorProps {
    entityState?: EntityState;
}

export const StateDisplayIndicator = ({entityState}: StateDisplayIndicatorProps) => {
    switch (entityState) {
        case EntityState.DEFAULT:
            return <></>;
        case EntityState.ALERT:
            return <FilledCircle width={12} height={12} className="fill-red-500" />;
        case EntityState.INACTIVE:
            return <FilledCircle width={12} height={12} className="fill-neutral-500" />;
        case EntityState.DANGER:
        case EntityState.WARNING:
        case EntityState.SUCCESS:
        case EntityState.PENDING:
        case undefined:
            return <></>;
        default:
            return UnreachableCode.never(entityState, <></>);
    }
};

export const StateDisplayText = (stateDisplayProps: {valueAtIdx: number; stateColumn?: StateColumn}) => {
    if (!stateDisplayProps.stateColumn) {
        return <></>;
    }

    const {value_map} = stateDisplayProps.stateColumn;
    const valueMapPair = value_map.find(
        (value) => value.key === stateDisplayProps.stateColumn?.cells[stateDisplayProps.valueAtIdx],
    );

    if (!valueMapPair) {
        return <></>;
    }

    switch (valueMapPair.key) {
        case EntityState.ALERT:
            return (
                <Typography variant="body-tabular-s-regular" color="danger-primary">
                    {valueMapPair.value}
                </Typography>
            );
        case EntityState.INACTIVE:
            return (
                <Typography variant="body-tabular-s-regular" color="secondary">
                    {valueMapPair.value}
                </Typography>
            );
        case EntityState.DEFAULT:
            return <></>;
        default:
            return <></>;
    }
};
