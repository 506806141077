import {useCallback, useEffect, useState} from "react";

import {ResponsiveDrawer} from "common/components/drawer/ResponsiveDrawer";
import {EventName} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";

import {FleetOwnerPortalService, FleetOwnerService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {FormState, useForm} from "@bolteu/kalep-form-react";
import {Drawer, IconButton, SelectOption} from "@bolteu/kalep-react";
import {Bin as BinIcon, Filters as FiltersIcon} from "@bolteu/kalep-react-icons";

import {DrawerFilters, TabName} from "..";
import {areAllFilterValuesDefault} from "../utils/areAllFilterValuesDefault";
import {getDefaultFilters} from "../utils/getDefaultFilters";

import CampaignStatus = FleetOwnerPortalService.CampaignStatus;
import City = FleetOwnerService.City;

function isSelectOptionDisabled(status: CampaignStatus, selectedStatuses: CampaignStatus[] | undefined) {
    // Force to keep at least one status selected
    if (selectedStatuses && selectedStatuses.length === 1) {
        return selectedStatuses.includes(status);
    }

    return false;
}

interface FilterDrawerProps {
    activeTab: TabName;
    cities: City[];
    filters: DrawerFilters;
    setFilters: (filters: DrawerFilters) => void;
}

const FilterDrawer = ({activeTab, cities, filters, setFilters}: FilterDrawerProps) => {
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();

    const [isOpen, setIsOpen] = useState(false);

    const {Form, Button, SelectField, CheckboxField, setData, useFieldValue} = useForm(filters);
    const selectedStatuses = useFieldValue("statuses");
    const selectedCities = useFieldValue("city_id");
    const isPolygonSelected = useFieldValue("polygon_location");

    const isClearFiltersButtonShown = !areAllFilterValuesDefault(activeTab, {
        statuses: selectedStatuses,
        city_id: selectedCities,
        polygon_location: isPolygonSelected,
    });

    useEffect(() => {
        setData(filters);
    }, [setData, filters]);

    const handleFiltersClose = useCallback(() => {
        setIsOpen(false);
    }, []);

    const handleFilterButtonClick = useCallback(() => {
        setIsOpen(true);
        trackEvent(EventName.CampaignsListFilterDrawerOpened);
    }, [trackEvent]);

    const handleClearFiltersButtonClick = useCallback(() => {
        const defaultFilters = getDefaultFilters(activeTab);

        setData(defaultFilters);
    }, [setData, activeTab]);

    const handleFormSubmit = useCallback(
        async ({data: formData}: FormState<DrawerFilters>) => {
            setFilters(formData);
            setIsOpen(false);
        },
        [setFilters],
    );

    const handleCancelFiltersButtonClick = useCallback(() => {
        setData({
            city_id: filters.city_id,
            statuses: filters.statuses,
            polygon_location: Boolean(filters.polygon_location),
        });
        setIsOpen(false);
    }, [setData, filters]);

    const statusesSelectOptions: SelectOption[] = [
        {
            value: CampaignStatus.ACTIVE,
            title: i18n("auth.app.fleet.campaigns.filters.status.options.active"),
            disabled: isSelectOptionDisabled(CampaignStatus.ACTIVE, selectedStatuses),
        },
        {
            value: CampaignStatus.UPCOMING,
            title: i18n("auth.app.fleet.campaigns.filters.status.options.upcoming"),
            disabled: isSelectOptionDisabled(CampaignStatus.UPCOMING, selectedStatuses),
        },
    ];

    const defaultOption = {
        value: undefined as unknown as null, // undefined works but not allowed in Kalep types
        title: i18n("common.all"),
    };
    const citiesOptions = cities.map((city) => ({
        value: city.city_id,
        title: city.name,
    }));
    const citiesSelectOptions: SelectOption[] = [defaultOption, ...citiesOptions];

    const statusesTextList = selectedStatuses
        ?.map((status) => i18n(`auth.app.fleet.campaigns.filters.status.options.${status}`))
        .join(", ");

    return (
        <>
            <IconButton
                overrideClassName="flex"
                shape="square"
                icon={<FiltersIcon />}
                onClick={handleFilterButtonClick}
                aria-label="Open filter drawer"
            />
            <Form onSubmit={handleFormSubmit}>
                <ResponsiveDrawer
                    isOpen={isOpen}
                    title={i18n("auth.app.fleet.campaigns.filters.title")}
                    onRequestClose={handleFiltersClose}
                >
                    <Drawer.Content>
                        <div className="flex flex-col gap-4">
                            {activeTab === TabName.ActiveAndUpcoming && (
                                <SelectField
                                    name="statuses"
                                    multiple
                                    label={i18n("auth.app.fleet.campaigns.filters.status.title")}
                                    options={statusesSelectOptions}
                                    placeholder={statusesTextList}
                                />
                            )}
                            <SelectField
                                name="city_id"
                                label={i18n("auth.app.fleet.campaigns.filters.location.title")}
                                placeholder={i18n("common.all")}
                                options={citiesSelectOptions}
                            />
                            <div className="pt-3">
                                <CheckboxField
                                    name="polygon_location"
                                    label={i18n("auth.app.fleet.campaigns.filters.hide_special_locations.title")}
                                />
                            </div>
                        </div>
                    </Drawer.Content>
                    <Drawer.Footer>
                        <Button submit>{i18n("common.apply")}</Button>
                        <Button variant="secondary" onClick={handleCancelFiltersButtonClick}>
                            {i18n("common.cancel")}
                        </Button>
                        {isClearFiltersButtonShown && (
                            <Button variant="secondary" onClick={handleClearFiltersButtonClick} startIcon={<BinIcon />}>
                                {i18n("common.filters.clear_filters")}
                            </Button>
                        )}
                    </Drawer.Footer>
                </ResponsiveDrawer>
            </Form>
        </>
    );
};

export default FilterDrawer;
