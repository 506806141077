import {useCallback, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";

import {ComboboxText} from "common/components/dynamicForm/ComboboxText";
import {Description} from "common/components/dynamicForm/description/Description";
import {AdditionalFields, FormValueType} from "common/components/dynamicForm/types";
import {getApiToFormValues, getFilteredAndSortedFields, getFormField} from "common/components/dynamicForm/util";
import {ValidatorResult} from "@fleet/common/hooks/useFetch";

import {FleetOwnerVerificationNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {useForm} from "@bolteu/kalep-form-react";
import {Alert, Typography} from "@bolteu/kalep-react";

import {useSubmitOtpForm} from "./hooks/useSubmitOtpForm";

interface VerificationDynamicFormProps {
    formData: FleetOwnerVerificationNoAuthService.GetOtpFormResponse;
    onBack?: () => void;
    shouldShowBackButton?: boolean;
    waitSecondsOnSubmitSuccess?: (waitSeconds: number) => void;
}

export const SubmitOtpDynamicForm = ({
    formData,
    onBack,
    waitSecondsOnSubmitSuccess,
    shouldShowBackButton = true,
}: VerificationDynamicFormProps) => {
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [validatorResults, setValidatorResults] = useState<ValidatorResult[]>([]);
    const [formValue] = useState<FormValueType>(getApiToFormValues(formData.fields));
    const navigate = useNavigate();
    const params = useParams();
    const useFormValue = useForm<FormValueType, AdditionalFields>(formValue, {
        ComboboxTextField: ComboboxText,
    });
    const {onSubmitForm} = useSubmitOtpForm({
        attemptKey: params.key,
        setErrorMessage,
        setValidatorResults,
        waitSecondsOnSubmitSuccess,
    });

    const {Form, Button} = useFormValue;

    const onBackClick = useCallback(() => {
        if (onBack) {
            onBack();
            return;
        }
        navigate(-1);
    }, [navigate, onBack]);

    const onSecondaryActionClick = useCallback(() => {
        if (formData.form_type === FleetOwnerVerificationNoAuthService.FormType.TWO_FACTOR_AUTH_CONFIRMATION) {
            onSubmitForm(formData)(
                {data: {}, difference: {}},
                FleetOwnerVerificationNoAuthService.TwoFactorAuthConfirmationType.CREATE_NEW_ACCOUNT,
            );
        }
    }, [formData, onSubmitForm]);

    const onPrimaryActionClick = useCallback(() => {
        if (formData.form_type === FleetOwnerVerificationNoAuthService.FormType.TWO_FACTOR_AUTH_CONFIRMATION) {
            onSubmitForm(formData)(
                {data: {}, difference: {}},
                FleetOwnerVerificationNoAuthService.TwoFactorAuthConfirmationType.CONTINUE_WITH_EXISTING_ACCOUNT,
            );
        }
    }, [formData, onSubmitForm]);

    const shouldShowCustomActionButtons =
        formData.form_type === FleetOwnerVerificationNoAuthService.FormType.TWO_FACTOR_AUTH_CONFIRMATION &&
        Boolean(formData.form.secondary_action_button_label);

    return (
        <div className="w-full">
            <div className="mb-8 flex flex-col items-center gap-2">
                <Typography variant="title-primary" fontSize="text-2xl">
                    {formData.form.title}
                </Typography>
                <Typography variant="body-secondary">
                    <div className="text-content-secondary text-center text-base">
                        <Description description={formData.form.description} />
                    </div>
                </Typography>
            </div>
            <div className="sm:px-16">
                {errorMessage && (
                    <Alert severity="error" overrideClassName="text-base mb-2">
                        {errorMessage}
                    </Alert>
                )}
                <Form
                    validatorResults={validatorResults}
                    onSubmit={onSubmitForm(formData)}
                    className="flex w-full flex-col"
                >
                    {formData.fields.length !== 0 && (
                        <div className="mb-8 flex flex-col gap-4">
                            {getFilteredAndSortedFields(formData.fields).map((field) => (
                                <div key={field.name}>{getFormField(field, validatorResults, useFormValue)}</div>
                            ))}
                        </div>
                    )}
                    {!shouldShowCustomActionButtons && (
                        <div className="flex gap-4">
                            {shouldShowBackButton && (
                                <div className="w-full">
                                    <Button fullWidth variant="secondary" onClick={onBackClick}>
                                        {formData.form.back_button_label}
                                    </Button>
                                </div>
                            )}
                            <div className="w-full">
                                <Button fullWidth submit>
                                    {formData.form.submit_button_label}
                                </Button>
                            </div>
                        </div>
                    )}
                    {shouldShowCustomActionButtons && (
                        <div className="flex flex-col gap-4 pt-2">
                            <Button fullWidth onClick={onPrimaryActionClick}>
                                {formData.form.submit_button_label}
                            </Button>
                            <Button fullWidth variant="secondary" onClick={onSecondaryActionClick}>
                                {formData.form.secondary_action_button_label}
                            </Button>
                        </div>
                    )}
                </Form>
            </div>
        </div>
    );
};
