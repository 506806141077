import {CountryCode} from "common/geo/constants/countries";

// Dusseldorf compliance requirement
const companiesWithAcceptSettingHidden = [77294, 77386, 77295, 77292];
const companiesWithIncomingOrders: string[] = [CountryCode.Germany];

export const isAutoAcceptSettingVisible = (companyId: number, countryCode: string) =>
    !companiesWithAcceptSettingHidden.includes(companyId) && companiesWithIncomingOrders.includes(countryCode);

export const isIncomingOrdersFeatureEnabled = (countryCode: string) =>
    companiesWithIncomingOrders.includes(countryCode);
