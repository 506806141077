import {useI18n} from "common/hooks/useI18n";

import {Grid, Island} from "@bolteu/kalep-react";
import {Hide as HideIcon} from "@bolteu/kalep-react-icons";

const CampaignHiddenIsland = () => {
    const {i18n} = useI18n();

    const title = i18n("auth.app.fleet.campaigns.details.campaign_hidden_island.title");
    const description = i18n("auth.app.fleet.campaigns.details.campaign_hidden_island.description");

    return (
        <Grid columns={12}>
            <Grid.Item colSpan={7}>
                <div className="w-fit">
                    <Island>
                        <div className="flex gap-4">
                            <HideIcon />
                            <div className="flex flex-col gap-2">
                                <p className="text-sm font-semibold">{title}</p>
                                <p className="text-secondary text-sm">{description}</p>
                            </div>
                        </div>
                    </Island>
                </div>
            </Grid.Item>
        </Grid>
    );
};

export default CampaignHiddenIsland;
