import {useI18n} from "common/hooks/useI18n";

interface Props {
    expiredDocuments: boolean;
    declinedDocuments: boolean;
}

interface Result {
    titleText: string;
    descriptionText: string;
}

export const useDocumentsAlert = ({expiredDocuments, declinedDocuments}: Props): Result => {
    const {i18n} = useI18n();

    const descriptionText = i18n("auth.app.fleet.driver-profile.documents.alert.alert_description");

    if (expiredDocuments && declinedDocuments) {
        return {
            titleText: i18n("auth.app.fleet.driver-profile.documents.alert.alert_title.documents_expired_and_declined"),
            descriptionText,
        };
    }
    if (expiredDocuments) {
        return {
            titleText: i18n("auth.app.fleet.driver-profile.documents.alert.alert_title.documents_expired"),
            descriptionText,
        };
    }
    if (declinedDocuments) {
        return {
            titleText: i18n("auth.app.fleet.driver-profile.documents.alert.alert_title.documents_declined"),
            descriptionText,
        };
    }

    return {titleText: "", descriptionText: ""};
};
