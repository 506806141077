import {FC, useCallback, useState} from "react";

import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {useI18n} from "common/hooks/useI18n";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {Button} from "@bolteu/kalep-react";

import AddShiftDialog from "./AddShiftDialog";

interface PageHeaderProps {
    tabSelectorComponent: FC;
    onShiftCreated: () => void;
    onManageAssignmentsClick: () => void;
    hasShifts: boolean;
}

const PageHeader: FC<PageHeaderProps> = ({
    tabSelectorComponent,
    onShiftCreated,
    onManageAssignmentsClick,
    hasShifts,
}) => {
    const {i18n} = useI18n();
    const viewPort = useTailwindViewport();
    const [isAddShiftDialogOpen, setIsAddShiftDialogOpen] = useState(false);

    const isMobileView = viewPort === "sm";
    const TabSelector = tabSelectorComponent;

    const onAddShiftClick = useCallback(() => {
        setIsAddShiftDialogOpen(true);
    }, []);

    const closeAddShiftDialog = useCallback(() => {
        setIsAddShiftDialogOpen(false);
    }, []);

    return (
        <div className="flex flex-col gap-6">
            <div className="flex flex-col justify-between gap-4 md:flex-row">
                <ResponsiveHeader type={ResponsiveHeaderType.MainPage} text={i18n("auth.app.fleet.shifts.title")} />
                <div className="grid grid-cols-2 items-center justify-center gap-4 md:flex">
                    <Button onClick={onAddShiftClick} fullWidth={isMobileView}>
                        {i18n("auth.app.fleet.shifts.add_shift.title")}
                    </Button>
                    <Button
                        onClick={onManageAssignmentsClick}
                        variant="secondary"
                        fullWidth={isMobileView}
                        disabled={!hasShifts}
                    >
                        {i18n("auth.app.fleet.shifts.assign_shift.title")}
                    </Button>
                </div>
                {isAddShiftDialogOpen && (
                    <AddShiftDialog closeDialog={closeAddShiftDialog} onShiftCreated={onShiftCreated} />
                )}
            </div>
            <TabSelector />
        </div>
    );
};

export default PageHeader;
