import BugsnagWeb from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import {BugsnagBase} from "@bolteu/bugsnag-service-base";
import {getReactBindings} from "@bolteu/bugsnag-service-react-bindings";

import {hasStatisticsConsent} from "../utils/hasStatisticsConsent";

interface Config extends BugsnagBase.Config {
    apiKey: string;
    appVersion: string;
    releaseStage?: string;
    appType?: string;
}

class BugsnagInternalService extends BugsnagBase.Service<typeof BugsnagWeb> {
    constructor({isDevMode, apiKey, appVersion, releaseStage, appType}: Config) {
        super({bugsnag: BugsnagWeb, isDevMode});
        if (!this.isDevMode) {
            this._bugsnagStatic.start({
                apiKey,
                appVersion,
                releaseStage,
                appType,
                plugins: [new BugsnagPluginReact()],
            });
        }
    }
}

export const BugsnagService = (
    isDevMode: boolean,
    bugsnagApiKey: string,
    appVersion: string,
    appStage: string,
    brand: string,
) => {
    const bugsnag = new BugsnagInternalService({
        isDevMode,
        apiKey: bugsnagApiKey,
        appVersion,
        releaseStage: appStage,
        appType: brand,
    });

    const {ErrorBoundary} = getReactBindings(bugsnag);

    const bugsnagNotify = (error: Error) => {
        if (hasStatisticsConsent()) {
            bugsnag.notify(error);
        }
    };

    const bugsnagSetUser = (id: string) => {
        if (hasStatisticsConsent()) {
            bugsnag.setUser({id});
        }
    };

    const bugsnagLeaveBreadcrumb = (name: string, metadata?: {[key: string]: unknown}) => {
        if (hasStatisticsConsent()) {
            bugsnag.leaveBreadcrumb(name, metadata);
        }
    };

    return {ErrorBoundary, bugsnagNotify, bugsnagSetUser, bugsnagLeaveBreadcrumb};
};
