import {useContext} from "react";

import {useI18n} from "common/hooks/useI18n";
import {FeaturesContextProvider} from "FeaturesProvider";

import {Button} from "@bolteu/kalep-react";

import {DriverDialogContextProvider} from "./DriverDialogProvider";

const AddDriver = () => {
    const {i18n} = useI18n();
    const featuresContext = useContext(FeaturesContextProvider);

    const {openInviteDriverDialog} = useContext(DriverDialogContextProvider);

    return (
        <Button onClick={openInviteDriverDialog}>
            {featuresContext?.driver_invitations
                ? i18n("auth.app.fleet.dashboard.invitations.select-title")
                : i18n("auth.app.fleet.dashboard.add-driver")}
        </Button>
    );
};

export {AddDriver};
