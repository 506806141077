import {LogoLink} from "./LogoLink";
import {NavWrapper} from "./NavWrapper";
import {SidebarButton} from "./Sidebar";
import {TopBarProfileMenu} from "./TopBarProfileMenu";

interface NonAccountTopBarProps {
    isSidebarOpen: boolean;
    toggleSidebar: () => void;
}

export const NonAccountTopBar = ({isSidebarOpen, toggleSidebar}: NonAccountTopBarProps) => {
    return (
        <NavWrapper>
            <div className="flex items-center" data-testid="navbar">
                <div className="md:hidden">
                    <SidebarButton isSidebarOpen={isSidebarOpen} onClick={toggleSidebar} />
                </div>
                <div className="flex items-center" data-testid="empty-navbar">
                    <LogoLink to="/" />
                </div>
            </div>
            <div className="ml-8 flex min-w-0 items-center gap-4">
                <div className="hidden sm:block">
                    <TopBarProfileMenu />
                </div>
            </div>
        </NavWrapper>
    );
};
