import {FC, useCallback, useContext, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";

import {FixedHeaderTable} from "common/components/styledComponent/FixedHeaderTable";
import {getDriverRegistrationStatus} from "common/constants/driverRegistrationStatus";
import {EventName} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {GhostButton} from "@bolteu/kalep-react";
import {ChevronDown} from "@bolteu/kalep-react-icons";
import {AccordionTable, RowActionContext} from "@bolteu/kalep-table-react";

import {useAccordionTableColumns} from "../hooks/driver-registration/useAccordionTableColumns";
import {useTableColumns} from "../hooks/driver-registration/useTableColumns";
import {DriverInvitationStatus, DriverRegistration, DriverRegistrationStatus} from "../index";

interface DriverRegistrationTableProps {
    driverRegistrations: DriverRegistration[];
}

export interface DriverRegistrationsTableData {
    id: string;
    driver: string;
    status: DriverRegistrationStatus;
    email: string;
    phone: string;
}

export enum DriverRegistrationType {
    INVITATION = "invitation",
    REGISTRATION = "registration",
}

const MAX_SHOW_ROWS = 7;

const DriverRegistrationsTable: FC<DriverRegistrationTableProps> = ({driverRegistrations}) => {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const {trackEvent} = useTracking();
    const fleetId = useContext(AccountContextProvider)?.getFleet()?.id;

    const [shouldShowAll, setShouldShowAll] = useState(driverRegistrations.length <= MAX_SHOW_ROWS);
    const showAllRows = useCallback(() => setShouldShowAll(true), []);

    const getFields = (registration: DriverRegistration) => {
        return {
            id: registration.id,
            hash: registration.hash,
            driver: registration.driver,
            status: registration.status,
            email: registration.email ?? "",
            phone: registration.phone,
        };
    };

    const columns = useTableColumns();
    const accordionTableColumns = useAccordionTableColumns();

    const items = useMemo((): DriverRegistrationsTableData[] => {
        if (shouldShowAll) {
            return driverRegistrations.map((r) => {
                return getFields(r);
            });
        }
        return driverRegistrations.slice(0, MAX_SHOW_ROWS).map((d) => {
            return getFields(d);
        });
    }, [driverRegistrations, shouldShowAll]);

    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    const getItemHeader = useCallback(
        (item: DriverRegistrationsTableData) => {
            return {
                primary: item.driver,
                secondary: `${columns.status?.label}: ${i18n(
                    `auth.app.fleet.driver-registrations.status.${getDriverRegistrationStatus(item.status).label}`,
                )}`,
            };
        },
        [columns.status?.label, i18n],
    );

    const goToDriverRegistration = useCallback(
        (id: string, routeType: DriverRegistrationType) => () => {
            if (!fleetId) {
                return;
            }

            const eventName =
                routeType === DriverRegistrationType.REGISTRATION
                    ? EventName.InvitedDriversApplicationClicked
                    : EventName.InvitedDriversInvitationClicked;
            trackEvent(eventName);
            navigate(`${routeType}/${id}`);
        },
        [fleetId, navigate, trackEvent],
    );

    const inlineActions = useMemo(() => {
        return [
            {
                label: i18n("driver.open_profile"),
                customInlineRenderer: (context: RowActionContext<DriverRegistrationsTableData>) => {
                    const {id, status} = context.row;
                    const applicationType =
                        status === DriverInvitationStatus.INVITED
                            ? DriverRegistrationType.INVITATION
                            : DriverRegistrationType.REGISTRATION;

                    return (
                        <button type="button" onClick={goToDriverRegistration(id, applicationType)}>
                            <div className="text-action-primary">{i18n("driver.open_application_details")}</div>
                        </button>
                    );
                },
            },
        ];
    }, [i18n, goToDriverRegistration]);

    return (
        <>
            {isMobileView ? (
                <AccordionTable
                    columns={accordionTableColumns}
                    items={items}
                    aria-label="Invited drivers table"
                    getItemHeader={getItemHeader}
                    inlineActions={inlineActions}
                    paddingStart={0}
                    paddingEnd={0}
                />
            ) : (
                <FixedHeaderTable columns={columns} items={items} aria-label="Invited drivers table" />
            )}
            {!shouldShowAll && (
                <div className="flex flex-row justify-center">
                    <GhostButton onClick={showAllRows} overrideClassName="text-green-700 mt-3">
                        {i18n("common.view_all_total_count", {totalCount: driverRegistrations.length})}
                        <ChevronDown size="xs" className="ml-1 text-green-700" />
                    </GhostButton>
                </div>
            )}
        </>
    );
};

export default DriverRegistrationsTable;
