import {FleetOwnerRegistrationService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {Hint} from "./Hint";
import {UseFormValueType} from "./types";

interface EmailProps {
    useFormValue: UseFormValueType;
    fieldConfig: FleetOwnerRegistrationService.EmailField;
}

const Email = ({useFormValue, fieldConfig}: EmailProps) => {
    const {TextField} = useFormValue;
    const {name, label, placeholder, hint, required: isRequired, disabled: isDisabled} = fieldConfig;

    return (
        <div>
            <TextField
                name={name as never}
                type="text"
                label={label}
                placeholder={placeholder}
                required={isRequired}
                helperText={<Hint hint={hint} />}
                disabled={isDisabled}
            />
        </div>
    );
};

export {Email};
