import {useEffect} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {ComboBox} from "@bolteu/kalep-react";

import {useAssigmentDrivers} from "../hooks/useAssigmentDrivers";

interface Props {
    orderId: number;
    disabled?: boolean;
    selectedDriverId?: number | null;
    onChange: (driverId?: number) => void;
    validationError?: string;
}

const getDriversForScheduledRideAssignmentFunction = (
    api: Api,
    body: FleetPortalOrderService.GetDriversForScheduledRideAssignment,
) => api.fleetPortalOrder.getDriversForScheduledRideAssignment(body);

export const DriverComboBoxForOrderAssignment = ({
    orderId,
    disabled,
    selectedDriverId,
    onChange,
    validationError,
}: Props) => {
    const {i18n} = useI18n();
    const {fetch: getDrivers, data, status} = useFetch(getDriversForScheduledRideAssignmentFunction);

    const {onDriverChange, driversOptions} = useAssigmentDrivers({
        fetchData: data,
        fetchStatus: status,
        selectedDriverId,
        onChange,
    });

    useEffect(() => {
        if (getDrivers) {
            getDrivers({order_id: orderId});
        }
    }, [getDrivers, orderId]);

    const isLoading = [FetchStatus.Init, FetchStatus.Loading].includes(status);
    const selectedDriverOption = driversOptions?.find((option) => option.value === selectedDriverId);

    return (
        <ComboBox
            placeholder={i18n("auth.app.orders.scheduled_rides.select-driver-placeholder")}
            value={selectedDriverOption}
            options={driversOptions ?? []}
            onChange={onDriverChange}
            fullWidth
            disabled={disabled || isLoading}
            loading={isLoading}
            error={Boolean(validationError)}
            helperText={validationError}
        />
    );
};
