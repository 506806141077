import {createContext, useCallback, useEffect, useState} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";

interface ListingProviderProps {
    children: React.ReactNode;
    listingId: number;
}

export interface ListingProviderValue {
    listing: VehicleMarketplaceService.GetListingResponse | null;
    reloadListing: () => void;
    listingPreviewImageUrl: string | null;
    setListingPreviewImageUrl: (url: string) => void;
}

const ListingContextProvider = createContext<ListingProviderValue>({
    listing: null,
    reloadListing: () => {},
    listingPreviewImageUrl: null,
    setListingPreviewImageUrl: () => {},
});

const getListingFetch = (api: Api, request: VehicleMarketplaceService.GetListingRequest) =>
    api.vehicleMarketplace.vehicleListingGet(request);

const ListingProvider = ({children, listingId}: ListingProviderProps) => {
    const [listing, setListing] = useState<VehicleMarketplaceService.GetListingResponse | null>(null);
    const [listingPreviewImageUrl, setListingPreviewImageUrl] = useState<string | null>(null);

    const {data, fetch, status} = useFetch(getListingFetch);

    useEffect(() => {
        if (!listingId || !fetch) {
            return;
        }

        fetch({listing_id: listingId});
    }, [fetch, listingId]);

    useEffect(() => {
        if (status === FetchStatus.Success) {
            setListing(data);
        }
    }, [status, data]);

    const reloadListing = useCallback(() => {
        if (fetch && listingId) {
            fetch({listing_id: listingId});
        }
    }, [fetch, listingId]);

    const contextValue: ListingProviderValue = {
        listing,
        reloadListing,
        listingPreviewImageUrl,
        setListingPreviewImageUrl,
    };

    return <ListingContextProvider.Provider value={contextValue}>{children}</ListingContextProvider.Provider>;
};

export {ListingProvider, ListingContextProvider};
