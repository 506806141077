import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {
    Cash as CashIcon,
    Company as CompanyIcon,
    Image as ImageIcon,
    KalepIcon,
    Offer as OfferIcon,
    Percent as PercentIcon,
    SandGlass as SandGlassIcon,
    Surge as SurgeIcon,
    User as UserIcon,
    Vehicle as VehicleIcon,
} from "@bolteu/kalep-react-icons";

import KalepIconName = FleetOwnerPortalService.KalepIconName;

export function iconNameToKalepIcon(iconName: KalepIconName | string): (props: KalepIcon) => JSX.Element {
    switch (iconName) {
        case KalepIconName.USER:
            return UserIcon;
        case KalepIconName.VEHICLE:
            return VehicleIcon;
        case KalepIconName.COMPANY:
            return CompanyIcon;
        case KalepIconName.OFFER:
            return OfferIcon;
        case KalepIconName.CASH:
            return CashIcon;
        case KalepIconName.SURGE:
            return SurgeIcon;
        case KalepIconName.PERCENT:
            return PercentIcon;
        case KalepIconName.SANDGLASS:
            return SandGlassIcon;
        default:
            return ImageIcon;
    }
}
