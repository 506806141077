import {useMemo} from "react";
import {useIntl} from "react-intl";

import {Dash} from "common/constants";
import {useI18n} from "common/hooks/useI18n";
import {format} from "date-fns";
import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";

import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";
import {TableHeaders} from "@bolteu/kalep-table-react/build/types";

import {AutoAcceptedOrdersTableData} from "../components/AutoAcceptedIncomingOrdersTable";

export function useTableColumns() {
    const intl = useIntl();
    const {i18n} = useI18n();

    return useMemo<TableHeaders<AutoAcceptedOrdersTableData>>(() => {
        return {
            accepted_time: {
                label: i18n("auth.app.orders.incoming.time"),
                renderCell: (item: AutoAcceptedOrdersTableData) => {
                    const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];
                    const date = format(item.incoming_time * 1000, "P", {locale: dateFnsLocale});
                    const time = format(item.incoming_time * 1000, "HH:mm", {locale: dateFnsLocale});

                    return (
                        <DefaultCellContent compact>
                            <div className="flex h-full w-full flex-col justify-center">
                                <div className="text-primary antialiased">{date}</div>
                                <div className="text-secondary whitespace-nowrap text-xs antialiased">{time}</div>
                            </div>
                        </DefaultCellContent>
                    );
                },
            },
            driver_name: {
                label: i18n("auth.app.orders.incoming.driver"),
                renderCell: (item: AutoAcceptedOrdersTableData) => (
                    <DefaultCellContent>{item.driver_name}</DefaultCellContent>
                ),
            },
            client_name: {
                label: i18n("auth.app.orders.incoming.rider"),
                renderCell: (item: AutoAcceptedOrdersTableData) => (
                    <DefaultCellContent>{item.client_name}</DefaultCellContent>
                ),
            },
            pickup_address: {
                label: i18n("auth.app.orders.incoming.from"),
                renderCell: (item: AutoAcceptedOrdersTableData) => (
                    <DefaultCellContent className={item.address === Dash.EM ? "text-content-tertiary" : ""}>
                        {item.address}
                    </DefaultCellContent>
                ),
            },
        };
    }, [i18n, intl.locale]);
}
