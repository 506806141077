import {useCallback, useContext} from "react";

import {useI18n} from "common/hooks/useI18n";
import {CaptchaData, VerificationContextProvider} from "features/account/pages/Verification/VerificationProvider";

import {FleetOwnerVerificationNoAuthService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import CaptchaV2 from "./CaptchaV2";
import CaptchaV3 from "./CaptchaV3";

interface Props {
    captchaData: CaptchaData;
}

const CaptchaWrapper = ({captchaData}: Props) => {
    const {i18n} = useI18n();
    const {setRequestCodeError, requestCode} = useContext(VerificationContextProvider);

    const handleSubmit = useCallback(
        (captchaToken: string | null) => {
            if (!captchaToken) {
                setRequestCodeError(i18n("auth.app.error-view.something-went-wrong.title"));
            } else {
                requestCode({challenge: {token: captchaToken, type: captchaData.type}});
            }
        },
        [captchaData.type, i18n, setRequestCodeError, requestCode],
    );

    switch (captchaData.type) {
        case FleetOwnerVerificationNoAuthService.CaptchaChallengeType.WEBVIEW:
            return <CaptchaV2 siteKey={captchaData.siteKey} onCaptchaSubmit={handleSubmit} />;
        case FleetOwnerVerificationNoAuthService.CaptchaChallengeType.RECAPTCHA_NATIVE: {
            return <CaptchaV3 onCaptchaSubmit={handleSubmit} />;
        }
        default: {
            setRequestCodeError(i18n("auth.app.error-view.something-went-wrong.title"));
        }
    }

    return null;
};

export default CaptchaWrapper;
