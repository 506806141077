import {useCallback, useContext, useEffect, useState} from "react";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import {Page} from "common/components/page/Page";
import {useTabs} from "common/components/tab/useTabs";
import {EventName} from "common/constants/events";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import {FeaturesContextProvider} from "FeaturesProvider";

import {useSnackbar} from "@bolteu/kalep-react";

import {NavigationBarVisbilityContextProvider} from "../../../NavigationBarVisibilityProvider";
import PageHeader from "./components/PageHeader";
import {ShiftTab, useTabSelectorOptions} from "./hooks/useTabSelectorOptions";
import ActivityLog from "./log";
import {Planning} from "./planning";
import ManageShiftAssignmentsPage from "./planning/components/ManageShiftAssignmentsPage";

const fetchFunction = (api: Api) => api.fleetShiftManagement.getShifts();

const ShiftsPage = () => {
    const {trackEvent} = useTracking();
    const snackbar = useSnackbar();
    const {i18n} = useI18n();
    const tabs = useTabSelectorOptions();
    const features = useContext(FeaturesContextProvider);
    const {setIsNavigationBarVisible} = useContext(NavigationBarVisbilityContextProvider);
    const {activeTab, TabSelector} = useTabs(tabs, ShiftTab.ActivityLog);

    const [isManageAssignmentsPageOpen, setIsManageAssignmentsPageOpen] = useState(false);

    const {data: shifts, fetch: fetchShifts, status: shiftGetStatus} = useFetch(fetchFunction);

    const getShifts = useCallback(() => {
        if (fetchShifts && features?.shifts) {
            fetchShifts({});
        }
    }, [features?.shifts, fetchShifts]);

    useEffect(() => {
        getShifts();
    }, [getShifts]);

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.shifts.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    const handleManageAssignmentsClick = useCallback(() => {
        trackEvent(EventName.ManageShiftAssignmentsButtonClicked);
        setIsNavigationBarVisible(false);
        setIsManageAssignmentsPageOpen(true);
    }, [setIsNavigationBarVisible, trackEvent]);

    const handleCloseManageAssignmentsPage = useCallback(() => {
        setIsNavigationBarVisible(true);
        setIsManageAssignmentsPageOpen(false);
    }, [setIsNavigationBarVisible]);

    const handleAssignmentsUpdated = useCallback(() => {
        snackbar.add(
            {
                description: i18n("auth.app.fleet.shifts.assign_shift.shifts_assigned"),
            },
            {timeout: 3000},
        );
        getShifts();
    }, [getShifts, i18n, snackbar]);

    if (isManageAssignmentsPageOpen) {
        return (
            <ManageShiftAssignmentsPage
                onAssignmentsUpdated={handleAssignmentsUpdated}
                closePage={handleCloseManageAssignmentsPage}
            />
        );
    }

    if (!features?.shifts) {
        return <ErrorView type={ErrorViewType.ServiceUnavailable} />;
    }

    let Body = null;
    switch (activeTab) {
        case ShiftTab.ActivityLog:
            Body = <ActivityLog />;
            break;
        case ShiftTab.Planning:
        default:
            Body = <Planning shifts={shifts} shiftGetStatus={shiftGetStatus} getShifts={getShifts} />;
    }

    return (
        <Page>
            <PageHeader
                tabSelectorComponent={TabSelector}
                onShiftCreated={getShifts}
                onManageAssignmentsClick={handleManageAssignmentsClick}
                hasShifts={Boolean(shifts && shifts?.list.length > 0)}
            />
            {Body}
        </Page>
    );
};

export default ShiftsPage;
