import {LeadToFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";

interface ListDataProps {
    title: string;
    list?: LeadToFleetService.GenericField[];
}

const GenericFieldList = ({title, list}: ListDataProps) => {
    if (!list?.length) {
        return null;
    }
    return (
        <div>
            <h5 className="text-content-secondary mb-1 font-semibold">{title}</h5>
            <ul className="list-disc pl-6">
                {list.map((item) => (
                    <li key={item.name} className="text-base">
                        <span className="text-content-secondary">{item.name}:</span> {item.value}
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default GenericFieldList;
