import {FC, useCallback, useContext, useEffect, useState} from "react";
import {useIntl} from "react-intl";

import {EventName, EventPropertiesDownloadType} from "common/constants/events";
import {MimeTypes} from "common/constants/mimeTypes";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {ApiPeriod, filePeriod} from "common/services/period";
import {cleanStringForFileName, downloadBase64File} from "common/util/downloadUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button} from "@bolteu/kalep-react";

import {useDriversDownloadApi} from "../hooks/driver/useDriversDowloadApi";

interface DriversDownloadProps {
    period: ApiPeriod;
    isError: boolean;
}

const DriversDownload: FC<DriversDownloadProps> = ({period, isError}) => {
    const account = useContext(AccountContextProvider);
    const {setNotification} = useContext(NotificationContext);

    const intl = useIntl();
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();
    const downloadApi = useDriversDownloadApi(period);

    const [base64File, setBase64File] = useState<string | null>(null);

    const {data: downloadedReport, fetch: downloadReport, status: downloadedReportStatus} = useFetch(downloadApi);

    useEffect(() => {
        if (downloadedReportStatus === FetchStatus.Success) {
            if (!downloadedReport.file) {
                setNotification({
                    type: NotificationType.ERROR,
                    text: i18n("auth.app.orders.history.no-data-error"),
                });
            } else {
                setBase64File(downloadedReport.file);
            }
        }
    }, [downloadedReport, downloadedReportStatus, i18n, setNotification]);

    useEffect(() => {
        const companyName = account.selectedCompany?.company.name;
        if (base64File && companyName) {
            const periodStr = filePeriod(period, intl.locale);
            const cleanCompanyName = cleanStringForFileName(companyName);

            const fileName = `${i18n(
                "auth.app.fleet.engagement-dashboard.fleet_drivers",
            )}-${periodStr}-${cleanCompanyName}`;
            downloadBase64File(base64File, MimeTypes.CSV, fileName);
            setBase64File(null);
        }
    }, [
        account.selectedCompany?.company.name,
        base64File,
        downloadedReport,
        downloadedReportStatus,
        intl,
        i18n,
        period,
    ]);

    const downloadCsv = useCallback(() => {
        if (downloadReport) {
            downloadReport({format: FleetOwnerPortalService.FileFormat.CSV});
            trackEvent(EventName.FileDownloaded, {downloadType: EventPropertiesDownloadType.EngagementDashboardCsv});
        }
    }, [downloadReport, trackEvent]);

    return (
        <Button
            variant="secondary"
            onClick={downloadCsv}
            loading={downloadedReportStatus === FetchStatus.Loading}
            disabled={isError}
        >
            {i18n("common.download")}
        </Button>
    );
};

export default DriversDownload;
