import {useMemo} from "react";
import {useNavigate} from "react-router-dom";

import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Accordion, AccordionItem, Button, Menu, MenuItem} from "@bolteu/kalep-react";
import SvgChevronDown from "@bolteu/kalep-react-icons/dist/ChevronDown";

import {AccordionTableCell} from "./AccordionTableCell";
import MobileAccordionTitle from "./MobileAccordionTitle";
import {ApiDrivenTableTransforms, ApiDriverTableInlineAction, UseableColumns} from "./types";
import {generateSimpleKey, isDateColumn, isReferenceColumn, isStatusColumn} from "./util";

import ApiColumn = FleetOwnerPortalService.ApiColumn;
import ReferenceType = FleetOwnerPortalService.ReferenceType;
import ReferenceEntity = FleetOwnerPortalService.ReferenceEntity;
import ReferenceColumn = FleetOwnerPortalService.ReferenceColumn;

type ApiDrivenTableMobileProps = UseableColumns & {
    numberOfRecords: number;
    emptyStateComponent?: React.ReactNode;
    inlineActions?: ApiDriverTableInlineAction[];
    transforms?: ApiDrivenTableTransforms;
};

export function ApiDrivenTableMobile(apiDrivenTableMobileProps: ApiDrivenTableMobileProps) {
    const {i18n} = useI18n();
    const navigate = useNavigate();
    const {getDriverDetailsPath, getVehicleDetailsPath, getVehicleListingApplicationPath} = useAbsolutePath();
    const {
        userDefinedVisibleColumns,
        subColumns,
        stateColumns,
        numberOfRecords,
        statusColumn,
        transforms,
        inlineActions,
    } = apiDrivenTableMobileProps;

    const getSubColumns = useMemo(
        () =>
            userDefinedVisibleColumns.reduce((acc, column) => {
                acc[column.key] = subColumns.filter((c) => c.relation_key === column.key);
                return acc;
            }, {} as Record<string, FleetOwnerPortalService.ApiColumn[]>),
        [userDefinedVisibleColumns, subColumns],
    );

    const generateProfilePath = (referenceType: ReferenceType, column: ApiColumn, rowIdx: number): string => {
        const entity = column.cells[rowIdx] as ReferenceEntity;
        switch (referenceType) {
            case ReferenceType.DRIVER:
                return getDriverDetailsPath(Number(entity.id));
            case ReferenceType.VEHICLE:
                return getVehicleDetailsPath(Number(entity.id));
            case ReferenceType.VEHICLE_LISTING_APPLICATION:
                return getVehicleListingApplicationPath(Number(entity.id));
            default:
                throw new Error(`Unhandled reference type: ${referenceType}`);
        }
    };

    const goToProfile = (referenceType: ReferenceType, column: ApiColumn, rowIdx: number) => {
        const path = generateProfilePath(referenceType, column, rowIdx);
        return () => navigate(path);
    };

    const getMenuItem = (column: ReferenceColumn, rowIdx: number) => {
        const openProfile =
            column.reference_type === ReferenceType.DRIVER
                ? i18n("auth.app.fleet.shifts.activity_log.driver_profile")
                : i18n("auth.app.fleet.shifts.activity_log.vehicle_profile");

        return (
            <MenuItem
                key={column.cells[rowIdx].id}
                label={openProfile}
                onClick={goToProfile(column.reference_type, column, rowIdx)}
            />
        );
    };

    const getReferenceColumnListText = (referenceType: ReferenceType) => {
        switch (referenceType) {
            case ReferenceType.DRIVER:
                return i18n("auth.app.fleet.shifts.activity_log.driver_profile");
            case ReferenceType.VEHICLE:
                return i18n("auth.app.fleet.shifts.activity_log.vehicle_profile");
            case ReferenceType.VEHICLE_LISTING_APPLICATION:
                return i18n("auth.app.fleet.vehicle_marketplace.listings.table.listing_application");

            default:
                return UnreachableCode.never(referenceType, <></>);
        }
    };

    const getProfileLinks = (referenceColumns: ReferenceColumn[], rowIdx: number) => {
        const profileButton = (
            <Button variant="secondary" endIcon={<SvgChevronDown />}>
                {i18n("auth.app.fleet.shifts.activity_log.see_profiles")}
            </Button>
        );

        const validReferences = referenceColumns.filter((column) => column.cells[rowIdx]?.id);
        if (validReferences.length > 1) {
            return (
                <div>
                    <Menu menuButton={profileButton} portal>
                        {validReferences.map((column) => getMenuItem(column, rowIdx))}
                    </Menu>
                </div>
            );
        }

        if (validReferences.length === 1) {
            const column = validReferences[0];
            return (
                <button type="button" onClick={goToProfile(column.reference_type, column, rowIdx)}>
                    <div className="text-action-primary">{getReferenceColumnListText(column.reference_type)}</div>
                </button>
            );
        }

        return null;
    };

    if (numberOfRecords === 0 && apiDrivenTableMobileProps.emptyStateComponent) {
        return <>{apiDrivenTableMobileProps.emptyStateComponent}</>;
    }

    return (
        <div className="w-full">
            <Accordion>
                {Array.from({length: numberOfRecords}).map((_, rowIdx) => (
                    <AccordionItem
                        key={generateSimpleKey(rowIdx)}
                        id={`${rowIdx}`}
                        title={{
                            primary: (
                                <MobileAccordionTitle
                                    rowIdx={rowIdx}
                                    stateColumns={stateColumns}
                                    statusColumn={statusColumn}
                                    userDefinedVisibleColumns={userDefinedVisibleColumns}
                                    transforms={transforms}
                                />
                            ),
                        }}
                    >
                        <ul className="m-0 flex list-none flex-col gap-4 p-0">
                            {userDefinedVisibleColumns
                                .filter(
                                    (column) =>
                                        !isReferenceColumn(column) && !isDateColumn(column) && !isStatusColumn(column),
                                )
                                .map((column) => (
                                    <li key={column.key}>
                                        <AccordionTableCell
                                            column={column}
                                            valueAtIdx={rowIdx}
                                            subColumns={getSubColumns[column.key]}
                                            transform={transforms ? transforms[column.key] : undefined}
                                        />
                                    </li>
                                ))}
                        </ul>
                        <div className="flex flex-wrap items-center justify-between pt-5">
                            {getProfileLinks(
                                userDefinedVisibleColumns.filter(isReferenceColumn) as ReferenceColumn[],
                                rowIdx,
                            )}
                            <div className="flex flex-wrap items-center justify-end gap-1">
                                {inlineActions?.map((action) => action.render(rowIdx))}
                            </div>
                        </div>
                    </AccordionItem>
                ))}
            </Accordion>
        </div>
    );
}
