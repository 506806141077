import {I18nFn} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {MAX_GRAPH_SECTIONS, MIX_GRAPH_SECTION_WIDTH} from "../../constants";
import {BalanceEarnings, BalanceExpenses} from "../../types";
import {GraphData, GraphDataSection} from "./types";

import Balance = FleetOwnerPortalService.Balance;

type ExpensesWithoutPayouts = Omit<BalanceExpenses, "payouts">;

export interface GetGraphsData {
    income: GraphData;
    outcome: GraphData;
}

export function getGraphsData(i18n: I18nFn, data: Balance): GetGraphsData {
    const {payouts, ...expensesWithoutPayouts} = data.expenses;

    const incomeTotalValue = calculateTotalValue(data.earnings as Record<string, number>);
    const outcomeTotalValue = calculateTotalValue(expensesWithoutPayouts);

    const incomeGraphSections = Object.entries(data.earnings).map(([key, value]) => ({
        name: i18n(`auth.app.fleet.balance.transactions_list.earnings.${key as keyof BalanceEarnings}.title`),
        value: value as number,
    }));
    const outcomeGraphSections = Object.entries(expensesWithoutPayouts).map(([key, value]) => ({
        name: i18n(`auth.app.fleet.balance.transactions_list.expenses.${key as keyof ExpensesWithoutPayouts}.title`),
        value,
    }));

    const incomeGraphScale = Math.min(incomeTotalValue / outcomeTotalValue, 1);
    const outcomeGraphScale = Math.min(outcomeTotalValue / incomeTotalValue, 1);

    const incomeSections = getGraphSection(i18n, incomeGraphSections, incomeTotalValue);
    const outcomeSections = getGraphSection(i18n, outcomeGraphSections, outcomeTotalValue);

    return {
        income: {widthScale: incomeGraphScale, total: incomeTotalValue, sections: incomeSections},
        outcome: {widthScale: outcomeGraphScale, total: outcomeTotalValue, sections: outcomeSections},
    };
}

function calculateTotalValue(data: Record<string, number>): number {
    return Object.values(data).reduce((acc, value) => acc + value, 0);
}

function getGraphSection(i18n: I18nFn, graphSections: GraphDataSection[], totalValue: number): GraphDataSection[] {
    const sectionsWithoutZeroValue = graphSections.filter(({value}) => value !== 0);
    sectionsWithoutZeroValue.sort((a, b) => b.value - a.value);

    let otherSections: GraphDataSection[] = [];
    const sectionsWithoutOther: GraphDataSection[] = [];

    if (sectionsWithoutZeroValue.length > MAX_GRAPH_SECTIONS) {
        otherSections = sectionsWithoutZeroValue.splice(MAX_GRAPH_SECTIONS - 1);
    }

    for (const section of sectionsWithoutZeroValue) {
        if (section.value / totalValue < MIX_GRAPH_SECTION_WIDTH) {
            otherSections.push(section);
        } else {
            sectionsWithoutOther.push(section);
        }
    }

    const otherTotalValue = otherSections.reduce((acc, {value}) => acc + value, 0);
    if (otherTotalValue === 0) {
        return sectionsWithoutOther;
    }

    const otherSection: GraphDataSection = {
        name: i18n("auth.app.fleet.balance.transactions.card.graph.other"),
        value: otherTotalValue,
    };
    return [...sectionsWithoutOther, otherSection];
}
