import {FleetOwnerPortalService, FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import FileRow from "./FileRow";
import styles from "./generatedFiles.module.css";
import Header from "./Header";

interface Props {
    drivers: FleetOwnerPortalService.Driver[];
    reports: FleetOwnerReportingService.CompanyReportWithUrl[];
    onCollapse: () => void;
}

const GeneratedFilesBody = ({drivers, reports, onCollapse}: Props) => {
    return (
        <div
            className={`flex max-h-[400px] max-w-[400px] flex-col gap-3 rounded-lg border border-neutral-300 bg-white py-4 ${styles["custom-shadow"]}`}
        >
            <Header collapse={onCollapse} />
            <div className="overflow-y-auto px-6">
                {reports.map((report) => (
                    <FileRow key={report.id} row={report} drivers={drivers} />
                ))}
            </div>
        </div>
    );
};

export default GeneratedFilesBody;
