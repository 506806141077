import {useCallback} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

const createTinFunc = (api: Api, body: FleetOwnerPortalService.AddTaxIdentificationRequest) =>
    api.fleetOwnerPortal.addTaxIdentification(body);

const updateTinFunc = (api: Api, body: FleetOwnerPortalService.EditTaxIdentificationRequest) =>
    api.fleetOwnerPortal.updateTaxIdentification(body);

const deleteTinFunc = (api: Api, body: FleetOwnerPortalService.DeleteTaxIdentificationRequest) =>
    api.fleetOwnerPortal.deleteTaxIdentificationDetails(body);

export interface TinApiData {
    id: number | undefined;
    country: string;
    tin: string;
}

export const useTin = () => {
    const {fetch: createTinFetch, status: createTinStatus} = useFetch(createTinFunc);
    const {fetch: updateTinFetch, status: updateTinStatus} = useFetch(updateTinFunc);
    const {fetch: deleteTinFetch, status: deleteTinStatus} = useFetch(deleteTinFunc);

    const addTin = useCallback(
        (tinData: TinApiData) => {
            const {country, id, tin} = tinData;
            if (id && updateTinFetch) {
                return updateTinFetch({
                    tin_identification_number: {
                        country,
                        tin,
                        id,
                    },
                });
            }
            if (createTinFetch) {
                return createTinFetch({
                    tin_identification_number: {
                        country,
                        tin,
                    },
                });
            }
            return Promise.reject();
        },
        [updateTinFetch, createTinFetch],
    );

    const deleteTin = useCallback(
        (id: number) => {
            if (deleteTinFetch && id) {
                return deleteTinFetch({
                    id,
                });
            }
            return Promise.reject();
        },
        [deleteTinFetch],
    );

    const isLoading = [createTinStatus, updateTinStatus, deleteTinStatus].includes(FetchStatus.Loading);

    return {
        addTin,
        deleteTin,
        isLoading,
        createTinStatus,
        deleteTinStatus,
        updateTinStatus,
    };
};
