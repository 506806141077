import {useCallback, useMemo, useState} from "react";
import {useIntl} from "react-intl";

import {Dash} from "common/constants";
import {useI18n} from "common/hooks/useI18n";
import {format, parse} from "date-fns";
import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";

import {Dialog, GhostButton} from "@bolteu/kalep-react";

import {HoldingFleetInvoicesTableData} from "../hooks/useInvoices";

const MAX_SHOW_SUB_FLEETS = 5;

interface Props {
    invoice: HoldingFleetInvoicesTableData;
    onClose: () => void;
}

export const InvoiceDetailsDialog = ({invoice, onClose}: Props) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const [isAllShown, setIsAllShown] = useState(false);

    const showAllSubFleets = useCallback(() => setIsAllShown(true), []);

    const subFleets = useMemo(() => {
        if (isAllShown) {
            return invoice.sub_fleets.map((subFleet) => <li key={subFleet.id}>{subFleet.name}</li>);
        }
        return invoice.sub_fleets
            .slice(0, MAX_SHOW_SUB_FLEETS)
            .map((subFleet) => <li key={subFleet.id}>{subFleet.name}</li>);
    }, [invoice.sub_fleets, isAllShown]);

    const isViewAllButtonShown = !isAllShown && invoice.sub_fleets.length > 5;

    const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];
    const dateOptions = {locale: dateFnsLocale};
    const date = format(parse(invoice.date, "P", new Date(), dateOptions), "d MMM, yyyy", dateOptions);
    const dueDate =
        invoice.due_date === Dash.EM
            ? Dash.EM
            : format(parse(invoice.due_date, "P", new Date(), dateOptions), "d MMM, yyyy", dateOptions);

    return (
        <Dialog isOpen onRequestClose={onClose} title={`${i18n("common.invoice_number")} ${invoice.public_id}`}>
            <Dialog.Content>
                <div className="pb-3">
                    <div className="flex flex-col gap-1 pb-2">
                        <p className="text-neutral-700">{i18n("common.total_sum")}</p>
                        <p className="text-xl font-semibold">
                            {invoice.total_sum}{" "}
                            <span className="text-lg font-normal text-neutral-700">{invoice.currency}</span>
                        </p>
                    </div>
                    <div className="flex flex-col gap-1 py-2">
                        <p className="text-neutral-700">{i18n("common.date")}</p>
                        <p>{date}</p>
                    </div>
                    <div className="flex flex-col gap-1 py-2">
                        <p className="text-neutral-700">{i18n("common.due_date")}</p>
                        <p className={dueDate === Dash.EM ? "text-content-tertiary" : ""}>{dueDate}</p>
                    </div>
                    <div className="flex flex-col gap-1 pt-2">
                        <p className="text-neutral-700">
                            {i18n("auth.app.invoices.holding-fleet.invoices_table.subsidiaries_in_invoice")}
                        </p>
                        <ul className="list-disc pl-6 text-neutral-900">{subFleets}</ul>
                        {isViewAllButtonShown && (
                            <GhostButton onClick={showAllSubFleets} overrideClassName="text-green-700">
                                {i18n("common.view_all")}
                            </GhostButton>
                        )}
                    </div>
                </div>
            </Dialog.Content>
        </Dialog>
    );
};
