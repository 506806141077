import {useMemo} from "react";

import {FetchStatus} from "common/hooks/useFetch";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";

import {FleetShiftManagementService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {ShiftCard} from "../components/ShiftCard";

interface Props {
    shifts?: FleetShiftManagementService.ShiftWithAllocationSummary[];
    fetchStatus: FetchStatus;
    onDeleteClick: (shiftId: number) => void;
    onEditClick: (shift: FleetShiftManagementService.ShiftWithAllocationSummary) => void;
}

export const useShifts = ({shifts, fetchStatus, onDeleteClick, onEditClick}: Props) => {
    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";
    const isMediumView = viewport === "md";

    const shiftsList = useMemo(() => {
        if ([FetchStatus.Init, FetchStatus.Loading, FetchStatus.Error].includes(fetchStatus)) {
            return null;
        }
        if (!shifts || !shifts.length) {
            return null;
        }

        const getShiftCard = (shift: FleetShiftManagementService.ShiftWithAllocationSummary) => (
            <ShiftCard key={shift.id} shift={shift} onDeleteClick={onDeleteClick} onEditClick={onEditClick} />
        );

        let List: JSX.Element | JSX.Element[] | null;
        if (isMobileView) {
            List = shifts.map((shift) => getShiftCard(shift));
        } else if (isMediumView) {
            List = (
                <>
                    <div className="flex flex-col gap-4">
                        {shifts.map((shift, i) => (i % 2 === 0 ? getShiftCard(shift) : null))}
                    </div>
                    <div className="flex flex-col gap-4">
                        {shifts.map((shift, i) => (i % 2 === 1 ? getShiftCard(shift) : null))}
                    </div>
                </>
            );
        } else {
            List = (
                <>
                    <div className="flex flex-col gap-4">
                        {shifts.map((shift, i) => (i % 3 === 0 ? getShiftCard(shift) : null))}
                    </div>
                    <div className="flex flex-col gap-4">
                        {shifts.map((shift, i) => (i % 3 === 1 ? getShiftCard(shift) : null))}
                    </div>
                    <div className="flex flex-col gap-4">
                        {shifts.map((shift, i) => (i % 3 === 2 ? getShiftCard(shift) : null))}
                    </div>
                </>
            );
        }

        return (
            <div className="grid grid-cols-1 content-start items-start gap-4 overflow-y-auto pb-7 md:grid-cols-2 lg:grid-cols-3">
                {List}
            </div>
        );
    }, [fetchStatus, isMediumView, isMobileView, onDeleteClick, onEditClick, shifts]);

    return shiftsList;
};
