import {useI18n} from "common/hooks/useI18n";

import {Alert} from "@bolteu/kalep-react";

interface Props {
    hasInvoices: boolean;
    hasHoldingFleetInvoicing: boolean;
}

const FleetIsSubsidiaryBanner = ({hasInvoices, hasHoldingFleetInvoicing}: Props) => {
    const {i18n} = useI18n();

    if (!hasInvoices || !hasHoldingFleetInvoicing) {
        return null;
    }

    return (
        <Alert
            title={i18n("auth.app.invoices.taxify-invoices.sub-fleet.warning.title")}
            severity="warning"
            overrideClassName="max-w-3xl mb-4 text-base"
        >
            <p className="text-base">{i18n("auth.app.invoices.taxify-invoices.sub-fleet.warning.description")}</p>
        </Alert>
    );
};

export default FleetIsSubsidiaryBanner;
