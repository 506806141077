import {FC, useEffect} from "react";

import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {Page} from "common/components/page/Page";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";

import {ExpiringDocumentsTable} from "./ExpiringDocumentsTable";

const ExpiringDocumentsPage: FC = () => {
    const {i18n} = useI18n();

    useEffect(() => {
        const pageTitle = i18n("auth.app.fleet.expiring-documents.title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    return (
        <>
            <Page>
                <ResponsiveHeader
                    type={ResponsiveHeaderType.MainPage}
                    text={i18n("auth.app.fleet.expiring-documents.title")}
                />
                <ExpiringDocumentsTable />
            </Page>
        </>
    );
};

export default ExpiringDocumentsPage;
