import {FC} from "react";

import DriverIcon from "assets/icons/car-driver.svg?react";
import CashlessIcon from "assets/icons/cashless.svg?react";
import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {getDriverName} from "common/constants/driverName";
import {useI18n} from "common/hooks/useI18n";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip} from "@bolteu/kalep-react";

import DriverProfileBodyStatusChip from "./StatusChip";

import EntityPortalStatus = FleetOwnerPortalService.EntityPortalStatus;

interface PageHeaderProps {
    driver: FleetOwnerPortalService.GetDriverResponse;
    shouldShowCashPaymentsChip: boolean;
}

const PageHeader: FC<PageHeaderProps> = ({driver, shouldShowCashPaymentsChip}) => {
    const {i18n} = useI18n();

    const isDriverDeactivated = driver.portal_status === EntityPortalStatus.Deactivated;
    const isStatusChipShown = driver.portal_status !== EntityPortalStatus.Active;
    const iconOpacity = isDriverDeactivated ? 0.4 : 1;

    return (
        <div className="flex flex-row">
            <div className="flex-0">
                <DriverIcon height="72" width="72" opacity={iconOpacity} />
            </div>
            <div className="flex flex-col justify-center pl-6">
                <ResponsiveHeader
                    disabled={isDriverDeactivated}
                    type={ResponsiveHeaderType.InnerPage}
                    text={getDriverName(driver.first_name, driver.last_name, driver.email)}
                />
                {(isStatusChipShown || shouldShowCashPaymentsChip) && (
                    <div className="flex flex-col gap-2 pt-4 md:flex-row">
                        {isStatusChipShown && (
                            <div className="flex-0">
                                <DriverProfileBodyStatusChip status={driver.portal_status} />
                            </div>
                        )}
                        {shouldShowCashPaymentsChip && (
                            <div className="flex-0">
                                <Chip
                                    label={i18n("driver.cash_rides_disabled")}
                                    size="sm"
                                    startIcon={<CashlessIcon />}
                                    color="neutral"
                                    variant="filled"
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default PageHeader;
