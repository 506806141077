import {useCallback, useContext} from "react";
import {matchPath, useLocation, useNavigate} from "react-router-dom";

import {CompanyOnboardingRoutePrefix, HoldingFleetRoutePrefix} from "common/constants/routes";
import {Company, CompanyType, isActiveFleet, isFleet} from "common/constants/types";
import {REDIRECT_PARAM} from "common/layouts/CompanyManagementLayout";
import {LocationWithState, SearchLocationState} from "common/navigation/types/locationState";
import {ActiveCompanyStorage} from "common/services/authService";
import {localStorageService} from "common/services/LocalStorageService";
import {getNestedRouteReplaced, nestedRoutes} from "common/util/nestedRouteUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {FleetOwnerAuthNoAuthService, FleetOwnerService} from "@bolteu/bolt-server-api-fleet-owner-portal";

export enum CompanySwitchType {
    DEFAULT = "default", // try to keep the current navigation path if possible
    NAVIGATE_TO_INDEX = "navigate_to_index",
    STATE_SWITCH_ONLY = "state_switch_only",
}

const getNewPathForSameCompanyTypeAndStatus = (
    newCompany: number,
    newCompanyType: FleetOwnerAuthNoAuthService.CompanyIdentityType,
    previousCompany: number,
    previousPath: string,
) => {
    // To not navigate FO to some nested routes which have specific ids
    const nestedRoute = nestedRoutes.find((path) => matchPath({path}, previousPath));
    if (nestedRoute) {
        return getNestedRouteReplaced(nestedRoute, newCompany, newCompanyType);
    }
    return previousPath.replace(String(previousCompany), String(newCompany));
};

const getNewPathForGoRedirectRoute = (newCompany: number, previousPath: string) => {
    return previousPath.replace(REDIRECT_PARAM, String(newCompany));
};

const getIndexPath = (
    newCompany: number,
    newCompanyType: FleetOwnerAuthNoAuthService.CompanyIdentityType,
    isNewCompanyInactive: boolean,
) => {
    if (newCompanyType === CompanyType.FLEET_COMPANY && !isNewCompanyInactive) {
        return `/${newCompany}`;
    }
    if (newCompanyType === CompanyType.FLEET_COMPANY && isNewCompanyInactive) {
        return `/${CompanyOnboardingRoutePrefix}/${newCompany}`;
    }
    if (newCompanyType === CompanyType.HOLDING_FLEET) {
        return `/${HoldingFleetRoutePrefix}/${newCompany}`;
    }
    return "/";
};

export function useCompanySelection() {
    const navigate = useNavigate();
    const location = useLocation() as LocationWithState<SearchLocationState>;
    const accountState = useContext(AccountContextProvider);

    const getNavigationData = useCallback(
        (
            newCompany: number,
            newCompanyType: FleetOwnerAuthNoAuthService.CompanyIdentityType,
            newCompanyStatus: FleetOwnerService.ExpandedCompanyStatus | undefined,
            previousCompany: number | undefined,
            previousCompanyType: FleetOwnerAuthNoAuthService.CompanyIdentityType | undefined,
            previousCompanyStatus: FleetOwnerService.ExpandedCompanyStatus | undefined,
            navigateToIndex: boolean,
        ) => {
            const [searchParameters, previousPath] = [location.search, location.pathname];
            let newPath: string;

            const isSwitchToSameCompanyType = previousCompany && previousCompanyType === newCompanyType;
            const isSwitchToSameCompanyStatus = previousCompanyStatus && previousCompanyStatus === newCompanyStatus;
            if (!navigateToIndex && isSwitchToSameCompanyType && isSwitchToSameCompanyStatus) {
                newPath = getNewPathForSameCompanyTypeAndStatus(
                    newCompany,
                    newCompanyType,
                    previousCompany,
                    previousPath,
                );
            } else if (!previousCompany && newCompanyType === CompanyType.FLEET_COMPANY && location.state?.prevPath) {
                newPath = getNewPathForGoRedirectRoute(newCompany, previousPath);
            } else {
                newPath = getIndexPath(newCompany, newCompanyType, newCompanyStatus !== "active");
            }
            return {newPath, searchParameters};
        },
        [location],
    );

    const setStateToNewCompany = useCallback(
        (newCompany: Company, newCompanyType: FleetOwnerAuthNoAuthService.CompanyIdentityType) => {
            if (isActiveFleet(newCompany)) {
                localStorageService.setItem(ActiveCompanyStorage, String(newCompany.id));
            }
            accountState.setSelectedCompany({
                company: newCompany,
                type: newCompanyType,
            });
        },
        [accountState],
    );

    return useCallback(
        async (
            company: Company,
            companyType: CompanyType,
            switchType: CompanySwitchType = CompanySwitchType.DEFAULT,
        ) => {
            let isStatusChanged = false;
            if (isFleet(company) && isFleet(accountState.selectedCompany?.company)) {
                isStatusChanged = company.status !== accountState.selectedCompany?.company.status;
            }

            if (
                company.id !== accountState.selectedCompany?.company.id ||
                companyType !== accountState.selectedCompany?.type ||
                isStatusChanged
            ) {
                const previousSelectedCompany = accountState.selectedCompany?.company.id;
                const previousSelectedCompanyType = accountState.selectedCompany?.type;
                const previousSelectedCompanyStatus = isFleet(accountState.selectedCompany?.company)
                    ? accountState.selectedCompany?.company.status
                    : undefined;

                const companyStatus = isFleet(company) ? company.status : undefined;

                setStateToNewCompany(company, companyType);

                if (switchType !== CompanySwitchType.STATE_SWITCH_ONLY) {
                    const {newPath, searchParameters} = getNavigationData(
                        company.id,
                        companyType,
                        companyStatus,
                        previousSelectedCompany,
                        previousSelectedCompanyType,
                        previousSelectedCompanyStatus,
                        switchType === CompanySwitchType.NAVIGATE_TO_INDEX,
                    );

                    navigate({
                        pathname: newPath,
                        search: searchParameters,
                    });
                }
            }
        },
        [
            accountState.selectedCompany?.company,
            accountState.selectedCompany?.type,
            setStateToNewCompany,
            getNavigationData,
            navigate,
        ],
    );
}
