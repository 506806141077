import {FC, useEffect} from "react";

import {twMerge} from "tailwind-merge";

import {Notification} from "../services/notificationProvider";

export interface Props {
    notification: Notification | null;
    setNotification: (notification: Notification | null) => void;
}

const TopLeftCornerNotification: FC<Props> = ({notification, setNotification}) => {
    useEffect(() => {
        const timeout = setTimeout(() => setNotification(null), notification?.timeout ?? 4000);
        return () => clearTimeout(timeout);
    }, [notification, setNotification]);

    const getNtfSubClass = () => {
        if (notification?.type === "success") {
            return "bg-green-500";
        }
        return "bg-red-500";
    };

    return (
        <div
            className={twMerge(
                "fixed top-16 left-2 z-[999] max-w-[300px] break-words rounded-sm p-2 text-white shadow-md sm:max-w-md",
                getNtfSubClass(),
                !notification && "hidden",
            )}
        >
            {notification?.text}
        </div>
    );
};

export default TopLeftCornerNotification;
