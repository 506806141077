import {IntlShape} from "react-intl";

import {ApiPeriod, apiPeriodToDates, Period} from "common/services/period";

export function formatPeriodWithYear(intl: IntlShape, period: ApiPeriod) {
    const date = apiPeriodToDates(period);
    switch (period.period) {
        case Period.ONGOING_DAY:
            return intl.formatDate(new Date(), {dateStyle: "medium"});
        case Period.MONTH:
            return intl.formatDate(date.start, {month: "long", year: "numeric"});
        case Period.WEEK:
        case Period.CUSTOM:
        default:
            return intl.formatDateTimeRange(date.start, date.end, {dateStyle: "medium"});
    }
}

export function formatPeriodWithoutYear(intl: IntlShape, period: ApiPeriod) {
    const date = apiPeriodToDates(period);
    switch (period.period) {
        case Period.ONGOING_DAY:
            return intl.formatDate(new Date(), {month: "long", day: "numeric"});
        case Period.MONTH:
            return intl.formatDate(date.start, {month: "long"});
        case Period.WEEK:
        case Period.CUSTOM:
        default:
            return intl.formatDateTimeRange(date.start, date.end, {month: "long", day: "numeric"});
    }
}
