import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Chip} from "@bolteu/kalep-react";

interface VehicleListingStatusChipProps {
    status: VehicleMarketplaceService.ListingStatus;
}
export const VehicleListingStatusChip = ({status}: VehicleListingStatusChipProps) => {
    switch (status) {
        case VehicleMarketplaceService.ListingStatus.PUBLISHED:
            return <Chip label="Active" appearance="action" size="sm" />;
        case VehicleMarketplaceService.ListingStatus.DRAFT:
            return <Chip label="Draft" appearance="promo" size="sm" />;
        case VehicleMarketplaceService.ListingStatus.INACTIVE:
            return <Chip label="Deactivated" appearance="neutral" size="sm" />;
        default:
            return UnreachableCode.never(status, null);
    }
};
