import {useCallback, useContext, useEffect} from "react";

import {FixedHeaderTable} from "common/components/styledComponent/FixedHeaderTable";
import {useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {OrderFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import NoOrders from "../../components/NoOrders";
import {IncomingOrdersType, PollingInterval} from "..";
import {useTableColumns} from "../hooks/useAutoAcceptedOrderTableColumns";

interface Props {
    setIncomingOrdersType: (type: IncomingOrdersType) => void;
}

export interface AutoAcceptedOrdersTableData {
    id: string;
    driver_name: string;
    address: string | null;
    incoming_time: number;
    client_name: string | null;
}

const getOrdersFetchFunction = (api: Api) => api.orderFleetShardedApi.getRecentlyAcceptedOrders();

const AutoAcceptedOrdersTable = ({setIncomingOrdersType}: Props) => {
    const columns = useTableColumns();

    const fleet = useContext(AccountContextProvider)?.getFleet();

    const {data, fetch} = useFetch(getOrdersFetchFunction);

    useEffect(() => {
        if (fleet && fetch) {
            fetch({});
            const interval = setInterval(() => fetch({}), PollingInterval);
            return () => {
                clearInterval(interval);
            };
        }

        return () => {};
    }, [fetch, fleet]);

    useEffect(() => {
        if (data) {
            setIncomingOrdersType(
                data?.is_auto_accept === 1 ? IncomingOrdersType.AUTO_ACCEPTED_ORDERS : IncomingOrdersType.MANUAL_ORDERS,
            );
        }
    }, [data, setIncomingOrdersType]);

    const renderEmptyPlaceholder = useCallback(() => {
        return <NoOrders />;
    }, []);

    const getFields = (order: OrderFleetService.FleetAcceptedOrder): AutoAcceptedOrdersTableData => {
        return {
            id: String(order.order_try_id),
            incoming_time: order.created,
            address: order.address,
            driver_name: order.driver_name,
            client_name: order.client_name,
        };
    };

    const items = data?.accepted_orders.map(getFields) || [];

    return (
        <FixedHeaderTable
            columns={columns}
            items={items}
            aria-label="Auto accepted orders table"
            renderEmptyPlaceholder={renderEmptyPlaceholder}
        />
    );
};

export default AutoAcceptedOrdersTable;
