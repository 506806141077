import {useContext, useEffect} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import DriverRegistrationByCompanyIdAndSource = FleetOwnerPortalService.DriverRegistrationByCompanyIdAndSource;
import {AccountContextProvider} from "../../../../account/accountStateProvider";

interface DriverRegistrationSuccess {
    data: DriverRegistrationByCompanyIdAndSource;
    status: FetchStatus.Success;
}

interface DriverRegistrationSuccessElse {
    data: null;
    status: FetchStatus.Error | FetchStatus.Init | FetchStatus.Loading;
}

const getDriverRegistrationsDataFetchFunction = (api: Api) => api.fleetOwnerPortal.getDriverRegistrations();

export function useDriverRegistration(id: number): DriverRegistrationSuccess | DriverRegistrationSuccessElse {
    const fleet = useContext(AccountContextProvider)?.getFleet();
    const {status, data, fetch} = useFetch(getDriverRegistrationsDataFetchFunction);

    useEffect(() => {
        if (fleet) {
            if (fetch) {
                fetch({});
            }
        }
    }, [fetch, fleet]);

    if (status === FetchStatus.Success) {
        const registrationData = data.list.find((registration) => registration.id === id);
        if (registrationData) {
            return {
                data: registrationData,
                status,
            };
        }

        // Error if queried registration ID can't be found in the list of registrations
        return {
            data: null,
            status: FetchStatus.Error,
        };
    }

    return {
        data: null,
        status,
    };
}
