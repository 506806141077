import {useCallback, useEffect, useState} from "react";

import {UploadDocument} from "common/components/document/DocumentUpload/UploadDocument";
import {FetchStatus} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {apiTimestampToDate} from "common/util/apiTimestampToDate";
import {getDayMonthYearDate, getIsoDate} from "common/util/FormatUtil";
import Markdown from "markdown-to-jsx";
import {twMerge} from "tailwind-merge";

import {Button, IconButton, ListItemLayout, Typography} from "@bolteu/kalep-react";
import {Bin} from "@bolteu/kalep-react-icons";

interface DocManageProps {
    deletingStatus: FetchStatus;
    uploadingStatus: FetchStatus;
    isRequired: boolean;
    isExpiryRequired: boolean;
    documentId?: number;
    documentName?: string;
    documentExpires?: number;
    label?: string;
    hint?: string;
    error?: string;
    onUpload: (selectedFile: File | null, expires?: string) => Promise<void>;
    onDelete: (documentId: number) => Promise<void>;
}

const ManageDocument = (props: DocManageProps) => {
    const {
        deletingStatus,
        uploadingStatus,
        isRequired,
        isExpiryRequired,
        documentId,
        documentName,
        documentExpires,
        label,
        hint,
        error,
        onUpload,
        onDelete,
    } = props;

    const {i18n} = useI18n();
    const [isUploadDrawerOpen, setIsUploadDrawerOpen] = useState(false);

    const fileExists = documentId && documentName;

    const isUploading = uploadingStatus === FetchStatus.Loading;
    const isDeleting = deletingStatus === FetchStatus.Loading;

    const onClose = useCallback(() => {
        setIsUploadDrawerOpen(false);
    }, []);

    const uploadFile = useCallback(() => {
        setIsUploadDrawerOpen(true);
    }, []);

    useEffect(() => {
        if (uploadingStatus === FetchStatus.Success) {
            setIsUploadDrawerOpen(false);
        }
    }, [uploadingStatus]);

    const onConfirmClick = useCallback(
        async (selectedFile: File | null, expiryDate: Date | null) => {
            if (!onUpload) {
                return;
            }

            await onUpload(selectedFile, expiryDate ? getIsoDate(expiryDate) : undefined);
        },
        [onUpload],
    );

    const onDeleteClick = useCallback(async () => {
        if (!documentId || !onDelete) {
            return;
        }

        await onDelete(documentId);
    }, [onDelete, documentId]);

    const renderDeleteFile = useCallback(() => {
        return (
            <IconButton
                icon={<Bin />}
                variant="ghost"
                onClick={onDeleteClick}
                aria-label="Delete"
                loading={isDeleting}
                value="deleteDocBtn"
            />
        );
    }, [isDeleting, onDeleteClick]);

    const isUploadDisabled = useCallback(
        (selectedFile: File | null, selectedExpiryDate: Date | null) => {
            if (!selectedFile) {
                return true;
            }

            if (isExpiryRequired && !selectedExpiryDate) {
                return true;
            }

            return false;
        },
        [isExpiryRequired],
    );

    return (
        <div>
            <Typography variant="title-secondary" as="h5" fontSize="text-xl" paddingBottom={2}>
                <div className={twMerge(isRequired && "after:text-danger-primary after:content-['_*']")}>{label}</div>
            </Typography>
            {hint && (
                <Typography variant="body-primary">
                    <div className="whitespace-pre-wrap">
                        <Markdown
                            options={{
                                overrides: {
                                    a: {
                                        props: {
                                            className: "text-link-primary",
                                        },
                                    },
                                    p: {
                                        props: {
                                            className: "mb-4",
                                        },
                                    },
                                },
                            }}
                        >
                            {hint}
                        </Markdown>
                    </div>
                </Typography>
            )}
            {!fileExists && (
                <Button variant="secondary" onClick={uploadFile} loading={isUploading}>
                    {i18n("upload-file")}
                </Button>
            )}
            {fileExists && (
                <ListItemLayout
                    primary={documentName}
                    secondary={
                        documentExpires &&
                        i18n("common.expires_at_date", {
                            date: getDayMonthYearDate(apiTimestampToDate(documentExpires)),
                        })
                    }
                    renderEndSlot={renderDeleteFile}
                    paddingStart={0}
                    paddingTop={0}
                    paddingBottom={0}
                />
            )}
            {isUploadDrawerOpen && (
                <UploadDocument
                    isLoading={isUploading}
                    onClose={onClose}
                    onConfirmClick={onConfirmClick}
                    showExpiryDatePicker={isExpiryRequired}
                    error={error}
                    isDisabledFn={isUploadDisabled}
                />
            )}
            <div className="text-danger-primary mt-2 font-sans text-sm">{error}</div>
        </div>
    );
};

export default ManageDocument;
