import {FC} from "react";

import Header, {HeaderSize} from "common/components/header/Header";
import {Tier} from "common/constants/types";
import {useI18n} from "common/hooks/useI18n";
import {TextButton} from "@fleet/common/components/TextButton";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {IconButton, Island} from "@bolteu/kalep-react";
import {InfoCircleOutlined} from "@bolteu/kalep-react-icons";

import {useDriverRewardsIcons} from "../../../hooks/useDriverRewardsIcons";
import ProgressBar from "./ProgressBar";
import ScoreSection from "./ScoreSection";
import GetLoyaltyLevelDetailsForFleetOwnerResponse = FleetOwnerPortalService.GetLoyaltyLevelDetailsForFleetOwnerResponse;

interface RewardsLevelProps {
    data: GetLoyaltyLevelDetailsForFleetOwnerResponse;
    glossaryTitle?: string;
    handleRewardsGlossaryOpen: () => void;
    handleLevelsAndBenefitsGlossaryOpen: () => void;
}

const LEVEL_COLORS = new Map<Tier, {background: string; progress_bar: string}>([
    [0, {background: "var(--color-bronze-background)", progress_bar: "var(--color-bronze-progress-bar)"}],
    [1, {background: "var(--color-silver-background)", progress_bar: "var(--color-silver-progress-bar)"}],
    [2, {background: "var(--color-gold-background)", progress_bar: "var(--color-gold-progress-bar)"}],
    [3, {background: "var(--color-platinum-background)", progress_bar: "var(--color-platinum-progress-bar)"}],
]);

const FALLBACK_COLORS = {
    background: "white",
    progress_bar: "white",
};

const DriverRewardsLevelDetails: FC<RewardsLevelProps> = ({
    data,
    glossaryTitle,
    handleRewardsGlossaryOpen,
    handleLevelsAndBenefitsGlossaryOpen,
}) => {
    const {i18n} = useI18n();
    const {driver_score: driverScore, acceptance_rate: acceptanceRate, current_level: currentLevel, content} = data;

    const levelIcon = useDriverRewardsIcons(currentLevel.tier as Tier, currentLevel.status);
    const isRewardsActiveForDriver = currentLevel.status === FleetOwnerPortalService.TierStatus.ACTIVE;
    const progressPercentage = currentLevel.points_goal
        ? Math.min(Math.floor((currentLevel.points / currentLevel.points_goal) * 100), 100)
        : 100;

    const getColor = (type: "background" | "progress_bar") => {
        const colors = LEVEL_COLORS.get(currentLevel.tier as Tier);
        return colors?.[type] ?? FALLBACK_COLORS[type];
    };

    const goalDescription = isRewardsActiveForDriver
        ? content.rewards_section.points_goal_description
        : content.rewards_section.reactivate_description;

    return (
        <Island>
            <div className="flex flex-row items-center justify-between pb-4">
                <Header size={HeaderSize.ExtraSmall} text={content.rewards_section.title} />
                <IconButton
                    aria-label="Levels and benefits information"
                    size="sm"
                    onClick={handleLevelsAndBenefitsGlossaryOpen}
                    icon={<InfoCircleOutlined />}
                />
            </div>
            <div className="flex flex-col justify-between">
                <div className="rounded-lg p-5" style={{backgroundColor: getColor("background")}}>
                    <div className="mb-5 flex items-center">
                        <span className="mr-2">{levelIcon}</span>
                        <div className="flex flex-col">
                            <p className="text-lg font-semibold">{currentLevel.level_name}</p>
                            {!isRewardsActiveForDriver && (
                                <span className="text-secondary text-sm">{i18n("common.inactive")}</span>
                            )}
                        </div>
                    </div>
                    <div className="mb-1 flex items-center justify-between">
                        <p>{content.rewards_section.points_label}</p>
                        <p className="text-xl">
                            <span className="font-semibold">{currentLevel.points}</span>
                            {currentLevel.points_goal && (
                                <span className="text-secondary">/{currentLevel.points_goal}</span>
                            )}
                        </p>
                    </div>

                    <ProgressBar progress={progressPercentage} progressColor={getColor("progress_bar")} />
                    <p className={`text-xs ${isRewardsActiveForDriver ? "text-secondary" : "text-danger-primary"}`}>
                        {goalDescription}
                    </p>
                </div>

                <div className="mt-4 flex flex-col">
                    <ScoreSection
                        label={content.performance_section.driver_score_label}
                        unit={driverScore.unit}
                        current={driverScore.current}
                        required={driverScore.required}
                    />
                </div>

                <div className="border-separator my-3 h-0 border-b-[1px]" />

                <div className="flex flex-col">
                    <ScoreSection
                        label={content.performance_section.acceptance_rate_label}
                        unit={acceptanceRate.unit}
                        current={acceptanceRate.current}
                        required={acceptanceRate.required}
                    />
                </div>

                <div className="border-separator my-3 h-0 border-b-[1px]" />

                {glossaryTitle && (
                    <TextButton
                        onClick={handleRewardsGlossaryOpen}
                        text={glossaryTitle}
                        className="text-action-primary text-left font-semibold"
                    />
                )}
            </div>
        </Island>
    );
};

export default DriverRewardsLevelDetails;
