import {FC} from "react";

import Header, {HeaderSize} from "common/components/header/Header";
import LanguageSelector from "common/components/languageSelector";
import {useI18n} from "common/hooks/useI18n";

import NotificationPreferences from "./NotificationPreferences";

const GeneralSettings: FC = () => {
    const {i18n} = useI18n();

    return (
        <div>
            <Header size={HeaderSize.Small} text={i18n("auth.app.settings.notification_preferences.title")} />
            <p className="text-secondary mt-2 mb-4">{i18n("auth.app.settings.notification_preferences.description")}</p>
            <NotificationPreferences />
            <Header size={HeaderSize.Small} text={i18n("common.language")} className="mt-6" />
            <p className="text-secondary mt-2 mb-4">{i18n("auth.app.account.preferred_language")}</p>
            <LanguageSelector />
        </div>
    );
};

export default GeneralSettings;
