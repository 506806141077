import {Dash} from "common/constants";

import {Typography, TypographyColor} from "@bolteu/kalep-react";
import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";

import {AccordionTableCellContent} from "../styledComponent/AccordionTableCellContent";

interface Props {
    data: string | number;
    measure: string;
    appendMeasure?: boolean;
    extraData?: string | number;
    className?: string;
    compact?: boolean;
}

const DataAndMeasuresCell = ({data, measure, appendMeasure, compact, className, extraData}: Props) => {
    let contentColor = "text-primary";

    const makeContent = (value: string | number | undefined) => {
        if (value === undefined) {
            return null;
        }

        if (value === Dash.HYPHEN || value === Dash.EN || value === Dash.EM) {
            return <>{value}</>;
        }

        let content = (
            <>
                {value} <span className="text-xs text-neutral-700">{measure}</span>
            </>
        );
        if (appendMeasure) {
            content = (
                <>
                    <span className="text-xs text-neutral-700">{measure}</span> {value}
                </>
            );
        }

        return content;
    };

    const content = makeContent(data);
    const extraContent = makeContent(extraData);

    if (data === Dash.HYPHEN || data === Dash.EN || data === Dash.EM) {
        contentColor = "text-content-tertiary";
    }

    return (
        <DefaultCellContent className={`${contentColor} ${className}`} compact={compact}>
            <div className="whitespace-nowrap">{content}</div>
            {extraData && <div className="text-content-tertiary text-xs">{extraContent}</div>}
        </DefaultCellContent>
    );
};

export const AccordionTableDataAndMeasuresCell = ({
    data,
    measure,
    appendMeasure,
    compact,
    extraData,
    className,
}: Props) => {
    let contentColor = "text-primary";
    let content = (
        <>
            {data} <span>{measure}</span>
        </>
    );
    if (appendMeasure) {
        content = (
            <>
                <span>{measure}</span> {data}
            </>
        );
    }
    if (data === Dash.HYPHEN || data === Dash.EN || data === Dash.EM) {
        contentColor = "text-content-tertiary";
        content = <>{data}</>;
    }

    return (
        <AccordionTableCellContent className={`${contentColor} ${className}`} compact={compact}>
            <div className="whitespace-nowrap">{content}</div>
            {extraData && <div className="text-content-tertiary text-xs">{extraData}</div>}
        </AccordionTableCellContent>
    );
};

export default DataAndMeasuresCell;

type KalepAccordionTableDataAndMeasuresCellProps = Omit<Props, "className" | "compact">;
export const KalepAccordionTableDataAndMeasuresCell = ({
    data,
    measure,
    appendMeasure,
    extraData,
}: KalepAccordionTableDataAndMeasuresCellProps) => {
    let contentColor: TypographyColor = "primary";

    const measureElement = (
        <Typography color="secondary" fontSize="text-sm" inline>
            {measure}
        </Typography>
    );

    let content: JSX.Element | string = (
        <>
            <>{data}</> {measureElement}
        </>
    );
    if (appendMeasure) {
        content = (
            <>
                {measureElement} {data}
            </>
        );
    }
    if (data === Dash.HYPHEN || data === Dash.EN || data === Dash.EM) {
        contentColor = "tertiary";
        content = data;
    }

    return (
        <Typography color={contentColor}>
            <div className="whitespace-nowrap">{content}</div>
            {extraData && (
                <Typography color="tertiary" fontSize="text-sm">
                    {extraData}
                </Typography>
            )}
        </Typography>
    );
};
