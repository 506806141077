import React, {ChangeEventHandler, FC, useCallback, useContext, useEffect, useState} from "react";

import {CountryCode} from "common/geo/constants/countries";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {TranslationKeys} from "config/translations";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {InvoicingFiscalizationService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, Dialog, Grid, TextField} from "@bolteu/kalep-react";
import {PasswordTextField} from "@bolteu/kalep-react/build/components/TextField/PasswordTextField";
import {Verified} from "@bolteu/kalep-react-icons";
import FonoatechAccountDetailsExternal = InvoicingFiscalizationService.FonoatechAccountDetailsExternal;

interface CompanyTaxInformationEditForm {
    username: string;
    password: string;
    certificate_base64: string;
    certificate_password: string;
}

const getTaxInfoFunc = (api: Api) => api.invoicingFiscalization.getCompanyTaxInfo();
const saveTaxInfoFunc = (api: Api, body: InvoicingFiscalizationService.FleetOwnerPortalSaveCompanyTaxInfoBody) =>
    api.invoicingFiscalization.saveCompanyTaxInfo(body);

const shouldShowErrors = (taxInfo: FonoatechAccountDetailsExternal | null): boolean =>
    !!taxInfo && Array.isArray(taxInfo.error_codes) && taxInfo.error_codes.length > 0;

const isTaxInfoOk = (taxInfo: FonoatechAccountDetailsExternal | null): boolean =>
    !!taxInfo && !shouldShowErrors(taxInfo);

const maskTaxInfo = (innerTaxInfo: FonoatechAccountDetailsExternal) => ({
    username: innerTaxInfo?.username ?? "",
    password: innerTaxInfo?.has_password ? "*****" : "",
    certificate_base64: innerTaxInfo?.has_certificate ? "*****" : "",
    certificate_password: innerTaxInfo?.has_certificate ? "*****" : "",
});

export const TaxIntegration: FC = () => {
    const {i18n} = useI18n();

    const [shouldShowEditDialog, setShouldShowEditDialog] = React.useState(false);

    // These two states track API call status for each save attempt.
    // We can't use fetch statuses as they can't be reset - if the user reopens the edit modal after saving, the statuses will be still "success" and the modal will not be shown.
    // First state tracks the status of save itself while the second one tracks reloading of the data which is used to determine whether user data is valid
    const [hasFinishedSavingWithoutErrors, setHasFinishedSavingWithoutErrors] = React.useState(false);
    const [hasReloadedDataWithoutErrors, setHasReloadedDataWithoutErrors] = React.useState(false);

    const [taxInfoEditForm, setTaxInfoEditForm] = useState<CompanyTaxInformationEditForm>({
        username: "",
        password: "",
        certificate_base64: "",
        certificate_password: "",
    });

    const fleet = useContext(AccountContextProvider)?.getFleet();

    const {fetch: getTaxInfoFetch, status: taxInfoStatus, data: taxInfoData} = useFetch(getTaxInfoFunc);
    const {fetch: saveTaxInfoFetch, status: saveTaxInfoStatus} = useFetch(saveTaxInfoFunc);

    const isLoading = [taxInfoStatus, saveTaxInfoStatus].includes(FetchStatus.Loading);

    useEffect(() => {
        if (getTaxInfoFetch) {
            getTaxInfoFetch({});
        }
    }, [getTaxInfoFetch]);

    const onEditClick = useCallback(() => {
        setShouldShowEditDialog(true);
    }, [setShouldShowEditDialog]);

    const onSaveClick = useCallback(() => {
        if (saveTaxInfoFetch) {
            saveTaxInfoFetch({
                companyInfo: {
                    ...taxInfoEditForm,
                },
                onboardNow: true,
            });
        }
    }, [saveTaxInfoFetch, taxInfoEditForm]);

    // Detect save is done and initiate reload of the data
    useEffect(() => {
        if (saveTaxInfoStatus === FetchStatus.Success && getTaxInfoFetch) {
            getTaxInfoFetch({});
            setHasFinishedSavingWithoutErrors(true);
        }
    }, [getTaxInfoFetch, saveTaxInfoStatus]);

    // Detect reload is done
    useEffect(() => {
        if (hasFinishedSavingWithoutErrors && taxInfoStatus === FetchStatus.Success) {
            setHasReloadedDataWithoutErrors(true);
        }
    }, [hasFinishedSavingWithoutErrors, taxInfoStatus]);

    // Determine whether modal can be automatically closed
    useEffect(() => {
        if (!taxInfoData || !shouldShowEditDialog || !hasFinishedSavingWithoutErrors || !hasReloadedDataWithoutErrors) {
            return;
        }
        if (isTaxInfoOk(taxInfoData)) {
            setShouldShowEditDialog(false);
        }
    }, [hasFinishedSavingWithoutErrors, hasReloadedDataWithoutErrors, taxInfoData, shouldShowEditDialog]);

    // Reset API call statuses when modal is closed
    useEffect(() => {
        if (!shouldShowEditDialog) {
            setHasFinishedSavingWithoutErrors(false);
            setHasReloadedDataWithoutErrors(false);
        }
    }, [shouldShowEditDialog]);

    const onCloseClick = useCallback(() => {
        setShouldShowEditDialog(false);
    }, [setShouldShowEditDialog]);

    const handleFormChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (e) => setTaxInfoEditForm((old) => ({...old, [e.target.name]: e.target.value})),
        [setTaxInfoEditForm],
    );

    const isShowCertificateProperties = fleet?.country === CountryCode.Hungary;
    const maskedTaxInfo = taxInfoData ? maskTaxInfo(taxInfoData) : null;

    return (
        <>
            <div className="mb-4 flex items-center gap-1">
                <div className="font-semibold tracking-normal text-neutral-900">
                    {i18n("auth.app.company-settings.tax.title")}
                </div>
                {isTaxInfoOk(taxInfoData) && <Verified />}
            </div>
            <Grid columns={1}>
                <TextField
                    name="username"
                    placeholder={i18n("auth.app.company-settings.tax.tech_user_id")}
                    value={maskedTaxInfo?.username ?? ""}
                    disabled
                />
                <TextField
                    name="password"
                    placeholder={i18n("auth.app.company-settings.tax.password")}
                    value={maskedTaxInfo?.password ?? ""}
                    disabled
                />
                {isShowCertificateProperties && (
                    <>
                        <TextField
                            name="certificate"
                            placeholder={i18n("auth.app.company-settings.tax.certificate")}
                            value={maskedTaxInfo?.certificate_base64 ?? ""}
                            disabled
                        />
                        <TextField
                            name="certificate_password"
                            placeholder={i18n("auth.app.company-settings.tax.cert_password")}
                            value={maskedTaxInfo?.certificate_password ?? ""}
                            disabled
                        />
                    </>
                )}
            </Grid>
            <Button variant="tertiary" size="sm" onClick={onEditClick} overrideClassName="mt-4">
                {i18n("auth.app.company-settings.tax.edit")}
            </Button>
            <Dialog
                onRequestClose={onCloseClick}
                isOpen={shouldShowEditDialog}
                title={i18n("auth.app.company-settings.tax.fill_title")}
            >
                <Dialog.Content>
                    <Grid columns={1}>
                        <TextField
                            name="username"
                            placeholder={i18n("auth.app.company-settings.tax.tech_user_id")}
                            onChange={handleFormChange}
                            required
                        />
                        <PasswordTextField
                            name="password"
                            placeholder={i18n("auth.app.company-settings.tax.password")}
                            onChange={handleFormChange}
                            required
                        />
                        {isShowCertificateProperties && (
                            <>
                                <TextField
                                    name="certificate_base64"
                                    placeholder={i18n("auth.app.company-settings.tax.certificate")}
                                    onChange={handleFormChange}
                                    required
                                />
                                <TextField
                                    name="certificate_password"
                                    placeholder={i18n("auth.app.company-settings.tax.cert_password")}
                                    onChange={handleFormChange}
                                    required
                                />
                            </>
                        )}
                        {shouldShowErrors(taxInfoData) && (
                            <>
                                {taxInfoData?.error_codes?.map((error) => (
                                    <p className="text-danger-primary" key={error}>
                                        {i18n(
                                            `auth.app.company-settings.tax.error.${error}` as TranslationKeys,
                                            undefined,
                                            "auth.app.company-settings.tax.error.general_error",
                                        )}
                                    </p>
                                ))}
                            </>
                        )}
                    </Grid>
                </Dialog.Content>
                <Dialog.Footer>
                    <Button disabled={isLoading} onClick={onSaveClick}>
                        {i18n("auth.app.company-settings.save")}
                    </Button>
                    <Button variant="secondary" onClick={onCloseClick}>
                        {i18n("auth.app.company-settings.cancel")}
                    </Button>
                </Dialog.Footer>
            </Dialog>
        </>
    );
};
