import {useCallback, useContext, useEffect} from "react";

import LoadingSpinner from "common/components/spinner";
import {EventName, EventPropertiesDownloadType, EventPropertiesFormSubmissionFailedName} from "common/constants/events";
import {MimeTypes} from "common/constants/mimeTypes";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api} from "common/services/apiProvider";
import {downloadBase64File} from "common/util/downloadUtil";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {HoldingFleetService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Menu, MenuItem} from "@bolteu/kalep-react";
import {MoreIos} from "@bolteu/kalep-react-icons";

import {HoldingFleetInvoicesTableData} from "../hooks/useInvoices";

interface Props {
    item: HoldingFleetInvoicesTableData;
    onInvoiceDetailsClick: (item: HoldingFleetInvoicesTableData) => () => void;
}

const fetchBoltInvoicePdf = (api: Api, body: HoldingFleetService.GetBoltInvoicePdfRequest) =>
    api.holdingFleet.holdingFleetGetBoltInvoicePdf(body, {timeout: 60_000});

const TableActions = ({item, onInvoiceDetailsClick}: Props) => {
    const {i18n} = useI18n();
    const holdingFleet = useContext(AccountContextProvider)?.getHoldingFleet();
    const {fetch, status, data, error} = useFetch(fetchBoltInvoicePdf);
    const {trackEvent} = useTracking();

    useEffect(() => {
        if (status === FetchStatus.Success && data.file) {
            const fileName = i18n(
                "auth.app.invoices.holding-fleet.pdf_filename",
                {invoiceNo: item.public_id},
                undefined,
            );
            downloadBase64File(data.file, MimeTypes.PDF, fileName);
            trackEvent(EventName.FileDownloaded, {downloadType: EventPropertiesDownloadType.EnterpriseInvoicePdf});
        }
    }, [data, i18n, item, status, trackEvent]);

    useEffect(() => {
        if (status === FetchStatus.Error && error.message !== "") {
            trackEvent(EventName.FormSubmissionFailed, {
                formName: EventPropertiesFormSubmissionFailedName.DownloadEnterpriseInvoice,
                errorMessage: error.message,
            });
        }
    }, [data, error.message, status, trackEvent]);

    const downloadPdf = useCallback(() => {
        if (!fetch || !holdingFleet) {
            return;
        }
        fetch({invoice_id: item.id});
    }, [fetch, holdingFleet, item]);

    if (FetchStatus.Loading === status) {
        return <LoadingSpinner show />;
    }

    return (
        <Menu
            menuButton={
                <button type="button">
                    <MoreIos />
                </button>
            }
            overrideClassName="w-56"
        >
            <MenuItem
                onClick={onInvoiceDetailsClick(item)}
                label={i18n("auth.app.invoices.holding-fleet.invoices_table.invoice_details")}
            />
            <MenuItem onClick={downloadPdf} label={i18n("common.download_pdf")} />
        </Menu>
    );
};

export default TableActions;
