import {useCallback, useMemo, useState} from "react";

import {ResponsiveDrawer} from "common/components/drawer/ResponsiveDrawer";
import {useI18n} from "common/hooks/useI18n";
import {getIsoDate} from "common/util/FormatUtil";

import {FleetPortalDocumentService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Button, DatePicker, Drawer, Select, SelectOption} from "@bolteu/kalep-react";
import {Bin} from "@bolteu/kalep-react-icons";

export interface ExpiringDocumentsFilters {
    expirationPeriodStart: string | null;
    expirationPeriodEnd: string;
    entityTypes: FleetPortalDocumentService.OwnerType[];
}

export interface ExpiringDocumentsFiltersDrawerProps {
    isOpen: boolean;
    filters: ExpiringDocumentsFilters;
    defaultFilters: ExpiringDocumentsFilters;
    onFiltersChanged: (filters: ExpiringDocumentsFilters) => void;
    onClosed: () => void;
}

export const ExpiringDocumentsFiltersDrawer = ({
    isOpen,
    filters,
    defaultFilters,
    onFiltersChanged,
    onClosed,
}: ExpiringDocumentsFiltersDrawerProps) => {
    const {i18n} = useI18n();

    const [isClearFiltersButtonShown, setIsClearFiltersButtonShown] = useState(false);
    const [entityTypes, setEntityTypes] = useState(filters.entityTypes);
    const [expirationPeriodStart, setExpirationPeriodStart] = useState(filters.expirationPeriodStart);
    const [expirationPeriodEnd, setExpirationPeriodEnd] = useState(filters.expirationPeriodEnd);

    const entityTypeOptions: SelectOption[] = useMemo(
        () => [
            {
                value: FleetPortalDocumentService.OwnerType.DRIVER,
                title: i18n("auth.app.fleet.expiring-documents.filters.entity_type.driver"),
            },
            {
                value: FleetPortalDocumentService.OwnerType.CAR,
                title: i18n("auth.app.fleet.expiring-documents.filters.entity_type.car"),
            },
            {
                value: FleetPortalDocumentService.OwnerType.COMPANY,
                title: i18n("auth.app.fleet.expiring-documents.filters.entity_type.company"),
            },
        ],
        [i18n],
    );

    const onCloseClicked = useCallback(() => {
        onClosed();
    }, [onClosed]);

    const handleSubmitClicked = useCallback(() => {
        onFiltersChanged({
            expirationPeriodStart,
            expirationPeriodEnd,
            entityTypes,
        });
    }, [entityTypes, expirationPeriodEnd, expirationPeriodStart, onFiltersChanged]);

    const entityTypesSelectText = useMemo(
        () =>
            entityTypes
                ?.map((entityType) => i18n(`auth.app.fleet.expiring-documents.filters.entity_type.${entityType}`))
                .join(", "),
        [entityTypes, i18n],
    );

    const handleClearFiltersButtonClick = useCallback(() => {
        setEntityTypes(defaultFilters.entityTypes);
        setExpirationPeriodStart(defaultFilters.expirationPeriodStart);
        setExpirationPeriodEnd(defaultFilters.expirationPeriodEnd);
        setIsClearFiltersButtonShown(false);
    }, [defaultFilters]);

    const handleEntityTypesChange = useCallback(
        (newValue: SelectOption | SelectOption[] | null) => {
            if (Array.isArray(newValue)) {
                const newEntityTypes = newValue.map((option) => option.value as FleetPortalDocumentService.OwnerType);
                setEntityTypes(newEntityTypes);
                setIsClearFiltersButtonShown(newEntityTypes !== defaultFilters.entityTypes);
            }
        },
        [setEntityTypes, defaultFilters.entityTypes],
    );

    const handleExpirationPeriodStartChange = useCallback(
        (newDate: Date | Date[] | null) => {
            if (!Array.isArray(newDate)) {
                const newExpirationPeriodStart = newDate ? getIsoDate(newDate) : null;
                setExpirationPeriodStart(newExpirationPeriodStart);
                setIsClearFiltersButtonShown(newExpirationPeriodStart !== defaultFilters.expirationPeriodStart);
            }
        },
        [setExpirationPeriodStart, defaultFilters.expirationPeriodStart],
    );

    const handleExpirationPeriodEndChange = useCallback(
        (newDate: Date | Date[] | null) => {
            if (!Array.isArray(newDate) && newDate !== null) {
                const newExpirationPeriodEnd = getIsoDate(newDate);
                setExpirationPeriodEnd(newExpirationPeriodEnd);
                setIsClearFiltersButtonShown(newExpirationPeriodEnd !== defaultFilters.expirationPeriodEnd);
            }
        },
        [setExpirationPeriodEnd, defaultFilters.expirationPeriodEnd],
    );

    return (
        <ResponsiveDrawer
            onRequestClose={onCloseClicked}
            isOpen={isOpen}
            title={i18n("auth.app.fleet.campaigns.filters.title")}
        >
            <Drawer.Content>
                <div className="flex flex-col gap-4">
                    <DatePicker
                        value={toDate(expirationPeriodStart)}
                        label={i18n("auth.app.fleet.expiring-documents.filters.expiration.start")}
                        onChange={handleExpirationPeriodStartChange}
                        fullWidth
                    />
                    <DatePicker
                        required
                        value={toDate(expirationPeriodEnd)}
                        label={i18n("auth.app.fleet.expiring-documents.filters.expiration.end")}
                        onChange={handleExpirationPeriodEndChange}
                        fullWidth
                    />
                    <Select
                        multiple
                        label={i18n("auth.app.fleet.expiring-documents.filters.type")}
                        placeholder={entityTypesSelectText}
                        value={toSelectOptions(entityTypes, entityTypeOptions)}
                        options={entityTypeOptions}
                        onChange={handleEntityTypesChange}
                        fullWidth
                    />
                </div>
            </Drawer.Content>
            <Drawer.Footer>
                <Button variant="primary" onClick={handleSubmitClicked}>
                    {i18n("common.apply")}
                </Button>
                <Button variant="secondary" onClick={onCloseClicked}>
                    {i18n("auth.app.company-settings.cancel")}
                </Button>
                {isClearFiltersButtonShown && (
                    <Button variant="secondary" onClick={handleClearFiltersButtonClick} startIcon={<Bin />}>
                        {i18n("common.filters.clear_filters")}
                    </Button>
                )}
            </Drawer.Footer>
        </ResponsiveDrawer>
    );
};

function toDate(value: string | null) {
    return value ? new Date(value) : null;
}

function toSelectOptions(
    entityTypes: FleetPortalDocumentService.OwnerType[],
    entityTypeOptions: SelectOption[],
): SelectOption[] {
    return entityTypes.flatMap((entityType) => entityTypeOptions.find((option) => option.value === entityType) ?? []);
}
