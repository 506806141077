import {useI18n} from "common/hooks/useI18n";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import ListingFieldsTranslations = VehicleMarketplaceService.ListingFieldsTranslations;

interface Props {
    listingDataTranslations: ListingFieldsTranslations;
}

const ConditionAndMileage = ({listingDataTranslations}: Props) => {
    const {i18n} = useI18n();

    const title = i18n("auth.app.fleet.vehicle_marketplace.listings.view.listing_data.headers.condition_and_mileage");

    const conditionText = i18n(
        "auth.app.fleet.vehicle_marketplace.listings.view.listing_data.condition_and_mileage.condition",
    );
    const conditionValue = listingDataTranslations.vehicle_specifics_fields?.values.condition?.value;

    const mileageText = i18n(
        "auth.app.fleet.vehicle_marketplace.listings.view.listing_data.condition_and_mileage.mileage",
    );
    const mileageValue = listingDataTranslations.vehicle_specifics_fields?.values.mileage?.value;

    if (!conditionValue && !mileageValue) {
        return null;
    }

    return (
        <div>
            <p className="bolt-font-heading-xs-accent pb-4">{title}</p>
            <ul className="list-disc pl-5">
                {conditionValue && (
                    <li>
                        {conditionText}: {conditionValue}
                    </li>
                )}
                {mileageValue && (
                    <li>
                        {mileageText}: {mileageValue}
                    </li>
                )}
            </ul>
        </div>
    );
};

export default ConditionAndMileage;
