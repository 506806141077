import {FC, useContext} from "react";

import TopLeftCornerNotification from "@fleet/common/components/TopLeftCornerNotification";
import {useTailwindViewport} from "@fleet/common/hooks/useTailwindViewport";
import {NotificationContext} from "@fleet/common/services/notificationProvider";
import {useId} from "@react-aria/utils";

import {BaseModal, Drawer, DrawerProps} from "@bolteu/kalep-react";

const MobileDrawer: FC<DrawerProps> = ({isOpen, title, onRequestClose, children}: DrawerProps) => {
    const {notification, setNotification} = useContext(NotificationContext);
    const titleId = useId();

    return (
        <BaseModal isOpen={isOpen} onRequestClose={onRequestClose} aria-labelledby={titleId} placement="m-0">
            {notification !== null && (
                <TopLeftCornerNotification
                    key="base_modal_notification"
                    notification={notification}
                    setNotification={setNotification}
                />
            )}
            <div className="bg-layer-floor-1 w-dvh h-dvh flex flex-col">
                <BaseModal.Header title={title} titleId={titleId}>
                    <BaseModal.Close onClose={onRequestClose} />
                </BaseModal.Header>
                {children}
            </div>
        </BaseModal>
    );
};

const ResponsiveDrawer: FC<DrawerProps> = (props: DrawerProps) => {
    const viewport = useTailwindViewport();
    const isMobileView = viewport === "sm";

    return isMobileView ? <MobileDrawer {...props} /> : <Drawer {...props} />;
};

export {ResponsiveDrawer};
