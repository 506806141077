import {useCallback, useContext, useEffect, useState} from "react";

import LoadingSpinner from "common/components/spinner";
import {EventName} from "common/constants/events";
import {useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {Api, ApiContextProvider} from "common/services/apiProvider";
import {isError} from "common/util/isErrorType";
import {TranslationKeys} from "config/translations";
import {AccountContextProvider} from "features/account/accountStateProvider";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";

import {isAutoAcceptSettingVisible} from "../contants";
import AutoAcceptedIncomingOrdersTable from "./components/AutoAcceptedIncomingOrdersTable";
import AutoAcceptSetting from "./components/AutoAcceptSetting";
import ManualAcceptIncomingOrdersTable from "./components/ManualAcceptIncomingOrdersTable";

export const PollingInterval = 5000;

export enum IncomingOrdersType {
    INITIALIZATION = "initialization",
    MANUAL_ORDERS = "manual_orders",
    AUTO_ACCEPTED_ORDERS = "auto_accepted_orders",
}

const getOrdersFetchFunction = (api: Api) => api.orderFleetShardedApi.getPendingOrders();

const IncomingOrders = () => {
    const {i18n} = useI18n();
    const {setNotification} = useContext(NotificationContext);

    const {data, fetch} = useFetch(getOrdersFetchFunction);

    const [incomingOrdersType, setIncomingOrdersType] = useState<IncomingOrdersType>(IncomingOrdersType.INITIALIZATION);
    const api = useContext(ApiContextProvider);
    const fleet = useContext(AccountContextProvider)?.getFleet();

    const {trackEvent} = useTracking();

    useEffect(() => {
        if (fetch) {
            fetch({});
        }
    }, [fetch]);

    useEffect(() => {
        if (data && incomingOrdersType === IncomingOrdersType.INITIALIZATION) {
            if (data.is_auto_accept === 1) {
                setIncomingOrdersType(IncomingOrdersType.AUTO_ACCEPTED_ORDERS);
            } else {
                setIncomingOrdersType(IncomingOrdersType.MANUAL_ORDERS);
            }
        }
    }, [data, incomingOrdersType]);

    const isAutoAcceptanceEnabled = IncomingOrdersType.AUTO_ACCEPTED_ORDERS === incomingOrdersType;

    const handleAutoAcceptanceToggle = useCallback(async () => {
        try {
            await api?.orderFleetShardedApi.updateOrderSettings({
                is_auto_accept: isAutoAcceptanceEnabled ? 0 : 1,
            });
            setIncomingOrdersType(
                isAutoAcceptanceEnabled ? IncomingOrdersType.MANUAL_ORDERS : IncomingOrdersType.AUTO_ACCEPTED_ORDERS,
            );

            trackEvent(isAutoAcceptanceEnabled ? EventName.OrderAutoAcceptDisabled : EventName.OrderAutoAcceptEnabled);
        } catch (error) {
            if (isError(error)) {
                const errorText = i18n(`api.error.${error.message}` as TranslationKeys, undefined, "api.default_error");

                setNotification({
                    type: NotificationType.ERROR,
                    text: errorText,
                    timeout: 6000,
                });
            }
        }
    }, [api, setIncomingOrdersType, isAutoAcceptanceEnabled, setNotification, i18n, trackEvent]);

    const isShowAutoAcceptSetting = fleet && isAutoAcceptSettingVisible(fleet.id, fleet.country);

    return (
        <div className="h-full w-full">
            <div className="container mx-auto mb-14 flex flex-col gap-y-6">
                {isShowAutoAcceptSetting && (
                    <AutoAcceptSetting
                        handleAutoAcceptanceToggle={handleAutoAcceptanceToggle}
                        isAutoAcceptanceEnabled={isAutoAcceptanceEnabled}
                    />
                )}
                {incomingOrdersType === IncomingOrdersType.AUTO_ACCEPTED_ORDERS && (
                    <AutoAcceptedIncomingOrdersTable setIncomingOrdersType={setIncomingOrdersType} />
                )}
                {incomingOrdersType === IncomingOrdersType.MANUAL_ORDERS && (
                    <ManualAcceptIncomingOrdersTable setIncomingOrdersType={setIncomingOrdersType} />
                )}
                {incomingOrdersType === IncomingOrdersType.INITIALIZATION && (
                    <LoadingSpinner show parentClassName="pt-12 w-full" />
                )}
            </div>
        </div>
    );
};

export default IncomingOrders;
