import {FC} from "react";
import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";
import {ApiPeriod} from "common/services/period";

import {formatPeriodWithoutYear} from "../../utils/formatPeriod";

interface NetEarningsTableHeaderProps {
    period: ApiPeriod;
}

const NetEarningsTableHeader: FC<NetEarningsTableHeaderProps> = ({period}) => {
    const {i18n} = useI18n();
    const intl = useIntl();

    const headerText = i18n("auth.app.fleet.balance.transactions.card.header.net_earnings_for_date", {
        netEarnings: i18n("auth.app.fleet.balance.transactions.card.header.net_earnings"),
        date: formatPeriodWithoutYear(intl, period),
    });
    const descriptionText = i18n("auth.app.fleet.balance.transactions.card.net_earnings_description", {
        cashPayments: i18n("auth.app.fleet.balance.transactions_list.earnings.cash_payment.title"),
    });

    return (
        <div className="flex flex-col gap-2">
            <span className="text-lg font-semibold">{headerText}</span>
            <span className="text-secondary text-sm">{descriptionText}</span>
        </div>
    );
};

export default NetEarningsTableHeader;
