import {useI18n} from "common/hooks/useI18n";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import ListingFieldsTranslations = VehicleMarketplaceService.ListingFieldsTranslations;

interface Props {
    listingDataTranslations: ListingFieldsTranslations;
}

const Details = ({listingDataTranslations}: Props) => {
    const {i18n} = useI18n();

    const title = i18n("auth.app.fleet.vehicle_marketplace.listings.view.listing_data.headers.details");

    const colorText = i18n("auth.app.fleet.vehicle_marketplace.listings.view.listing_data.details.available_colors");
    const colorValues = listingDataTranslations.vehicle_specifics_fields?.values.vehicle_colors?.value;

    const gearboxText = i18n("auth.app.fleet.vehicle_marketplace.listings.view.listing_data.details.gearbox");
    const gearboxValue = listingDataTranslations.vehicle_specifics_fields?.values.gearbox?.value;

    const fuelTypeText = i18n("auth.app.fleet.vehicle_marketplace.listings.view.listing_data.details.fuel_type");
    const fuelTypeValue = listingDataTranslations.vehicle_specifics_fields?.values.fuel_type?.value;

    return (
        <div>
            <p className="bolt-font-heading-xs-accent pb-4">{title}</p>
            <ul className="list-disc pl-5">
                <li>
                    {colorText}: {colorValues}
                </li>
                {gearboxValue && (
                    <li>
                        {gearboxText}: {gearboxValue}
                    </li>
                )}
                {fuelTypeValue && (
                    <li>
                        {fuelTypeText}: {fuelTypeValue}
                    </li>
                )}
            </ul>
        </div>
    );
};

export default Details;
