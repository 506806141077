import {IntlShape, useIntl} from "react-intl";

import {Dash} from "common/constants";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Island} from "@bolteu/kalep-react";

import PolygonMap from "./PolygonMap";

import InfoBlockItem = FleetOwnerPortalService.InfoBlockItem;
import BlockItemType = FleetOwnerPortalService.BlockItemType;
import TextSize = FleetOwnerPortalService.TextSize;

interface InfoBlockProps {
    title?: string;
    items: InfoBlockItem[];
    isIsland?: boolean;
}

function infoBlockItemToComponent(intl: IntlShape, item: InfoBlockItem): React.ReactNode {
    const SmallWrapper = ({children}: {children: React.ReactNode}) => {
        return (
            <div className="flex flex-col gap-0">
                <p className="text-secondary text-base">{item.title}</p>
                <p className="text-base">{children}</p>
                {item.subtext && <p className="text-secondary text-sm">{item.subtext}</p>}
            </div>
        );
    };

    const LargeWrapper = ({children}: {children: React.ReactNode}) => {
        return (
            <div className="flex flex-col gap-1">
                <p className="text-secondary text-lg">{item.title}</p>
                <p className="text-3xl font-semibold">{children}</p>
                {item.subtext && <p className="text-secondary text-base">{item.subtext}</p>}
            </div>
        );
    };

    const Wrapper = item.text_size === TextSize.LARGE ? LargeWrapper : SmallWrapper;

    switch (item.type) {
        case BlockItemType.TEXT:
            return <Wrapper>{item.value || Dash.EM}</Wrapper>;
        case BlockItemType.LOCATION:
            return (
                <div>
                    <Wrapper>{item.value}</Wrapper>
                    {item.polygon && <PolygonMap polygon={item.polygon} />}
                </div>
            );
        case BlockItemType.PERIOD:
            return (
                <Wrapper>
                    {intl.formatDateTimeRange(item.value.start * 1000, item.value.end * 1000, {
                        dateStyle: "medium",
                        timeStyle: "short",
                    })}
                </Wrapper>
            );
        default:
            return UnreachableCode.never(item, null);
    }
}

const InfoBlock = ({title, items, isIsland}: InfoBlockProps) => {
    const intl = useIntl();

    const Wrapper = ({children}: {children: React.ReactNode}) =>
        isIsland ? <Island>{children}</Island> : <div>{children}</div>;

    return (
        <Wrapper>
            {title && <p className="mb-4 text-lg font-semibold">{title}</p>}
            {items
                .filter(({value}) => value !== null)
                .map((item) => {
                    return (
                        <div
                            className="border-t-[1px] border-neutral-300 py-2 first:border-t-0 first:pt-0 last:pb-0"
                            key={item.title}
                        >
                            {infoBlockItemToComponent(intl, item)}
                        </div>
                    );
                })}
        </Wrapper>
    );
};

export default InfoBlock;
