import {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {AreaCodeSelector} from "common/components/areaCodeSelector";
import {useI18n} from "common/hooks/useI18n";
import {FleetOwnerAuthNoAuthApiClient} from "common/services/apiClients/noAuthApiClient";
import {LocationItem} from "common/services/authService";
import {localStorageService} from "common/services/LocalStorageService";
import {isValidationError} from "common/util/isErrorType";
import config from "config";
import {useVerificationNavigations} from "features/account/components/Verification/hooks/useVerificationNavigations";
import {ApplicationContextProvider} from "features/app/appStateProvider";
import {useIpLocationData} from "@fleet/common/hooks/useIpLocationData";

import {FormState, useForm} from "@bolteu/kalep-form-react";
import {Grid, Typography} from "@bolteu/kalep-react";

interface LoginForm {
    areaCode: string;
    phoneNumber: string;
}

export function PhoneNumberForm() {
    const {i18n} = useI18n();
    const [isWaitingTransition, setIsWaitingTransition] = useState(false);
    const [isPageLoading, setIsPageLoading] = useState(true);
    const appState = useContext(ApplicationContextProvider);
    const locationData = useIpLocationData(config.geoServiceUrl, localStorageService.getItemKey(LocationItem));
    const [defaultCountry, setDefaultCountry] = useState("");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const navigate = useNavigate();
    const {getVerificationTo} = useVerificationNavigations();

    const {Form, TextField, Button, setData} = useForm<LoginForm, Record<never, never>>({
        areaCode: "",
        phoneNumber: "",
    });

    useEffect(() => {
        if (locationData) {
            setDefaultCountry(locationData.country_iso_code);
        }
    }, [locationData, setData]);

    // When isAuthError === true and user navigates to login page
    useEffect(() => {
        if (appState.isAuthError && isPageLoading) {
            appState.setIsAuthError(false);
        }
        setIsPageLoading(false);
    }, [appState, isPageLoading, setIsPageLoading]);

    const handleSubmit = useCallback(
        async (state: FormState<LoginForm>) => {
            setErrorMessage(null);
            try {
                const phone = state.data.areaCode + state.data.phoneNumber;
                const authResponse = await FleetOwnerAuthNoAuthApiClient.startPhoneAuthentication({phone});
                setIsWaitingTransition(true);
                navigate(getVerificationTo(authResponse.attempt_hash));
            } catch (error) {
                if (isValidationError(error) && error.validatorResults.some((result) => result.property === "phone")) {
                    setErrorMessage(i18n("api.error.validation.phone_number.invalid"));
                } else {
                    setErrorMessage(i18n("api.error.SERVER_ERROR"));
                }
            }
        },
        [navigate, getVerificationTo, i18n],
    );

    const handlePhoneAreaCode = useCallback(
        (code: string) => {
            setData({areaCode: code});
        },
        [setData],
    );

    return (
        <Form onSubmit={handleSubmit} className="flex w-full max-w-[356px] flex-col" disabled={isWaitingTransition}>
            <div className="mb-8 flex flex-col gap-2">
                <Grid columns={12}>
                    <Grid.Item colSpan={5}>
                        <AreaCodeSelector
                            handlePhoneAreaCode={handlePhoneAreaCode}
                            required
                            defaultValue={defaultCountry}
                        />
                    </Grid.Item>
                    <Grid.Item colSpan={7}>
                        <TextField
                            id="phoneNumber"
                            name="phoneNumber"
                            type="number"
                            label=""
                            placeholder={i18n("login.phone_label")}
                            required
                            autoComplete="on"
                        />
                    </Grid.Item>
                </Grid>
                {!!errorMessage && (
                    <Typography variant="body-primary" fontSize="text-sm">
                        <div className="text-danger-primary"> {errorMessage} </div>
                    </Typography>
                )}
            </div>
            <Button fullWidth submit>
                {i18n("login.log_in")}
            </Button>
        </Form>
    );
}
