import {useEffect} from "react";
import {useIntl} from "react-intl";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {apiTimestampToDate} from "common/util/apiTimestampToDate";

import BalanceStatCard from "./BalanceStatCard";

const fetchFunction = (api: Api) => api.fleetOwnerPortal.getFleetBalanceSummary();

const BalanceCardsRow = () => {
    const {i18n} = useI18n();
    const intl = useIntl();
    const {fetch, data, status} = useFetch(fetchFunction);
    const isSuccess = status === FetchStatus.Success;

    useEffect(() => {
        if (fetch) {
            fetch({});
        }
    }, [fetch]);

    const currentBalance = data?.current_balance ?? 0;
    const currencySymbol = data?.currency ?? "";
    const currentBalanceTitle = i18n("auth.app.fleet.balance.cards_row.current_balance.title");
    const currentBalanceSubtext =
        data?.next_payout_date && currentBalance >= 0
            ? i18n("auth.app.fleet.balance.cards_row.current_balance.subText", {
                  date: intl.formatDate(apiTimestampToDate(data.next_payout_date), {
                      weekday: "short",
                      day: "numeric",
                      month: "short",
                  }),
              })
            : "";

    return (
        <div className="flex gap-4 overflow-auto">
            <BalanceStatCard
                title={currentBalanceTitle}
                value={currentBalance}
                currencySymbol={currencySymbol}
                subText={currentBalanceSubtext}
                isLoading={!isSuccess}
            />
        </div>
    );
};

export default BalanceCardsRow;
