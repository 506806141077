import {useMemo} from "react";

import {Check} from "@bolteu/kalep-react-icons";

export interface CheckmarkListRow {
    text: string;
    subtext?: string;
}

interface Props {
    list: CheckmarkListRow[];
}

const CheckmarkList = ({list}: Props) => {
    const checkmarkListComponents = useMemo(
        () =>
            list.map(({text, subtext}) => (
                <div key={text} className="flex items-center gap-2">
                    <Check className="text-action-secondary" />
                    <div>
                        <p>{text}</p>
                        {subtext && <p className="text-secondary text-sm">{subtext}</p>}
                    </div>
                </div>
            )),
        [list],
    );
    return <div className="flex flex-col gap-2">{checkmarkListComponents}</div>;
};

export default CheckmarkList;
