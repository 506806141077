import {useEffect} from "react";

import {useTabs} from "common/components/tab/useTabs";
import {useI18n} from "common/hooks/useI18n";
import {getDocumentTitle} from "common/util/DocumentTitleUtil";
import config from "config/index";
import NoAuthLayout from "features/account/components/NoAuthLayout";

import {Link as KalepLink} from "@bolteu/kalep-react";

import {EmailUsernameForm} from "./components/EmailUsernameForm";
import {PhoneNumberForm} from "./components/PhoneNumberForm";
import {TabName, useTabSelectorOptions} from "./hooks/useTabSelectorOptions";

const LoginPage = () => {
    const {i18n} = useI18n();
    const tabs = useTabSelectorOptions();
    const {activeTab, TabSelector} = useTabs(tabs, TabName.EmailUsername);

    useEffect(() => {
        const pageTitle = i18n("login.page_title");
        document.title = getDocumentTitle(pageTitle);
    }, [i18n]);

    let Form = <EmailUsernameForm />;
    switch (activeTab) {
        case TabName.PhoneNumber:
            Form = <PhoneNumberForm />;
            break;
        case TabName.EmailUsername:
        default:
            Form = <EmailUsernameForm />;
    }

    const signupUrl = config.landingPageUrl;

    return (
        <NoAuthLayout gap="large">
            <h1 className="text-2xl font-semibold">{i18n("login.title")}</h1>
            <TabSelector />
            {Form}
            <hr className="h-px w-full max-w-[356px] bg-neutral-300 text-neutral-300" />
            <p className="text-neutral-700">
                {i18n("login.not_a_manager")} <KalepLink href={signupUrl}>{i18n("login.sign_up")}</KalepLink>
            </p>
        </NoAuthLayout>
    );
};

export default LoginPage;
