import {useIntl} from "react-intl";

import {apiTimestampToDate} from "common/util/apiTimestampToDate";
import {getDayMonthTimeDate, getDayMonthYearDate, getTime} from "common/util/FormatUtil";
import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";

import DateType = FleetOwnerPortalService.DateType;

interface Props {
    value: number;
    isSubCell?: boolean;
    type: DateType;
}

export const DateCell = ({value, isSubCell, type}: Props) => {
    const intl = useIntl();
    const fontSize = isSubCell ? "text-xs" : "text-sm";
    const color = isSubCell ? "secondary" : undefined;

    let dateStr: string | undefined;
    switch (type) {
        case DateType.DATETIME:
            dateStr = `${getDayMonthYearDate(apiTimestampToDate(value))}, ${getTime(apiTimestampToDate(value))}`;
            break;
        case DateType.SHORT_DATETIME: {
            const unix = Math.floor(apiTimestampToDate(value).getTime() / 1000);
            dateStr = getDayMonthTimeDate(unix, intl);
            break;
        }
        default:
            dateStr = UnreachableCode.never(
                type,
                `${getDayMonthYearDate(apiTimestampToDate(value))}, ${getTime(apiTimestampToDate(value))}`,
            );
    }

    return (
        <div className="flex justify-start whitespace-nowrap">
            <Typography variant="body-tabular-m-regular" color={color} fontSize={fontSize}>
                {dateStr}
            </Typography>
        </div>
    );
};
