import {FC} from "react";

import {ResponsiveDrawer} from "common/components/drawer/ResponsiveDrawer";
import {useI18n} from "common/hooks/useI18n";

import {Button, Drawer, Typography} from "@bolteu/kalep-react";

interface GlossaryProps {
    isOpen: boolean;
    handleClose: () => void;
    items: GlossaryItemProps[];
}

export interface GlossaryItemProps {
    title: string;
    text: string;
}

const GlossaryItem = ({title, text}: GlossaryItemProps) => {
    return (
        <div className="flex flex-col gap-2">
            <Typography variant="title-secondary">{title}</Typography>
            <Typography variant="body-primary">{text}</Typography>
        </div>
    );
};

const Glossary: FC<GlossaryProps> = ({isOpen, handleClose, items}: GlossaryProps) => {
    const {i18n} = useI18n();

    return (
        <ResponsiveDrawer isOpen={isOpen} title={i18n("common.glossary")} onRequestClose={handleClose}>
            <Drawer.Content>
                <div className="flex flex-col gap-6">
                    {items.map(({title, text}) => (
                        <GlossaryItem key={title} title={title} text={text} />
                    ))}
                </div>
            </Drawer.Content>
            <Drawer.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    {i18n("common.close")}
                </Button>
            </Drawer.Footer>
        </ResponsiveDrawer>
    );
};

export {Glossary};
