import {getVehiclePictureByType} from "@fleet/common/utils/vehicle/getVehiclePictureByType";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";

interface Props {
    vehicleModelBodyType: VehicleMarketplaceService.CarModelBodyType;
}

const VehiclePicture = ({vehicleModelBodyType}: Props) => (
    <img src={getVehiclePictureByType(vehicleModelBodyType)} alt="Vehicle" className="max-h-52 object-cover" />
);

export default VehiclePicture;
