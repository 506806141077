import {useCallback, useContext, useState} from "react";
import {Outlet} from "react-router-dom";

import {BackDrop} from "common/navigation/components/Backdrop";
import {NonAccountTopBar} from "common/navigation/components/NonAccountTopBar";
import {Sidebar} from "common/navigation/components/Sidebar";
import {Topbar} from "common/navigation/components/Topbar";
import {NavigationLink} from "common/navigation/hooks/useNavigationLinks";
import {SupportWidgetContextProvider} from "SupportWidgetProvider";

export type TopBarVariant = "ACCOUNT" | "NON_ACCOUNT";

interface BaseLayoutProps {
    navigationLinks: NavigationLink[][];
    topbarVariant: TopBarVariant;
}

const BaseLayout = ({navigationLinks, topbarVariant}: BaseLayoutProps) => {
    const [isSidebarOpen, setIsSideBarOpen] = useState(false);

    const closeSideBar = useCallback(() => setIsSideBarOpen(false), []);
    const toggleSideBar = useCallback(() => setIsSideBarOpen(!isSidebarOpen), [isSidebarOpen]);

    const {isSupportWidgetEnabled, hasUnreadCases, toggleSupportWidget} = useContext(SupportWidgetContextProvider);

    const toggleSupportWidgetWrapper = useCallback(() => {
        toggleSupportWidget();
    }, [toggleSupportWidget]);

    return (
        <>
            {topbarVariant === "ACCOUNT" && (
                <Topbar
                    navigationLinks={navigationLinks}
                    isSidebarOpen={isSidebarOpen}
                    toggleSidebar={toggleSideBar}
                    isSupportWidgetEnabled={isSupportWidgetEnabled}
                    hasUnreadSupportCases={hasUnreadCases}
                    toggleSupportWidget={toggleSupportWidgetWrapper}
                />
            )}
            {topbarVariant === "NON_ACCOUNT" && (
                <NonAccountTopBar isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSideBar} />
            )}
            <div>
                <Sidebar
                    isOpen={isSidebarOpen}
                    setOpen={setIsSideBarOpen}
                    navigationLinks={navigationLinks}
                    onLinkClick={closeSideBar}
                />
                <BackDrop isVisible={isSidebarOpen} onClick={closeSideBar} />
                <div className="flex-1">
                    <div className="flex h-full text-base leading-normal">
                        <div className="flex h-full w-full flex-col xl:items-center">
                            <Outlet />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default BaseLayout;
