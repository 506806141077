import {FC} from "react";

import {useI18n} from "common/hooks/useI18n";

import {Alert} from "@bolteu/kalep-react";

import CredentialsBlockItem from "./CredentialsBlockItem";

interface CredentialsBlockProps {
    clientId: string;
    clientSecret: string;
}

const CredentialsBlock: FC<CredentialsBlockProps> = ({clientId, clientSecret}) => {
    const {i18n} = useI18n();

    const credentialsText = i18n("auth.app.fleet.api-credentials.credentials.credentials.info_text");
    const credentialsClientIdText = i18n("auth.app.fleet.api-credentials.credentials.credentials.client_id");
    const credentialsClientSecretText = i18n("auth.app.fleet.api-credentials.credentials.credentials.client_secret");
    const credentialsSubtext = i18n("auth.app.fleet.api-credentials.credentials.credentials.subtext");

    return (
        <div className="flex flex-col gap-4">
            <Alert severity="info">{credentialsText}</Alert>
            <div className="mt-2 flex gap-4">
                <CredentialsBlockItem label={credentialsClientIdText} value={clientId} />
                <CredentialsBlockItem label={credentialsClientSecretText} value={clientSecret} />
            </div>
            <p className="text-secondary text-sm">{credentialsSubtext}</p>
        </div>
    );
};

export default CredentialsBlock;
