import LoadingSpinner from "common/components/spinner";
import {FixedHeaderTable} from "common/components/styledComponent/FixedHeaderTable";

import NoInvoices from "../components/NoInvoices";
import FleetIsSubsidiaryBanner from "./components/FleetIsSubsidiaryBanner";
import {useBoltInvoices} from "./hooks/useBoltInvoices";
import {useTableColumns} from "./hooks/useTableColumns";

const BoltToFleetInvoices = () => {
    const {invoices, isLoading, hasHoldingFleetInvoicing} = useBoltInvoices();

    const columns = useTableColumns();

    if (isLoading) {
        return <LoadingSpinner show />;
    }
    if (!invoices.length) {
        return <NoInvoices hasHoldingFleetInvoicing={hasHoldingFleetInvoicing} />;
    }

    const tableTopPadding = hasHoldingFleetInvoicing ? "" : "pt-2";

    return (
        <>
            <FleetIsSubsidiaryBanner
                hasInvoices={!!invoices.length}
                hasHoldingFleetInvoicing={hasHoldingFleetInvoicing}
            />
            <div className={`w-9/12 min-w-[688px] ${tableTopPadding}`}>
                <FixedHeaderTable items={invoices} columns={columns} aria-label="Bolt invoices" />
            </div>
        </>
    );
};
export default BoltToFleetInvoices;
