import {useCallback, useMemo} from "react";
import {Link} from "react-router-dom";

import AccordionTableWithCustomizableColumns, {
    CustomizableAccordionTableHeaders,
} from "common/components/table/AccordionTableWithCustomizableColumns";
import {VehicleListingStatusChip} from "common/components/vehicles/VehicleListingStatusChip";
import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";
import {getDayMonthYearDate} from "common/util/FormatUtil";
import {TranslationKeys} from "config/translations";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Typography} from "@bolteu/kalep-react";
import {RowActionContext} from "@bolteu/kalep-table-react";

import {offerTypeToReadable} from "./RentingOutTable";

interface RentingOutAccordionTableProps {
    items: VehicleMarketplaceService.SearchCompanyListingsResponse;
}

export default function RentingOutAccordionTable({items}: RentingOutAccordionTableProps) {
    const {i18n} = useI18n();

    const {getVehicleListingEditPath, getVehicleListingViewPath} = useAbsolutePath();

    const columns = useMemo<
        CustomizableAccordionTableHeaders<VehicleMarketplaceService.CompanyListingSearchResult>
    >(() => {
        return {
            offer_price: {
                label: i18n("auth.app.fleet.vehicle_marketplace.listings.table.offer_price"),
                direction: "horizontal",
                renderCell: (item: VehicleMarketplaceService.CompanyListingSearchResult) => {
                    if (!item.offer_price) {
                        return <div>-</div>;
                    }
                    return (
                        <div className="flex flex-col">
                            <div className="flex items-center gap-x-1">
                                <Typography variant="body-tabular-m-regular">{item.offer_price}</Typography>
                                <Typography variant="body-tabular-s-regular" color="secondary">
                                    {item.offer_price_currency}
                                </Typography>
                            </div>
                            <Typography variant="body-tabular-s-regular" color="secondary">
                                {item.offer_period
                                    ? i18n(
                                          `auth.app.fleet.vehicle_marketplace.listings.table.offer_period.${item.offer_period}`,
                                      )
                                    : "-"}
                            </Typography>
                        </div>
                    );
                },
            },
            offer_type: {
                label: i18n("auth.app.fleet.vehicle_marketplace.listings.table.payment_type"),
                direction: "horizontal",
                renderCell: (item: VehicleMarketplaceService.CompanyListingSearchResult) => {
                    return <div>{offerTypeToReadable(item.offer_type, i18n)}</div>;
                },
            },
            publish_date: {
                label: i18n("auth.app.fleet.vehicle_marketplace.listings.table.publish_date"),
                direction: "horizontal",
                renderCell: (item: VehicleMarketplaceService.CompanyListingSearchResult) => {
                    if (!item.published_date) {
                        return <div>-</div>;
                    }
                    return <div>{getDayMonthYearDate(new Date(item.published_date * 1000))}</div>;
                },
            },
            applicants: {
                label: i18n("auth.app.fleet.vehicle_marketplace.listings.table.applicants"),
                direction: "horizontal",
                renderCell: (item: VehicleMarketplaceService.CompanyListingSearchResult) => {
                    return <div>{item.number_of_applicants}</div>;
                },
            },
        };
    }, [i18n]);

    const getItemHeader = useCallback(
        (item: VehicleMarketplaceService.CompanyListingSearchResult) => {
            return {
                primary: (
                    <div className="flex items-center gap-x-1">
                        <div className="w-full">
                            {item.vehicle_manufacturer} {item.vehicle_model_name}, {item.vehicle_years}
                        </div>
                        <div className="min-w-1/6">
                            <VehicleListingStatusChip status={item.status} />
                        </div>
                    </div>
                ),
                secondary: (
                    <div>
                        {item.vehicle_colors
                            ?.map((color) => i18n(`vehicle.color.${color}` as TranslationKeys))
                            .join(", ")}
                    </div>
                ),
            };
        },
        [i18n],
    );

    const inlineActions = useMemo(() => {
        return [
            {
                label: i18n("auth.app.fleet.vehicle_marketplace.listings.table.open_listing_details"),
                customInlineRenderer: (
                    context: RowActionContext<VehicleMarketplaceService.CompanyListingSearchResult>,
                ) => {
                    const linkToListing =
                        context.row.status === VehicleMarketplaceService.ListingStatus.DRAFT
                            ? getVehicleListingEditPath(Number(context.row.id))
                            : getVehicleListingViewPath(Number(context.row.id));

                    return (
                        <button type="button">
                            <Link className="text-action-primary hover:underline" to={linkToListing}>
                                {i18n("auth.app.fleet.vehicle_marketplace.listings.table.open_listing_details")}
                            </Link>
                        </button>
                    );
                },
            },
        ];
    }, [i18n, getVehicleListingEditPath, getVehicleListingViewPath]);

    return (
        <AccordionTableWithCustomizableColumns
            id="renting-out-accordion-table"
            columns={columns}
            items={items.rows}
            inlineActions={inlineActions}
            getItemHeader={getItemHeader}
            paddingStart={2}
            paddingEnd={0}
        />
    );
}
