import {useIntl} from "react-intl";

import {PaymentMethod} from "common/constants/PaymentMethod";
import {useI18n} from "common/hooks/useI18n";
import {parseYearMonthDate} from "common/util/FormatUtil";
import {format} from "date-fns";
import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";

import {FleetOwnerPortalService, FleetOwnerReportingService} from "@bolteu/bolt-server-api-fleet-owner-portal";

export const useGeneratedFileTitle = (
    row: FleetOwnerReportingService.CompanyReport,
    drivers: FleetOwnerPortalService.Driver[],
) => {
    const intl = useIntl();
    const {i18n} = useI18n();
    const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];

    const fileTitleBase = i18n("auth.app.invoices.fleet-to-rider.generated_files.file_title");
    const dateStr = format(parseYearMonthDate(row.month), "MMM yyyy", {locale: dateFnsLocale});
    const paymentStr = row.payment_method ? i18n(`paymentMethods.${row.payment_method as PaymentMethod}`) : "";
    let driverName = "";
    if (row.driver_id) {
        const driver = drivers.find(({id}) => id === row.driver_id);
        driverName = driver ? `${driver.first_name} ${driver.last_name}` : i18n("common.driver");
    }

    return `${[fileTitleBase, driverName, paymentStr, dateStr]
        .filter((str) => str !== "")
        .join(" ")
        .replaceAll(" ", "_")}.csv`;
};
