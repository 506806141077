import {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";

type SetValue<T> = Dispatch<SetStateAction<T>>;

declare global {
    interface WindowEventMap {
        local_storage: CustomEvent;
    }
}

// Hook taken from https://github.com/juliencrn/usehooks-ts/blob/master/packages/usehooks-ts/src/useLocalStorage/useLocalStorage.ts
/* eslint-disable no-console -- Disabled as warnings are not critical */
export function useLocalStorage<T>(key: string, initialValue: T): [T, SetValue<T>] {
    // Get from local storage then
    // parse stored json or return initialValue
    const readValue = useCallback((): T => {
        // Prevent build error "window is undefined" but keeps working
        if (typeof window === "undefined") {
            return initialValue;
        }

        try {
            const item = window.localStorage.getItem(key);
            return item ? (parseJSON(item) as T) : initialValue;
        } catch (error) {
            console.warn(`Error reading localStorage key “${key}”:`, error);
            return initialValue;
        }
    }, [initialValue, key]);

    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<T>(readValue);

    const setValue: SetValue<T> = useCallback(
        (value) => {
            // Prevent build error "window is undefined" but keeps working
            if (typeof window === "undefined") {
                console.warn(`Tried setting localStorage key “${key}” even though environment is not a client`);
            }

            try {
                // Allow value to be a function so we have the same API as useState
                const newValue = value instanceof Function ? value(storedValue) : value;

                // Save to local storage
                window.localStorage.setItem(key, JSON.stringify(newValue));

                // Save state
                setStoredValue(newValue);

                // We dispatch a custom event so every useLocalStorage hook are notified
                window.dispatchEvent(new Event("local_storage"));
            } catch (error) {
                console.warn(`Error setting localStorage key “${key}”:`, error);
            }
        },
        [key, storedValue],
    );

    useEffect(() => {
        setStoredValue(readValue());
        // eslint-disable-next-line react-hooks/exhaustive-deps -- should only run once
    }, []);

    const handleStorageChange = useCallback(
        (event: StorageEvent | CustomEvent) => {
            if ((event as StorageEvent)?.key && (event as StorageEvent).key !== key) {
                return;
            }
            setStoredValue(readValue());
        },
        [key, readValue],
    );

    useEffect(() => {
        // Define the listening target
        const targetElement = window;

        if (!(targetElement && targetElement.addEventListener)) {
            return undefined;
        }

        // Create event listener that calls handler function stored in ref

        targetElement.addEventListener("local_storage", handleStorageChange);

        // Remove event listener on cleanup
        return () => {
            targetElement.removeEventListener("local_storage", handleStorageChange);
        };
    }, [handleStorageChange]);

    return [storedValue, setValue];
}

// A wrapper for "JSON.parse()"" to support "undefined" value
function parseJSON<T>(value: string | null): T | undefined {
    try {
        return value === "undefined" ? undefined : (JSON.parse(value ?? "") as T);
    } catch {
        console.log("parsing error on", {value});
        return undefined;
    }
}
/* eslint-enable  */
