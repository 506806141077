import React, {useEffect} from "react";
import {BrowserRouter as Router} from "react-router-dom";

import AuthInit from "AuthInit";
import ScrollToTop from "common/components/scrollToTop/ScrollToTop";
import {ApiProvider} from "common/services/apiProvider";
import config from "config/index";
import {AccountProvider} from "features/account/accountStateProvider";
import {ApplicationProvider} from "features/app/appStateProvider";
import {ThirdPartyProvider} from "features/app/appThirdPartyProvider";
import TermsAndConditionsUpdate from "features/app/termsAndConditions/TermsAndConditionsUpdate";
import {FeaturesProvider} from "FeaturesProvider";
import {SupportWidgetProvider} from "SupportWidgetProvider";
import {NotificationProvider} from "@fleet/common/services/notificationProvider";

import {SnackbarProvider} from "@bolteu/kalep-react";

import {NavigationBarVisibilityProvider} from "./NavigationBarVisibilityProvider";

const App: React.FC = () => {
    const PreRouteCheck = () => {
        useEffect(() => {
            const {pathname} = window.location;
            if (pathname !== "/v2" && !pathname.startsWith("/v2/")) {
                window.location.replace("/v2");
            }
        }, []);

        return null;
    };

    return (
        <ThirdPartyProvider>
            <ApplicationProvider>
                <AccountProvider>
                    <PreRouteCheck />
                    <Router basename={config.routerBasename}>
                        <ScrollToTop />
                        <ApiProvider>
                            <FeaturesProvider>
                                <SupportWidgetProvider>
                                    <NavigationBarVisibilityProvider>
                                        <NotificationProvider>
                                            <SnackbarProvider>
                                                <TermsAndConditionsUpdate />
                                                <AuthInit />
                                            </SnackbarProvider>
                                        </NotificationProvider>
                                    </NavigationBarVisibilityProvider>
                                </SupportWidgetProvider>
                            </FeaturesProvider>
                        </ApiProvider>
                    </Router>
                </AccountProvider>
            </ApplicationProvider>
        </ThirdPartyProvider>
    );
};

export default App;
