import {useI18n} from "common/hooks/useI18n";
import WizardFileUpload from "features/companies/vehicleListings/common/components/WizardFileUpload";

const Photos = () => {
    const {i18n} = useI18n();

    const title = i18n("auth.app.fleet.vehicle_marketplace.listings.view.listing_data.headers.photos");

    return (
        <div>
            <p className="bolt-font-heading-xs-accent pb-4">{title}</p>
            <WizardFileUpload isReadOnly />
        </div>
    );
};

export default Photos;
