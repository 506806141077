import {FC, useCallback, useEffect, useMemo, useState} from "react";
import {useSearchParams} from "react-router-dom";

import {EventName, TabNameEvent} from "common/constants/events";
import {useTracking} from "common/hooks/useTracking";

import {Tabs} from "@bolteu/kalep-react";

import {SEARCH_PARAM_TAB_NAME} from "./constants";
import {getTabFromSearchParams} from "./getTabFromSearchParams";

export interface Tab<T extends string> {
    // id is ignored for the default tab so that default tab looks like "/page" instead of "/page?tab=default_tab
    id: T;
    title: string;
    trackingEventTabName: TabNameEvent;
}

export interface UseTabs<T extends string> {
    tabs: Tab<T>[];
    defaultTab: T;
}

function findSelectedTab<T extends string>(selectedTab: string, tabs: Tab<T>[], defaultTab: T): Tab<T> | null {
    return tabs.find((tab) => tab.id === selectedTab) || tabs.find((tab) => tab.id === defaultTab) || null;
}

export const useTabs = <T extends string>(tabs: Tab<T>[], defaultTab: T) => {
    const [searchParams, setSearchParams] = useSearchParams();

    const {trackEvent} = useTracking();

    const [activeTab, setActiveTab] = useState<T>(getTabFromSearchParams<T>(tabs, defaultTab, searchParams));

    useEffect(() => {
        const tabSearchParam = searchParams.get(SEARCH_PARAM_TAB_NAME);
        const selectedTab = findSelectedTab(tabSearchParam || "", tabs, defaultTab);
        if (selectedTab) {
            setActiveTab(selectedTab.id);
        }
    }, [defaultTab, tabs, searchParams]);

    const handleTabChange = useCallback(
        (tabChangeValue: string) => {
            const selectedTab = findSelectedTab(tabChangeValue, tabs, defaultTab);
            if (!selectedTab) {
                return;
            }

            setActiveTab(selectedTab.id);
            setSearchParams((prevSearchParams) => {
                if (selectedTab.id === defaultTab) {
                    prevSearchParams.delete(SEARCH_PARAM_TAB_NAME);
                } else {
                    prevSearchParams.set(SEARCH_PARAM_TAB_NAME, selectedTab.id);
                }
                return prevSearchParams;
            });

            if (selectedTab.id === activeTab) {
                return;
            }
            trackEvent(EventName.TabClicked, {tabName: selectedTab.trackingEventTabName});
        },
        [tabs, defaultTab, activeTab, setSearchParams, trackEvent],
    );

    const TabSelector: FC = useMemo(() => {
        return () => (
            <Tabs selectedTab={activeTab} onChange={handleTabChange}>
                {tabs.map((tab) => (
                    <Tabs.Tab key={tab.id} id={tab.id} label={tab.title} />
                ))}
            </Tabs>
        );
    }, [tabs, activeTab, handleTabChange]);

    return {
        activeTab,
        TabSelector,
    };
};
