import {useMemo} from "react";
import {useIntl} from "react-intl";

import DataAndMeasuresCell from "common/components/table/DataAndMeasuresCell";
import {Dash} from "common/constants";
import {EventName} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import config from "config/index";
import {format} from "date-fns";
import {intlLocaleToDateFnsLocale} from "@fleet/common/services/translations";

import {GhostButton, Tooltip} from "@bolteu/kalep-react";
import {OpenIn} from "@bolteu/kalep-react-icons";
import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";
import {TableHeaders} from "@bolteu/kalep-table-react/build/types";

import {FleetToRiderInvoiceData} from "./useInvoices";

export function useTableColumns() {
    const intl = useIntl();
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();

    return useMemo<TableHeaders<FleetToRiderInvoiceData>>(() => {
        const openPdf = (uuid: string) => () => {
            trackEvent(EventName.FleetToRiderInvoicePdfClicked);
            window.open(`${config.invoiceServiceUrl}/?s=${encodeURIComponent(uuid)}`);
        };

        return {
            created: {
                label: i18n("common.date"),
                renderCell: (item: FleetToRiderInvoiceData) => {
                    const dateFnsLocale = intlLocaleToDateFnsLocale[intl.locale];
                    const date = format(item.createdDate, "P", {locale: dateFnsLocale});
                    const time = format(item.createdDate, "HH:mm", {locale: dateFnsLocale});

                    return (
                        <DefaultCellContent compact>
                            <div className="flex h-full w-full flex-col justify-center">
                                <div className="text-primary antialiased">{date}</div>
                                <div className="text-secondary whitespace-nowrap text-xs antialiased">{time}</div>
                            </div>
                        </DefaultCellContent>
                    );
                },
            },
            driverName: {
                label: i18n("common.driver"),
            },
            pickupAddress: {
                label: i18n("auth.app.invoices.fleet-to-rider.pickup_address"),
                renderCell: (item: FleetToRiderInvoiceData) => (
                    <DefaultCellContent className={item.pickupAddress === Dash.EM ? "text-content-tertiary" : ""}>
                        {item.pickupAddress}
                    </DefaultCellContent>
                ),
            },
            paymentMethod: {
                label: i18n("common.payment_method"),
            },
            sum: {
                label: i18n("auth.app.invoices.fleet-to-rider.sum"),
                renderCell: (item: FleetToRiderInvoiceData) => (
                    <DataAndMeasuresCell data={item.sum} measure={item.currency} />
                ),
                align: "right",
            },
            uuid: {
                label: "",
                renderCell: (item: FleetToRiderInvoiceData) => (
                    <DefaultCellContent className="justify-center">
                        <Tooltip content={i18n("common.open_pdf")} placement="top">
                            <GhostButton onClick={openPdf(item.uuid)}>
                                <OpenIn size="sm" />
                            </GhostButton>
                        </Tooltip>
                    </DefaultCellContent>
                ),
            },
        };
    }, [i18n, intl.locale, trackEvent]);
}
