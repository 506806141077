import {useMemo} from "react";

import DataAndMeasuresCell from "common/components/table/DataAndMeasuresCell";
import {useI18n} from "common/hooks/useI18n";

import {TableHeaders} from "@bolteu/kalep-table-react/build/types";

import {PayoutInvoiceData} from "./usePayouts";

export function useTableColumns() {
    const {i18n} = useI18n();

    return useMemo<TableHeaders<PayoutInvoiceData>>(() => {
        return {
            date: {
                label: i18n("auth.app.reports.payouts.fields.paid-out"),
            },
            amount: {
                label: i18n("auth.app.reports.payouts.fields.amount"),
                renderCell: (item: PayoutInvoiceData) => (
                    <DataAndMeasuresCell data={item.amount} measure={item.currency} />
                ),
                align: "right",
            },
        };
    }, [i18n]);
}
