import {useCallback, useContext} from "react";
import {useNavigate} from "react-router-dom";

import {useAbsolutePath} from "common/hooks/useAbsolutePath";
import {useI18n} from "common/hooks/useI18n";
import {FeaturesContextProvider} from "FeaturesProvider";

import {Button, Menu, MenuItem} from "@bolteu/kalep-react";
import {ChevronDown} from "@bolteu/kalep-react-icons";

interface Props {
    openAddVehiclePage: () => void;
}

const AddVehiclesButton = ({openAddVehiclePage}: Props) => {
    const navigate = useNavigate();

    const {i18n} = useI18n();
    const {getVehicleListingCreatePath} = useAbsolutePath();

    const features = useContext(FeaturesContextProvider);

    const isVehicleMarketplaceEnabled = features?.vehicle_marketplace;

    const goToVehicleMarketplace = useCallback(
        () => navigate(getVehicleListingCreatePath()),
        [navigate, getVehicleListingCreatePath],
    );

    const menus: Array<{label: string; onClick: () => void}> = [
        {
            label: i18n("auth.app.fleet.dashboard.add-vehicle-to-fleet"),
            onClick: openAddVehiclePage,
        },
    ];

    if (isVehicleMarketplaceEnabled) {
        menus.push({
            onClick: goToVehicleMarketplace,
            label: i18n("auth.app.fleet.dashboard.rent-out-vehicle"),
        });
    }

    if (menus.length === 1) {
        return <Button onClick={openAddVehiclePage}>{i18n("auth.app.fleet.dashboard.add-vehicle")}</Button>;
    }

    return (
        <Menu
            menuButton={
                <Button>
                    {i18n("auth.app.fleet.dashboard.add-vehicle")} <ChevronDown size="sm" />
                </Button>
            }
            align="end"
        >
            {menus.map((menu) => (
                <MenuItem key={menu.label} label={menu.label} onClick={menu.onClick} />
            ))}
        </Menu>
    );
};

export default AddVehiclesButton;
