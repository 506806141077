import {FC} from "react";

import {Alert} from "@bolteu/kalep-react";

import {useDocumentsAlert} from "./useDocumentsAlert";

export interface DocumentsAlertProps {
    expiredDocuments: boolean;
    declinedDocuments: boolean;
}

export const DocumentsAlert: FC<DocumentsAlertProps> = ({expiredDocuments, declinedDocuments}) => {
    const {titleText, descriptionText} = useDocumentsAlert({expiredDocuments, declinedDocuments});

    if (!expiredDocuments && !declinedDocuments) {
        return null;
    }

    return (
        <Alert severity="error">
            <p className="mb-2 font-semibold">{titleText}</p>
            <p className="text-secondary">{descriptionText}</p>
        </Alert>
    );
};
