import {useI18n} from "common/hooks/useI18n";

import {Receipt} from "@bolteu/kalep-react-icons";

const NoInvoices = () => {
    const {i18n} = useI18n();

    return (
        <div className="mt-16 mb-8 flex flex-col items-center justify-center gap-2">
            <Receipt className="mb-2 h-[64px] w-[64px] text-neutral-600" />
            <h5 className="max-w-[420px] text-center text-xl font-semibold text-neutral-900">
                {i18n("auth.app.invoices.no_invoices")}
            </h5>
        </div>
    );
};

export default NoInvoices;
