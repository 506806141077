import {subDays} from "date-fns";

export const SHOW_EXPIRATION_IN_DAYS = 90;

export function isExpiring(expirationDate: Date) {
    return subDays(expirationDate, SHOW_EXPIRATION_IN_DAYS) <= new Date();
}

export function hasExpired(expirationDate: Date) {
    return expirationDate <= new Date();
}
