import {FC} from "react";
import {useIntl} from "react-intl";

import {useI18n} from "common/hooks/useI18n";

import {Chip} from "@bolteu/kalep-react";

interface ClientInfoBlockProps {
    partialClientId: string;
    lastCredentialsChangeTimestamp: number;
}

const ClientInfoBlock: FC<ClientInfoBlockProps> = ({partialClientId, lastCredentialsChangeTimestamp}) => {
    const {i18n} = useI18n();
    const intl = useIntl();

    const clientInfoDateText = i18n("auth.app.fleet.api-credentials.credentials.client_info.created_or_renewed_at", {
        date: intl.formatDate(lastCredentialsChangeTimestamp * 1000, {dateStyle: "medium"}),
    });
    const clientInfoActiveText = i18n("auth.app.fleet.api-credentials.credentials.client_info.state.active");

    const clientId = partialClientId + "•".repeat(6);

    return (
        <div className="flex items-center justify-between">
            <div>
                <p>{clientId}</p>
                <p className="text-secondary text-sm">{clientInfoDateText}</p>
            </div>
            <Chip size="sm" appearance="action" label={clientInfoActiveText} />
        </div>
    );
};

export default ClientInfoBlock;
