import {useContext, useEffect, useMemo, useState} from "react";

import {useI18n} from "common/hooks/useI18n";
import {useValidationErrorMessages} from "common/hooks/useValidationErrorMessages";
import {Api, ApiContextProvider} from "common/services/apiProvider";
import {
    isCarFormValidationError,
    isDynamicFormValidationError,
    isError,
    isValidationError,
} from "common/util/isErrorType";
import {TranslationKeys} from "config/translations";
import {FetchError, FetchStatus, Files, UseFetch, ValidatorResult} from "@fleet/common/hooks/useFetch";
import {NotificationContext, NotificationType} from "@fleet/common/services/notificationProvider";

export {FetchStatus};

export type FetchFunction<T, R> = (api: Api, body: T, files?: Files) => Promise<R>;
/**
 * General `useFetch` hook that can be used with any api calls.
 * @param fetchFunction Function that hook will on returning `fetch` function call.
 * Should accept CommonApis argument, so you don't have to import it yourself and check if CommonApis is not null.
 *
 * @example
 * // Define `fetchFunction` outside component body
 * const fetchFunction = (api: Api, body: DriverEarningsService.GetCompanyEarningsRecentRequest) =>
 *  apis.driverEarningsBasicAuth.getCompanyEarningsRecent(body);
 *
 * // Provide this function to `useFetch` hook inside component
 * const {data, status} = useFetch(fetchFunction);
 *
 * // Call fetch when you need it
 * React.useEffect(() => {
 *     if (fetch) {
 *         fetch({period});
 *     }
 * }, [fetch, accountState.account?.selectedCompany, period]);
 *
 * @example
 * // If you don't need request body for endpoint call - don't accept it in `fetchFunction`
 * const fetchFunction = (api: Api) => api.carRentalPayment.carRentalPaymentDeleteUploadedPayments();
 *
 * const {data, status} = useFetch(fetchFunction);
 *
 * // And then call fetch with empty object or null
 * const confirmUploadedPaymentsDeletion = useCallback(async () => {
 *     if (fetch) {
 *         fetch({});
 *     }
 * }, [fetch]);
 *
 * @returns Fetch status (see types), data if it was successfully fetched and `fetch` function which can be null depending on the provided fetchFunction.
 */
export function useFetch<T, R, F>(fetchFunction: FetchFunction<T, R>): UseFetch<T, R> {
    const {i18n} = useI18n();
    const api = useContext(ApiContextProvider);
    const {setNotification} = useContext(NotificationContext);
    const [errorMessage, setErrorMessage] = useState("");
    const [validationErrors, setValidationErrors] = useState<ValidatorResult[]>([]);

    const {getValidationErrorMessages, getCarFormValidationErrorMessages, getDynamicFormValidationErrorMessages} =
        useValidationErrorMessages();

    const [status, setStatus] = useState(FetchStatus.Init);
    const [data, setData] = useState<R | null>(null);

    const error = useMemo<FetchError>(
        () => ({message: errorMessage, validationErrors}),
        [validationErrors, errorMessage],
    );

    useEffect(() => {
        return () => {
            setStatus(FetchStatus.Init);
            setData(null);
            setErrorMessage("");
            setValidationErrors([]);
        };
    }, []);

    const fetch = useMemo(() => {
        if (!api) {
            return null;
        }

        return async (body: T, files?: F) => {
            setStatus(FetchStatus.Loading);

            try {
                let res: R;
                if (files) {
                    res = await fetchFunction(api, body, files);
                } else {
                    res = await fetchFunction(api, body);
                }
                setData(res);
                setStatus(FetchStatus.Success);
                setErrorMessage("");
                setValidationErrors([]);
            } catch (e) {
                setStatus(FetchStatus.Error);
                if (isValidationError(e)) {
                    setErrorMessage("INVALID_REQUEST");
                    setValidationErrors(getValidationErrorMessages(e));
                } else if (isCarFormValidationError(e)) {
                    setValidationErrors(getCarFormValidationErrorMessages(e.response.data));
                } else if (isDynamicFormValidationError(e)) {
                    setValidationErrors(getDynamicFormValidationErrorMessages(e.response.data));
                } else if (isError(e)) {
                    setErrorMessage(e.message);
                    setValidationErrors([]);
                }
            }
        };
    }, [
        api,
        fetchFunction,
        getValidationErrorMessages,
        getCarFormValidationErrorMessages,
        getDynamicFormValidationErrorMessages,
    ]);

    useEffect(() => {
        if (errorMessage) {
            setNotification({
                type: NotificationType.ERROR,
                text: i18n(`api.error.${errorMessage}` as TranslationKeys, undefined, "api.default_error"),
                timeout: 6000,
            });
            setErrorMessage("");
        }
    }, [errorMessage, i18n, setNotification]);

    if (status === FetchStatus.Init) {
        return {
            status,
            data: null,
            fetch,
            error: {
                message: errorMessage,
                validationErrors,
            },
        };
    }

    if (status === FetchStatus.Success) {
        return {
            status,
            data: data as R,
            fetch,
            error,
        };
    }

    if (status === FetchStatus.Loading) {
        return {
            status,
            data,
            fetch,
            error,
        };
    }

    return {
        status: FetchStatus.Error,
        data,
        fetch,
        error,
    };
}
