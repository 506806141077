import {FC, useCallback, useEffect, useMemo, useState} from "react";

import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {useI18n} from "common/hooks/useI18n";
import {Api} from "common/services/apiProvider";
import {isValidationError} from "common/util/isErrorType";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {FormState, useForm} from "@bolteu/kalep-form-react";
import {FormChange} from "@bolteu/kalep-form-react/src/Form/types";
import {Alert, Dialog, SelectOption} from "@bolteu/kalep-react";

export interface FormOfWorkDetails {
    formOfWork: FleetOwnerPortalService.FormOfWork | null;
    contractNumber: string | null;
}

export interface DialogProps {
    onClosed: () => void;
    onConfirmed: (state: FormOfWorkDetails) => void;
    driverId: number;
    formOfWorkConfig: FleetOwnerPortalService.FormOfWorkConfig[];
    initialFormOfWork: FleetOwnerPortalService.FormOfWork | null;
    initialContractNumber: string | null;
}

const updateDriverFetchFunction = (api: Api, body: FleetOwnerPortalService.UpdateDriverRequest) =>
    api.fleetOwnerPortal.updateDriver(body);

const DriverFormOfWorkDialog: FC<DialogProps> = ({
    onClosed,
    onConfirmed,
    driverId,
    formOfWorkConfig,
    initialFormOfWork,
    initialContractNumber,
}) => {
    const {i18n} = useI18n();
    const [confirmedFormOfWorkDetails, setConfirmedFormOfWorkDetails] = useState<FormOfWorkDetails | null>(null);
    const {status: updateStatus, fetch: updateDriver, error: updateError} = useFetch(updateDriverFetchFunction);

    const formOfWorkSelectOptions: SelectOption[] = useMemo(
        () =>
            formOfWorkConfig.map((config) => ({
                value: config.value,
                title: i18n(`auth.app.fleet.driver-profile.form_of_work_details.${config.value}`),
            })),
        [formOfWorkConfig, i18n],
    );

    const {Form, TextField, SelectField, Button, useFieldValue, setData} = useForm({
        formOfWork: initialFormOfWork,
        contractNumber: initialContractNumber,
    });
    const formOfWork = useFieldValue("formOfWork");
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const onRequestClose = useCallback(() => {
        onClosed();
    }, [onClosed]);

    const onFormSubmit = useCallback(
        async (state: FormState<FormOfWorkDetails>) => {
            if (state.data.formOfWork === null) {
                setErrorMessage(i18n("auth.app.fleet.driver-profile.form_of_work_dialog.form_of_work_required"));
                return;
            }

            if (updateDriver) {
                await updateDriver({
                    driver_id: driverId,
                    form_of_work: state.data.formOfWork,
                    contract_number: state.data.contractNumber,
                });
                setConfirmedFormOfWorkDetails(state.data);
            }
        },
        [driverId, i18n, updateDriver],
    );

    const onFormChange = useCallback(
        (change: FormChange<FormOfWorkDetails>) => {
            setErrorMessage(null);
            if (change.event.name === "formOfWork") {
                if (isContractNumberRequired(formOfWork, formOfWorkConfig)) {
                    setData({contractNumber: null});
                } else {
                    setData({contractNumber: initialContractNumber});
                }
            }
        },
        [formOfWork, formOfWorkConfig, initialContractNumber, setData],
    );

    useEffect(() => {
        if (updateStatus === FetchStatus.Success && confirmedFormOfWorkDetails) {
            onConfirmed(confirmedFormOfWorkDetails);
        } else if (updateStatus === FetchStatus.Error) {
            if (isValidationError(updateError)) {
                setErrorMessage(i18n("auth.app.fleet.driver-profile.form_of_work_dialog.form_of_work_details_invalid"));
            } else {
                setErrorMessage(i18n("api.default_error"));
            }
        }
    }, [i18n, onConfirmed, updateStatus, confirmedFormOfWorkDetails, updateError]);

    return (
        <Dialog
            isOpen
            onRequestClose={onRequestClose}
            title={i18n("auth.app.fleet.driver-profile.form_of_work_dialog.title")}
        >
            <Form onSubmit={onFormSubmit} onChange={onFormChange}>
                <Dialog.Content>
                    {errorMessage && (
                        <Alert severity="error" overrideClassName="text-base mb-2">
                            {errorMessage}
                        </Alert>
                    )}
                    <SelectField
                        name="formOfWork"
                        required
                        label={i18n("auth.app.fleet.driver-profile.form_of_work_dialog.form_of_work_label")}
                        placeholder={i18n("auth.app.fleet.driver-profile.form_of_work_dialog.form_of_work_placeholder")}
                        helperText={i18n("auth.app.fleet.driver-profile.form_of_work_dialog.form_of_work_helper_text")}
                        options={formOfWorkSelectOptions}
                    />
                    <TextField
                        name="contractNumber"
                        required={isContractNumberRequired(formOfWork, formOfWorkConfig)}
                        label={i18n("auth.app.fleet.driver-profile.form_of_work_dialog.contract_number_label")}
                        placeholder={i18n(
                            "auth.app.fleet.driver-profile.form_of_work_dialog.contract_number_placeholder",
                        )}
                        helperText={i18n(
                            "auth.app.fleet.driver-profile.form_of_work_dialog.contract_number_helper_text",
                        )}
                        disabled={!isContractNumberRequired(formOfWork, formOfWorkConfig)}
                    />
                </Dialog.Content>
                <Dialog.Footer>
                    <Button submit>{i18n("common.confirm")}</Button>
                </Dialog.Footer>
            </Form>
        </Dialog>
    );
};

function isContractNumberRequired(
    formOfWork: FleetOwnerPortalService.FormOfWork | null | undefined,
    config: FleetOwnerPortalService.FormOfWorkConfig[],
): boolean {
    if (formOfWork === null || formOfWork === undefined) {
        return false;
    }
    return config.find((c) => c.value === formOfWork)?.contractNumberRequired || false;
}

export default DriverFormOfWorkDialog;
