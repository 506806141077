import {ResponsiveHeader, ResponsiveHeaderType} from "common/components/header/ResponsiveHeader";
import {iconNameToKalepIcon} from "common/util/iconNameToKalepIcon";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {Grid} from "@bolteu/kalep-react";

import ChipRow from "./ChipRow";

import BonusIconVariant = FleetOwnerPortalService.BonusIconVariant;
import BonusIcon = FleetOwnerPortalService.BonusIcon;
import ApiChip = FleetOwnerPortalService.Chip;

interface DetailsHeaderProps {
    icon: BonusIcon;
    name: string;
    chips: ApiChip[];
}

const DetailsHeader = ({icon, name, chips}: DetailsHeaderProps) => {
    const CampaignIcon = iconNameToKalepIcon(icon.kalep_name);
    const campaignIconColor = icon.variant === BonusIconVariant.GREY ? "bg-neutral-600" : "bg-green-500";

    return (
        <Grid columns={12}>
            <Grid.Item colSpan={7}>
                <div className="flex gap-6">
                    <div className={`min-w-20 flex h-20 items-center justify-center rounded-full ${campaignIconColor}`}>
                        <CampaignIcon color="white" width="40" height="40" />
                    </div>
                    <div className="flex flex-col gap-3">
                        <ResponsiveHeader type={ResponsiveHeaderType.InnerPage} text={name} />
                        <ChipRow chips={chips} />
                    </div>
                </div>
            </Grid.Item>
        </Grid>
    );
};

export default DetailsHeader;
