export const getDriverName = (
    firstName: string | null | undefined,
    lastName: string | null | undefined,
    email: string | null | undefined,
) => {
    if (!firstName || !lastName) {
        return email || "";
    }
    return `${firstName} ${lastName}`;
};
