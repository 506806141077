import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {TabName} from "..";
import {getDefaultFilters} from "./getDefaultFilters";

import CampaignFilters = FleetOwnerPortalService.CampaignFilters;

export function isFilterValueDefault(
    activeTab: TabName,
    key: keyof CampaignFilters,
    value: CampaignFilters[keyof CampaignFilters],
): boolean {
    const defaultFilters = getDefaultFilters(activeTab);

    switch (key) {
        case "statuses": {
            const defaultValues = defaultFilters[key];
            const currentValues = value as CampaignFilters["statuses"];
            if (!defaultValues || !currentValues) {
                return true;
            }
            return defaultValues.every((defaultValue) => currentValues.includes(defaultValue));
        }
        case "city_id":
            return defaultFilters[key] === value;
        case "polygon_location":
            return Boolean(defaultFilters[key]) === Boolean(value);
        case "name":
            return !value;
        default:
            return UnreachableCode.never(key, false);
    }
}
