import {useContext, useMemo} from "react";
import {useNavigate} from "react-router-dom";

import {getDriverRegistrationStatus} from "common/constants/driverRegistrationStatus";
import {EventName} from "common/constants/events";
import {useI18n} from "common/hooks/useI18n";
import {useTracking} from "common/hooks/useTracking";
import {AccountContextProvider} from "features/account/accountStateProvider";

import {Chip} from "@bolteu/kalep-react";
import {DefaultCellContent} from "@bolteu/kalep-table-react/build/components/CellContent";
import {TableHeaders} from "@bolteu/kalep-table-react/build/types";

import {DriverRegistrationsTableData, DriverRegistrationType} from "../../components/DriverRegistrationsTable";
import {DriverInvitationStatus, DriverRegistrationStatus} from "../../index";

export function useTableColumns() {
    const fleetId = useContext(AccountContextProvider)?.getFleet()?.id;
    const navigate = useNavigate();
    const {i18n} = useI18n();
    const {trackEvent} = useTracking();

    return useMemo<TableHeaders<DriverRegistrationsTableData>>(() => {
        const goToDriverRegistration = (driverRegistrationId: string, status: DriverRegistrationStatus) => () => {
            if (!fleetId) {
                return;
            }

            const routeType =
                status === DriverInvitationStatus.INVITED
                    ? DriverRegistrationType.INVITATION
                    : DriverRegistrationType.REGISTRATION;
            const eventName =
                routeType === DriverRegistrationType.REGISTRATION
                    ? EventName.InvitedDriversApplicationClicked
                    : EventName.InvitedDriversInvitationClicked;

            trackEvent(eventName);
            navigate(`${routeType}/${driverRegistrationId}`);
        };

        return {
            driver: {
                label: i18n("common.driver"),
                renderCell: (item: DriverRegistrationsTableData) => (
                    <DefaultCellContent>
                        <div className="flex items-center justify-start gap-2">
                            <button
                                type="button"
                                onClick={goToDriverRegistration(item.id, item.status)}
                                className="hover:underline"
                            >
                                <p>{item.driver}</p>
                            </button>
                        </div>
                    </DefaultCellContent>
                ),
            },
            status: {
                label: i18n("common.status"),
                renderCell: (item: DriverRegistrationsTableData) => {
                    const statusData = getDriverRegistrationStatus(item.status);
                    return (
                        <Chip
                            label={i18n(`auth.app.fleet.driver-registrations.status.${statusData.label}`)}
                            size="sm"
                            color={statusData.color}
                            variant="outline"
                        />
                    );
                },
            },
            email: {label: i18n("common.email")},
            phone: {label: i18n("common.phone")},
        };
    }, [i18n, fleetId, trackEvent, navigate]);
}
