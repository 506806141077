import {SEARCH_PARAM_TAB_NAME} from "./constants";
import {Tab} from "./useTabs";

export function getTabFromSearchParams<T extends string>(
    tabs: Tab<T>[],
    defaultTab: T,
    searchParams: URLSearchParams,
): T {
    const tab = searchParams.get(SEARCH_PARAM_TAB_NAME);
    return tabs.some((t) => t.id === tab) ? (tab as T) : defaultTab;
}
