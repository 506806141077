import {useEffect} from "react";

import {useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";

const getSendofflineFeaturesFuntion = (api: Api) => api.fleetOwnerPortal.getSendDriverOfflineFeatures();

export function useSendOfflineFeatures(): SendOfflineFeaturesResponse {
    const {data, fetch: getSendOfflineFeatures} = useFetch(getSendofflineFeaturesFuntion);

    useEffect(() => {
        if (getSendOfflineFeatures) {
            getSendOfflineFeatures({});
        }
    }, [getSendOfflineFeatures]);

    return {
        send_offline_enabled: data?.send_offline_enabled ?? false,
        driver_trail_period_ms: data?.driver_trail_period_ms ?? 0,
        show_driver_route_trail: data?.show_driver_route_trail ?? false,
        show_last_dropoff_and_waiting_time: data?.show_last_dropoff_and_waiting_time ?? false,
    };
}

interface SendOfflineFeaturesResponse {
    send_offline_enabled: boolean;
    driver_trail_period_ms: number;
    show_driver_route_trail: boolean;
    show_last_dropoff_and_waiting_time: boolean;
}
