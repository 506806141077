import {useCallback, useEffect, useMemo} from "react";
import {useIntl} from "react-intl";

import ErrorView, {ErrorViewType} from "common/components/error/ErrorView";
import LoadingSpinner from "common/components/spinner";
import {FixedHeaderTable} from "common/components/styledComponent/FixedHeaderTable";
import {FetchStatus, useFetch} from "common/hooks/useFetch";
import {Api} from "common/services/apiProvider";
import {ApiPeriod} from "common/services/period";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {useTableColumns} from "../../common/hooks/useTableColumns";
import {useTableRows} from "../../common/hooks/useTableRows";
import {TabName} from "..";
import {areAllFilterValuesDefault} from "../utils/areAllFilterValuesDefault";
import {isPeriodDefault} from "../utils/isPeriodDefault";
import {mapBaseListRequest} from "../utils/mapBaseListRequest";
import EmptyCampaignsList from "./EmptyCampaignsList";
import EmptySearchList from "./EmptySearchList";

import GetCampaignListRequest = FleetOwnerPortalService.GetCampaignListRequest;
import CampaignFilters = FleetOwnerPortalService.CampaignFilters;
import ColumnType = FleetOwnerPortalService.ColumnType;

interface CampaignsTableProps {
    activeTab: TabName;
    period: ApiPeriod;
    filters: CampaignFilters;
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
}

const fetchFunction = (api: Api, body: GetCampaignListRequest) =>
    api.fleetOwnerPortal.fleetIncentivesGetCampaignList(body);

const CampaignsTable = ({activeTab, filters, period, isLoading, setIsLoading}: CampaignsTableProps) => {
    const intl = useIntl();

    const {data, status, fetch} = useFetch(fetchFunction);

    const apiColumns = useMemo(() => data?.columns || [], [data?.columns]);
    const columns = useTableColumns(apiColumns);
    const {sortedRows, orderBy, handleOrderByChange} = useTableRows(
        apiColumns,
        ColumnType.PERIOD,
        activeTab === TabName.ActiveAndUpcoming ? "ASC" : "DESC",
    );

    useEffect(() => {
        if (fetch) {
            fetch(mapBaseListRequest(intl.locale, period, filters));
        }
    }, [fetch, intl.locale, filters, period]);

    useEffect(() => {
        if (status === FetchStatus.Init || status === FetchStatus.Loading) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [status, setIsLoading]);

    const renderEmptyPlaceholder = useCallback(() => {
        if (areAllFilterValuesDefault(activeTab, filters) && isPeriodDefault(activeTab, period)) {
            return <EmptyCampaignsList />;
        }

        return <EmptySearchList />;
    }, [activeTab, filters, period]);

    if (status === FetchStatus.Error) {
        return <ErrorView type={ErrorViewType.SomethingWentWrong} />;
    }

    if (!data) {
        return <LoadingSpinner show />;
    }

    return (
        <FixedHeaderTable
            isLoading={isLoading}
            columns={columns}
            items={sortedRows}
            orderBy={orderBy}
            onOrderByChange={handleOrderByChange}
            renderEmptyPlaceholder={renderEmptyPlaceholder}
            aria-label="Campaigns list table"
        />
    );
};

export default CampaignsTable;
