import {DocumentsList} from "common/components/document/DocumentsList";
import {DocumentOwnerType, useDocumentsList} from "common/components/document/useDocumentsList";

import {VehicleMarketplaceService} from "@bolteu/bolt-server-api-fleet-owner-portal";

export function ApplicationDriverDocuments({
    application,
}: {
    application: VehicleMarketplaceService.ApplicationWithPartnerAndListingDetails;
}) {
    const {documentsListProps} = useDocumentsList({
        areDocumentsEnabled: true,
        owner: {id: application?.applicant_id, type: DocumentOwnerType.Driver},
        isUploadAllowed: false,
    });

    return <div>{documentsListProps && <DocumentsList {...documentsListProps} />}</div>;
}
