import {UnreachableCode} from "@fleet/common/utils/UnreachableCode";

import {FleetPortalOrderService} from "@bolteu/bolt-server-api-fleet-owner-portal";
import {
    Airplane,
    Basket,
    Boat,
    Building,
    Bus,
    Business,
    Camera,
    Campground,
    Carsharing,
    Cash,
    Company,
    Eco,
    Food,
    Gym,
    Heart,
    History,
    Medical,
    Metro,
    NightLife,
    Park,
    ParkingSpot,
    Pin,
    ShoppingBag,
    Spa,
    Stadium,
    Star,
    Store,
    TouristAttraction,
    Train,
    Vehicle,
    Zoo,
} from "@bolteu/kalep-react-icons";

import SuggestionImage = FleetPortalOrderService.SuggestionImage;

export const getIconFromImageIdentifier = (imageIdentifier?: SuggestionImage) => {
    const className = "text-secondary";
    switch (imageIdentifier) {
        case SuggestionImage.HOME:
        case SuggestionImage.WORK:
        case SuggestionImage.CALENDAR:
        case SuggestionImage.HOME_V1:
        case SuggestionImage.WORK_V1:
        case SuggestionImage.DEFAULT:
        case SuggestionImage.DEFAULT_V1:
        case SuggestionImage.PIN:
        case undefined:
            return <Pin className={className} />;
        case SuggestionImage.NATURAL:
            return <Park className={className} />;
        case SuggestionImage.BOLT_DRIVE:
            return <Carsharing className={className} />;
        case SuggestionImage.HISTORY_V1:
        case SuggestionImage.HISTORY:
            return <History className={className} />;
        case SuggestionImage.AIRPORT:
            return <Airplane className={className} />;
        case SuggestionImage.EDUCATION:
        case SuggestionImage.GOVERNMENT:
        case SuggestionImage.BUILDING:
            return <Building className={className} />;
        case SuggestionImage.FOOD:
            return <Food className={className} />;
        case SuggestionImage.HEART:
            return <Heart className={className} />;
        case SuggestionImage.HEALTH:
            return <Medical className={className} />;
        case SuggestionImage.NIGHTLIFE:
            return <NightLife className={className} />;
        case SuggestionImage.BUSINESS:
            return <Business className={className} />;
        case SuggestionImage.ECO:
            return <Eco className={className} />;
        case SuggestionImage.ZOO:
            return <Zoo className={className} />;
        case SuggestionImage.GYM:
            return <Gym className={className} />;
        case SuggestionImage.SPA:
            return <Spa className={className} />;
        case SuggestionImage.STAR:
            return <Star className={className} />;
        case SuggestionImage.STADIUM:
            return <Stadium className={className} />;
        case SuggestionImage.CAMPGROUND:
            return <Campground className={className} />;
        case SuggestionImage.TOURIST_ATTRACTION:
            return <TouristAttraction className={className} />;
        case SuggestionImage.PARKING_SPOT:
            return <ParkingSpot className={className} />;
        case SuggestionImage.COMPANY:
            return <Company className={className} />;
        case SuggestionImage.BOAT:
            return <Boat className={className} />;
        case SuggestionImage.METRO:
            return <Metro className={className} />;
        case SuggestionImage.TRAIN:
            return <Train className={className} />;
        case SuggestionImage.BUS:
            return <Bus className={className} />;
        case SuggestionImage.VEHICLE:
            return <Vehicle className={className} />;
        case SuggestionImage.GROCERIES:
            return <Basket className={className} />;
        case SuggestionImage.STORE:
            return <Store className={className} />;
        case SuggestionImage.RETAIL:
            return <ShoppingBag className={className} />;
        case SuggestionImage.CASH:
            return <Cash className={className} />;
        case SuggestionImage.CAMERA:
            return <Camera className={className} />;
        default:
            return UnreachableCode.never(imageIdentifier, <Pin className={className} />);
    }
};
