import {useCallback, useState} from "react";

import {BBox, GoogleMapApi, Map, Maps, Ref} from "./types";

export const useMapCommon = () => {
    const [googleMapsApi, setGoogleMapsApi] = useState<GoogleMapApi | undefined>();
    const [bounds, setBounds] = useState<BBox>([
        -84.822265625, 15.129091742705643, 110.82226562499999, 68.20980341203649,
    ]);

    const onGoogleApiLoaded = useCallback(({map, maps, ref}: {map: Map; maps: Maps; ref: Ref}) => {
        setGoogleMapsApi({
            map,
            maps,
            ref,
        });
    }, []);

    const getBbox = useCallback((): BBox => {
        const mapBounds = googleMapsApi?.map.getBounds();
        if (!mapBounds) {
            return [0, 0, 0, 0];
        }
        const sw = mapBounds.getSouthWest();
        const ne = mapBounds.getNorthEast();
        return [sw.lng(), sw.lat(), ne.lng(), ne.lat()];
    }, [googleMapsApi?.map]);

    return {bounds, setBounds, googleMapsApi, onGoogleApiLoaded, getBbox};
};
