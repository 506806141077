import {useCallback, useEffect, useState} from "react";

import {GoogleMaps} from "common/geo/components/google-maps/GoogleMaps";
import {MapHelper} from "common/geo/components/google-maps/MapHelper";
import {GoogleMapApi} from "common/geo/components/google-maps/types";

import {FleetOwnerPortalService} from "@bolteu/bolt-server-api-fleet-owner-portal";

interface PolygonMapProps {
    polygon: FleetOwnerPortalService.LatLng[];
}

const PolygonMap = ({polygon}: PolygonMapProps) => {
    const [googleMapsApi, setGoogleMapsApi] = useState<GoogleMapApi | null>(null);

    const onGoogleApiLoaded = useCallback(
        ({maps, map, ref}: GoogleMapApi) => {
            const mapPolygon = new maps.Polygon({
                paths: polygon,
                strokeColor: "#259E65",
                strokeOpacity: 1,
                strokeWeight: 3,
                fillColor: "#259E65",
                fillOpacity: 0.25,
            });
            mapPolygon.setMap(map);

            setGoogleMapsApi({maps, map, ref});
        },
        [polygon],
    );

    useEffect(() => {
        if (googleMapsApi) {
            MapHelper.fitBounds(googleMapsApi, polygon);
        }
    }, [googleMapsApi, polygon]);

    return (
        <div className="mt-3 h-64">
            <GoogleMaps
                options={{
                    mapTypeId: "roadmap",
                    clickableIcons: false,
                    streetViewControl: false,
                    fullscreenControl: false,
                }}
                defaultCenter={{lat: 0, lng: 0}}
                defaultZoom={0}
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={onGoogleApiLoaded}
            />
        </div>
    );
};

export default PolygonMap;
