import {useTopBarHeightTailwindConfig} from "common/hooks/useTopBarHeightTailwindConfig";

import {Table, TableItem, TableProps} from "@bolteu/kalep-table-react";

import {getOverrideStyledKalepComponent} from ".";

export function FixedHeaderTable<Entity extends TableItem>(props: TableProps<Entity>) {
    const {tableFixedHeaderClass: TABLE_FIXED_HEADER_CLASSNAME} = useTopBarHeightTailwindConfig();
    const StyledTable = getOverrideStyledKalepComponent<TableProps<Entity>>(Table, TABLE_FIXED_HEADER_CLASSNAME);
    return <StyledTable {...props} />;
}
