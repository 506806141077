import {useCallback, useContext} from "react";

import {CompanyType, Fleet} from "common/constants/types";
import {CompanySwitchType, useCompanySelection} from "common/hooks/useCompanySelection";
import {ResponseCodes} from "common/ResponseCodes";
import {ApiContextProvider} from "common/services/apiProvider";
import {isServerError} from "common/util/isErrorType";
import equal from "fast-deep-equal/es6";

import {FleetOwnerService} from "@bolteu/bolt-server-api-fleet-owner-portal";

import {ApplicationContextProvider} from "../../app/appStateProvider";
import {AccountContextProvider, AccountState} from "../accountStateProvider";

const useAccountState = () => {
    const accountState = useContext(AccountContextProvider);
    const appState = useContext(ApplicationContextProvider);
    const api = useContext(ApiContextProvider);
    const onSelectCompany = useCompanySelection();

    const setProfileFromApi = (as: AccountState, user: FleetOwnerService.GetProfileResponse) => {
        as.setProfile({
            id: user.id,
            email: user.email,
            name: `${user.first_name} ${user.last_name}`,
            firstName: user.first_name,
            lastName: user.last_name,
            fleets: user.companies as Fleet[],
            holdingFleets: user.holding_fleets ?? [],
            companyRegistrationHash: user.company_registration_hash,
        });
    };

    const refreshProfile = useCallback(async () => {
        if (api) {
            try {
                const user = await api.fleetOwner.portalGetProfile();

                if (user?.get_profile_polling_interval) {
                    setTimeout(refreshProfile, user.get_profile_polling_interval * 1000);
                }

                if (
                    user &&
                    (!equal(user.companies, accountState.profile?.fleets) ||
                        !equal(user.holding_fleets, accountState.profile?.holdingFleets))
                ) {
                    setProfileFromApi(accountState, user);

                    const fleetCompany = user.companies.find(
                        (company) => company.id === accountState.selectedCompany?.company.id,
                    ) as Fleet;
                    if (fleetCompany) {
                        onSelectCompany(fleetCompany, CompanyType.FLEET_COMPANY, CompanySwitchType.STATE_SWITCH_ONLY);
                    }
                }
                return user;
            } catch (error) {
                if (isServerError(error)) {
                    if (
                        error.response.code === ResponseCodes.FLEET_OWNER_NOT_FOUND ||
                        error.response.code === ResponseCodes.FLEET_OWNER_NOT_APPROVED
                    ) {
                        appState.setIsAppReady(true);
                    }
                }
            }
        }

        return null;
    }, [accountState, api, appState, onSelectCompany]);

    return {setProfileFromApi, refreshProfile};
};

export {useAccountState};
