import {FleetRoute} from "common/constants/routes";
import {useI18n} from "common/hooks/useI18n";

export function useBreadcrumbs(fleetId?: number) {
    const {i18n} = useI18n();

    return [
        {
            href: `/${fleetId}/${FleetRoute.VEHICLES}`,
            title: i18n("auth.app.sidebar.vehicles"),
        },
        {
            href: "",
            title: i18n("auth.app.sidebar.car-rentals_v2"),
        },
    ];
}
