import {FC, useCallback, useMemo} from "react";
import {Link} from "react-router-dom";

import CloseButton from "common/components/CloseButton";
import {localStorageService} from "common/services/LocalStorageService";
import {useLocalStorage} from "@fleet/common/hooks/useLocalStorage";

import {Typography} from "@bolteu/kalep-react";

interface FixedTooltipSettings {
    fixed_tooltip_visible?: boolean;
}

interface TooltipProps {
    targetId: string;
    featureId: string;
    title: string;
    content: string;
    buttonTitle: string;
    linkTo?: string;
}

const FixedTooltip: FC<TooltipProps> = ({title, content, buttonTitle, featureId, linkTo, targetId}) => {
    const [settings, setSettings] = useLocalStorage<FixedTooltipSettings>(
        localStorageService.getFixedTooltipSettingsKey(featureId),
        {fixed_tooltip_visible: true},
    );

    const setTooltipVisibility = useCallback(
        (isVisible: boolean) => {
            setSettings((prevSettings) => ({
                ...prevSettings,
                fixed_tooltip_visible: isVisible,
            }));
        },
        [setSettings],
    );

    const closeTooltip = useCallback(() => {
        setTooltipVisibility(false);
    }, [setTooltipVisibility]);

    const targetElement = useMemo(() => document.querySelector(`.${targetId}`), [targetId]);
    const targetRect = useMemo(() => targetElement?.getBoundingClientRect() ?? null, [targetElement]);

    if (!settings.fixed_tooltip_visible || !targetElement || !targetRect) {
        return null;
    }

    const tooltipStyles = {
        top: `${targetRect.bottom + window.scrollY + 15}px`,
        left: `${targetRect.left - 18}px`,
        boxShadow: "0px 2px 15px rgba(0, 0, 0, 0.2)",
    };

    return (
        <div className="absolute max-w-[300px] rounded-md bg-white p-4" style={tooltipStyles}>
            <div className="absolute top-[-6px] left-[20px] h-3 w-3 rotate-45 bg-white" />
            <div className="flex flex-row justify-between">
                <div className="mt-1">
                    <Typography variant="body-primary" fontWeight="semibold" fontSize="text-lg">
                        {title}
                    </Typography>
                </div>
                <CloseButton onClick={closeTooltip} light />
            </div>
            <div className="flex flex-col gap-3">
                <Typography variant="body-primary" fontSize="text-sm">
                    {content}
                </Typography>
                {linkTo && (
                    <Link className="text-action-primary text-left font-semibold" to={linkTo} onClick={closeTooltip}>
                        {buttonTitle}
                    </Link>
                )}
            </div>
        </div>
    );
};

export default FixedTooltip;
